import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";

////placement images

export const addNewPlacementImage = async (placementId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/bch/placements/${placementId}/placementImages`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deletePlacementImage = async (placementImageId) => {
  try {
    const responce = await myaxios.delete(
      `/bch/placementImages/${placementImageId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updatPlacemetImage = async (placementImageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/bch/placementImages/${placementImageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllPlacementImage = async (placementId) => {
  try {
    const responce = await myaxios.get(
      `/bch/placements/${placementId}/placementImages`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

////Recorded  Subject

export const addRecordedVideoSubject = async (batchCourseId, subjectData) => {
  try {
    const responce = await myaxios.post(
      `/bch/batchCourses/${batchCourseId}/recordedVideoSubjects`,
      subjectData,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteRecordedVideoSubject = async (recordedVideoSubjectsId) => {
  try {
    const responce = await myaxios.delete(
      `/bch/recordedVideoSubjects/${recordedVideoSubjectsId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateRecordedVideoSubject = async (
  recordedVideoSubjectsId,
  subjectData
) => {
  try {
    const responce = await myaxios.put(
      `/bch/recordedVideoSubjects/${recordedVideoSubjectsId}`,
      subjectData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllRecordedSubjectVideo = async (batchCourseId) => {
  try {
    const responce = await myaxios.get(
      `/bch/batchCourses/${batchCourseId}/recordedVideoSubjects`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//// recoded subject images

export const addRecordedSubjectImages = async (subjectId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/bch/recordedVideoSubjects/${subjectId}/images`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteRecodedSubjectImages = async (imageId) => {
  try {
    const responce = await myaxios.delete(
      `/bch/recordedVideoSubjects/images/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateRecodedSubjectImages = async (imageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/bch/recordedVideoSubjects/images/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllRecodedSubjectImages = async (subjectId) => {
  try {
    const responce = await myaxios.get(
      `/bch/recordedVideoSubjects/${subjectId}/images`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
//// recoded Subject video

export const addRecodedSubjectVideo = async (
  recordedVideoSubjectsId,
  videoData
) => {
  try {
    const responce = await myaxios.post(
      `/bch/recordedVideoSubjects/${recordedVideoSubjectsId}/recordedVideos`,
      videoData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteRecodedSubjectVideo = async (recordedVideoId) => {
  try {
    const responce = await myaxios.delete(
      `/bch/recordedVideos/${recordedVideoId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updatedRecodedSubjectVideo = async (
  recordedVideoId,
  videoData
) => {
  try {
    const responce = await myaxios.put(
      `/bch/recordedVideos/${recordedVideoId}`,
      videoData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getRecodedSubjectVideos = async (recordedVideoSubjectsId) => {
  try {
    const responce = await myaxios.get(
      `/bch/recordedVideoSubjects/${recordedVideoSubjectsId}/recordedVideos`,
      {
        headers: authHeader(),
      }
    );
    console.log(responce.data);
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

///

export const addNewBatch = async () => {
  try {
    const responce = await myaxios.get(`/bch/newBatchs`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};
export const getAllBatchs = async () => {
  try {
    const responce = await myaxios.get("/bch/batchs", {
      headers: authHeader(),
    });
    console.log(responce);
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getBatchDetails = async () => {
  try {
    const responce = await myaxios.get(`/bch/batchs/details`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {}
};

export const deleteBatch = async (batch_Id) => {
  try {
    const responce = await myaxios.delete(`/bch/batchs/${batch_Id}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const addNewSubBatch = async (batchId, payload) => {
  try {
    const responce = await myaxios.post(
      `/bch/batchs/${batchId}/subBatchs`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};
export const getAllSubBatchs = async (batchId) => {
  try {
    const responce = await myaxios.get(`/bch/batchs/${batchId}/subBatchs`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteSubBatch = async (subBatchId) => {
  try {
    const responce = await myaxios.delete(`/bch/subBatchs/${subBatchId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const addNewBatchCourse = async (subBatchId, courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/bch/subBatchs/${subBatchId}/batchCourses/${courseTypeId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    console.log(responce);
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};
export const getAllBatchCourse = async (subBatchId) => {
  try {
    const responce = await myaxios.get(
      `/bch/subBatch/${subBatchId}/batchCourses`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteBatchCourse = async (batchCourseId) => {
  try {
    const responce = await myaxios.delete(
      `/bch/batchCourses/${batchCourseId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateBatchCourseDetails = async (batchCourseId, payload) => {
  try {
    const responce = await myaxios.put(
      `/bch/batchCourses/${batchCourseId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const setBatchCourse_StatusHandler = async (batchCourseId, payload) => {
  try {
    const responce = await myaxios.put(
      `/bch/batchCourses/${batchCourseId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

/////batch course subject

export const addNewSubjectToBatchCourse = async (
  batchCourseId,
  teacherId,
  payload
) => {
  try {
    const responce = await myaxios.post(
      `/bch/batchCourses/${batchCourseId}/subjects/${teacherId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllBatchCourseSubject = async (batchCourseId) => {
  try {
    const responce = await myaxios.get(
      `/bch/batchCourses/${batchCourseId}/subjects`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const setPermitionStatusSubject = async (
  batchCourseSubjectId,
  payload
) => {
  try {
    const responce = await myaxios.put(
      `/bch/batchCourseSubject/${batchCourseSubjectId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteBatchCourseSubject = async (batchCourseSubjectId) => {
  try {
    const responce = await myaxios.delete(
      `/bch/batchCourseSubject/${batchCourseSubjectId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

///// batch course student

export const getBatchCourseStudents = async (batchCourseId) => {
  try {
    const responce = await myaxios.get(
      `/bch/batchCourses/${batchCourseId}/students`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const setStudent_PermitionStatus = async (
  batchCourseStudentId,
  payload
) => {
  try {
    const responce = await myaxios.put(
      `/bch/batchCourseStudent/${batchCourseStudentId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteBatchCourseStudent = async (batchCourseStudentId) => {
  try {
    const responce = await myaxios.delete(
      `/bch/batchCourseStudent/${batchCourseStudentId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const addNewPlacement = async (batchCourseId, payload) => {
  try {
    const responce = await myaxios.post(
      `/bch/batchCourses/${batchCourseId}/Placements`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllPlacement = async (batchCourseId) => {
  try {
    const responce = await myaxios.get(
      `/bch/batchCourses/${batchCourseId}/Placements`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const deletePlacement = async (placementId) => {
  try {
    const responce = await myaxios.delete(`/bch/placements/${placementId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updatePlacementData = async (placementId, payload) => {
  try {
    const responce = await myaxios.put(
      `/bch/placements/${placementId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};
