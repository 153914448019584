import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  batchs: [],
  subBatchs: [],
  batchCourses: [],
  batchCourseSubjects: [],
  batchCourseStudebnts: [],
  batchCoursePlacements: [],
  batchAllSubjects: [],
  batchAllTeachers: [],
  allPlacement: [],
  batchRecordedSubjects: [],
  batchRecordedSubjectsImages: [],
  recodedSubjectVideo: [],
};

const BatchReducer = createSlice({
  name: "batchData",
  initialState,
  reducers: {
    addBatchesToStore: (state, action) => {
      state.batchs = action.payload;
    },
    addsubBatchsToStore: (state, action) => {
      state.subBatchs = action.payload;
    },
    addBatchCoursesToStore: (state, action) => {
      state.batchCourses = action.payload;
    },
    addBatchCourseSubjectsToStore: (state, action) => {
      state.batchCourseSubjects = action.payload;
    },
    addBatchCourseStudebntsToStore: (state, action) => {
      state.batchCourseStudebnts = action.payload;
    },
    addBatchCoursePlacementsToStore: (state, action) => {
      state.batchCoursePlacements = action.payload;
    },
    addBatchAllSubjectsToStore: (state, action) => {
      state.batchAllSubjects = action.payload;
    },
    addBatchAllTeachersToStore: (state, action) => {
      state.batchAllTeachers = action.payload;
    },
    addallPlacement: (state, action) => {
      state.allPlacement = action.payload;
    },
    addbatchRecordedSubjects: (state, action) => {
      state.batchRecordedSubjects = action.payload;
    },
    addrecodedSubjectVideo: (state, action) => {
      state.recodedSubjectVideo = action.payload;
    },
    addbatchRecordedSubjectsImages: (state, action) => {
      state.batchRecordedSubjectsImages = action.payload;
    },
  },
});

export const {
  addbatchRecordedSubjects,
  addrecodedSubjectVideo,
  addBatchesToStore,
  addallPlacement,
  addsubBatchsToStore,
  addBatchCoursesToStore,
  addBatchCourseSubjectsToStore,
  addBatchCourseStudebntsToStore,
  addBatchCoursePlacementsToStore,
  addBatchAllSubjectsToStore,
  addBatchAllTeachersToStore,
  addbatchRecordedSubjectsImages,
} = BatchReducer.actions;

export default BatchReducer.reducer;
