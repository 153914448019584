import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { addallPlacement } from "../../../../../../Redux/Reduuces/BatchReducer";
import { useSelector, useDispatch } from "react-redux";
import {
  deletePlacement,
  getAllPlacement,
  updatePlacementData,
} from "../../../../../../Redux/ActionCompo/BatchActions";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
} from "@mui/material";
import "../../../../../../Genaral.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
const Placements = () => {
  const { batchId, subBatchId, batchCourseId } = useParams();

  const allPlacement = useSelector((state) => state.batchData.allPlacement);
  const dispatch = useDispatch();

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  useEffect(() => {
    const getData = async () => {
      const data = await getAllPlacement(batchCourseId);
      dispatch(addallPlacement(data));
    };
    getData();
  }, [batchCourseId, dispatch]);

  const handleUpdateRequest = async (id, type, status) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the recent placement?"
      );
    } else if (type === "ACTIVE") {
      setUpdateType("ACTIVE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `Are you sure you want to   ${
          status ? "deactive" : "active"
        } recent place ment?`
      );
    } else if (type === "LINKEDIN") {
      setUpdateType("LINKEDIN");
      setOpenDialog(true);
      setUpdateAlertMessage(`Are you sure you want to  update the link?`);
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deletePlacement(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("recent placement delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ACTIVE") {
      const payload = {
        active_placement: "change",
      };
      const responce = await updatePlacementData(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("details updated successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getAllPlacement(batchCourseId);
    dispatch(addallPlacement(data));
  };

  return (
    <div className="Placements-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All placements</h4>
          <Link to={"addPlacements"} className="link">
            Add New placement
          </Link>
        </div>
      </div>
      <Link
        to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/details`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>
      <TableContainer component={Paper} className="Placements-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>PLACEMENT ID</TableCell>
              <TableCell>COMPANY NAME</TableCell>
              <TableCell>COMPONY INTRUDUCTION</TableCell>
              <TableCell>ACTIVE</TableCell>
              <TableCell>APPLY LINK</TableCell>
              <TableCell>IMAGES</TableCell>
              <TableCell>DELETE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allPlacement &&
              allPlacement?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.placement_Id}</TableCell>
                  <TableCell>{val.company_Name}</TableCell>
                  <TableCell>{val.company_Intruduction}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        handleUpdateRequest(
                          val.placement_Id,
                          "ACTIVE",
                          val.active_placement === "A"
                        );
                      }}
                      variant="contained"
                      size="small"
                      className={`${
                        val.active_placement === "A"
                          ? "Active_status"
                          : "Deactive_satus"
                      }`}
                    >
                      {val.active_placement === "A" ? "ACTIVE" : "DEACTIVE"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        window.open(val.apply_link, "_blank");
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      link
                    </Button>
                  </TableCell>
                  <TableCell>
                    <NavLink to={`${val.placement_Id}/images`}>
                      <Button>view</Button>
                    </NavLink>
                  </TableCell>
                  <TableCell>
                    <span
                      onClick={() => {
                        handleUpdateRequest(val.placement_Id, "DELETE");
                      }}
                      className="delete_button"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};
export default Placements;
