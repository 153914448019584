import React, { useState } from "react";
import { Link } from "react-router-dom";
import { addNewSubject } from "../../Redux/ActionCompo/AdminGenaralActions";
import { Snackbar } from "@mui/material";
const AddNewSubject = () => {
  const [subject_Name, setSubject_Name] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    const newSubject = {
      subjectName: subject_Name,
    };

    const responce = await addNewSubject(newSubject);
    if (responce?.status === true) {
      setSubject_Name("");

      setSuccessMessage("branch added successfully");
      setOpenSnackbar(true);
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ width: "100%" }} className="AddNewSubject-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={"/subjects"} className="link">
            All Subject
          </Link>

          <h4>Add New Subject </h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <label>Enter Enroll Type</label>
              <input
                type="text"
                placeholder="Enroll Type"
                value={subject_Name}
                onChange={(e) => {
                  setSubject_Name(e.target.value);
                }}
              />
              <span>error message</span>
            </div>
            <button type="submit">Add</button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddNewSubject;
