import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { addNewProjects } from "../../../../../../Redux/ActionCompo/CourseAction";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../../../../../../Genaral.css";
import { Snackbar } from "@mui/material";
const AddProjects = () => {
  const [project_Name, setProject_Name] = useState("");
  const [project_Desc, setProject_Desc] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { branchId, courseId, courseTypeId } = useParams();

  const validateForm = () => {
    if (project_Name.trim() === "" || project_Desc.trim() === "") {
      setError("Project Name and Project Desc cannot be empty.");
      return false;
    }
    return true;
  };

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return; // Do not submit if validation fails
    }

    const newProject = {
      project_Name: project_Name,
      project_Desc: project_Desc,
    };

    const responce = await addNewProjects(courseTypeId, newProject);
    if (responce?.status === true) {
      setProject_Name("");
      setProject_Desc("");

      setSuccessMessage("project added successfully");
      setOpenSnackbar(true);
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddProjects-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/projects`}
            className="link"
          >
            All Projects
          </Link>
          <h4>Add New Project</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                fullWidth
                type="text"
                label="Enter Project Name"
                value={project_Name}
                onChange={(e) => setProject_Name(e.target.value)}
                error={!!error}
                helperText={error}
              />
            </div>
            <div className="form-element">
              <TextField
                fullWidth
                multiline
                rows={5}
                label="Enter Project Desc"
                value={project_Desc}
                onChange={(e) => setProject_Desc(e.target.value)}
                error={!!error}
                helperText={error}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={5000} // Auto-hide after 5 seconds
          onClose={handleCloseSnackbar}
          message={successMessage}
        />
      </div>
    </div>
  );
};

export default AddProjects;
