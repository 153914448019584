import React from "react";
import "./CourseTypeSingleVideo.scss";
import { Button } from "@mui/material";
import {
  deleteCourseTypeVideos,
  updateCourseTypeVideos,
} from "../../../Redux/ActionCompo/CourseAction";
import "../../../Genaral.css";
import ReactPlayer from "react-player";
const CourseTypeSingleVideo = (props) => {
  const videoData = props.videoData;

  const updateCourseTypeVideoData = async (videoId) => {
    const data = {
      active_status: "change",
    };

    const responce = await updateCourseTypeVideos(videoId, data);

    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const deleteCourseTypeVideoData = async (videoId) => {
    const responce = await deleteCourseTypeVideos(videoId);

    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="video-main-components">
      <div className="video-main-components-image-content">
        <ReactPlayer url={videoData.video_URL} width={"100%"} height={"100%"} />
        {/* <video src={} controls></video> */}
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={videoData?.active_Status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => {
            updateCourseTypeVideoData(videoData.courseType_Video_Id);
          }}
        >
          {videoData.active_Status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteCourseTypeVideoData(videoData.courseType_Video_Id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default CourseTypeSingleVideo;
