import { myaxios } from "../ApiBase";
import authHeader from "./auth-header";

export const addJuzzItJobDomain = async (jobDomainData) => {
  try {
    const response = await myaxios.post(`/job/jobDomains`, jobDomainData, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const deleteJuzzIdJobDomain = async (jobDomainId) => {
  try {
    const response = await myaxios.delete(`/job/jobDomain/${jobDomainId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateJobDomainData = async (jobDomainId, jobDomainData) => {
  try {
    const response = await myaxios.put(
      `/job/jobDomain/${jobDomainId}`,
      jobDomainData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const getJobDomainData = async () => {
  try {
    const response = await myaxios.get(`/job/jobDomains`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

//// job domain jobs

export const addJobDomainJob = async (jobDomainId, jobData) => {
  try {
    const response = await myaxios.post(
      `/job/jobDomain/${jobDomainId}/jobs`,
      jobData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const deleteJobDomainJob = async (jobId) => {
  try {
    const response = await myaxios.delete(`/job/jobs/${jobId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateJobDomainJob = async (jobId, jobData) => {
  try {
    const response = await myaxios.put(`/job/jobs/${jobId}`, jobData, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const getJobDomainJobData = async (jobDomainId) => {
  try {
    const response = await myaxios.get(`/job/jobDomain/${jobDomainId}/jobs`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

/// job domain job image

export const addJobDomainJobImage = async (jobId, jobImage) => {
  try {
    const response = await myaxios.post(
      `/job/domainJob/${jobId}/images`,
      jobImage,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const deleteJobDomainImage = async (imageId) => {
  try {
    const response = await myaxios.delete(`/job/jobImages/${imageId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateJobDomainImage = async (imageId, imageData) => {
  try {
    const response = await myaxios.put(`/job/jobImages/${imageId}`, imageData, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {}
};

export const getJobDomainImage = async (jobId) => {
  try {
    const response = await myaxios.get(`/job/domainJob/${jobId}/images`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

///// job domain job location

export const addJobDomainJobLocation = async (jobId, LocationData) => {
  try {
    const response = await myaxios.post(
      `/job/domainJob/${jobId}/locations`,
      LocationData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const deleteJobDomainLocation = async (jobLocatinId) => {
  try {
    const response = await myaxios.delete(`/job/jobLocations/${jobLocatinId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateJobDomainLocation = async (jobLocatinId, LocationData) => {
  try {
    const response = await myaxios.put(
      `/job/jobLocations/${jobLocatinId}`,
      LocationData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const getJobDomainLocation = async (jobId) => {
  try {
    const response = await myaxios.get(`/job/domainJob/${jobId}/locations`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

/// job domain job RequiredQualifications

export const addJobDomainJobRequiredQualifications = async (
  jobId,
  RequiredQualificationsData
) => {
  try {
    const response = await myaxios.post(
      `/job/domainJob/${jobId}/requiredQualifications`,
      RequiredQualificationsData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const deleteJobDomainRequiredQualifications = async (
  qualificationId
) => {
  try {
    const response = await myaxios.delete(
      `/job/requiredQualifications/${qualificationId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateJobDomainRequiredQualifications = async (
  qualificationId,
  RequiredQualificationsData
) => {
  try {
    const response = await myaxios.put(
      `/job/requiredQualifications/${qualificationId}`,
      RequiredQualificationsData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const getJobDomainRequiredQualifications = async (jobId) => {
  try {
    const response = await myaxios.get(
      `/job/domainJob/${jobId}/requiredQualifications`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// job domain job Requirements

export const addJobDomainJobRequirements = async (jobId, RequirementsData) => {
  try {
    const response = await myaxios.post(
      `/job/domainJob/${jobId}/requirements`,
      RequirementsData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const deleteJobDomainRequirements = async (requirementId) => {
  try {
    const response = await myaxios.delete(
      `/job/requirements/${requirementId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateJobDomainRequirements = async (
  requirementId,
  RequirementsData
) => {
  try {
    const response = await myaxios.put(
      `/job/requirements/${requirementId}`,
      RequirementsData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const getJobDomainRequirements = async (jobId) => {
  try {
    const response = await myaxios.get(`/job/domainJob/${jobId}/requirements`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

/// job domain job Responcibilitys

export const addJobDomainJobResponcibilitys = async (
  jobId,
  ResponcibilitysData
) => {
  try {
    const response = await myaxios.post(
      `/job/domainJob/${jobId}/responcibilitys`,
      ResponcibilitysData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const deleteJobDomainResponcibilitys = async (responcibilityId) => {
  try {
    const response = await myaxios.delete(
      `/job/responcibilitys/${responcibilityId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateJobDomainResponcibilitys = async (
  responcibilityId,
  ResponcibilitysData
) => {
  try {
    const response = await myaxios.put(
      `/job/responcibilitys/${responcibilityId}`,
      ResponcibilitysData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const getJobDomainResponcibilitys = async (jobId) => {
  try {
    const response = await myaxios.get(
      `/job/domainJob/${jobId}/responcibilitys`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// job domain job Applicants

export const addJobDomainJobApplicants = async (jobId, ApplicantsData) => {
  try {
    const response = await myaxios.post(
      `/job/domainJob/${jobId}/applicants`,
      ApplicantsData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const deleteJobDomainApplicants = async (applicantId) => {
  try {
    const response = await myaxios.delete(`/job/applicants/${applicantId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

// export const updateJobDomainApplicants = async () => {
//   try {
//     const response = await myaxios.put(``, ApplicantsData, {
//       headers: authHeader(),
//     });

//     return response.data;
//   } catch (error) {}
// };

export const getJobDomainApplicants = async (jobId) => {
  try {
    const response = await myaxios.get(`/job/domainJob/${jobId}/applicants`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

/// job domain jon SkillRequireds

export const addJobDomainJobSkillRequireds = async (
  jobId,
  SkillRequiredsData
) => {
  try {
    const response = await myaxios.post(
      `/job/domainJob/${jobId}/skillRequireds`,
      SkillRequiredsData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const deleteJobDomainSkillRequireds = async (skillId) => {
  try {
    const response = await myaxios.delete(`/job/skillRequireds/${skillId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateJobDomainSkillRequireds = async (
  skillId,
  SkillRequiredsData
) => {
  try {
    const response = await myaxios.put(
      `/job/skillRequireds/${skillId}`,
      SkillRequiredsData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {}
};

export const getJobDomainSkillRequireds = async (jobId) => {
  try {
    const response = await myaxios.get(
      `/job/domainJob/${jobId}/skillRequireds`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};
