import React, { useEffect, useState } from "react";
import "./Events.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  deleteEventData,
  getEventData,
  updateEventData,
} from "../../Redux/ActionCompo/EventActions";
import { useDispatch, useSelector } from "react-redux";
import { setEvents } from "../../Redux/Reduuces/EventReducer";
const Events = () => {
  const dispatch = useDispatch();

  const events = useSelector((state) => state.EventData.events);
  const [order, setOrder] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const data = await getEventData();
      dispatch(setEvents(data));
    };
    getData();
  }, [dispatch]);

  const updateorderData = async (EVENT_ID) => {
    const data = {
      order: Number(order),
    };

    updateEventData(EVENT_ID, data);
  };

  const updateActiveStatusData = async (EVENT_ID) => {
    const data = {
      ACTIVE_STATUS: "change",
    };

    updateEventData(EVENT_ID, data);
  };

  const handleDeleteEvent = async (id) => {
    deleteEventData(id);
  };

  const navigate = useNavigate();

  return (
    <div className="Events-main-container" style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4> All event</h4>
          <Link to={`add`} className="link">
            Add New event
          </Link>
        </div>
      </div>

      <TextField
        variant="outlined"
        value={order}
        onChange={(e) => {
          setOrder(e.target.value);
        }}
      />

      <TableContainer style={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>SI/NO</TableCell>
            <TableCell>Event Name</TableCell>
            <TableCell>Event order</TableCell>
            <TableCell>update</TableCell>
            <TableCell>Active Status</TableCell>
            <TableCell>delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events &&
            events.length > 0 &&
            events?.map((val, key) => (
              <TableRow key={key}>
                <TableCell>{key + 1}</TableCell>
                <TableCell
                  onClick={() => {
                    navigate(`/Events/${val.EVENT_ID}/EventType`);
                  }}
                >
                  {val.EVANT_NAME}
                </TableCell>
                <TableCell>{val.ORDER}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => {
                      updateorderData(val.EVENT_ID);
                    }}
                  >
                    update
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color={`${val.ACTIVE_STATUS === "A" ? "success" : "error"}`}
                    onClick={() => {
                      updateActiveStatusData(val.EVENT_ID);
                    }}
                  >
                    {val.ACTIVE_STATUS === "A" ? "active" : "DeActive"}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleDeleteEvent(val.EVENT_ID);
                    }}
                  >
                    delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TableContainer>
    </div>
  );
};
export default Events;
