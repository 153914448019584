import React from "react";
import { Outlet } from "react-router-dom";
import "./RootCourse.scss";
const RootCourse = () => {
  return (
    <div className="rootCourse-main-container">
      <div className="rootCourse-container">courses</div>
      <Outlet />
    </div>
  );
};

export default RootCourse;
