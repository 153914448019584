import React from "react";
import { Button } from "@mui/material";
import {
  deleteRecentPlacementCertificate,
  updateRecentPlacementCertificate,
} from "../../../../../Redux/ActionCompo/RecentPlacementsAction";
const SingleRecentPlacementCertificate = (props) => {
  const imageData = props.ToolImage;
  console.log(imageData);
  const deleteToolImage = async (imageId) => {
    const responce = await deleteRecentPlacementCertificate(imageId);
    console.log(responce);
  };

  const updateToolImageData = async (imageId) => {
    const data = {
      active_status: "change",
    };

    const responce = await updateRecentPlacementCertificate(imageId, data);
    console.log(responce);
  };
  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData.certificate_URL} alt="dsad"></img>
      </div>
      <span>{imageData?.certificate_Name}</span>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_Status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => {
            updateToolImageData(imageData.certificate_Id);
          }}
        >
          {imageData.active_Status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteToolImage(imageData.certificate_Id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default SingleRecentPlacementCertificate;
