import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CourseTypeSingleImage from "./Components/CourseTypeSingleImage";
import {
  addNewCourseTypeBagroundImages,
  addNewCourseTypeImage,
  getAllCourseTypeBagroundImages,
  getAllCourseTypeImages,
} from "../../../../Redux/ActionCompo/CourseAction";
import { uploadImage } from "../../../../Redux/ActionCompo/CloudenarowImageUplode";
import { useDispatch, useSelector } from "react-redux";
import {
  addcourseTypeBagroundImages,
  addcourseTypeImage,
} from "../../../../Redux/Reduuces/CourceReducer";
import CourseTypeSingleBagroundImage from "./Components/CourseTypeSingleBagroundImage";
import "../../../../Genaral.css";
import { Snackbar } from "@mui/material";
const CourseTypeImage = () => {
  const { courseTypeId } = useParams();
  const [file, setFile] = useState(null);
  const [bacgroundIMAGE, setbacgroundIMAGE] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const courseTypeImageData = useSelector(
    (state) => state.courseData.courseTypeImage
  );
  const courseTypeBagroundImageData = useSelector(
    (state) => state.courseData.courseTypeBagroundImages
  );

  console.log(courseTypeImageData);

  const dispatch = useDispatch();

  const handleAddImage = async (event) => {
    event.preventDefault();
    const data = await uploadImage(file);
    if (data) {
      const newImageData = {
        image_id: "dasdss",
        image_URL: data?.secure_url,
      };
      const response = await addNewCourseTypeImage(courseTypeId, newImageData);
      if (response?.status === true) {
        setSuccessMessage("branch added successfully");
        setOpenSnackbar(true);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getAllCourseTypeImages(courseTypeId);
      dispatch(addcourseTypeImage(data));
    };
    getData();
  }, [courseTypeId, dispatch]);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllCourseTypeBagroundImages(courseTypeId);
      dispatch(addcourseTypeBagroundImages(data));
    };
    getData();
  }, [courseTypeId, dispatch]);

  const handleAddBagroundImage = async (event) => {
    event.preventDefault();
    const data = await uploadImage(bacgroundIMAGE);
    if (data) {
      const newImageData = {
        id: "damjmdsjscas",
        image_URL: data?.secure_url,
      };
      console.log(newImageData);
      const response = await addNewCourseTypeBagroundImages(
        courseTypeId,
        newImageData
      );
      if (response?.status === true) {
        setFile(null);
        setSuccessMessage("branch added successfully");
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="CourseTypeImage-main-container">
      <div className="CourseTypeImage-image-main-container">
        <div className="IMAGE-FORM-MAIN-CONTAINER">
          <div className="IMAGE-FORM-CONTAINER">
            <form onSubmit={handleAddImage}>
              <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              ></input>
              <button type="submit">add file</button>
            </form>
          </div>
        </div>

        <div className="ALL-IMAGE-MAIN-CONTAINER">
          <div className="ALL-IMAGE-CONTAINER-heading">
            <h1>All Images</h1>
          </div>
          <div className="ALL-IMAGE-CONTAINER">
            {courseTypeImageData &&
              courseTypeImageData.map((val, key) => {
                return (
                  <CourseTypeSingleImage courseTypeImage={val} key={key} />
                );
              })}
          </div>
        </div>
      </div>

      <div className="CourseTypeImage-bagroung-mainimageContainer">
        <div className="ALL-IMAGE-CONTAINER-heading">
          <h1>baground image </h1>
        </div>

        <div className="IMAGE-FORM-MAIN-CONTAINER">
          <div className="IMAGE-FORM-CONTAINER">
            <form onSubmit={handleAddBagroundImage}>
              <input
                type="file"
                onChange={(e) => setbacgroundIMAGE(e.target.files[0])}
              ></input>
              <button type="submit">add file</button>
            </form>
          </div>
        </div>
        <div className="ALL-IMAGE-CONTAINER">
          {courseTypeBagroundImageData &&
            courseTypeBagroundImageData.map((val, key) => {
              return (
                <CourseTypeSingleBagroundImage
                  courseTypeImage={val}
                  key={key}
                />
              );
            })}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default CourseTypeImage;
