import React, { useState } from "react";
import { Link } from "react-router-dom";
import { addNewEnrollType } from "../../Redux/ActionCompo/AdminGenaralActions";
import { Snackbar, TextField, Button, MenuItem } from "@mui/material";

const AddEnrollType = () => {
  const [enrollType, setEnrollType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();
    const newEnrollRequest = {
      enrollType: enrollType,
    };
    const response = await addNewEnrollType(newEnrollRequest);

    if (response?.status === true) {
      setEnrollType("");
      setSuccessMessage("Enroll type added successfully");
      setOpenSnackbar(true);
    }
  };

  const enrollTypes = [
    {
      id: "dscs",
      type: "ONLINE",
    },
    {
      id: "dscs",
      type: "OFFLINE",
    },
    {
      id: "dscs",
      type: "RECODED",
    },
  ];

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ width: "100%" }} className="AddEnrollType-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={"/enrollTypes"} className="link">
            All enrollTypes
          </Link>
          <h4>Add New enrollTypes</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <label>Enter Enroll Type</label>
              <TextField
                select
                value={enrollType}
                onChange={(e) => setEnrollType(e.target.value)}
                variant="outlined"
                fullWidth
                required
                label="Enroll Type"
              >
                <MenuItem value="">Select</MenuItem>
                {enrollTypes &&
                  enrollTypes?.map((val, key) => (
                    <MenuItem key={key} value={val.type}>
                      {val.type}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddEnrollType;
