import React, { useState } from "react";
import "./AddEventType.scss";
import { Button, TextField } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { addNewEventType } from "../../../Redux/ActionCompo/EventActions";
const AddEventType = () => {
  const [eventType, setEventType] = useState("");

  const { eventId } = useParams();

  const [eventTypeError, setEventTypeError] = useState("");

  const addEventTypeHandler = async (event) => {
    event.preventDefault();
    if (eventType.trim() === "") {
      setEventTypeError("event  type is required");
      return;
    }
    const data = {
      Event_Type_Name: eventType,
    };
    addNewEventType(eventId, data);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={`/Events/${eventId}/EventType`} className="link">
            All event
          </Link>
          <h4>Add New event</h4>
        </div>
      </div>
      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={addEventTypeHandler}>
            <TextField
              variant="outlined"
              type="text"
              name="eventName"
              label="Enter Evant name"
              value={eventType}
              onChange={(e) => {
                setEventType(e.target.value);
              }}
              error={eventTypeError}
              helperText={eventTypeError}
            />
            <Button type="submit">add</Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEventType;
