import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";

import "./LandingPageDetails.scss";
const LandingPageDetails = () => {
  const { pageId, pageName } = useParams();
  return (
    <div className="LandingPageDetails-main-container">
      <Link to={`/landingPages`} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>

      <div className="LandingPageDetails-container">
        <div className="LandingPageDetails-page-link">
          <h1>link</h1>
          <p>
            https://juzzit.in/page/{pageId}/{pageName}
          </p>
          <p>
            {" "}
            https://bespoke-begonia-18ecca.netlify.app/page/{pageId}/{pageName}
          </p>
        </div>
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/landingPages/${pageId}/${pageName}/applicants`}
              className={`link`}
            >
              Applicants
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/landingPages/${pageId}/${pageName}/banner`}
              className={`link`}
            >
              Banner
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink
              to={`/landingPages/${pageId}/${pageName}/keyHighlite`}
              className={`link`}
            >
              Key Highlite
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink
              to={`/landingPages/${pageId}/${pageName}/objectives`}
              className={`link`}
            >
              Objectives
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default LandingPageDetails;
