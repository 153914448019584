import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { addLandingPage } from "../../Redux/ActionCompo/LandingPageActions";
const AddLandingPage = () => {
  const [essentials_Type, setEssential_Type] = useState("");
  const [essentials_Desc, setAssential_Desc] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorDesc, setErrorDesc] = useState("");

  const onsubmiteHandler = (event) => {
    event.preventDefault();
    console.log("asdxasda");

    // Empty input validation
    if (essentials_Type.trim() === "") {
      setErrorType("Essential Type is required");
      return;
    }
    if (essentials_Desc.trim() === "") {
      setErrorDesc("Essential Description is required");
      return;
    }

    const newEssential = {
      topic: essentials_Type,
      topic_desc: essentials_Desc,
    };
    const response = addLandingPage(newEssential);
    if (response?.status === true) {
      setEssential_Type("");
      setErrorDesc("");
    }
  };
  return (
    <div style={{ width: "100%" }} className="AddEssentials-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={`/landingPages`} className="link">
            All landing page
          </Link>
          <h4>Add New landing page </h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                label="Enter Essential Type"
                value={essentials_Type}
                onChange={(e) => setEssential_Type(e.target.value)}
                error={!!errorType}
                helperText={errorType}
                fullWidth
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter Essential Desc"
                value={essentials_Desc}
                onChange={(e) => setAssential_Desc(e.target.value)}
                error={!!errorDesc}
                helperText={errorDesc}
                fullWidth
                multiline
                rows={4}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddLandingPage;
