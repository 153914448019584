import React, { useEffect, useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Snackbar,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecentPlacementQualification,
  getRecentPlacementQualification,
  updateRecentPlacementQualification,
} from "../../../../../Redux/ActionCompo/RecentPlacementsAction";
import { addPlacementStudentQualification } from "../../../../../Redux/Reduuces/RecentPlacementsReducers";
const PlacemetAcadamicQualification = () => {
  const [order, setOrder] = useState(0);

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const { recentplacementId } = useParams();
  const placementStudentQualification = useSelector(
    (state) => state.recentPlacementData.placementStudentQualification
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getRecentPlacementQualification(recentplacementId);
      dispatch(addPlacementStudentQualification(data));
    };
    getData();
  }, [dispatch, recentplacementId]);

  const handleUpdateRequest = async (id, type, status) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the blog detaile?"
      );
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to update the order of blog details ?"
      );
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteRecentPlacementQualification(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("blog details delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ORDER") {
      if (order && order > 0) {
        const data = {
          order: Number(order),
        };
        const responce = await updateRecentPlacementQualification(
          UpdateId,
          data
        );
        if (responce?.status === true) {
          setSuccessMessage("blog detail order  updated successfully");
          setOpenSnackbar(true);
        }
      }
    }
    const data = await getRecentPlacementQualification(recentplacementId);
    dispatch(addPlacementStudentQualification(data));
  };

  return (
    <div style={{ width: "100vw" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>qualification</h4>
          <Link to={"add"} className="link">
            qualification
          </Link>
        </div>
      </div>
      <Link
        to={`/recentplacements/${recentplacementId}/details`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TextField
        label="order"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="course-id"> SI/NO</TableCell>
              <TableCell className="course-name">Qualification</TableCell>
              <TableCell className="future-course">
                Qualifivation from
              </TableCell>
              <TableCell className="active-status">date</TableCell>
              <TableCell className="course-Image">order</TableCell>
              <TableCell className="course-Image">update</TableCell>
              <TableCell className="delete-button">delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {placementStudentQualification &&
              placementStudentQualification?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.qualification}</TableCell>
                  <TableCell>{val.qualification_from}</TableCell>
                  <TableCell>{val.date}</TableCell>
                  <TableCell>{val.order}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        handleUpdateRequest(val.qualification_id, "ORDER")
                      }
                    >
                      update
                    </Button>
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleUpdateRequest(val.qualification_id, "DELETE")
                    }
                  >
                    <i class="fa-solid fa-trash-can"></i>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default PlacemetAcadamicQualification;
