import React, { useState } from "react";
import { Button, Container, TextField } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { addRecodedSubjectVideo } from "../../../../../../../Redux/ActionCompo/BatchActions";
import "../../../../../../../Genaral.css";
const AddNewRecordedSubjectVideo = () => {
  const [videoTopic, setVideoTopic] = useState();
  const [videoDesc, setVideoDesc] = useState();
  const [videoURL, setVideoURL] = useState();
  const [error, setError] = useState("");

  const { batchId, subBatchId, batchCourseId, subjectId } = useParams();
  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    // Check for empty input
    if (!videoTopic || !videoDesc || !videoDesc) {
      setError("feild cannot be empty");
      return;
    }
    const newSubject = {
      id: "asdbh",
      vedio_Topic: videoTopic,
      video_Desc: videoDesc,
      video_URL: videoURL,
    };
    const responce = await addRecodedSubjectVideo(subjectId, newSubject);

    if (responce?.status === true) {
      window.location.reload();
    }
    console.log(responce);
  };

  return (
    <Container className="AddBatchRecodedVideoSubject-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/recordedSubject/${subjectId}/videos`}
            className="link"
          >
            All srecorded subjects
          </Link>
          <h4 variant="h4">Add New subject</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                type="text"
                label="Enter video Topic"
                variant="outlined"
                fullWidth
                value={videoTopic}
                onChange={(e) => setVideoTopic(e.target.value)}
                error={Boolean(error)}
                helperText={error}
              />
              <TextField
                type="text"
                label="Enter video Desc"
                variant="outlined"
                fullWidth
                value={videoDesc}
                onChange={(e) => setVideoDesc(e.target.value)}
                error={Boolean(error)}
                helperText={error}
              />
              <TextField
                type="text"
                label="Enter video url"
                variant="outlined"
                fullWidth
                value={videoURL}
                onChange={(e) => setVideoURL(e.target.value)}
                error={Boolean(error)}
                helperText={error}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default AddNewRecordedSubjectVideo;
