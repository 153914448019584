import React, { useState } from "react";
import { Button, Container, TextField } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { addRecordedVideoSubject } from "../../../../../../Redux/ActionCompo/BatchActions";
import "../../../../../../Genaral.css";
const AddBatchRecodedVideoSubject = () => {
  const [subjectName, setSubjectName] = useState("");
  const [error, setError] = useState("");
  const { batchId, subBatchId, batchCourseId } = useParams();
  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    // Check for empty input
    if (!subjectName.trim()) {
      setError("Sub Batch Name cannot be empty");
      return;
    }

    const newSubject = {
      video_Subject_Name: subjectName.trim(),
    };

    const responce = await addRecordedVideoSubject(batchCourseId, newSubject);
    console.log(responce);
  };

  return (
    <Container className="AddBatchRecodedVideoSubject-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/recordedSubject`}
            className="link"
          >
            All Recorded subjects
          </Link>
          <h4 variant="h4">Add New subject</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                type="text"
                label="Enter Subject Name"
                variant="outlined"
                fullWidth
                value={subjectName}
                onChange={(e) => setSubjectName(e.target.value)}
                error={Boolean(error)}
                helperText={error}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default AddBatchRecodedVideoSubject;
