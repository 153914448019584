import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";

/// branch categorys

export const addNewBranchCategory = async (branchCategorysData) => {
  try {
    const responce = await myaxios.post(
      `/cur/branchCategorys`,
      branchCategorysData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const deleteBranchCategory = async (branchCategorysId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/branchCategorys/${branchCategorysId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getNewBranchCategory = async () => {
  try {
    const response = await myaxios.get(`/cur/branchCategorys`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

////branch type

export const addNewbranchType = async (branchTypeData) => {
  try {
    const response = await myaxios.post(`/cur/branchTypes`, branchTypeData, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const deleteBranchType = async (branchTypeId) => {
  try {
    const response = await myaxios.delete(`/cur/branchTypes/${branchTypeId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const getBranchTypeData = async () => {
  try {
    const responce = await myaxios.get(`/cur/branchTypes`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {}
};

/// course position

export const addCoursePosition = async (positionData) => {
  try {
    const responce = await myaxios.post(`/cur/coursePositions`, positionData, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {}
};

export const deleteCoursePosition = async (positionId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/coursePositions/${positionId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getCoursePosition = async () => {
  try {
    const responce = await myaxios.get(`/cur/coursePositions`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {}
};

///branch api

export const addNewBranch = async (branchData) => {
  try {
    const response = await myaxios.post(`/cur/branchs`, branchData, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const deleteBranch = async (branch_id) => {
  try {
    const response = await myaxios.delete(`/cur/branchs/${branch_id}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateBranch = async (branch_id, branchData) => {
  try {
    const response = await myaxios.put(
      `/cur/branchs/${branch_id}`,
      branchData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getAllBranch = async () => {
  try {
    const response = await myaxios.get(`/cur/branchs`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

///course ImageApi

export const addCourseImageToDataBase = async (courseId, imagedata) => {
  try {
    const responce = await myaxios.post(
      `/cur/courses/${courseId}/courseImages`,
      imagedata,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllCouresImages = async (courseId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courses/${courseId}/courseImages`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteCourseImage = async (courseImageId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/courseImages/${courseImageId}`,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateCourseImages = async (courseImageId, imagedata) => {
  try {
    const responce = await myaxios.put(
      `/cur/courseImages/${courseImageId}`,
      imagedata,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

///  courseType Image

export const addNewCourseTypeImage = async (courseTypeId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/courseTypeImages`,
      imageData,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteCourseTypeImage = async (courseTypeImageId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/courseTypeImages/${courseTypeImageId}`,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateCourseTypeImage = async (courseTypeImageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/cur/courseTypeImages/${courseTypeImageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllCourseTypeImages = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/courseTypeImages`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

///courseType baground image

export const addNewCourseTypeBagroundImages = async (
  courseTypeId,
  imageData
) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/courseTypeBagroundImages`,
      imageData,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteCourseTypeBagroundImages = async (
  courseTypeBagroundImageId
) => {
  try {
    const responce = await myaxios.delete(
      `/cur/courseTypeBagroundImages/${courseTypeBagroundImageId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateCourseTypeBagroundImage = async (
  courseTypeBagroundImageId,
  imageData
) => {
  try {
    const responce = await myaxios.put(
      `/cur/courseTypeBagroundImages/${courseTypeBagroundImageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllCourseTypeBagroundImages = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/courseTypeBagroundImages`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//// courseTypevideo

export const addCourseTypeVideos = async (courseTypeId, vedioData) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/courseTypeVideos`,
      vedioData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteCourseTypeVideos = async (courseTypeVideoId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/courseTypeVideos/${courseTypeVideoId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateCourseTypeVideos = async (courseTypeVideoId, videoData) => {
  try {
    const responce = await myaxios.put(
      `/cur/courseTypeVideos/${courseTypeVideoId}`,
      videoData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllImageVideos = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/courseTypeVideos`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//// course TYPE  tool image

export const addCourseTypeToolImage = async (toolId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/cur/tools/${toolId}/toolImages`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteCourseTypeToolsImage = async (toolImageId) => {
  try {
    const responce = await myaxios.delete(`/cur/toolImages/${toolImageId}`, {
      headers: authHeader(),
    });

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateCourseTypeTooleImage = async (toolImageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/cur/toolImages/${toolImageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getCourseTypeTools = async (toolId) => {
  try {
    const responce = await myaxios.get(`/cur/tools/${toolId}/toolImages`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//// topic Video

export const addNewTopicVideos = async (topicId, videoData) => {
  try {
    const responce = await myaxios.post(
      `/cur/topics/${topicId}/topicVideos`,
      videoData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const addNewTopicVideosURL = async (topicId, videoData) => {
  try {
    const responce = await myaxios.post(
      `/cur/topics/${topicId}/topicVideosURL`,
      videoData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteTopicVideo = async (topicVideoId) => {
  try {
    const responce = await myaxios.delete(`/cur/topicVideos/${topicVideoId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateTopicVideos = async (topicVideoId, videoData) => {
  try {
    const responce = await myaxios.put(
      `/cur/topicVideos/${topicVideoId}`,
      videoData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllTopicVodeos = async (topicId) => {
  try {
    const responce = await myaxios.get(`/cur/topics/${topicId}/topicVideos`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/// topic assignment

export const AddTopicAssignmentTostore = async (topicId, formData) => {
  try {
    const response = await myaxios.post(
      `/cur/topic/${topicId}/assignments`,
      formData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteTopicAssignment = async (assignmentId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/topic/assignments/${assignmentId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const updateTopicAssignment = async (assignmentId, assignmentData) => {
  try {
    const responce = await myaxios.put(
      `/cur/topic/assignment/${assignmentId}`,
      assignmentData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getTopicAssignment = async (topicId) => {
  try {
    const responce = await myaxios.get(`/cur/topic/${topicId}/assignments`, {
      headers: authHeader(),
    });

    return responce.data;
  } catch (error) {}
};

/// course Apis

export const addNewCourse = async (branch_id, payload) => {
  try {
    const response = await myaxios.post(
      `/cur/branch/${branch_id}/courses`,
      payload,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllCourses = async () => {
  try {
    const responce = await myaxios.get("cur/courses", {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllCoursesByBranch = async (branch_id) => {
  try {
    const responce = await myaxios.get(`cur/branch/${branch_id}/courses`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const activeStatusChange = async (courseId, payload) => {
  try {
    const responce = await myaxios.put(`/cur/courses/${courseId}`, payload, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteCourse = async (courseId) => {
  try {
    const responce = await myaxios.delete(`/cur/courses/${courseId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllCourseType = async (courseId) => {
  try {
    const responce = await myaxios.get(`/cur/courses/${courseId}/courseTypes`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const addNewCourseType = async (courseId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/courses/${courseId}/courseTypes`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

////  couese Type Details

export const getCourseTypeDetails = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/courseDetails`,
      {
        headers: authHeader(),
      }
    );

    console.log(responce.data);
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const addNewCourseTypeDetails = async (courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/courseDetails`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const upDateCourseTypeDetails = async (courseTypeId, payload) => {
  try {
    const responce = await myaxios.put(
      `/cur/courseTypes/${courseTypeId}/courseDetails`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const active_courseTypeHandler = async (courseTypeId, payload) => {
  try {
    const responce = await myaxios.put(
      `/cur/courseTypes/${courseTypeId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const delete_courseType = async (courseTypeId) => {
  try {
    const responce = await myaxios.delete(`/cur/courseTypes/${courseTypeId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

//// courseTYPE DETAILS CONDITIONS

export const addCourseTypeDetailsConditions = async (
  courseTypeId,
  conditionData
) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/detailsConditions`,
      conditionData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const deleteCourseTypeDetailsConditions = async (conditionId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/courseTypes/detailsConditions/${conditionId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const updateCourseTypeDetailsConditions = async (
  conditionId,
  conditionData
) => {
  try {
    const responce = await myaxios.put(
      `/cur/courseTypes/detailsConditions/${conditionId}`,
      conditionData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getCourseTypeConditions = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/detailsConditions`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const deleteCourseTypeConditionHeading = async (conditionId, data) => {
  try {
    const response = await myaxios.put(
      `/cur/courseTypes/detailsConditions/${conditionId}/heading`,
      data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

///// course and courseType api

export const getAllCourseCourseType = async () => {
  try {
    const responce = await myaxios.get("/cur/courses/courseTypes", {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/// essentials

export const addNewEssentials = async (courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/essentials`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllEssentials = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/essentials`,
      {
        headers: authHeader(),
      }
    );
    console.log(responce.data);
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteEssentials = async (essentialId) => {
  try {
    const responce = await myaxios.delete(`/cur/essestials/${essentialId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

///////

export const addNewObjective = async (courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/objectives`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllObjective = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/objectives`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return [];
  }
};

export const deleteObjective = async (objectiveId) => {
  try {
    const responce = await myaxios.delete(`/cur/objectives/${objectiveId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateObjective = async (objectiveId, data) => {
  try {
    const responce = await myaxios.put(`/cur/objectives/${objectiveId}`, data, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

////

export const addNewModule = async (courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/models`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllModule = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/models`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteModule = async (modelId) => {
  try {
    const responce = await myaxios.delete(`/cur/models/${modelId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const setActive_module_Status = async (module_id, payload) => {
  try {
    const responce = await myaxios.put(`/cur/module/${module_id}`, payload, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

//// module images

export const addModuleImges = async (moduleId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/cur/module/${moduleId}/images`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteModuleImage = async (imageId) => {
  try {
    const responce = await myaxios.delete(`/cur/module/images/${imageId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateModuleImages = async (imageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/cur/module/images/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllModuleImages = async (moduleId) => {
  try {
    const responce = await myaxios.get(`/cur/module/${moduleId}/images`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//// topic

export const addNewTopic = async (lessonId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/lessons/${lessonId}/topics`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllTopics = async (lessonId) => {
  try {
    const responce = await myaxios.get(`/cur/lessons/${lessonId}/topics`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return [];
  }
};

export const deleteTopic = async (topicId) => {
  try {
    const responce = await myaxios.delete(`/cur/topics/${topicId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const setActive_Topic_status = async (topic_Id, payload) => {
  try {
    const responce = await myaxios.put(`/cur/topics/${topic_Id}`, payload, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

/////

export const addNewLesson = async (modelId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/models/${modelId}/lessons`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllLesson = async (modelId) => {
  try {
    const responce = await myaxios.get(`/cur/models/${modelId}/models`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteLesson = async (lessonId) => {
  try {
    const responce = await myaxios.delete(`/cur/lessons/${lessonId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const setActive_lesson_Status = async (lessonId, payload) => {
  try {
    const responce = await myaxios.put(`/cur/lesson/${lessonId}`, payload, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

////

export const addNewKeyHighLight = async (courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/keyHighlights`,
      payload,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllKeyHighLight = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/keyHighlights`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return [];
  }
};

export const deleteKeyHighLight = async (keyHighlightId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/keyHighlights/${keyHighlightId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};
export const updateKeyHighLight = async (keyHighlightId, data) => {
  try {
    const responce = await myaxios.put(
      `/cur/keyHighlights/${keyHighlightId}`,
      data,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

////

export const addNewProjects = async (courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/projects`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllProjects = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/projects`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteProjects = async (projectsId) => {
  try {
    const responce = await myaxios.delete(`/cur/projects/${projectsId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateProjects = async (projectsId, data) => {
  try {
    const responce = await myaxios.put(`/cur/projects/${projectsId}`, data, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

/////
export const addNewTools = async (courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/tools`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllTools = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/tools`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteTools = async (toolId) => {
  try {
    const responce = await myaxios.delete(`/cur/tools/${toolId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateTools = async (toolId, toolData) => {
  try {
    const responce = await myaxios.put(`/cur/tools/${toolId}`, toolData, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

//course type faq
export const addCourseTypeFaq = async (courseTypeId, faqData) => {
  try {
    const response = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/FAQs`,
      faqData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeFaq = async (FaqId) => {
  try {
    const response = await myaxios.delete(`/cur/FAQs/${FaqId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeFaq = async (FaqId, faqData) => {
  try {
    const response = await myaxios.put(`/cur/FAQs/${FaqId}`, faqData, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const getCourseTypeFaq = async (courseTypeId) => {
  try {
    const response = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/FAQs`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

//course type program hight light
export const addCourseTypeProgramHighLight = async (
  courseTypeId,
  ProgramHighLightData
) => {
  try {
    const response = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/programhighlight`,
      ProgramHighLightData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeProgramHighLight = async (programhighlightId) => {
  try {
    const response = await myaxios.delete(
      `/cur/programhighlight/${programhighlightId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeProgramHighLight = async (
  programhighlightId,
  ProgramHighLightData
) => {
  try {
    const response = await myaxios.put(
      `/cur/programhighlight/${programhighlightId}`,
      ProgramHighLightData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypeProgramHighLight = async (courseTypeId) => {
  try {
    const response = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/programhighlight`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

////course type cereer service

export const addCourseTypeCereerService = async (
  courseTypeId,
  CereerServiceData
) => {
  try {
    const response = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/cereerServices`,
      CereerServiceData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeCereerService = async (cereerServiceId) => {
  try {
    const response = await myaxios.delete(
      `/cur/cereerServices/${cereerServiceId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeCereerService = async (
  cereerServiceId,
  CereerServiceData
) => {
  try {
    const response = await myaxios.put(
      `/cur/cereerServices/${cereerServiceId}`,
      CereerServiceData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypeCereerService = async (courseTypeId) => {
  try {
    const response = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/cereerServices`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// course  Type cereer service details

export const addCourseTypeCereerServiceDetails = async (
  cereerServicesId,
  CereerServiceDetailsData
) => {
  try {
    const response = await myaxios.post(
      `/cur/cereerServices/${cereerServicesId}/cereerServicesDetails`,
      CereerServiceDetailsData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeCereerServiceDetails = async (
  cereerServiceDetailsId
) => {
  try {
    const response = await myaxios.delete(
      `/cur/cereerServicesDetails/${cereerServiceDetailsId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeCereerServiceDetails = async (
  cereerServiceDetailsId,
  CereerServiceDetailsData
) => {
  try {
    const response = await myaxios.put(
      `/cur/cereerServicesDetails/${cereerServiceDetailsId}`,
      CereerServiceDetailsData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypeCereerServiceDetails = async (cereerServicesId) => {
  try {
    const response = await myaxios.get(
      `/cur/cereerServices/${cereerServicesId}/cereerServicesDetails`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// course  Type cereer service details images

export const addCourseTypeCereerServiceDetailsImages = async (
  cereerServiceDetailsId,
  CereerServiceDetailsImagesData
) => {
  try {
    const response = await myaxios.post(
      `/cur/cereerServicesDetails/${cereerServiceDetailsId}/images`,
      CereerServiceDetailsImagesData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeCereerServiceDetailsImages = async (imageId) => {
  try {
    const response = await myaxios.delete(
      `/cur/cereerServicesDetailsImage/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeCereerServiceDetailsImages = async (
  imageId,
  CereerServiceDetailsImagesData
) => {
  try {
    const response = await myaxios.put(
      `/cur/cereerServicesDetailsImage/${imageId}`,
      CereerServiceDetailsImagesData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypeCereerServiceDetailsImages = async (
  cereerServiceDetailsId
) => {
  try {
    const response = await myaxios.get(
      `/cur/cereerServicesDetails/${cereerServiceDetailsId}/images`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

//course type Program Structure1
export const addCourseTypeProgramStructure1 = async (
  courseTypeId,
  programStructure1Data
) => {
  try {
    const response = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/programStructure1`,
      programStructure1Data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeProgramStructure1 = async (
  programStructure1Id
) => {
  try {
    const response = await myaxios.delete(
      `/cur/programStructure1/${programStructure1Id}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeProgramStructure1 = async (
  programStructure1Id,
  programStructure1Data
) => {
  try {
    const response = await myaxios.put(
      `/cur/programStructure1/${programStructure1Id}`,
      programStructure1Data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypeProgramStructure1 = async (courseTypeId) => {
  try {
    const response = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/programStructure1`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

//course type Program Structure2
export const addCourseTypeProgramStructure2 = async (
  courseTypeId,
  programStructure2Data
) => {
  try {
    const response = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/programStructure2`,
      programStructure2Data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeProgramStructure2 = async (
  programStructure2Id
) => {
  try {
    const response = await myaxios.delete(
      `/cur/programStructure2/${programStructure2Id}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeProgramStructure2 = async (
  programhighlightId,
  programStructure2Data
) => {
  try {
    const response = await myaxios.put(
      `/cur/programStructure2/${programhighlightId}`,
      programStructure2Data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypeProgramStructure2 = async (courseTypeId) => {
  try {
    const response = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/programStructure2`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

//course type Program Structure2 LESSON
export const addCourseTypeProgramStructure2Lesson = async (
  programStructure2Id,
  programStructure2lessonData
) => {
  try {
    const response = await myaxios.post(
      `/cur/programStructure2/${programStructure2Id}/programStructure2lessons`,
      programStructure2lessonData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeProgramStructure2Lesson = async (
  programStructure2lessonId
) => {
  try {
    const response = await myaxios.delete(
      `/cur/programStructure2lessons/${programStructure2lessonId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeProgramStructure2Lesson = async (
  programStructure2lessonId,
  programStructure2lessonData
) => {
  try {
    const response = await myaxios.put(
      `/cur/programStructure2lessons/${programStructure2lessonId}`,
      programStructure2lessonData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypeProgramStructure2Lesson = async (
  programStructure2Id
) => {
  try {
    const response = await myaxios.get(
      `/cur/programStructure2/${programStructure2Id}/programStructure2lessons`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// course type tools to master

export const addCourseTypeToolsToMaster = async (
  courseTypeId,
  toolsToMasterData
) => {
  try {
    const response = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/toolsToMaster`,
      toolsToMasterData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeToolsToMaster = async (toolsToMasterId) => {
  try {
    const response = await myaxios.delete(
      `/cur/courseToolsToMaster/${toolsToMasterId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeToolsToMaster = async (
  toolsToMasterId,
  toolsToMasterData
) => {
  try {
    const response = await myaxios.put(
      `/cur/courseToolsToMaster/${toolsToMasterId}`,
      toolsToMasterData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypeToolsToMaster = async (courseTypeId) => {
  try {
    const response = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/toolsToMaster`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

// coursetype employee role

export const addCourseTypeEmployeeRole = async (
  courseTypeId,
  employeeRolesData
) => {
  try {
    const response = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/employeeRoles`,
      employeeRolesData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeEmployeeRole = async (employeeRolesId) => {
  try {
    const response = await myaxios.delete(
      `/cur/courseEnployeeRole/${employeeRolesId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateCourseTypeEmployeeRole = async (
  employeeRolesId,
  employeeRolesData
) => {
  try {
    const response = await myaxios.put(
      `/cur/courseEnployeeRole/${employeeRolesId}`,
      employeeRolesData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypeEmployeeRole = async (courseTypeId) => {
  try {
    const response = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/employeeRoles`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

//// course type certificate

export const addCourseTypeCertificate = async (
  courseTypeId,
  certificateData
) => {
  try {
    const responce = await myaxios.post(
      `/cur/courseType/${courseTypeId}/certificate`,
      certificateData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const deleteCourseTypeCertificate = async (certificateId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/courseTypes/certificates/${certificateId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const updateCourseTypeCertificate = async (
  certificateId,
  certificateData
) => {
  try {
    const responce = await myaxios.put(
      `/cur/courseTypes/certificates/${certificateId}`,
      certificateData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getCourseTypeCertificate = async (courseTypeId) => {
  try {
    const response = await myaxios.get(
      `/cur/courseType/${courseTypeId}/certificate`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

///// course TYPE aluminiWorks At

export const addCourseTypeAluminiWorksAtImage = async (
  courseTypeId,
  imageData
) => {
  try {
    const response = await myaxios.post(
      `/cur/courseTypes/${courseTypeId}/aluminiImage`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteCourseTypeAluminiWorkAtImage = async (imageId) => {
  try {
    const responce = await myaxios.delete(
      `/cur/courseTypes/aluminiImages/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const updateCourseTypeAluminiWorksAtImage = async (
  imageId,
  imageData
) => {
  try {
    const responce = await myaxios.put(
      `/cur/courseTypes/aluminiImages/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getCoursePositionTypeAluminiWorksAtImage = async (
  courseTypeId
) => {
  try {
    const responce = await myaxios.get(
      `/cur/courseTypes/${courseTypeId}/aluminiImage`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};
