import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Link, useParams } from "react-router-dom";
import { addRecentPlacementAcadamicSkillsLearned } from "../../../../../Redux/ActionCompo/RecentPlacementsAction";
import { Snackbar } from "@mui/material";

const AddRecentPlacementSkillLearned = () => {
  const { recentplacementId } = useParams();

  // Step 1: Add state variables
  const [skillName, setSkillName] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Step 2: Validation checks for empty fields
  const validateForm = () => {
    const errors = {};

    if (!skillName.trim()) {
      errors.skillName = "Skill Name is required";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  // Step 3: Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      Skill_Name: skillName,
    };

    if (validateForm()) {
      const response = await addRecentPlacementAcadamicSkillsLearned(
        recentplacementId,
        data
      );
      if (response?.status === true) {
        setSkillName("");
        setSuccessMessage("branch added successfully");
        setOpenSnackbar(true);
      }
    } else {
      console.log("Form has errors. Please fix them.");
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ width: "100vw" }} className="AddClassLinks-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/recentplacements/${recentplacementId}/skillsLearned`}
            className="link"
          >
            skills learned
          </Link>
          <h4>Add skills learned</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={handleSubmit}>
            {/* Step 2: Display validation errors */}
            <TextField
              label="Enter Skill Name"
              type="text"
              fullWidth
              value={skillName}
              onChange={(e) => setSkillName(e.target.value)}
              error={!!formErrors.skillName}
              helperText={formErrors.skillName}
            />

            <button type="submit">Add</button>
          </form>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddRecentPlacementSkillLearned;
