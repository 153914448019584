import React from "react";
import { Button } from "@mui/material";
import {
  deleteRecodedSubjectImages,
  updateRecodedSubjectImages,
} from "../../../../../../../../Redux/ActionCompo/BatchActions";
import "../../../../../../../../Genaral.css";
const SingleRecodedSubjectImage = (props) => {
  const imageData = props.courseTypeImage;
  console.log(imageData);

  const activeStatusHandler = async (imageId) => {
    const data = {
      active_status: "change",
    };

    const responce = await updateRecodedSubjectImages(imageId, data);

    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const deleteHandler = async (imageId) => {
    const responce = await deleteRecodedSubjectImages(imageId);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData?.image_URL} alt="sx"></img>
      </div>

      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => activeStatusHandler(imageData.image_Id)}
        >
          {imageData?.active_status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteHandler(imageData.image_Id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default SingleRecodedSubjectImage;
