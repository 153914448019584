import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
  eventTypes: [],
  eventTypeImage: [],
  eventTypeVideo: [],
};

const EventReducer = createSlice({
  name: "events Data",
  initialState,
  reducers: {
    setEvents(state, actions) {
      state.events = actions.payload;
    },
    setEventTypes(state, action) {
      state.eventTypes = action.payload;
    },
    setEventTypeImage(state, action) {
      state.eventTypeImage = action.payload;
    },
    setEventTypeVideo(state, action) {
      state.eventTypeVideo = action.payload;
    },
  },
});

export const {
  setEvents,
  setEventTypes,
  setEventTypeImage,
  setEventTypeVideo,
} = EventReducer.actions;

export default EventReducer.reducer;
