import React, { useEffect } from "react";
import "./TeacherProfile.scss";
import { addTeacherProfileData } from "../../../Redux/Reduuces/TeacherReducers";
import { useSelector, useDispatch } from "react-redux";
import { getTeachetData } from "../../../Redux/ActionCompo/TeacherActions";
const TeacherProfile = () => {
  const teacherData = useSelector((state) => state.teacher.teacher_profileData);
  const dispatch = useDispatch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const getData = async () => {
      const teacherId = user.userId;
      const data = await getTeachetData(teacherId);
      dispatch(addTeacherProfileData(data));
    };

    getData();
  }, [dispatch]);

  return (
    <div className="TeacherProfile-main-container">
      <div className="TeacherProfile-container">
        <div className="TeacherProfile-content-container">
          <div className="TeacherProfile-content">
            <h4>TRAINER Id</h4>
            <p>{teacherData?.teacherId}</p>
          </div>

          <div className="TeacherProfile-content">
            <h4>TRAINER Name</h4>
            <p>{teacherData?.teacherName}</p>
          </div>

          <div className="TeacherProfile-content">
            <h4>TRAINER Email</h4>
            <p>{teacherData?.teacherEmail}</p>
          </div>

          <div className="TeacherProfile-content">
            <h4>TRAINER Mobile Number</h4>
            <p>{teacherData?.teacherMobileNumber}</p>
          </div>

          <div className="TeacherProfile-content">
            <h4>TRAINER Address</h4>
            <p>{teacherData?.teacherAddress}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherProfile;
