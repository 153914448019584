import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteJobDomainApplicants,
  getJobDomainApplicants,
} from "../../../../../Redux/ActionCompo/JuzzItJobActions";
import { setJobApplicants } from "../../../../../Redux/Reduuces/JuzzItJobReducer";
import * as XLSX from "xlsx";
const JobApplicants = () => {
  const { jobDomainId, jobId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const dispatch = useDispatch();
  const jobApplicants = useSelector((state) => state.jobData.jobApplicants);

  useEffect(() => {
    const getadata = async () => {
      const data = await getJobDomainApplicants(jobId);
      dispatch(setJobApplicants(data));
    };
    getadata();
  }, [jobId, dispatch]);

  const handleUpdateRequest = async (id, type) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to delete the essential ?");
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteJobDomainApplicants(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("essential delete successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getJobDomainApplicants(jobId);
    dispatch(setJobApplicants(data));
  };

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `jobApplicants.xlsx`);
  };

  return (
    <div className="Essentials-main-container" style={{ width: "100%" }}>
      <Link
        to={`/JobDomain/${jobDomainId}/jobs/${jobId}/JobDetails`}
        className="back"
      >
        <i class="fa-solid fa-backward"></i>
      </Link>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="course-id">
                {" "}
                <Button onClick={() => downloadExcel(jobApplicants)}>
                  {" "}
                  Download As Excel
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell>ESSENTIAL ID</TableCell>
              <TableCell>NAME</TableCell>
              <TableCell>EMAIL</TableCell>
              <TableCell>MOBILE NUMBER</TableCell>
              <TableCell>DELETE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobApplicants?.map((val, key) => (
              <TableRow key={key}>
                <TableCell>{key + 1}</TableCell>
                <TableCell>{val.NAME}</TableCell>
                <TableCell>{val.EMAIL}</TableCell>
                <TableCell>{val.MOBILE_NUMBER}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      handleUpdateRequest(val.essential_id, "DELETE");
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default JobApplicants;
