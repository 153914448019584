import { createSlice } from "@reduxjs/toolkit";

const initialStatte = {
  jobDomain: [],
  jobs: [],
  jobImages: [],
  jobLocations: [],
  jobRequiredQualification: [],
  jobRequirements: [],
  jobResponcibilitys: [],
  jobSkillRequireds: [],
  jobApplicants: [],
};

const JuzzItJobReducer = createSlice({
  name: "jobs",
  initialState: initialStatte,
  reducers: {
    setJobDomain(state, action) {
      state.jobDomain = action.payload;
    },
    setJobs(state, action) {
      state.jobs = action.payload;
    },
    setJobImages(state, action) {
      state.jobImages = action.payload;
    },
    setJobLocations(state, action) {
      state.jobLocations = action.payload;
    },

    setJobRequiredQualification(state, action) {
      state.jobRequiredQualification = action.payload;
    },

    setJobRequirements(state, action) {
      state.jobRequirements = action.payload;
    },

    setJobResponcibilitys(state, action) {
      state.jobResponcibilitys = action.payload;
    },

    setJobSkillRequireds(state, action) {
      state.jobSkillRequireds = action.payload;
    },

    setJobApplicants(state, action) {
      state.jobApplicants = action.payload;
    },
  },
});

export const {
  setJobDomain,
  setJobs,
  setJobImages,
  setJobLocations,
  setJobRequiredQualification,
  setJobRequirements,
  setJobResponcibilitys,
  setJobSkillRequireds,
  setJobApplicants,
} = JuzzItJobReducer.actions;

export default JuzzItJobReducer.reducer;
