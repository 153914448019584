import React from "react";
import "./RecodedStudentRoot.scss";
import { Outlet } from "react-router-dom";
const RecodedStudentRoot = () => {
  return (
    <div className="RecodedStudentRoot-main-container">
      <div className="RecodedStudentRoot-header">Recoded Student Root</div>
      <div className="RecodedStudentRoot-body">
        <Outlet />
      </div>
    </div>
  );
};

export default RecodedStudentRoot;
