import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { addRecodedStudentPlacements } from "../../../../Redux/ActionCompo/TeacherActions";
import "../../../../Genaral.css";
const AddRecordedStudentPlacements = () => {
  const [companyName, setCompanyName] = useState("");
  const [companyIntroduction, setCompanyIntroduction] = useState("");
  const [applyLink, setApplyLink] = useState("");

  const { studentId } = useParams();

  const [companyNameError, setCompanyNameError] = useState("");
  const [companyIntroductionError, setCompanyIntroductionError] = useState("");
  const [applyLinkError, setApplyLinkError] = useState("");

  const onsubmitHandler = async (event) => {
    event.preventDefault();

    // Check for empty inputs
    if (!companyName) {
      setCompanyNameError("Company name is required");
    } else {
      setCompanyNameError("");
    }

    if (!companyIntroduction) {
      setCompanyIntroductionError("Company introduction is required");
    } else {
      setCompanyIntroductionError("");
    }

    if (!applyLink) {
      setApplyLinkError("Apply link is required");
    } else {
      setApplyLinkError("");
    }

    if (companyName && companyIntroduction && applyLink) {
      const newPlacement = {
        company_name: companyName,
        company_Intruduction: companyIntroduction,
        apply_link: applyLink,
      };

      const response = await addRecodedStudentPlacements(
        studentId,
        newPlacement
      );
      if (response?.status === true) {
        setCompanyName("");
        setApplyLink("");
        setCompanyIntroduction("");
      }
    }
  };

  return (
    <div className="AddPlacements-main-container">
      <div style={{ width: "100%" }} className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/teachers/recodedStudent/${studentId}/placements`}
            className="link"
          >
            All placements
          </Link>
          <h4 variant="h4">Add New Placement</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmitHandler}>
            <div className="form-element">
              <TextField
                fullWidth
                label="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                error={!!companyNameError}
                helperText={companyNameError}
              />
            </div>
            <div className="form-element">
              <TextField
                fullWidth
                label="Company Introduction"
                multiline
                minRows={4}
                value={companyIntroduction}
                onChange={(e) => setCompanyIntroduction(e.target.value)}
                error={!!companyIntroductionError}
                helperText={companyIntroductionError}
              />
            </div>
            <div className="form-element">
              <TextField
                fullWidth
                label="Apply Link"
                value={applyLink}
                onChange={(e) => setApplyLink(e.target.value)}
                error={!!applyLinkError}
                helperText={applyLinkError}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddRecordedStudentPlacements;
