import { myaxios } from "../ApiBase";

export const handleLogin = async (user) => {
  return {
    status: true,
    user: {
      userId: "12334",
      userType: user.userType,
    },
  };
};

export const adminLogIn = async (logindata) => {
  try {
    const responce = await myaxios.post("/Auth/admin/login", logindata);
    return responce.data;
  } catch (error) {
    return {
      successStatus: false,
      massage: error.response.data,
    };
  }
};

export const cretateAdminAcount = async (requestId, adminData) => {
  try {
    const responce = await myaxios.post(`/api/ceate/${requestId}`, adminData);
    return responce.data;
  } catch (error) {
    return {
      successStatus: false,
      massage: error.response.data,
    };
  }
};
export const trainerLogin = async (logindata) => {
  try {
    const responce = await myaxios.post("/Auth/trainer/login", logindata);
    return responce.data;
  } catch (error) {
    return {
      successStatus: false,
      massage: error.response.data,
    };
  }
};

export const serntSignUpOtpRequest = async (requestData) => {
  try {
    const responce = await myaxios.post(`/api/otp/sendRequest`, requestData);
    return responce.data;
  } catch (error) {}
};

export const validateUserDataWithOtp = async (requestId, logInData) => {
  try {
    const responce = await myaxios.post(
      `/Auth/trainer/otp/${requestId}/login`,
      logInData
    );
    return responce.data;
  } catch (error) {
    return {
      successStatus: false,
      massage: "pleace enter the currect otp",
    };
  }
};
export const validateAdminDataWithOtp = async (requestId, logInData) => {
  try {
    const responce = await myaxios.post(
      `/Auth/admin/otp/${requestId}/login`,
      logInData
    );
    return responce.data;
  } catch (error) {
    return {
      successStatus: false,
      massage: "pleace enter the currect otp",
    };
  }
};

export const adminExistById = async (email) => {
  try {
    const responce = await myaxios.get(`/api/admin/${email}/exists`);
    return responce.data;
  } catch (error) {}
};
