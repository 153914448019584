import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextField, Button, Grid, Snackbar } from "@mui/material";
import { addNewCoreTeamMember } from "../../../Redux/ActionCompo/TeamMemberActions";
const AddCoreTeamMember = () => {
  const [MemberName, setMemberName] = useState("");
  const [Member_Position, setMember_Position] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // const [Member_Intruduction, setMember_Intruduction] = useState("");

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    // if (validateForm()) {
    const newMember = {
      Member_Name: MemberName,
      Member_Position: Member_Position,
      Member_Intruduction: "NO",
    };
    console.log(newMember);

    const responce = await addNewCoreTeamMember(newMember);

    if (responce?.status === true) {
      setMemberName("");
      setMember_Position("");
      setSuccessMessage("branch type added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ width: "100%" }} className="AddNewTeacher-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={"/CoreTeamMember"} className="link">
            All teachers
          </Link>
          <h4>Add New Teacher</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Enter Member Name"
                  variant="outlined"
                  fullWidth
                  value={MemberName}
                  onChange={(e) => setMemberName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Enter Member_Position"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={Member_Position}
                  onChange={(e) => setMember_Position(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddCoreTeamMember;
