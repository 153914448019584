import React, { useEffect, useState } from "react";
import "./EventTypeVideo.scss";
import {
  addEventTypeVideo,
  getEventTypeVideo,
} from "../../../../../Redux/ActionCompo/EventActions";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { Button } from "@mui/base";
import { setEventTypeVideo } from "../../../../../Redux/Reduuces/EventReducer";
const EventTypeVideo = () => {
  const { eventId } = useParams();

  const [videoURL, setVideoURL] = useState();
  const [videoURLerror, setVideoURLerror] = useState();

  const { eventTypeId } = useParams();

  const dispatch = useDispatch();

  const eventTypeVideo = useSelector((state) => state.EventData.eventTypeVideo);

  useEffect(() => {
    const getData = async () => {
      const data = await getEventTypeVideo(eventTypeId);
      dispatch(setEventTypeVideo(data));
    };

    getData();
  }, [dispatch, eventTypeId]);

  const handleaddVideo = async (e) => {
    e.preventDefault();
    if (videoURL === "") {
      setVideoURLerror("URL is equired ");
    }
    const dateS = {
      video_URL: videoURL,
    };
    const responce = await addEventTypeVideo(eventTypeId, dateS);

    console.log(responce);
  };

  return (
    <div style={{ width: "100%" }}>
      <NavLink to={`/Events/${eventId}/EventType`}>back</NavLink>

      {eventTypeVideo && eventTypeVideo.length > 0 ? (
        <div></div>
      ) : (
        <div>
          <form onSubmit={handleaddVideo}>
            <TextField
              type="text"
              variant="outlined"
              value={videoURL}
              onChange={(e) => {
                setVideoURL(e.target.value);
              }}
              error={videoURLerror}
              helperText={videoURLerror}
            />

            <Button type="submit">add</Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default EventTypeVideo;
