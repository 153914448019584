import axios from "axios";

export const uploadImage = async (file) => {
  const data = new FormData();
  data.append("file", file);
  data.append("upload_preset", "xep3vumw");

  try {
    const responce = await axios.post(
      `https://api.cloudinary.com/v1_1/dwccqj4to/image/upload`,
      data
    );
    return responce.data;
  } catch (error) {}
};
