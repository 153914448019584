import React from "react";
import "./BatchCourseDetails.scss";
import { Link, useParams } from "react-router-dom";
const BatchCourseDetails = () => {
  const { batchId, subBatchId, batchCourseId } = useParams();

  return (
    <div className="BatchCourseDetails-main-container">
      <div className="BatchCourseDetails-link-container">
        <div className="link-contant">
          <h4>Batch Course Details</h4>
        </div>
      </div>

      <Link
        to={`/batch/${batchId}/subBatch/${subBatchId}/courses`}
        className="back"
      >
        <i class="fa-solid fa-backward"></i>
      </Link>
      <ul>
        <li>
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/students`}
            className="link"
          >
            <i class="fa-solid fa-circle-dot"></i>
            <span>Students</span>
          </Link>
        </li>
        <li>
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/subjects`}
            className="link"
          >
            <i class="fa-solid fa-circle-dot"></i>
            <span>Subjects</span>
          </Link>
        </li>
        <li>
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/placements`}
            className="link"
          >
            <i class="fa-solid fa-circle-dot"></i>
            <span>Placements</span>
          </Link>
        </li>
        <li>
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/recordedSubject`}
            className="link"
          >
            <i class="fa-solid fa-circle-dot"></i>
            <span>Recorded Subject</span>
          </Link>
        </li>
        <li>
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/email`}
            className="link"
          >
            <i class="fa-solid fa-circle-dot"></i>
            <span>Email</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default BatchCourseDetails;
