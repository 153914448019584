import React from "react";
import "./Loading.scss";
const Loading = () => {
  return (
    <div className="loading-page-container">
      <div className="loading-animation"></div>
      <p>Loading...</p>
    </div>
  );
};

export default Loading;
