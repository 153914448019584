import React from "react";

import { Button } from "@mui/material";
import {
  deleteCourseTypeImage,
  updateCourseTypeImage,
} from "../../../../../Redux/ActionCompo/CourseAction";
import "../../../../../Genaral.css";
const CourseTypeSingleImage = (props) => {
  const imageData = props.courseTypeImage;
  console.log(imageData);

  const deleteCourseTypeImageindatabase = async (imageId) => {
    const responce = await deleteCourseTypeImage(imageId);
    console.log(responce);
  };

  const updateCourseTypeImageData = async (imageId) => {
    const data = {
      active_status: "change",
    };

    const responce = await updateCourseTypeImage(imageId, data);
    console.log(responce);
  };

  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData.image_URL} alt="sdacas"></img>
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_Status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => {
            updateCourseTypeImageData(imageData.courseType_image_Id);
          }}
        >
          {imageData.active_Status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteCourseTypeImageindatabase(imageData.courseType_image_Id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default CourseTypeSingleImage;
