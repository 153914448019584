import React, { useEffect } from "react";
import "./MyRecodedStudents.scss";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllRecodedStudentBatchs } from "../../../Redux/ActionCompo/TeacherActions";
import { addteacher_RecodedStudents } from "../../../Redux/Reduuces/TeacherReducers";

const MyRecodedStudents = () => {
  const teacher_RecodedStudents = useSelector(
    (state) => state.teacher.teacher_RecodedStudents
  );

  const dispatch = useDispatch();

  const userData = localStorage.getItem("userData");
  let user = {};
  if (userData) {
    user = JSON.parse(userData);
  }

  useEffect(() => {
    const getData = async () => {
      const data = await getAllRecodedStudentBatchs(user?.userId);
      dispatch(addteacher_RecodedStudents(data));
    };
    getData();
  }, [dispatch, user?.userId]);

  return (
    <div>
      <div className="MyRecodedStudents-main-content">
        <ul>
          <li>
            <Link to={"/"} className="link">
              Profile
            </Link>
          </li>
          <li>
            <Link to={"/teachers/batchs"} className="link">
              <h3>Batchs</h3>
            </Link>
          </li>
          <li>
            <Link to={"/teachers/WorkShopBatchs"} className="link">
              WorkShop Batchs
            </Link>
          </li>
          <li className="link">
            <h3>Recoded Students</h3>
          </li>
        </ul>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> ID</TableCell>
                <TableCell> NAME</TableCell>
                <TableCell> email</TableCell>
                <TableCell> mobile number</TableCell>
                <TableCell>COURSE NAME</TableCell>
                <TableCell>Course Type NAME</TableCell>
                <TableCell>student course status</TableCell>
                <TableCell>session LINK</TableCell>
                <TableCell>placements</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teacher_RecodedStudents &&
                teacher_RecodedStudents?.map((val, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{val.RECORDED_STUDENT_ID}</TableCell>
                      <TableCell>{val.STUDENT_NAME}</TableCell>
                      <TableCell>{val.student_Data?.student_email}</TableCell>
                      <TableCell>{val.student_Data?.mobile_number}</TableCell>
                      <TableCell>{val.COURSE_NAME}</TableCell>
                      <TableCell>{val.COURSE_TYPE}</TableCell>
                      <TableCell>
                        <Link
                          className="link"
                          to={`/teachers/recodedStudent/${val.RECORDED_STUDENT_ID}/courseStatus`}
                        >
                          {" "}
                          view
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          className="link"
                          to={`/teachers/recodedStudent/${val.RECORDED_STUDENT_ID}/session`}
                        >
                          view
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          className="link"
                          to={`/teachers/recodedStudent/${val.RECORDED_STUDENT_ID}/placements`}
                        >
                          view
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default MyRecodedStudents;
