import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecodedStudentPlacementImage,
  getAllRecodedStudentPlacementImages,
} from "../../../../../Redux/ActionCompo/TeacherActions";
import { addteacher_recodedStudent_placement_images } from "../../../../../Redux/Reduuces/TeacherReducers";
import RecodedStudentSinglePlacementImage from "./RecodedStudentSinglePlacementImage";
import { uploadImage } from "../../../../../Redux/ActionCompo/CloudenarowImageUplode";
const RecodedStudentPlacementImages = () => {
  const { placementId } = useParams();

  const [file, setFile] = useState(null);

  const placementImages = useSelector(
    (state) => state.teacher.teacher_recodedStudent_placement_images
  );

  console.log(placementImages);

  const dispatch = useDispatch();

  const handleAddImage = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const data = await uploadImage(file);
    console.log(data?.secure_url);

    if (data) {
      const newImageData = {
        image_URL: data?.secure_url,
      };
      console.log(newImageData);
      const response = await addRecodedStudentPlacementImage(
        placementId,
        newImageData
      );
      console.log(response);
    }
  };

  useEffect(() => {
    const gatdata = async () => {
      const data = await getAllRecodedStudentPlacementImages(placementId);
      dispatch(addteacher_recodedStudent_placement_images(data));
    };

    gatdata();
  }, [placementId, dispatch]);

  return (
    <div>
      <NavLink to={``}>back</NavLink>
      <div className="IMAGE-FORM-MAIN-CONTAINER">
        <div className="IMAGE-FORM-CONTAINER">
          <form onSubmit={handleAddImage}>
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            ></input>
            <button type="submit">addfile</button>
          </form>
        </div>
      </div>
      <div className="ALL-IMAGE-MAIN-CONTAINER">
        <div className="ALL-IMAGE-CONTAINER-heading">
          <h1>All Images</h1>
        </div>
        <div className="ALL-IMAGE-CONTAINER">
          {placementImages &&
            placementImages?.map((val, key) => {
              return (
                <RecodedStudentSinglePlacementImage
                  PlacmentImage={val}
                  key={key}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default RecodedStudentPlacementImages;
