import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";
////// workShop student request

export const deleteWorkShopEnrollRequest = async (requestId) => {
  try {
    const responce = await myaxios.delete(`/wrst/enrollRequests/${requestId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllWorkShopEnrollRequest = async () => {
  try {
    const responce = await myaxios.get(`/wrst/enrollRequests`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getWorkShopEnrollRequestDetails = async (requestId) => {
  try {
    const responce = await myaxios.get(`/wrst/enrollRequests/${requestId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//// work shop batch

export const addWorkShopBatch = async (workShopId, workShopBatchData) => {
  try {
    const responce = await myaxios.post(
      `/wsbch/batchs/${workShopId}`,
      workShopBatchData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteWorkShopBatch = async (batchId) => {
  try {
    const responce = await myaxios.delete(`/wsbch/batchs/${batchId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateWorkShopBatch = async (batchId, workShopBatchData) => {
  try {
    const responce = await myaxios.put(
      `/wsbch/betchs/${batchId}`,
      workShopBatchData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllWorkShopBatch = async () => {
  try {
    const responce = await myaxios.get(`/wsbch/batchs`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

////  wprk shop batch students

export const addStudentToWorkShopBatch = async (
  batchId,
  studentId,
  requstId,
  studentData
) => {
  try {
    const responce = await myaxios.post(
      `/wsbch/workShopBatchs/${batchId}/students/${studentId}/${requstId}`,
      studentData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteStudentFromBatch = async (studentId) => {
  try {
    const responce = await myaxios.delete(
      `/wsbch/workShopBatchStudent/${studentId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateStudentData = async (studentId, studentData) => {
  try {
    const responce = await myaxios.put(
      `/wsbch/workShopBatchStudent/${studentId}`,
      studentData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllWorkShopBatchStudent = async (batchId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/workShopBatchs/${batchId}/students`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

///// work shop batch subjects

export const addSubjectToBatch = async (batchId, teacherId, subjectData) => {
  try {
    const responce = await myaxios.post(
      `/wsbch/workShopBatchs/${batchId}/subjects/${teacherId}`,
      subjectData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteSubjectFtomBatch = async (subjectId) => {
  try {
    const responce = await myaxios.delete(
      `/wsbch/workShopSubjects/${subjectId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateSubjectData = async (subjectId, subjectData) => {
  try {
    const responce = await myaxios.put(
      `/wsbch/workShopSubjects/${subjectId}`,
      subjectData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getWorkShopBatchSubjects = async (batchId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/workShopBatchs/${batchId}/subjects`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//// workShop placemets  addPlacemets to workSope batch

export const addPlacementToWorkShopBatch = async (batchId, placementData) => {
  try {
    const responce = await myaxios.post(
      `/wsbch/workShopBatchs/${batchId}/placements`,
      placementData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deletePlacemetFromWorkShopBatch = async (placementId) => {
  try {
    const responce = await myaxios.delete(
      `/wsbch/workShopPlacements/${placementId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updatePlcementData = async (placementId, placementData) => {
  try {
    const responce = await myaxios.put(
      `/wsbch/workShopPlacements/${placementId}`,
      placementData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllWorkShopPlacements = async (workShopBathcId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/workShopBatchs/${workShopBathcId}/placements`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/////   placement image

export const addWorkShopPlacemetImage = async (placementId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/wsbch/workShopPlacements/${placementId}/images`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deletePlacementImage = async (imageId) => {
  try {
    const responce = await myaxios.delete(
      `/wsbch/workShopPlacementsImage/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updatePlacementImages = async (imageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/wsbch/workShopPlacementsImage/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getPlacemetImages = async (placementId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/workShopPlacements/${placementId}/images`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
