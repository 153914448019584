import React from 'react'
import './SandEmail.scss'
import { Link, useParams } from 'react-router-dom'
const SandEmail = () => {

  const {batchId,subBatchId,batchCourseId}=useParams();

  return (
    <div>SandEmail


<Link to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/details`}className='back'><i class="fa-solid fa-backward"></i></Link>
    </div>
  )
}
export default SandEmail