import React, { useState } from "react";
import "./AddClassLinks.scss";
import { Link, useParams } from "react-router-dom";
import { addClassLinks } from "../../../../../Redux/ActionCompo/TeacherActions";
import TextField from "@mui/material/TextField";
import "../../../../../Genaral.css";
const AddClassLinks = () => {
  const { batchCourseId, batchCourseSubjectId } = useParams();

  const [classTitle, setClassTitle] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [classDate, setClassDate] = useState("");
  const [classTime, setClassTime] = useState("");
  const [classLink, setClassLink] = useState("");

  const [classTitleError, setClassTitleError] = useState("");
  const [subjectNameError, setSubjectNameError] = useState("");
  const [classDateError, setClassDateError] = useState("");
  const [classTimeError, setClassTimeError] = useState("");
  const [classLinkError, setClassLinkError] = useState("");

  const validateForm = () => {
    let isValid = true;

    if (!classTitle) {
      setClassTitleError("Class title is required");
      isValid = false;
    } else {
      setClassTitleError("");
    }

    if (!subjectName) {
      setSubjectNameError("Subject name is required");
      isValid = false;
    } else {
      setSubjectNameError("");
    }

    if (!classDate) {
      setClassDateError("Class date is required");
      isValid = false;
    } else {
      setClassDateError("");
    }

    if (!classTime) {
      setClassTimeError("Class time is required");
      isValid = false;
    } else {
      setClassTimeError("");
    }

    if (!classLink) {
      setClassLinkError("Class link is required");
      isValid = false;
    } else {
      setClassLinkError("");
    }

    return isValid;
  };

  const onSubmiteHandler = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const newClassLink = {
        class_Title: classTitle,
        subjectName: subjectName,
        class_Date: new Date(classDate),
        class_time: classTime,
        class_link: classLink,
      };

      const response = await addClassLinks(
        batchCourseId,
        batchCourseSubjectId,
        newClassLink
      );

      if (response?.status === true) {
        setClassDate("");
        setClassLink("");
        setClassTime("");
        setClassTitle("");
        setSubjectName("");
      }
    }
  };

  return (
    <div className="AddClassLinks-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/teacher/batch/${batchCourseId}/classLink/${batchCourseSubjectId}`}
            className="link"
          >
            All Class Links
          </Link>
          <h4>Add New Link</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onSubmiteHandler}>
            <TextField
              label="Enter Class Title"
              type="text"
              value={classTitle}
              onChange={(e) => setClassTitle(e.target.value)}
              error={!!classTitleError}
              helperText={classTitleError}
              fullWidth
            />

            <TextField
              label="Enter Subject Name"
              type="text"
              value={subjectName}
              onChange={(e) => setSubjectName(e.target.value)}
              error={!!subjectNameError}
              helperText={subjectNameError}
              fullWidth
            />

            <TextField
              type="date"
              value={classDate}
              onChange={(e) => setClassDate(e.target.value)}
              error={!!classDateError}
              helperText={classDateError}
              fullWidth
            />

            <TextField
              label="Enter Time"
              type="time"
              value={classTime}
              onChange={(e) => setClassTime(e.target.value)}
              error={!!classTimeError}
              helperText={classTimeError}
              fullWidth
            />

            <TextField
              label="Enter Class Link"
              type="text"
              value={classLink}
              onChange={(e) => setClassLink(e.target.value)}
              error={!!classLinkError}
              helperText={classLinkError}
              fullWidth
            />

            <button type="submit">Add</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddClassLinks;
