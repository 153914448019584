import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import { addJobDomainJob } from "../../../Redux/ActionCompo/JuzzItJobActions";

const AddJuzzItJob = () => {
  const { jobDomainId } = useParams();

  const [workExperience, setWorkExperience] = useState("");
  const [workExperienceError, setWorkExperienceError] = useState("");

  const [workType, setWorkType] = useState("");
  const [workTypeError, setWorkTypeError] = useState("");

  const [employmentType, setEmploymentType] = useState("");
  const [employmentTypeError, setEmploymentTypeError] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  const [jobRole, setJobRole] = useState("");
  const [jobRoleError, setJobRoleError] = useState("");

  const [essentialsDesc, setEssentialsDesc] = useState("");
  const [essentialsDescError, setEssentialsDescError] = useState("");

  const [jobIntroduction, setJobIntroduction] = useState("");
  const [jobIntroductionError, setJobIntroductionError] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const validateForm = () => {
    let isValid = true;

    if (!companyName.trim()) {
      setCompanyNameError("Company name is required");
      isValid = false;
    } else {
      setCompanyNameError("");
    }

    if (!jobRole.trim()) {
      setJobRoleError("Job role is required");
      isValid = false;
    } else {
      setJobRoleError("");
    }

    if (!essentialsDesc.trim()) {
      setEssentialsDescError("Essential description is required");
      isValid = false;
    } else {
      setEssentialsDescError("");
    }

    if (!jobIntroduction.trim()) {
      setJobIntroductionError("Job introduction is required");
      isValid = false;
    } else {
      setJobIntroductionError("");
    }

    if (!workExperience.trim()) {
      setWorkExperienceError("Work experience is required");
      isValid = false;
    } else {
      setWorkExperienceError("");
    }

    if (!workType.trim()) {
      setWorkTypeError("Work type is required");
      isValid = false;
    } else {
      setWorkTypeError("");
    }

    if (!employmentType.trim()) {
      setEmploymentTypeError("Employment type is required");
      isValid = false;
    } else {
      setEmploymentTypeError("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const newEssential = {
      CompanyName: companyName,
      CompanyDescription: essentialsDesc,
      EmployeementType: employmentType,
      JobCateguryOrRole: jobRole,
      WorkType: workType,
      WorkExperience: workExperience,
      JobIntruduction: jobIntroduction,
    };

    const response = await addJobDomainJob(jobDomainId, newEssential);

    if (response?.status === true) {
      setCompanyName("");
      setJobRole("");
      setEssentialsDesc("");
      setJobIntroduction("");
      setWorkExperience("");
      setWorkType("");
      setEmploymentType("");
      setSuccessMessage("Essential added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddEssentials-main-container" style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={`/JobDomain/${jobDomainId}/jobs`} className="link">
            All jobs
          </Link>
          <h4>Add New jobs </h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={handleSubmit}>
            <div className="form-element">
              <TextField
                label="Enter company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                error={!!companyNameError}
                helperText={companyNameError}
                fullWidth
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter job role"
                value={jobRole}
                onChange={(e) => setJobRole(e.target.value)}
                error={!!jobRoleError}
                helperText={jobRoleError}
                fullWidth
              />
            </div>

            <div className="form-element">
              <TextField
                label="Enter company Desc"
                value={essentialsDesc}
                onChange={(e) => setEssentialsDesc(e.target.value)}
                error={!!essentialsDescError}
                helperText={essentialsDescError}
                fullWidth
                multiline
                rows={4}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter job intruduct"
                value={jobIntroduction}
                onChange={(e) => setJobIntroduction(e.target.value)}
                error={!!jobIntroductionError}
                helperText={jobIntroductionError}
                fullWidth
                multiline
                rows={4}
              />
            </div>
            <label>Work Experience</label>

            <Select
              label="Select Work Experience"
              value={workExperience}
              onChange={(e) => setWorkExperience(e.target.value)}
              error={!!workExperienceError}
              helperText={workExperienceError}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>

              <MenuItem value="fresher">fresher</MenuItem>
              <MenuItem value="0-1 year">0-1 year</MenuItem>
              <MenuItem value="0-2 year">0-2 year</MenuItem>
              <MenuItem value="0-3 year">0-3 year</MenuItem>
              <MenuItem value="1-2 year">1-2 year</MenuItem>
              <MenuItem value="2-5 year">2-5 year</MenuItem>
              <MenuItem value="1-3 year">1-3 year</MenuItem>
              <MenuItem value="2-3 year">2-3 year</MenuItem>
              <MenuItem value="3-5 year">3-5 year</MenuItem>
              <MenuItem value="5-7 year">5-7 year</MenuItem>
              <MenuItem value="7 and more year">7 and more year</MenuItem>
            </Select>

            <label>Work Type</label>
            <Select
              label="Select Work Type"
              value={workType}
              onChange={(e) => setWorkType(e.target.value)}
              error={!!workTypeError}
              helperText={workTypeError}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>

              <MenuItem value="fulltime">Full-time</MenuItem>
              <MenuItem value="parttime">Part-time</MenuItem>
              <MenuItem value="contract">Contract</MenuItem>
            </Select>
            <label>Employment Type</label>
            <Select
              label="Select Employment Type"
              value={employmentType}
              onChange={(e) => setEmploymentType(e.target.value)}
              error={!!employmentTypeError}
              helperText={employmentTypeError}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>

              <MenuItem value="permanent">Permanent</MenuItem>
              <MenuItem value="temporary">Temporary</MenuItem>
              <MenuItem value="freelance">Freelance</MenuItem>
            </Select>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddJuzzItJob;
