import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recodedStudentsWithMenter: [],
  recodedStudentWithoutMenter: [],
  allMenter: [],
};

const RecodedStudentReducer = createSlice({
  name: "recodedStudent",
  initialState,
  reducers: {
    addRecodedStudentsWithMenter: (state, action) => {
      state.recodedStudentsWithMenter = action.payload;
    },
    addrecodedStudentWithoutMenter: (state, action) => {
      state.recodedStudentWithoutMenter = action.payload;
    },
    addallMenter: (state, action) => {
      state.allMenter = action.payload;
    },
  },
});

export const {
  addRecodedStudentsWithMenter,
  addrecodedStudentWithoutMenter,
  addallMenter,
} = RecodedStudentReducer.actions;

export default RecodedStudentReducer.reducer;
