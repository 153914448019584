import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewTopicVideosURL,
  deleteTopicVideo,
  getAllTopicVodeos,
} from "../../../../../../../../../Redux/ActionCompo/CourseAction";
import { addTopicVideos } from "../../../../../../../../../Redux/Reduuces/CourceReducer";
import { Button, Snackbar, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";

const TopicVideo = () => {
  const { topicId } = useParams();
  const TopicVideos = useSelector((state) => state.courseData.TopicVideos);
  // const [file, setFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getAllTopicVodeos(topicId);
      dispatch(addTopicVideos(data));
    };
    getData();
  }, [topicId, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!videoURL) {
      return;
    }

    const videoData = {
      VIDEO_URL: videoURL,
    };

    try {
      const response = await addNewTopicVideosURL(topicId, videoData);
      if (response.status === true) {
        setVideoURL("");
        setSuccessMessage("topic added successfully");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const deleteHandler = async (videoId) => {
    const response = await deleteTopicVideo(videoId);
    console.log(response);
  };

  return (
    <div>
      {TopicVideos && TopicVideos.length > 0 ? (
        <div>
          <ReactPlayer
            url={`https://d7p5gncpfqoge.cloudfront.net/${
              TopicVideos && TopicVideos[0]?.file_location
            }`}
            width="100%"
            height="100%"
            controls={true}
          />
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              deleteHandler(TopicVideos && TopicVideos[0]?.video_id);
            }}
          >
            delete
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            label="Enter video key"
            value={videoURL}
            onChange={(e) => setVideoURL(e.target.value)}
          ></TextField>
          <Button type="submit">submite</Button>
        </form>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default TopicVideo;

// import React, { useEffect, useState } from "react";
// import { NavLink, useParams } from "react-router-dom";
// import {
//   // addNewTopicVideos,
//   deleteTopicVideo,
//   getAllTopicVodeos,
// } from "../../../../../../../../../Redux/ActionCompo/CourseAction";
// import { useDispatch, useSelector } from "react-redux";
// import { addTopicVideos } from "../../../../../../../../../Redux/Reduuces/CourceReducer";
// import "../../../../../../../../../Genaral.css";
// import ReactPlayer from "react-player";
// import { Button } from "@mui/material";
// import { myaxios } from "../../../../../../../../../Redux/ApiBase";

// const TopicVideo = () => {
//   const { courseId, courseTypeId, moduleId, lessonId, topicId } = useParams();
//   const TopicVideos = useSelector((state) => state.courseData.TopicVideos);
//   const [file, setFile] = useState(null);
//   // const [uploading, setUploading] = useState(false);
//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };
//   const dispatch = useDispatch();
//   useEffect(() => {
//     const getData = async () => {
//       const data = await getAllTopicVodeos(topicId);
//       dispatch(addTopicVideos(data));
//     };
//     getData();
//   }, [topicId, dispatch]);

//   const handledata = async (event) => {
//     event.preventDefault();
//     const urldata = await myaxios.get(
//       `/api/topic/${topicId}/${file.name}/geturl`
//     );
//     const trimmedUrl = urldata?.data?.URL.replace(/\s/g, "");
//     // Use the generated pre-signed URL to upload the video
//     await fetch(trimmedUrl, {
//       method: "PUT",
//       body: file,
//       headers: {
//         "Content-Type": "video/mp4",
//       },
//     });
//   };

//   // const handleSubmit = async (event) => {
//   //   event.preventDefault();

//   //   if (!file) {
//   //     console.error("Please select a file");
//   //     return;
//   //   }

//   //   const formData = new FormData();
//   //   formData.append("file", file);

//   //   try {
//   //     setUploading(true);
//   //     const response = await addNewTopicVideos(topicId, formData);

//   //     console.log(response);
//   //   } catch (error) {
//   //     console.error("Error uploading video:", error);
//   //   } finally {
//   //     setUploading(false);
//   //   }
//   // };

//   const deleteHandler = async (videoId) => {
//     const response = await deleteTopicVideo(videoId);
//     console.log(response);
//   };

//   return (
//     <div>
//       <div className="pageLinkHeader-main">
//         <div className="pageLinkHeader-main-content">
//           <h4>All video </h4>
//           <NavLink to={"addVideo"} className="link">
//             Add New video
//           </NavLink>
//         </div>
//       </div>
//       <NavLink
//         to={`/courses/${courseId}/courseTypes/${courseTypeId}/models/${moduleId}/lessons/${lessonId}/topics`}
//       >
//         back
//       </NavLink>

//       {TopicVideos && TopicVideos.length > 0 ? (
//         <div>
//           <ReactPlayer
//             url={`https://d7p5gncpfqoge.cloudfront.net/${
//               TopicVideos && TopicVideos[0]?.file_location
//             }`}
//             width="100%"
//             height="100%"
//             controls={true}
//           />
//           <Button
//             variant="contained"
//             color="error"
//             onClick={() => {
//               deleteHandler(TopicVideos && TopicVideos[0]?.video_id);
//             }}
//           >
//             delete
//           </Button>
//         </div>
//       ) : (
//         <form onSubmit={handledata}>
//           <div>
//             <label htmlFor="file">Select File:</label>
//             <input type="file" id="file" onChange={handleFileChange} />
//           </div>
//           <div>
//             <button type="submit">Submit</button>
//           </div>
//         </form>
//       )}
//     </div>
//   );
// };

// export default TopicVideo;
