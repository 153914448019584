import { Button } from "@mui/material";
import React from "react";
import {
  deleteCourseTypeToolsToMaster,
  updateCourseTypeToolsToMaster,
} from "../../../../../../Redux/ActionCompo/CourseAction";
const ToolsToMasterSingleImage = (props) => {
  const imageData = props.courseTypeImage;
  console.log(imageData);

  const activeStatusHandler = async (imageId) => {
    const data = {
      active_status: "change",
    };

    const responce = await updateCourseTypeToolsToMaster(imageId, data);

    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const deleteHandler = async (imageId) => {
    const responce = await deleteCourseTypeToolsToMaster(imageId);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData?.image_URL} alt="sx"></img>
      </div>
      <h5>{imageData?.tool_name}</h5>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.Active_Status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => activeStatusHandler(imageData.tool_id)}
        >
          {imageData?.Active_Status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteHandler(imageData.tool_id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
export default ToolsToMasterSingleImage;
