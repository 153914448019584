import React, { useEffect, useState } from "react";
import { addBatchCourseSubjectsToStore } from "../../../../../../Redux/Reduuces/BatchReducer";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteBatchCourseSubject,
  getAllBatchCourseSubject,
  setPermitionStatusSubject,
} from "../../../../../../Redux/ActionCompo/BatchActions";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
} from "@mui/material";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "../../../../../../Genaral.css";
const CourseSubject = () => {
  const { batchId, subBatchId, batchCourseId } = useParams();

  const courseSubject = useSelector(
    (state) => state.batchData.batchCourseSubjects
  );

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllBatchCourseSubject(batchCourseId);
      dispatch(addBatchCourseSubjectsToStore(data));
    };
    getData();
  }, [batchCourseId, dispatch]);

  const handleUpdateRequest = async (id, type, status) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the recent placement?"
      );
    } else if (type === "TRAINER_PERMITION") {
      setUpdateType("ACTIVE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `Are you sure you want to   ${
          status ? "deactive" : "active"
        } recent place ment?`
      );
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteBatchCourseSubject(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("recent placement delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "TRAINER_PERMITION") {
      const payload = {
        teacher_PermitionStatus: "change",
      };
      const responce = await setPermitionStatusSubject(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("details updated successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getAllBatchCourseSubject(batchCourseId);
    dispatch(addBatchCourseSubjectsToStore(data));
  };

  return (
    <div className="CourseSubject-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Subject</h4>
          <Link to={"addSubject"} className="link">
            Add New Subject
          </Link>
        </div>
      </div>

      <Link
        to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/details`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TableContainer component={Paper} className="Course_subject-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SUBJECT ID</TableCell>
              <TableCell>SUBJECT NAME</TableCell>
              <TableCell>TEACHER NAME</TableCell>
              <TableCell>TEACHER ID</TableCell>
              <TableCell>PERMITION</TableCell>
              <TableCell>delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courseSubject?.map((val, key) => (
              <TableRow key={key}>
                <TableCell>{val.subject_Id}</TableCell>
                <TableCell>{val.subject_Name}</TableCell>
                <TableCell>{val.teacher_Name}</TableCell>
                <TableCell>{val.teacher_Id}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleUpdateRequest(val.subject_Id, "TRAINER_PERMITION");
                    }}
                    variant="contained"
                    size="small"
                    color={`${
                      val.teacher_PermitionStatus === "A" ? "success" : "error"
                    }`}
                  >
                    {val.teacher_PermitionStatus === "A" ? "ON" : "OFF"}
                  </Button>
                </TableCell>
                <TableCell>
                  <span
                    className="delete_button"
                    onClick={() => {
                      handleUpdateRequest(val.subject_Id, "DELETE ");
                    }}
                  >
                    <i className="fa-solid fa-trash-can"></i>
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default CourseSubject;
