import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { addBatchCoursesToStore } from "../../../../Redux/Reduuces/BatchReducer";
import {
  deleteBatchCourse,
  getAllBatchCourse,
  setBatchCourse_StatusHandler,
} from "../../../../Redux/ActionCompo/BatchActions";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
} from "@mui/material";
import "../../../../Genaral.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
const BatchCourse = () => {
  const { batchId, subBatchId } = useParams();
  const BatchCourses = useSelector((state) => state.batchData.batchCourses);
  const dispatch = useDispatch();

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  useEffect(() => {
    const gatData = async () => {
      const data = await getAllBatchCourse(subBatchId);
      dispatch(addBatchCoursesToStore(data));
    };

    gatData();
  }, [subBatchId, dispatch]);

  const handleUpdateRequest = async (id, type, status) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the recent placement?"
      );
    } else if (type === "ACTIVE") {
      setUpdateType("ACTIVE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `Are you sure you want to   ${
          status ? "deactive" : "active"
        } recent place ment?`
      );
    } else if (type === "ADMITION_STATUS") {
      setUpdateType("ADMITION_STATUS");
      setOpenDialog(true);
      setUpdateAlertMessage(`Are you sure you want to  update the link?`);
    } else if (type === "BATCH_COMPLETION") {
      setUpdateType("BATCH_COMPLETION");
      setOpenDialog(true);
      setUpdateAlertMessage(`Are you sure you want to  update the link?`);
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteBatchCourse(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("recent placement delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ACTIVE") {
      const payload = {
        active_batchCourse: "change",
      };
      const responce = await setBatchCourse_StatusHandler(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("details updated successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ADMITION_STATUS") {
      const payload = {
        admition_status: "change",
      };
      const responce = await setBatchCourse_StatusHandler(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("LINK  updated successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "BATCH_COMPLETION") {
      const payload = {
        batch_conpletion_Status: "change",
      };
      const responce = await setBatchCourse_StatusHandler(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("LINK  updated successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getAllBatchCourse(subBatchId);
    dispatch(addBatchCoursesToStore(data));
  };

  return (
    <div className="BatchCourse-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Courses</h4>
          <Link to={"addCourse"} className="link">
            Add New Course
          </Link>
        </div>
      </div>

      <Link to={`/batch/${batchId}/subBatch`} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TableContainer component={Paper} className="batch_course-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>BATCH ID</TableCell>
              <TableCell>BATCH NAME</TableCell>
              <TableCell>COURSE NAME</TableCell>
              <TableCell>COURSE TYPE</TableCell>
              <TableCell>ACTIVE</TableCell>
              <TableCell>ADMISSION</TableCell>
              <TableCell>BATCH COMPLETION</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {BatchCourses?.map((val, key) => (
              <TableRow key={key}>
                <TableCell>{val.batch_courseId}</TableCell>
                <TableCell>
                  <NavLink
                    to={`${val.batch_courseId}/details`}
                    className="nev_link"
                  >
                    {val.batch_courseName}
                  </NavLink>
                </TableCell>
                <TableCell>{val.course_Name}</TableCell>
                <TableCell>{val.course_TypeName}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleUpdateRequest(
                        val.batch_courseId,
                        "ACTIVE",
                        val.active_batchCourse === "A"
                      );
                    }}
                    variant="contained"
                    size="small"
                    color={`${
                      val.active_batchCourse === "A" ? "success" : "error"
                    }`}
                  >
                    {val.active_batchCourse === "A" ? "Active" : "Deactive"}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleUpdateRequest(
                        val.batch_courseId,
                        "ADMITION_STATUS",
                        val.admition_status === "A"
                      );
                    }}
                    variant="contained"
                    size="small"
                    color={`${
                      val.admition_status === "A" ? "success" : "error"
                    }`}
                  >
                    {val.admition_status === "A" ? "ON" : "OFF"}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleUpdateRequest(
                        val.batch_courseId,
                        "BATCH_COMPLETION",
                        val.batch_completionStatus === "A"
                      );
                    }}
                    variant="contained"
                    size="small"
                    color={`${
                      val.batch_completionStatus === "A" ? "success" : "error"
                    }`}
                  >
                    {val.batch_completionStatus === "A" ? "YES" : "NO"}
                  </Button>
                </TableCell>
                <TableCell>
                  <span
                    className="delete_button"
                    onClick={() => {
                      handleUpdateRequest(val.batch_courseId, "DELETE");
                    }}
                  >
                    <i className="fa-solid fa-trash-can"></i>
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default BatchCourse;
