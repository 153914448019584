import React, { useState } from "react";
import "./StudentDetail.scss";
import { Button, TextField } from "@mui/material";
import { getStudentDetailsByEmail } from "../../Redux/ActionCompo/StudentDetailActions";
import { useDispatch, useSelector } from "react-redux";
import { setStudentData } from "../../Redux/Reduuces/StudentDetailsReducer";
import { useNavigate } from "react-router-dom";

const StudentDetail = () => {
  const [email, setEmail] = useState("");
  const [showdetails, setShowDetails] = useState(false);

  const studentData = useSelector(
    (state) => state.studentDetailsData.studentData
  );

  const dispatch = useDispatch();

  const onSubmiteHandler = async (event) => {
    event.preventDefault();
    if (email) {
      setShowDetails(false);
      const data = await getStudentDetailsByEmail(email);
      dispatch(setStudentData(data));
      setShowDetails(true);
    }
  };

  const navigate = useNavigate();

  return (
    <div className="StudentDetail-main-container">
      <div className="StudentDetail-container">
        <div className="StudentDetail-heading-container">
          <form onSubmit={onSubmiteHandler}>
            <TextField
              variant="outlined"
              label="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></TextField>
            <Button type="submit" className="button">
              get details
            </Button>
          </form>
        </div>
        <div
          className={`StudentDetail-student-details  ${
            showdetails ? "showdetails" : ""
          }`}
        >
          {studentData && studentData?.student_id ? (
            <div className="studentDetails-content">
              <h4>
                <span> Name</span> : {studentData?.student_name}
              </h4>
              <h4>
                <span> Email</span> : {studentData?.student_email}
              </h4>
              <h4>
                <span> Mobile Number: </span>:{" "}
                {studentData?.student_mobile_number}
              </h4>
              <h4>
                <span> Address: </span>: {studentData?.student_address}
              </h4>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`${studentData?.student_id}`);
                }}
              >
                Course Details
              </Button>
            </div>
          ) : (
            <div className="studentdetail-notfound">
              <p>Details with the email not found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentDetail;
