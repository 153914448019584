import React, { useEffect, useState } from "react";
import SingleMainTeamMemberImage from "./SingleMainTeamMemberImage";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { Button, Input } from "@mui/material";
import { uploadImage } from "../../../../Redux/ActionCompo/CloudenarowImageUplode";
import {
  addMainTeamMemberImage,
  getAllMainTeamMemberImage,
} from "../../../../Redux/ActionCompo/TeamMemberActions";
import { addMainTeamMemberImages } from "../../../../Redux/Reduuces/TeamMemberReducer";
const MainTeamMemberImage = () => {
  const [file, setFile] = useState(null);
  const mainTeamMemberImages = useSelector(
    (state) => state.teamMemberData.mainTeamMemberImages
  );

  const { memberId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllMainTeamMemberImage(memberId);
      console.log(data);
      dispatch(addMainTeamMemberImages(data));
    };

    getData();
  }, [memberId, dispatch]);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid JPEG or PNG image.");
      }
    }
  };

  const handleAddImage = async () => {
    const data = await uploadImage(file);
    console.log(data?.secure_url);
    if (data) {
      const newImageData = {
        IMAGE_URL: data?.secure_url,
      };
      console.log(newImageData);
      const responce = await addMainTeamMemberImage(memberId, newImageData);
      console.log(responce);
    }
  };

  return (
    <div style={{ width: "100%" }} className="CourseImage-main-container">
      <div>
        <NavLink to={`/courses`}>back</NavLink>
        <div className="IMAGE-FORM-MAIN-CONTAINER">
          <div className="IMAGE-FORM-CONTAINER">
            <form>
              <Input
                type="file"
                accept="image/jpeg, image/png"
                onChange={handleImageChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddImage}
              >
                Add Image
              </Button>
            </form>
          </div>
        </div>

        <div className="ALL-IMAGE-MAIN-CONTAINER">
          <div className="ALL-IMAGE-CONTAINER-heading">
            <h1>All Images</h1>
          </div>
          <div className="ALL-IMAGE-CONTAINER">
            {mainTeamMemberImages &&
              mainTeamMemberImages.map((val, key) => (
                <SingleMainTeamMemberImage courseTypeImage={val} key={key} />
              ))}
            ,
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainTeamMemberImage;
