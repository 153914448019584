import React, { useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import {
  deleteLandingPageKeyHighLites,
  getLandingPageKeyHighLites,
} from "../../../../Redux/ActionCompo/LandingPageActions";
import { addlaningPageKeyHighliteToStore } from "../../../../Redux/Reduuces/LandingPageReducer";
import { useDispatch, useSelector } from "react-redux";
const LandingPageKeyHighLites = () => {
  const laningPageKeyHighlite = useSelector(
    (state) => state.landingPageData.laningPageKeyHighlite
  );

  const { pageId, pageName } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getadata = async () => {
      const data = await getLandingPageKeyHighLites(pageId);
      dispatch(addlaningPageKeyHighliteToStore(data));
    };
    getadata();
  }, [dispatch, pageId]);

  const deleteKeyHighLites = async (id) => {
    const responce = await deleteLandingPageKeyHighLites(id);
    console.log(responce);
  };
  return (
    <div style={{ width: "100vw" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>ALL key highlight</h4>
          <Link to={"add"} className="link">
            add New key highlight
          </Link>
        </div>
      </div>
      <Link to={`/landingPages/${pageId}/${pageName}/details`} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="course-id"> SI/NO</TableCell>
              <TableCell className="course-name">key highlight</TableCell>
              <TableCell className="delete-button">delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {laningPageKeyHighlite &&
              laningPageKeyHighlite?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.keyhighLite}</TableCell>
                  <TableCell>
                    <i
                      onClick={() => {
                        deleteKeyHighLites(val.keyhighligte_id);
                      }}
                      class="fa-solid fa-trash-can"
                    ></i>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LandingPageKeyHighLites;
