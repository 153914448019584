import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  placementStudentQualification: [],
  RecentPlacementsProjects: [],
  RecentPlacementsProjectsImage: [],
  RecentPlacementSkillsLearned: [],
  RecentPlacementProfileImage: [],
  RecentPlacementCertificate: [],
};

const RecentPlacementsReducers = createSlice({
  name: "genaral",
  initialState,
  reducers: {
    addPlacementStudentQualification(state, action) {
      state.placementStudentQualification = action.payload;
    },
    addRecentPlacementsProjects(state, action) {
      state.RecentPlacementsProjects = action.payload;
    },
    addRecentPlacementsProjectsImage(state, action) {
      state.RecentPlacementsProjectsImage = action.payload;
    },
    addRecentPlacementSkillsLearned(state, action) {
      state.RecentPlacementSkillsLearned = action.payload;
    },
    addRecentPlacementProfileImageToStore(state, action) {
      state.RecentPlacementProfileImage = action.payload;
    },
    addRecentPlacementCertificateToStore(state, action) {
      state.RecentPlacementCertificate = action.payload;
    },
  },
});

export const {
  addPlacementStudentQualification,
  addRecentPlacementsProjects,
  addRecentPlacementsProjectsImage,
  addRecentPlacementSkillsLearned,
  addRecentPlacementProfileImageToStore,
  addRecentPlacementCertificateToStore,
} = RecentPlacementsReducers.actions;

export default RecentPlacementsReducers.reducer;
