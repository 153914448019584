import React, { useEffect } from "react";
import "./StudentCoueseDetail.scss";
import { useParams } from "react-router-dom";
import SingleCourseCompo from "./SingleCourseCompo";
import { setEnrolledCourses } from "../../../Redux/Reduuces/StudentDetailsReducer";
import { getAllEnrolledCourseData } from "../../../Redux/ActionCompo/StudentDetailActions";
import { useDispatch, useSelector } from "react-redux";
const StudentCoueseDetail = () => {
  const { studentId } = useParams();

  const enrolledCourses = useSelector(
    (state) => state.studentDetailsData.enrolledCourses
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllEnrolledCourseData(studentId);
      dispatch(setEnrolledCourses(data));
    };
    getData();
  }, [dispatch, studentId]);

  return (
    <div className="StudentCoueseDetail-main-container">
      <div className="EnrolledLiveCourses-main-container">
        <h1>My Enrolled Courses</h1>
        <div className="EnrolledLiveCourses-content">
          {enrolledCourses &&
            enrolledCourses?.map((val, key) => {
              const courseName =
                val.course_type_name +
                " " +
                "Certification in" +
                " " +
                val.course_name;

              return (
                <SingleCourseCompo
                  courseId={val.course_type_id}
                  img={val.courseType_Images}
                  batchId={val.batch_course_id}
                  studentBatchId={val.batch_course_student_id}
                  key={key}
                  heading={courseName}
                  percentage={val.completed_percentage}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default StudentCoueseDetail;
