import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";
export const addNewBlog = async (blogData) => {
  try {
    const responce = await myaxios.post(`/blg/blogs`, blogData, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteBlog = async (blogId) => {
  try {
    const responce = await myaxios.delete(`/blg/blogs/${blogId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateBlogData = async (blogId, blogData) => {
  try {
    const responce = await myaxios.put(`/blg/blogs/${blogId}`, blogData, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllBlogs = async () => {
  try {
    const responce = await myaxios.get(`/blg/blogs`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

///

export const addBlogImage = async (blogId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/blg/blogs/${blogId}/images`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteBlogImage = async (imageId) => {
  try {
    const responce = await myaxios.delete(`/blg/blogs/images/${imageId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateBlogImageData = async (imageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/blg/blogs/images/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getBlogImages = async (blogId) => {
  try {
    const responce = await myaxios.get(`/blg/blogs/${blogId}/images`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

///

export const addBlogDetails = async (blogId, detailsData) => {
  try {
    const responce = await myaxios.post(
      `/blg/blogs/${blogId}/details`,
      detailsData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteBlogDetails = async (blogDetailsId) => {
  try {
    const responce = await myaxios.delete(`/blg/blogDetails/${blogDetailsId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateBlogDetails = async (blogDetailsId, detailsData) => {
  try {
    const responce = await myaxios.put(
      `/blg/blogDetails/${blogDetailsId}`,
      detailsData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllBlogDetails = async (blogId) => {
  try {
    const responce = await myaxios.get(`/blg/blogs/${blogId}/details`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/// comments

export const getAllBlogComments = async (blogId) => {
  try {
    const responce = await myaxios.get(`/blg/blogs/${blogId}/comments`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteBlogComments = async (blogCommentId) => {
  try {
    const responce = await myaxios.delete(
      `/blg/blogs/comments/${blogCommentId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};
