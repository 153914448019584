import "./App.scss";
import Home from "./AppComponents/HomeComponents/Home";

import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import "./Genaral.css";

import RootLayout from "./AppComponents/RootLayout";
import RootCourse from "./AppComponents/CourcesComponents/RootCourse";
import Courses from "./AppComponents/CourcesComponents/Courses";
import AddCourse from "./AppComponents/CourcesComponents/AddCourse";
import CourseTypes from "./AppComponents/CourcesComponents/Components/CourseTypes";
import CourseTypeDetails from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseTypeDetails";
import AddCourseType from "./AppComponents/CourcesComponents/Components/AddCourseType";
import Essentials from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseEssentialsCompo/Essentials";
import AddEssentials from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseEssentialsCompo/AddEssentials";
import Highlights from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseKeyHighlightCompo/Highlights";
import AddHighlights from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseKeyHighlightCompo/AddHighlights";
import Models from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/Models";
import AddModels from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/AddModels";
import Objectievs from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseObjectiveCompo/Objectievs";
import AddObjectievs from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseObjectiveCompo/AddObjectievs";
import Projects from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseProjectCompo/Projects";
import AddProjects from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseProjectCompo/AddProjects";
import Tools from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseTools/Tools";
import AddTools from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseTools/AddTools";
import Lesson from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/Components/Lesson";
import AddLesson from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/Components/AddLesson";
import Topics from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/Components/LessonCompo/Topics";
import AddTopics from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/Components/LessonCompo/AddTopics";
import RootBatch from "./AppComponents/BatchComponents/RootBatch";
import Batch from "./AppComponents/BatchComponents/Batch";
import AddBatch from "./AppComponents/BatchComponents/AddBatch";
import SubBatch from "./AppComponents/BatchComponents/Components/SubBatch";
import AddSubBatch from "./AppComponents/BatchComponents/Components/AddSubBatch";
import BatchCourse from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourse";
import AddBatchCourse from "./AppComponents/BatchComponents/Components/SubBatchComponent/AddBatchCourse";
import CourseStudent from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchCourseStudentCompo/CourseStudent";
import SandEmail from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchCourseEmailcomponents/SandEmail";
import Placements from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchCoursePlacementsCompo/Placements";
import CourseSubject from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchCourseCourseSubjects/CourseSubject";
import EnrollRequests from "./AppComponents/EnrollRequestComponents/EnrollRequests";
import EnrollRequestsDetails from "./AppComponents/EnrollRequestComponents/Components/EnrollRequestsDetails";
import BatchCourseDetails from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchCourseDetails/BatchCourseDetails";
import AddCourseSubject from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchCourseCourseSubjects/AddCourseSubject";
import AddPlacements from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchCoursePlacementsCompo/AddPlacements";
import AddCourseTypeDetails from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/AddCourseTypeDetails";
import AllTeachers from "./AppComponents/TeacherComponents/AllTeachers";
import TeacherDetailsInAdmin from "./AppComponents/TeacherComponents/TeacherDetailsInAdmin";
import AddNewTeacher from "./AppComponents/TeacherComponents/AddNewTeacher";
import AllSubject from "./AppComponents/SubjectComponents/AllSubject";
import AddNewSubject from "./AppComponents/SubjectComponents/AddNewSubject";
import CourseCategory from "./AppComponents/CourseCategoryComponent/CourseCategory";
import AddCourseCategory from "./AppComponents/CourseCategoryComponent/AddCourseCategory";
import EnrollType from "./AppComponents/EnrollTypesComponents/EnrollType";
import AddEnrollType from "./AppComponents/EnrollTypesComponents/AddEnrollType";
import CreateStudentCount from "./StopAppComponet/StudentListComponet/CreateStudentCount";
import SandStudentRequest from "./StopAppComponet/SandStudentRequestComponent/SandStudentRequest";
import AllStudents from "./StopAppComponet/StudentListComponet/AllStudents";
import LogIn from "./AuthEnticationComponent/LogIn";
import SignUp from "./AuthEnticationComponent/SignUp";
import TeacherHome from "./TeacherAppComponets/TeacherHome";
import TeacherMailRoot from "./TeacherAppComponets/TeacherMailRoot";
import MyBatchs from "./TeacherAppComponets/Components/TeacherAllBatches/MyBatchs";
import StudentDetails from "./TeacherAppComponets/Components/TeacherAllBatches/Component/StudentDetailsComponents/StudentDetails";
import BatchClassLink from "./TeacherAppComponets/Components/TeacherAllBatches/Component/BatchLinkComponent/BatchClassLink";
import BatchDetails from "./TeacherAppComponets/Components/TeacherAllBatches/Component/BatchDetailsComponents/BatchDetails";
import AddClassLinks from "./TeacherAppComponets/Components/TeacherAllBatches/Component/BatchLinkComponent/AddClassLinks";
import StaffMainRoot from "./StopAppComponet/StaffMainRoot";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setAuthStatus } from "./Redux/Reduuces/AuthReducer";
import CourseImage from "./AppComponents/CourcesComponents/CourseImageComponent/CourseImage";
import CourseTypeImage from "./AppComponents/CourcesComponents/Components/CourseTypeImageComponent/CourseTypeImage";
import ToolImage from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseTools/ToolImageComponent/ToolImage";
import TopicVideo from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/Components/LessonCompo/TopicVideoComponent/TopicVideo";
import CourseTypeVideo from "./AppComponents/CourcesComponents/Components/CourseTypeVideo";
import PlacementImage from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchCoursePlacementsCompo/placementImageComponent/placementImage";
import BatchRecodedVideoSubject from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchRecordedVideoComponent/BatchRecodedVideoSubject";
import AddBatchRecodedVideoSubject from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchRecordedVideoComponent/AddBatchRecodedVideoSubject";
import AddNewRecordedSubjectVideo from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchRecordedVideoComponent/components/AddNewRecordedSubjectVideo";
import AllSubjectRecodedVideoes from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchRecordedVideoComponent/components/AllSubjectRecodedVideoes";
import AllSalesExecutives from "./AppComponents/SelesExecutiveComponent/AllSalesExecutives";
import AddNewSalesExecutive from "./AppComponents/SelesExecutiveComponent/AddNewSalesExecutive";
import AddNewTopicVideo from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/Components/LessonCompo/TopicVideoComponent/AddNewTopicVideo";
import MyWorkShopBatch from "./TeacherAppComponets/Components/WorkShopBatchConponents/MyWorkShopBatch";
import WorkShopStudentDetails from "./TeacherAppComponets/Components/WorkShopBatchConponents/Component/WorkShopStudentDetailsComponents/WorkShopStudentDetails";
import WorkShopBatchClassLink from "./TeacherAppComponets/Components/WorkShopBatchConponents/Component/BatchLinkComponent/WorkShopBatchClassLink";
import AddWorkShopClassLinks from "./TeacherAppComponets/Components/WorkShopBatchConponents/Component/BatchLinkComponent/AddWorkShopClassLinks";
import WorkShopBatchDetailsInTeacher from "./TeacherAppComponets/Components/WorkShopBatchConponents/Component/WorkShopBatchDetailsComponents/WorkShopBatchDetailsInTeacher";
import RecodedStudentRoot from "./AppComponents/RecordedStudents/RecodedStudentRoot";
import AllRecordedStudentWithMenter from "./AppComponents/RecordedStudents/AllRecordedStudentWithMenter";
import AllRecordedStudentWithOutMenter from "./AppComponents/RecordedStudents/AllRecordedStudentWithOutMenter";
import AddMenterToTheRecodedStudent from "./AppComponents/RecordedStudents/components/AddMenterToTheRecodedStudent";
import RecodedStudentDetails from "./AppComponents/RecordedStudents/StudentCompo/RecodedStudentDetails";
import StudentMenterDetails from "./AppComponents/RecordedStudents/StudentCompo/StudentMenterDetails";
import MyRecodedStudents from "./TeacherAppComponets/Components/MyRecodedStudentComponents/MyRecodedStudents";
import RecodedStudentTokens from "./TeacherAppComponets/Components/MyRecodedStudentComponents/RecodedStudentTokensComponents/RecodedStudentTokens";
import RecordedStudentActiveToken from "./TeacherAppComponets/Components/MyRecodedStudentComponents/RecodedStudentTokensComponents/RecordedStudentActiveToken";
import RecodedStudentTokenMasseges from "./TeacherAppComponets/Components/MyRecodedStudentComponents/RecodedStudentTokensComponents/Components/RecodedStudentTokenMasseges";
import RecordedStudentPlacements from "./TeacherAppComponets/Components/MyRecodedStudentComponents/RecodedStudentPlacementsComponents/RecordedStudentPlacements";
import RecodedStudentSessions from "./TeacherAppComponets/Components/MyRecodedStudentComponents/RecodedStudentSessionsComponents/RecodedStudentSessions";
import StudentCourseStatus from "./TeacherAppComponets/Components/MyRecodedStudentComponents/StudentCourseStatusComponents/StudentCourseStatus";
import AddRecordedStudentPlacements from "./TeacherAppComponets/Components/MyRecodedStudentComponents/RecodedStudentPlacementsComponents/AddRecordedStudentPlacements";
import AddNewSession from "./TeacherAppComponets/Components/MyRecodedStudentComponents/RecodedStudentSessionsComponents/AddNewSession";
import RecodedStudentPlacementImages from "./TeacherAppComponets/Components/MyRecodedStudentComponents/RecodedStudentPlacementsComponents/Components/RecodedStudentPlacementImages";
import ModuleImage from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/ModuleImageComponents/ModuleImage";
import RecodedSubjectImage from "./AppComponents/BatchComponents/Components/SubBatchComponent/BatchCourseComponents/BatchRecordedVideoComponent/RecodedSubjectImageComponents/RecodedSubjectImage";
import MainTeamMember from "./AppComponents/TeamMemberComponents/MainTeamMemberConponents/MainTeamMember";
import AddMainTeamMember from "./AppComponents/TeamMemberComponents/MainTeamMemberConponents/AddMainTeamMember";
import MainTeamMemberImage from "./AppComponents/TeamMemberComponents/MainTeamMemberConponents/Components/MainTeamMemberImage";
import CoreTeamMember from "./AppComponents/TeamMemberComponents/CoreTeamMemberComponents/CoreTeamMember";
import AddCoreTeamMember from "./AppComponents/TeamMemberComponents/CoreTeamMemberComponents/AddCoreTeamMember";
import CoreTeamMemberImage from "./AppComponents/TeamMemberComponents/CoreTeamMemberComponents/Components/CoreTeamMemberImage";
import AllBlogs from "./AppComponents/BlogComponents/AllBlogs";
import AddBlog from "./AppComponents/BlogComponents/AddBlog";
import BlogDetails from "./AppComponents/BlogComponents/Components/BlogDetails";
import AddBlogDetails from "./AppComponents/BlogComponents/Components/AddBlogDetails";
import BlogImage from "./AppComponents/BlogComponents/BlogImageComponent/BlogImage";
import BlogComments from "./AppComponents/BlogComponents/Components/BlogComments";
//import StudentDetailsFormCompotent from "./AppComponents/StudentDetailsComponents/StudentDetailsFormCompotent";
import Branch from "./AppComponents/CourcesComponents/Branch";
import AddBranch from "./AppComponents/CourcesComponents/AddBranch";
import RecentPlacements from "./AppComponents/RecentPlacementsComponent/RecentPlacements";
import AddRecentPlacements from "./AppComponents/RecentPlacementsComponent/AddRecentPlacements";
import TrainerLogInWithOtp from "./AuthEnticationComponent/LoginWithOtpCompo/TrainerLogInWithOtp";
import AdminLogWithOtp from "./AuthEnticationComponent/LoginWithOtpCompo/AdminLogWithOtp";
import RecentPlacementDetails from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/RecentPlacementDetails";
import RecentPlacementSkillLearned from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/recentPlacementSkillLearnedCompo/RecentPlacementSkillLearned";
import RecentPlacementProjects from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/RecentPlacementProjectsCompo/RecentPlacementProjects";
import PlacemetAcadamicQualification from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/PlacemetAcadamicQualificationCompo/PlacemetAcadamicQualification";
import RecentPlacementProfileImage from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/RecentPlacementProfileImageCompo/RecentPlacementProfileImage";
import RecentPlacementCertificate from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/RecentPlacementCertificateCompo/RecentPlacementCertificate";
import AddRecentPlacementSkillLearned from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/recentPlacementSkillLearnedCompo/AddRecentPlacementSkillLearned";
import AddRecentPlacementProjects from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/RecentPlacementProjectsCompo/AddRecentPlacementProjects";
import AddRecentPlacementProfileImage from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/RecentPlacementProfileImageCompo/AddRecentPlacementProfileImage";
import AddRecentPlacementCertificate from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/RecentPlacementCertificateCompo/AddRecentPlacementCertificate";
import AddPlacemetAcadamicQualification from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/PlacemetAcadamicQualificationCompo/AddPlacemetAcadamicQualification";
import RecentPlacementProjectImage from "./AppComponents/RecentPlacementsComponent/RecentPlacementDetailsCompo/Component/RecentPlacementProjectsCompo/RecentPlacementProjectImage";
import LandingPage from "./AppComponents/LandingPageConponent/LandingPage";
import AddLandingPage from "./AppComponents/LandingPageConponent/AddLandingPage";
import TopicAssignment from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/Components/LessonCompo/TopicAssignmentComponent/TopicAssignment";
import AddTopicAssignment from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseModuleCompo/Components/LessonCompo/TopicAssignmentComponent/AddTopicAssignment";
import CoursePosition from "./AppComponents/CoursePositionComponent/CoursePosition";
import AddCoursePosition from "./AppComponents/CoursePositionComponent/AddCoursePosition";
import StudentDetail from "./AppComponents/StudentDetailByIdConponent/StudentDetail";
import StudentCoueseDetail from "./AppComponents/StudentDetailByIdConponent/Components/StudentCoueseDetail";
import VideoModules from "./AppComponents/StudentDetailByIdConponent/Components/ModuleCompo/VideoModules";
import ModulesLesson from "./AppComponents/StudentDetailByIdConponent/Components/ModuleCompo/LessonCompo/ModulesLesson";
import FAQ from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/FAQComponent/FAQ";
import AddFAQ from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/FAQComponent/AddFAQ";
import ProgramHighlight from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/programHighlightComponent/programHighlight";
import AddprogramHighlight from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/programHighlightComponent/AddprogramHighlight";
import CereerService from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CereerServiceComponents/CereerService";
import AddCereerService from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CereerServiceComponents/AddCereerService";
import CereerServiceDetails from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CereerServiceComponents/Components/CereerServiceDetails";
import AddCereerServiceDetails from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CereerServiceComponents/Components/AddCereerServiceDetails";
import CereerServiceDetailsImage from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CereerServiceComponents/Components/CereerServiceDetailsImage";
import ProgramStructure1 from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/ProgramStructure1Component/ProgramStructure1";
import AddProgramStructure1 from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/ProgramStructure1Component/AddProgramStructure1";
import EmployeeRole from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/EmployeeRoleComponents/EmployeeRole";
import AddEmployeeRole from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/EmployeeRoleComponents/AddEmployeeRole";
import ProgramStructure2 from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/ProgramStructure2Component/ProgramStructure2";
import AddProgramStructure2 from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/ProgramStructure2Component/AddProgramStructure2";
import ProgramStructure2Lesson from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/ProgramStructure2Component/Component/ProgramStructure2Lesson";
import AddProgramStructure2Lesson from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/ProgramStructure2Component/Component/AddProgramStructure2Lesson";
import ToolsToMaster from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/ToolsToMastertComponents/ToolsToMaster";
import BranchTypes from "./AppComponents/BranchTypesComponent/BranchTypes";
import AddBranchTypes from "./AppComponents/BranchTypesComponent/AddBranchTypes";
import BranchCategory from "./AppComponents/BranchCategoryComponent/BranchCategory";
import AddBranchCategory from "./AppComponents/BranchCategoryComponent/AddBranchCategory";
import AwsVideoBranch from "./AppComponents/AwsVideoComponents/AwsVideoBranch";
import AddAwsVideoBranch from "./AppComponents/AwsVideoComponents/AddAwsVideoBranch";
import AwsVideoBranchCourse from "./AppComponents/AwsVideoComponents/Components/AwsVideoBranchCourse";
import AddAwsVideoBranchCourse from "./AppComponents/AwsVideoComponents/Components/AddAwsVideoBranchCourse";
import AwsVideoCourseType from "./AppComponents/AwsVideoComponents/Components/CourseTypeComponents/AwsVideoCourseType";
import AddAwsVideoCourseType from "./AppComponents/AwsVideoComponents/Components/CourseTypeComponents/AddAwsVideoCourseType";
import AwsVideoModule from "./AppComponents/AwsVideoComponents/Components/CourseTypeComponents/ModuleComponent/AwsVideoModule";
import AddAwsVideoModule from "./AppComponents/AwsVideoComponents/Components/CourseTypeComponents/ModuleComponent/AddAwsVideoModule";
import AwsVideoLesson from "./AppComponents/AwsVideoComponents/Components/CourseTypeComponents/LessonComponent/AwsVideoLesson";
import AddAwsVideoLesson from "./AppComponents/AwsVideoComponents/Components/CourseTypeComponents/LessonComponent/AddAwsVideoLesson";
import AwsTopicVideo from "./AppComponents/AwsVideoComponents/Components/CourseTypeComponents/TopicComponents/AwsTopicVideo";
import CourseTypeCertificate from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/CourseTypeCertificateComponent/CourseTypeCertificate";
import AluminiWorksAtImage from "./AppComponents/CourcesComponents/Components/TypeComponents/CourseDetailsComponents/AluminWorkAtImageComponente/AluminiWorksAtImage";
import Events from "./AppComponents/EventsComponents/Events";
import AddEvents from "./AppComponents/EventsComponents/AddEvents";
import EventType from "./AppComponents/EventsComponents/Components/EventType";
import AddEventType from "./AppComponents/EventsComponents/Components/AddEventType";
import EventTypeImage from "./AppComponents/EventsComponents/Components/component/EventTypeImageComponent/EventTypeImage";
import EventTypeVideo from "./AppComponents/EventsComponents/Components/component/EventTypeVideoComponent/EventTypeVideo";
import JobDomain from "./AppComponents/JuzzItJobCompo/JobDomain";
import AddJobDomain from "./AppComponents/JuzzItJobCompo/AddJobDomain";
import JuzzItJob from "./AppComponents/JuzzItJobCompo/Components/JuzzItJob";
import AddJuzzItJob from "./AppComponents/JuzzItJobCompo/Components/AddJuzzItJob";
import JobDetails from "./AppComponents/JuzzItJobCompo/Components/Components/JobDetails";
import JobSkillRequireds from "./AppComponents/JuzzItJobCompo/Components/Components/jobSkillRequiredsCompo/JobSkillRequireds";
import AddJobSkillRequireds from "./AppComponents/JuzzItJobCompo/Components/Components/jobSkillRequiredsCompo/AddJobSkillRequireds";
import JobResponcibilitys from "./AppComponents/JuzzItJobCompo/Components/Components/jobResponcibilitysCompo/JobResponcibilitys";
import AddJobResponcibilitys from "./AppComponents/JuzzItJobCompo/Components/Components/jobResponcibilitysCompo/AddJobResponcibilitys";
import JobRequirements from "./AppComponents/JuzzItJobCompo/Components/Components/jobRequirementsCompo/JobRequirements";
import AddJobRequirements from "./AppComponents/JuzzItJobCompo/Components/Components/jobRequirementsCompo/AddJobRequirements";
import JobRequiredQualification from "./AppComponents/JuzzItJobCompo/Components/Components/jobRequiredQualificationCompo/JobRequiredQualification";
import AddJobRequiredQualification from "./AppComponents/JuzzItJobCompo/Components/Components/jobRequiredQualificationCompo/AddJobRequiredQualification";
import JobLocations from "./AppComponents/JuzzItJobCompo/Components/Components/jobLocationsCompo/JobLocations";
import AddJobLocations from "./AppComponents/JuzzItJobCompo/Components/Components/jobLocationsCompo/AddJobLocations";
import JobImages from "./AppComponents/JuzzItJobCompo/Components/Components/jobImagesCompo/JobImages";
import LandingPageDetails from "./AppComponents/LandingPageConponent/Components/LandingPageDetails";
import LandingPageApplicants from "./AppComponents/LandingPageConponent/Components/LandingPageApplicantsCompo/LandingPageApplicants";
import LandingPageBanner from "./AppComponents/LandingPageConponent/Components/LandingPageBannerCompo/LandingPageBanner";
import LandingPageKeyHighLites from "./AppComponents/LandingPageConponent/Components/LandingPageKeyHighLitesCompo/LandingPageKeyHighLites";
import LandingPageObjectives from "./AppComponents/LandingPageConponent/Components/LandingPageObjectivesCompo/LandingPageObjectives";
import AddLandingPageObjective from "./AppComponents/LandingPageConponent/Components/LandingPageObjectivesCompo/AddLandingPageObjective";
import AddLandingPageKeyHighLite from "./AppComponents/LandingPageConponent/Components/LandingPageKeyHighLitesCompo/AddLandingPageKeyHighLite";
import JobApplicants from "./AppComponents/JuzzItJobCompo/Components/Components/jobApplicantsCompo/JobApplicants";

const LogInRouter = createBrowserRouter([
  { path: "/", element: <LogIn /> },
  {
    path: "/signUp",
    element: <SignUp />,
  },
  {
    path: "/TrainerLogInWithOtp",
    element: <TrainerLogInWithOtp />,
  },
  {
    path: "/AdminLogINWithOTP",
    element: <AdminLogWithOtp />,
  },
]);

const TeacherRouter = createBrowserRouter([
  {
    path: "/",
    element: <TeacherMailRoot />,
    children: [
      { path: "/", element: <TeacherHome /> },
      { path: "/teachers/batchs", element: <MyBatchs /> },

      {
        path: "/teachers/batch/:batchCourseId/Students",
        element: <StudentDetails />,
      },

      {
        path: "/teacher/batch/:batchCourseId/classLink/:batchCourseSubjectId",
        element: <BatchClassLink />,
      },
      {
        path: "/teacher/batch/:batchCourseId/details",
        element: <BatchDetails />,
      },
      {
        path: "/teacher/batch/:batchCourseId/classLink/:batchCourseSubjectId/addClassLink",
        element: <AddClassLinks />,
      },

      ///workShop
      { path: "/teachers/WorkShopBatchs", element: <MyWorkShopBatch /> },
      {
        path: "/teachers/WorkShopBatchs/:WorkShopbatchId/Students",
        element: <WorkShopStudentDetails />,
      },
      {
        path: "/teacher/WorkShopBatchs/:WorkShopbatchId/details",
        element: <WorkShopBatchDetailsInTeacher />,
      },
      {
        path: "/teacher/WorkShopBatchs/:WorkShopbatchId/classLink/:WorkShopbatchSubjectId/addClassLink",
        element: <AddWorkShopClassLinks />,
      },
      {
        path: "/teacher/WorkShopBatchs/:WorkShopbatchId/classLink/:WorkShopbatchSubjectId",
        element: <WorkShopBatchClassLink />,
      },

      /// recoded student

      { path: "/teachers/recodedStudent", element: <MyRecodedStudents /> },
      {
        path: "/teachers/recodedStudent/:studentId/placements",
        element: <RecordedStudentPlacements />,
      },
      {
        path: "/teachers/recodedStudent/:studentId/placements/:placementId/images",
        element: <RecodedStudentPlacementImages />,
      },
      {
        path: "/teachers/recodedStudent/:studentId/placements/addPlacements",
        element: <AddRecordedStudentPlacements />,
      },
      {
        path: "/teachers/recodedStudent/:studentId/session",
        element: <RecodedStudentSessions />,
      },
      {
        path: "/teachers/recodedStudent/:studentId/courseStatus",
        element: <StudentCourseStatus />,
      },
      {
        path: "/teachers/recodedStudent/:studentId/session/addSessions",
        element: <AddNewSession />,
      },
      {
        path: "/teachers/recodedStudent/active/tokens",
        element: <RecordedStudentActiveToken />,
      },
      {
        path: "/teachers/recodedStudent/tokens",
        element: <RecodedStudentTokens />,
      },
      {
        path: "/teachers/recodedStudent/tokens/:tokenId/masseges",
        element: <RecodedStudentTokenMasseges />,
      },
    ],
  },
]);

const StaffRouter = createBrowserRouter([
  {
    path: "/",
    element: <StaffMainRoot />,
    children: [
      { path: "/requests", element: <EnrollRequests /> },
      { path: "/createStudentAcount", element: <CreateStudentCount /> },
      {
        path: "/Student/:studentId/sendEnrollRequest",
        element: <SandStudentRequest />,
      },
      { path: "/SandStudentRequest", element: <SandStudentRequest /> },
      {
        path: "/requests/:requestId/details",
        element: <EnrollRequestsDetails />,
      },
    ],
  },
]);

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/Events", element: <Events /> },
      { path: "/Events/add", element: <AddEvents /> },
      { path: "/Events/:eventId/EventType", element: <EventType /> },
      { path: "/Events/:eventId/EventType/add", element: <AddEventType /> },
      {
        path: "/Events/:eventId/EventType/:eventTypeId/images",
        element: <EventTypeImage />,
      },
      {
        path: "/Events/:eventId/EventType/:eventTypeId/videos",
        element: <EventTypeVideo />,
      },

      { path: "/JobDomain", element: <JobDomain /> },
      { path: "/JobDomain/add", element: <AddJobDomain /> },
      { path: "/JobDomain/:jobDomainId/jobs", element: <JuzzItJob /> },
      { path: "/JobDomain/:jobDomainId/jobs/add", element: <AddJuzzItJob /> },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobDetails",
        element: <JobDetails />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobApplicants",
        element: <JobApplicants />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobSkillRequireds",
        element: <JobSkillRequireds />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobSkillRequireds/add",
        element: <AddJobSkillRequireds />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobResponcibilitys",
        element: <JobResponcibilitys />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobResponcibilitys/add",
        element: <AddJobResponcibilitys />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobRequirements",
        element: <JobRequirements />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobRequirements/add",
        element: <AddJobRequirements />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobRequiredQualification",
        element: <JobRequiredQualification />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobRequiredQualification/add",
        element: <AddJobRequiredQualification />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobLocations",
        element: <JobLocations />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/JobLocations/add",
        element: <AddJobLocations />,
      },
      {
        path: "/JobDomain/:jobDomainId/jobs/:jobId/jobImages",
        element: <JobImages />,
      },

      { path: "/BranchTypes", element: <BranchTypes /> },
      { path: "/BranchTypes/add", element: <AddBranchTypes /> },
      { path: "/BranchCategorys", element: <BranchCategory /> },
      { path: "/BranchCategorys/add", element: <AddBranchCategory /> },

      { path: "/AwsVideoBranch", element: <AwsVideoBranch /> },
      { path: "/AwsVideoBranch/add", element: <AddAwsVideoBranch /> },
      {
        path: "/AwsVideoBranch/:awsVideoBranchId/courses",
        element: <AwsVideoBranchCourse />,
      },
      {
        path: "/AwsVideoBranch/:awsVideoBranchId/courses/add",
        element: <AddAwsVideoBranchCourse />,
      },
      {
        path: "/AwsVideoBranch/:awsVideoBranchId/courses/:AwsVideoBranchCourseId/courseTypes",
        element: <AwsVideoCourseType />,
      },
      {
        path: "/AwsVideoBranch/:awsVideoBranchId/courses/:AwsVideoBranchCourseId/courseTypes/add",
        element: <AddAwsVideoCourseType />,
      },
      {
        path: "/AwsVideoBranch/:awsVideoBranchId/courses/:AwsVideoBranchCourseId/courseTypes/:awsVideoCourseTypeId/modules",
        element: <AwsVideoModule />,
      },
      {
        path: "/AwsVideoBranch/:awsVideoBranchId/courses/:AwsVideoBranchCourseId/courseTypes/:awsVideoCourseTypeId/modules/add",
        element: <AddAwsVideoModule />,
      },
      {
        path: "/AwsVideoBranch/:awsVideoBranchId/courses/:AwsVideoBranchCourseId/courseTypes/:awsVideoCourseTypeId/modules/:awsVideoModuleId/lessons",
        element: <AwsVideoLesson />,
      },
      {
        path: "/AwsVideoBranch/:awsVideoBranchId/courses/:AwsVideoBranchCourseId/courseTypes/:awsVideoCourseTypeId/modules/:awsVideoModuleId/lessons/add",
        element: <AddAwsVideoLesson />,
      },
      {
        path: "/AwsVideoBranch/:awsVideoBranchId/courses/:AwsVideoBranchCourseId/courseTypes/:awsVideoCourseTypeId/modules/:awsVideoModuleId/lessons/:awsVideoLesson/topicVideos",
        element: <AwsTopicVideo />,
      },

      // {
      //   path: "StudentDetailsFormCompotent",
      //   element: <StudentDetailsFormCompotent />,
      // },
      { path: "/coursePosition", element: <CoursePosition /> },
      { path: "/coursePosition/add", element: <AddCoursePosition /> },
      { path: "/StudentDetails", element: <StudentDetail /> },
      { path: "/StudentDetails/:studentId", element: <StudentCoueseDetail /> },
      {
        path: "/StudentDetails/:studentId/:studentBatchId/:courseTypeId/details",
        element: <VideoModules />,
      },
      {
        path: "/StudentDetails/:studentId/:studentBatchId/:courseTypeId/details/module/:moduleId",
        element: <ModulesLesson />,
      },
      { path: "/MainTeamMember", element: <MainTeamMember /> },
      { path: "/CoreTeamMember", element: <CoreTeamMember /> },
      { path: "/MainTeamMember/addMember", element: <AddMainTeamMember /> },
      { path: "/CoreTeamMember/addMember", element: <AddCoreTeamMember /> },
      {
        path: "/MainTeamMember/:memberId/images",
        element: <MainTeamMemberImage />,
      },
      {
        path: "/CoreTeamMember/:memberId/images",
        element: <CoreTeamMemberImage />,
      },

      { path: "/blogs", element: <AllBlogs /> },
      { path: "/blogs/:blogId/images", element: <BlogImage /> },
      { path: "/blogs/:blogId/comments", element: <BlogComments /> },
      { path: "/blogs/:blogId/details", element: <BlogDetails /> },
      {
        path: "/blogs/:blogId/details/addDetails",
        element: <AddBlogDetails />,
      },
      { path: "/blogs/addblog", element: <AddBlog /> },
      /* landing page start */

      { path: "/landingPages", element: <LandingPage /> },
      { path: "/landingPages/add", element: <AddLandingPage /> },
      {
        path: "/landingPages/:pageId/:pageName/details",
        element: <LandingPageDetails />,
      },
      {
        path: "/landingPages/:pageId/:pageName/applicants",
        element: <LandingPageApplicants />,
      },
      {
        path: "/landingPages/:pageId/:pageName/banner",
        element: <LandingPageBanner />,
      },
      {
        path: "/landingPages/:pageId/:pageName/keyHighlite",
        element: <LandingPageKeyHighLites />,
      },
      {
        path: "/landingPages/:pageId/:pageName/keyHighlite/add",
        element: <AddLandingPageKeyHighLite />,
      },
      {
        path: "/landingPages/:pageId/:pageName/objectives",
        element: <LandingPageObjectives />,
      },
      {
        path: "/landingPages/:pageId/:pageName/objectives/add",
        element: <AddLandingPageObjective />,
      },

      /* landing page end */

      /* recent placment start */
      { path: "/recentplacements", element: <RecentPlacements /> },
      {
        path: "/recentplacements/addStudent",
        element: <AddRecentPlacements />,
      },

      {
        path: "/recentplacements/:recentplacementId/details",
        element: <RecentPlacementDetails />,
      },
      {
        path: "/recentplacements/:recentplacementId/acadamicQualification",
        element: <PlacemetAcadamicQualification />,
      },
      {
        path: "/recentplacements/:recentplacementId/acadamicQualification/add",
        element: <AddPlacemetAcadamicQualification />,
      },
      {
        path: "/recentplacements/:recentplacementId/skillsLearned",
        element: <RecentPlacementSkillLearned />,
      },
      {
        path: "/recentplacements/:recentplacementId/skillsLearned/add",
        element: <AddRecentPlacementSkillLearned />,
      },
      {
        path: "/recentplacements/:recentplacementId/certificate",
        element: <RecentPlacementCertificate />,
      },
      {
        path: "/recentplacements/:recentplacementId/certificate/add",
        element: <AddRecentPlacementCertificate />,
      },
      {
        path: "/recentplacements/:recentplacementId/ProfileImage",
        element: <RecentPlacementProfileImage />,
      },
      {
        path: "/recentplacements/:recentplacementId/ProfileImage/add",
        element: <AddRecentPlacementProfileImage />,
      },
      {
        path: "/recentplacements/:recentplacementId/projects",
        element: <RecentPlacementProjects />,
      },
      {
        path: "/recentplacements/:recentplacementId/projects/:projectId/images",
        element: <RecentPlacementProjectImage />,
      },
      {
        path: "/recentplacements/:recentplacementId/projects/add",
        element: <AddRecentPlacementProjects />,
      },

      /* recent placment end */
      {
        path: "/branch",
        element: <RootCourse />,
        children: [
          { path: "", element: <Branch /> },
          { path: "addbranch", element: <AddBranch /> },
          {
            path: ":branchId/courses",
            element: <Courses />,
          },
          { path: ":branchId/courses/addcourse", element: <AddCourse /> },
          {
            path: ":branchId/courses/:courseId/courseTypes",
            element: <CourseTypes />,
          },
          {
            path: ":branchId/courses/:courseId/addCourseTypes",
            element: <AddCourseType />,
          },
          {
            path: ":branchId/courses/:courseId/images",
            element: <CourseImage />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/images",
            element: <CourseTypeImage />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/videos",
            element: <CourseTypeVideo />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/details",
            element: <CourseTypeDetails />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/details/addDetails",
            element: <AddCourseTypeDetails />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/essentials",
            element: <Essentials />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/essentials/addEssentials",
            element: <AddEssentials />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/highlights",
            element: <Highlights />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/highlights/addHighlights",
            element: <AddHighlights />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models",
            element: <Models />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/:moduleId/images",
            element: <ModuleImage />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/addModels",
            element: <AddModels />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/:moduleId/lessons",
            element: <Lesson />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/:moduleId/lessons/addLessons",
            element: <AddLesson />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/:moduleId/lessons/:lessonId/topics/addTopics",
            element: <AddTopics />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/:moduleId/lessons/:lessonId/topics",
            element: <Topics />,
          },

          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/:moduleId/lessons/:lessonId/topics/:topicId/videos",
            element: <TopicVideo />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/:moduleId/lessons/:lessonId/topics/:topicId/videos/addVideo",
            element: <AddNewTopicVideo />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/:moduleId/lessons/:lessonId/topics/:topicId/assignment",
            element: <TopicAssignment />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/models/:moduleId/lessons/:lessonId/topics/:topicId/assignment/add",
            element: <AddTopicAssignment />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/objectievs",
            element: <Objectievs />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/objectievs/addObjectievs",
            element: <AddObjectievs />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/projects",
            element: <Projects />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/projects/addProjects",
            element: <AddProjects />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/tools",
            element: <Tools />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/faqs",
            element: <FAQ />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/faqs/add",
            element: <AddFAQ />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/programHighlight",
            element: <ProgramHighlight />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/programHighlight/add",
            element: <AddprogramHighlight />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/CereerService",
            element: <CereerService />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/CereerService/add",
            element: <AddCereerService />,
          },

          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/ProgramStructure1",
            element: <ProgramStructure1 />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/ProgramStructure1/add",
            element: <AddProgramStructure1 />,
          },

          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/ProgramStructure2",
            element: <ProgramStructure2 />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/ProgramStructure2/add",
            element: <AddProgramStructure2 />,
          },

          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/ProgramStructure2/:structureId/lessons",
            element: <ProgramStructure2Lesson />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/ProgramStructure2/:structureId/lessons/add",
            element: <AddProgramStructure2Lesson />,
          },

          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/certificate",
            element: <CourseTypeCertificate />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/EmployeeRole",
            element: <EmployeeRole />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/AluminiWorksAtImage",
            element: <AluminiWorksAtImage />,
          },

          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/ToolsToMaster",
            element: <ToolsToMaster />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/EmployeeRole/add",
            element: <AddEmployeeRole />,
          },

          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/CereerService/:CereerServiceId/details",
            element: <CereerServiceDetails />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/CereerService/:CereerServiceId/details/:detailsId/images",
            element: <CereerServiceDetailsImage />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/CereerService/:CereerServiceId/details/add",
            element: <AddCereerServiceDetails />,
          },

          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/tools/:toolId/images",
            element: <ToolImage />,
          },
          {
            path: ":branchId/courses/:courseId/courseTypes/:courseTypeId/tools/addTools",
            element: <AddTools />,
          },
        ],
      },
      {
        path: "/batch",
        element: <RootBatch />,

        children: [
          { path: "", element: <Batch /> },
          { path: "addBatch", element: <AddBatch /> },
          { path: ":batchId/subBatch", element: <SubBatch /> },
          { path: ":batchId/subBatch/addSubBatch", element: <AddSubBatch /> },
          {
            path: ":batchId/subBatch/:subBatchId/courses",
            element: <BatchCourse />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/addCourse",
            element: <AddBatchCourse />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/details",
            element: <BatchCourseDetails />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/students",
            element: <CourseStudent />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/subjects",
            element: <CourseSubject />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/subjects/addSubject",
            element: <AddCourseSubject />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/placements",
            element: <Placements />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/placements/:placementId/images",
            element: <PlacementImage />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/placements/addPlacements",
            element: <AddPlacements />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/email",
            element: <SandEmail />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/recordedSubject",
            element: <BatchRecodedVideoSubject />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/recordedSubject/:subjectId/images",
            element: <RecodedSubjectImage />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/recordedSubject/addSubject",
            element: <AddBatchRecodedVideoSubject />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/recordedSubject/:subjectId/videos",
            element: <AllSubjectRecodedVideoes />,
          },
          {
            path: ":batchId/subBatch/:subBatchId/courses/:batchCourseId/recordedSubject/:subjectId/videos/addVideo",
            element: <AddNewRecordedSubjectVideo />,
          },
        ],
      },

      { path: "/enrollRequests", element: <EnrollRequests /> },
      { path: "/SalesExecutives", element: <AllSalesExecutives /> },
      {
        path: "/SalesExecutives/AddSalesExecutive",
        element: <AddNewSalesExecutive />,
      },
      {
        path: "/enrollRequestS/:requestId/details",
        element: <EnrollRequestsDetails />,
      },
      { path: "/teachers", element: <AllTeachers /> },
      {
        path: "/teachers/:teacherId/details",
        element: <TeacherDetailsInAdmin />,
      },
      { path: "/teachers/addTeacher", element: <AddNewTeacher /> },
      { path: "/subjects", element: <AllSubject /> },
      { path: "/subjects/addSubject", element: <AddNewSubject /> },
      { path: "/categorys", element: <CourseCategory /> },
      { path: "/categorys/addcategory", element: <AddCourseCategory /> },
      { path: "/enrollTypes", element: <EnrollType /> },
      { path: "/enrollTypes/addEnrollType", element: <AddEnrollType /> },
      { path: "/CreateStudentCount", element: <CreateStudentCount /> },
      { path: "/SandStudentRequest", element: <SandStudentRequest /> },
      { path: "/AllStudents", element: <AllStudents /> },

      {
        path: "/recodedStudents",
        element: <RecodedStudentRoot />,
        children: [
          {
            path: "",
            element: <AllRecordedStudentWithOutMenter />,
          },
          {
            path: "withMenter",
            element: <AllRecordedStudentWithMenter />,
          },
          {
            path: ":recodedStudentId/addMenter",
            element: <AddMenterToTheRecodedStudent />,
          },
          {
            path: ":recodedStudentId/student/details",
            element: <RecodedStudentDetails />,
          },
          {
            path: ":recodedStudentId/menter/details",
            element: <StudentMenterDetails />,
          },
        ],
      },
    ],
  },
]);

function App() {
  const AuthStatus = useSelector((state) => state.AuthData.AuthStatus);
  console.log(AuthStatus);

  const [user, setUser] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.userId && userData.userType) {
      setUser(userData);
      dispatch(setAuthStatus(true));
      redirect("/");
    } else {
      dispatch(setAuthStatus(false));
    }
  }, [dispatch]);
  return (
    <div>
      {AuthStatus === false ? (
        <RouterProvider basename="/" router={LogInRouter} />
      ) : (
        <>
          {user?.userType === "ADMIN" && <RouterProvider router={router} />}
          {user?.userType === "TRAINER" && (
            <RouterProvider basename="/" router={TeacherRouter} />
          )}
          {user?.userType === "SALES_EXECUTIVE" && (
            <RouterProvider basename="/" router={StaffRouter} />
          )}
        </>
      )}
    </div>
  );
}

export default App;
