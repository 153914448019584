import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  deleteAwsVideoModule,
  getAwsVideoModule,
} from "../../../../../Redux/ActionCompo/AwsVideoActions";
import { setAwsVideoModule } from "../../../../../Redux/Reduuces/AwsVideoReducer";
const AwsVideoModule = () => {
  const { awsVideoBranchId, AwsVideoBranchCourseId, awsVideoCourseTypeId } =
    useParams();

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const dispatch = useDispatch();
  const awsVideoModule = useSelector(
    (state) => state.AwsVideoData.awsVideoModule
  );

  useEffect(() => {
    const getadata = async () => {
      const data = await getAwsVideoModule(awsVideoCourseTypeId);
      dispatch(setAwsVideoModule(data));
    };
    getadata();
  }, [awsVideoCourseTypeId, dispatch]);

  const navigate = useNavigate();

  const handleUpdateRequest = async (id, type) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to delete the essential ?");
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteAwsVideoModule(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("essential delete successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getAwsVideoModule(awsVideoCourseTypeId);
    dispatch(setAwsVideoModule(data));
  };

  return (
    <div className="FAQ-main-container" style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All program highlight</h4>
          <Link to={"add"} className="link">
            Add New program highlight
          </Link>
        </div>
      </div>

      <Link
        to={`/AwsVideoBranch/${awsVideoBranchId}/courses/${AwsVideoBranchCourseId}/courseTypes`}
        className="back"
      >
        <i class="fa-solid fa-backward"></i>
      </Link>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>Program Highlite</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {awsVideoModule &&
              awsVideoModule?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell
                    onClick={() => {
                      navigate(`${val.AWS_VIDEO_MODULE_ID}/lessons`);
                    }}
                  >
                    {val.AWS_VIDEO_MODULES_NAME}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        handleUpdateRequest(val.AWS_VIDEO_MODULE_ID, "DELETE");
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AwsVideoModule;
