import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./VideoModules.scss";
import { setEnrolledRecodedCourseModules } from "../../../../Redux/Reduuces/StudentDetailsReducer";
import SingleRecodedClassVideoModule from "./SingleRecodedClassVideoModule";
import { getRecodedStudentClassModules } from "../../../../Redux/ActionCompo/StudentDetailActions";

const VideoModules = () => {
  const { courseTypeId, studentBatchId } = useParams();
  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = useState(false);
  const enrolledRecodedCourseModules = useSelector(
    (state) => state.studentDetailsData.enrolledRecodedCourseModules
  );
  useEffect(() => {
    const getData = async () => {
      const data = await getRecodedStudentClassModules(
        studentBatchId,
        courseTypeId
      );
      dispatch(setEnrolledRecodedCourseModules(data));
    };
    getData();
  }, [dispatch, studentBatchId, courseTypeId]);

  // if (isLoading) {
  //   <Loading />;
  // }
  return (
    <div className="EnrolledLiveCourseRecordedVideo-main-container">
      <div className="EnrolledLiveCourseRecordedVideo-main-content">
        {enrolledRecodedCourseModules &&
          enrolledRecodedCourseModules?.map((val, key) => {
            return (
              <SingleRecodedClassVideoModule key={key} modeuleData={val} />
            );
          })}
      </div>
    </div>
  );
};

export default VideoModules;
