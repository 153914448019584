import React, { useEffect } from "react";
import { addallTeachersTostore } from "../../Redux/Reduuces/StudentTeacherAdmineReducer";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  deleteTeacher,
  getAllTeachers,
} from "../../Redux/ActionCompo/StudentTeachetAdminActions";

import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const AllTeachers = () => {
  const allTeachers = useSelector(
    (state) => state.studentTeacherData.allTeachers
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllTeachers();
      dispatch(addallTeachersTostore(data));
    };
    getData();
  }, [dispatch]);

  const deleteHandler = async (teacherId) => {
    const response = await deleteTeacher(teacherId);
    if (response?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div style={{ width: "100%" }} className="AllTeachers-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Teachers</h4>
          <Link to={"addTeacher"} className="link">
            Add New Teacher
          </Link>
        </div>
      </div>

      <div className="AllTeachers-tables">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>TEACHER ID</TableCell>
                <TableCell>TEACHER NAME</TableCell>
                <TableCell>TEACHER EMAIL</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTeachers?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.teacherId}</TableCell>
                  <TableCell>
                    <NavLink
                      to={`${val.teacherId}/details`}
                      className="nev_link"
                    >
                      {val.teacherName}
                    </NavLink>
                  </TableCell>
                  <TableCell>{val.teacherEmail}</TableCell>
                  <TableCell>
                    <IconButton
                      className="delete_button"
                      onClick={() => deleteHandler(val.teacherId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AllTeachers;
