import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./JuzzItJob.scss";
import {
  deleteJobDomainJob,
  getJobDomainJobData,
  updateJobDomainJob,
} from "../../../Redux/ActionCompo/JuzzItJobActions";
import { setJobs } from "../../../Redux/Reduuces/JuzzItJobReducer";

const JuzzItJob = () => {
  const { jobDomainId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.jobData.jobs);

  useEffect(() => {
    const getadata = async () => {
      const data = await getJobDomainJobData(jobDomainId);
      dispatch(setJobs(data));
    };
    getadata();
  }, [jobDomainId, dispatch]);

  const handleUpdateRequest = async (id, type, val) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to delete the essential ?");
    }
    if (type === "ACTIVE_STATUS") {
      setUpdateType("ACTIVE_STATUS");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `do you want to ${val === "A" ? "deactive" : "active"}`
      );
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteJobDomainJob(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("essential delete successfully");
        setOpenSnackbar(true);
      }
    }
    if (updateType === "ACTIVE_STATUS") {
      const data = {
        ACTIVE_STATUS: "change",
      };

      const responce = await updateJobDomainJob(UpdateId, data);
      if (responce?.status === true) {
        setSuccessMessage("essential delete successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getJobDomainJobData(jobDomainId);
    dispatch(setJobs(data));
  };

  const navigate = useNavigate();

  return (
    <div className="Essentials-main-container" style={{ overflow: "auto" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All job</h4>
          <Link to={"add"} className="link">
            Add New job
          </Link>
        </div>
      </div>

      <Link to={`/JobDomain`} className="back">
        <i class="fa-solid fa-backward"></i>
      </Link>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>COMPANY_NAME</TableCell>
              <TableCell>details</TableCell>
              <TableCell>COMPANY_DESCRIPTION</TableCell>
              <TableCell>CATEGORY_OR_ROLE</TableCell>
              <TableCell>JOB_INTRUDUCTION</TableCell>
              <TableCell>EMPLOYEEMENT_TYPE</TableCell>
              <TableCell>CREATED_DATE</TableCell>
              <TableCell>WORK_EXPERIENCE</TableCell>
              <TableCell>WORK_TYPE</TableCell>
              <TableCell>ACTIVE STATUS</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs?.map((val, key) => (
              <TableRow key={key}>
                <TableCell>{key + 1}</TableCell>
                <TableCell>{val.COMPANY_NAME}</TableCell>
                <TableCell
                  onClick={() => {
                    navigate(
                      `/JobDomain/${jobDomainId}/jobs/${val.JOB_ID}/JobDetails`
                    );
                  }}
                >
                  details
                </TableCell>
                <TableCell>{val.COMPANY_DESCRIPTION}</TableCell>
                <TableCell>{val.CATEGORY_OR_ROLE}</TableCell>
                <TableCell>{val.JOB_INTRUDUCTION}</TableCell>
                <TableCell>{val.EMPLOYEEMENT_TYPE}</TableCell>
                <TableCell>{val.CREATED_DATE}</TableCell>
                <TableCell>{val.WORK_EXPERIENCE}</TableCell>
                <TableCell>{val.WORK_TYPE}</TableCell>
                <TableCell>
                  <Button
                    color={val.ACTIVE_STATUS === "A" ? "success" : "error"}
                    variant="contained"
                    onClick={() => {
                      handleUpdateRequest(
                        val.JOB_ID,
                        "ACTIVE_STATUS",
                        val.ACTIVE_STATUS
                      );
                    }}
                  >
                    {val.ACTIVE_STATUS === "A" ? "active" : "Deactive"}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      handleUpdateRequest(val.JOB_ID, "DELETE");
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default JuzzItJob;
