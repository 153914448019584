import React, { useEffect, useState } from "react";
import { addallTeachersTostore } from "../../Redux/Reduuces/StudentTeacherAdmineReducer";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Button,
  TextField,
  Snackbar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./RecentPlacements.scss";
import {
  deleteRecentPlacement,
  getAllRecodedPlacements,
  updateRecodedPlacement,
} from "../../Redux/ActionCompo/GenaralActins";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
const RecentPlacements = () => {
  const [linkedInLink, setLinkedInLink] = useState("");
  const [order, setOrder] = useState(0);

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const allTeachers = useSelector(
    (state) => state.studentTeacherData.allTeachers
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllRecodedPlacements();
      dispatch(addallTeachersTostore(data));
    };
    getData();
  }, [dispatch]);

  const navigate = useNavigate();
  const handleUpdateRequest = async (id, type, status) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the recent placement?"
      );
    } else if (type === "ACTIVE") {
      setUpdateType("ACTIVE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `Are you sure you want to   ${
          status ? "deactive" : "active"
        } recent place ment?`
      );
    } else if (type === "LINKEDIN") {
      setUpdateType("LINKEDIN");
      setOpenDialog(true);
      setUpdateAlertMessage(`Are you sure you want to  update the link?`);
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage(`Are you sure you want to  update the link?`);
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteRecentPlacement(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("recent placement delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ACTIVE") {
      const payload = {
        active_Status: "change",
      };
      const responce = await updateRecodedPlacement(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("details updated successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "LINKEDIN") {
      if (linkedInLink) {
        const payload = {
          linkedin_Link: linkedInLink,
        };
        const responce = await updateRecodedPlacement(UpdateId, payload);
        if (responce?.status === true) {
          setSuccessMessage("LINK  updated successfully");
          setOpenSnackbar(true);
        }
      }
    } else if (updateType === "ORDER") {
      if (order) {
        const payload = {
          order: Number(order),
        };
        const responce = await updateRecodedPlacement(UpdateId, payload);
        if (responce?.status === true) {
          setSuccessMessage("LINK  updated successfully");
          setOpenSnackbar(true);
        }
      }
    }
    const data = await getAllRecodedPlacements();
    dispatch(addallTeachersTostore(data));
  };

  return (
    <div
      className="AllTeachers-main-container"
      style={{ height: "100%", overflow: "auto" }}
    >
      <div className="pageLinkHeader-main" style={{ width: "100%" }}>
        <div className="pageLinkHeader-main-content">
          <h4>All recent pacements</h4>
          <Link to={"addStudent"} className="link">
            Add placedStudent
          </Link>
        </div>
      </div>

      <TextField
        variant="outlined"
        label="Enter link"
        value={linkedInLink}
        onChange={(e) => setLinkedInLink(e.target.value)}
      ></TextField>
      <TextField
        variant="outlined"
        label="Enter order"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <div className="AllTeachers-tables">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="course-id">SI/NO</TableCell>
                <TableCell className="course-name">student Name</TableCell>
                <TableCell className="future-course">Placed Position</TableCell>
                <TableCell className="future-course">placed Company</TableCell>
                <TableCell className="future-course">
                  course UnderTaken
                </TableCell>
                <TableCell className="active-status">linked in link</TableCell>
                <TableCell className="active-status">ACTIVE</TableCell>
                <TableCell className="active-status">link</TableCell>
                <TableCell className="active-status">order</TableCell>
                <TableCell className="delete-button">delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTeachers?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell
                    onClick={() => {
                      navigate(
                        `/recentplacements/${val.recentPlacement_ID}/details`
                      );
                    }}
                  >
                    <Button>{val.StudentName}</Button>
                  </TableCell>
                  <TableCell>{val.PlacedPosition}</TableCell>
                  <TableCell>{val.PlacedCompany}</TableCell>
                  <TableCell>{val.CourseUnderTaken}</TableCell>
                  <TableCell>{val.linkedin_Link}</TableCell>
                  <TableCell>{val.ORDER}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        handleUpdateRequest(
                          val.recentPlacement_ID,
                          "ACTIVE",
                          val.active_Status === "A"
                        );
                      }}
                      variant="contained"
                      size="small"
                      color={`${
                        val.active_Status === "A" ? "success" : "error"
                      }`}
                    >
                      {val.active_Status === "A" ? "Active" : "Deactive"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        handleUpdateRequest(val.recentPlacement_ID, "LINKEDIN")
                      }
                    >
                      update link
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        handleUpdateRequest(val.recentPlacement_ID, "ORDER")
                      }
                    >
                      update order
                    </Button>
                  </TableCell>

                  <TableCell>
                    <IconButton
                      className="delete_button"
                      onClick={() =>
                        handleUpdateRequest(val.recentPlacement_ID, "DELETE")
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default RecentPlacements;
