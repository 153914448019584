import React, { useState } from "react";
import "./SandStudentRequest.scss";
import { sandEnrollRequest } from "../../Redux/ActionCompo/EnrollRequestActions";
const SandStudentRequest = () => {
  const [student_name, setStudent_name] = useState("");
  const [student_email, setStudent_email] = useState("");
  const [student_mobileNumber, setStudent_mobileNumber] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    const newStudent = {
      student_name: student_name,
      enroll_Type: "online",
      student_email: student_email,
      student_mobileNumber: Number(student_mobileNumber),
      country: country,
      state: state,
      district: district,
      pinCode: Number(pinCode),
    };

    const responce = await sandEnrollRequest(
      "STD001",
      "COURTYP001",
      newStudent
    );
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="SandStudentRequest-main-Container">
      <div className="SandStudentRequest-form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <label>Enter student Name</label>
              <input
                type="text"
                placeholder="Enter course Name"
                value={student_name}
                onChange={(e) => setStudent_name(e.target.value)}
              />
              <span>error message</span>
            </div>
            <div className="form-element">
              <label>Enter student email</label>
              <input
                type="text"
                placeholder="Enter course Name"
                value={student_email}
                onChange={(e) => {
                  setStudent_email(e.target.value);
                }}
              />
              <span>error message</span>
            </div>
            <div className="form-element">
              <label>Mobile Number</label>
              <input
                type="number"
                placeholder="Enter course Name"
                value={student_mobileNumber}
                onChange={(e) => setStudent_mobileNumber(e.target.value)}
              />
              <span>error message</span>
            </div>
            <div className="form-element">
              <label>Enter country</label>
              <input
                type="text"
                placeholder="Enter course Name"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
              <span>error message</span>
            </div>
            <div className="form-element">
              <label>Enter state</label>
              <input
                type="text"
                placeholder="Enter course Name"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              />
              <span>error message</span>
            </div>
            <div className="form-element">
              <label>Enter district</label>
              <input
                type="text"
                placeholder="Enter course Name"
                value={district}
                onChange={(e) => {
                  setDistrict(e.target.value);
                }}
              />
              <span>error message</span>
            </div>
            <div className="form-element">
              <label>Enter pinCode</label>
              <input
                type="number"
                placeholder="Enter course Name"
                value={pinCode}
                onChange={(e) => {
                  setPinCode(e.target.value);
                }}
              />
              <span>error message</span>
            </div>

            <button type="submit">Add</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SandStudentRequest;
