import React, { useState } from "react";
import { Link } from "react-router-dom";
import { addNewBatch } from "../../Redux/ActionCompo/BatchActions";
import { Button, Container, Snackbar } from "@mui/material";

const AddBatch = () => {
  // const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    const responce = await addNewBatch();
    if (responce?.status === true) {
      setSuccessMessage("batch for this month created successfully");
      setOpenSnackbar(true);
    } else {
      setSuccessMessage("batch for this is alrady created ");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container className="AddBatch-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={"/batch"} className="link">
            All Batches
          </Link>
          <h4 variant="h4">Add New Batch</h4>
        </div>
      </div>
      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            {/* <div className="form-element">
              <TextField
                type="text"
                label="Enter Batch Name"
                variant="outlined"
                fullWidth
                value={batch_Name}
                onChange={(e) => setBatch_Name(e.target.value)}
                error={Boolean(error)}
                helperText={error}
              />
            </div> */}

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </Container>
  );
};

export default AddBatch;
