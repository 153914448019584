import { myaxios } from "../ApiBase";

import authHeader from "./auth-header";

export const AddNewRecentPlacements = async (recentPlacementData) => {
  try {
    const responce = await myaxios.post(
      `/general/recentPlacements`,
      recentPlacementData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getAllRecodedPlacements = async () => {
  try {
    const responce = await myaxios.get(`/general/recentPlacements`, {
      headers: authHeader(),
    });

    return responce.data;
  } catch (error) {}
};

export const updateRecodedPlacement = async (
  placementId,
  RECENTpLACEMENTRdATA
) => {
  try {
    const responce = await myaxios.put(
      `/general/recentPlacements/${placementId}`,
      RECENTpLACEMENTRdATA,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const deleteRecentPlacement = async (placementId) => {
  try {
    const responce = await myaxios.delete(
      `/general/recentPlacements/${placementId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};
