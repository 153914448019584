import React from "react";
import "./RecordedStudentActiveToken.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
const RecordedStudentActiveToken = () => {
  const navigate = useNavigate();

  const tokens = [
    {
      student_Id: "caas",
      student_Name: "sdcs",
      student_email: "dfgd",
      student_mobileNumber: 9900805697,
      token_Id: "dscs",
      token_topic: "sfs",
      token_desc: "sfsfsdfvbfd dfg dfgvdfv dfvdf vdfvdfvdfvdd jh",
      active_status: "A",
    },
    {
      student_Id: "caas",
      student_Name: "sdcs",
      student_email: "dfgd",
      student_mobileNumber: 9900805697,
      token_Id: "dscs",
      token_topic: "sfs",
      token_desc: "sfsfsdfvbfd dfg dfgvdfv dfvdf vdfvdfvdfvdd jh",
      active_status: "A",
    },
    {
      student_Id: "caas",
      student_Name: "sdcs",
      student_email: "dfgd",
      student_mobileNumber: 9900805697,
      token_Id: "dscs",
      token_topic: "sfs",
      token_desc: "sfsfsdfvbfd dfg dfgvdfv dfvdf vdfvdfvdfvdd jh",
      active_status: "A",
    },
    {
      student_Id: "caas",
      student_Name: "sdcs",
      student_email: "dfgd",
      student_mobileNumber: 9900805697,
      token_Id: "dscs",
      token_topic: "sfs",
      token_desc: "sfsfsdfvbfd dfg dfgvdfv dfvdf vdfvdfvdfvdd jh",
      active_status: "A",
    },
    {
      student_Id: "caas",
      student_Name: "sdcs",
      student_email: "dfgd",
      student_mobileNumber: 9900805697,
      token_Id: "dscs",
      token_topic: "sfs",
      token_desc: "sfsfsdfvbfd dfg dfgvdfv dfvdf vdfvdfvdfvdd jh",
      active_status: "A",
    },
    {
      student_Id: "caas",
      student_Name: "sdcs",
      student_email: "dfgd",
      student_mobileNumber: 9900805697,
      token_Id: "dscs",
      token_topic: "sfs",
      token_desc: "sfsfsdfvbfd dfg dfgvdfv dfvdf vdfvdfvdfvdd jh",
      active_status: "A",
    },
  ];

  ///

  return (
    <div className="RecordedStudentActiveToken-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>active kokens</h4>
          <Link to={"/teachers/recodedStudent/tokens"} className="link">
            All tokens
          </Link>
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>student ID</TableCell>
              <TableCell>student name</TableCell>
              <TableCell>student email</TableCell>
              <TableCell>student mobile number</TableCell>
              <TableCell>token topic</TableCell>
              <TableCell>token desc</TableCell>
              <TableCell>current status</TableCell>
              <TableCell>MESSAGES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokens &&
              tokens?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.student_Id}</TableCell>
                  <TableCell>{val.student_Name}</TableCell>
                  <TableCell>{val.student_email}</TableCell>
                  <TableCell>{val.student_mobileNumber}</TableCell>
                  <TableCell>{val.token_topic}</TableCell>
                  <TableCell>{val.token_desc}</TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      color={val.active_status === "A" ? "success" : "error"}
                      className={`${
                        val.active_status === "A"
                          ? "Active_status"
                          : "Deactive_satus"
                      }`}
                    >
                      {val.active_status === "A" ? "active" : "De Active"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        navigate(
                          ` /teachers/recodedStudent/tokens/${val.token_Id}/masseges`
                        );
                      }}
                    >
                      view
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default RecordedStudentActiveToken;
