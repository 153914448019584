import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextField, Button, Grid, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import "../../Genaral.css";
import { AddNewRecentPlacements } from "../../Redux/ActionCompo/GenaralActins";

const AddRecentPlacements = () => {
  const [studentName, setStudentName] = useState("");
  const [placedPosition, setPlacedPosition] = useState("");
  const [placedCompany, setPlacedCompany] = useState("");
  const [courseUnderTaken, setCourseUnderTaken] = useState("");
  const [linkedin_Link, setlinkedin_Link] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const onsubmitHandler = async (event) => {
    event.preventDefault();

    // Check for empty fields
    if (
      !studentName ||
      !placedPosition ||
      !placedCompany ||
      !courseUnderTaken ||
      !linkedin_Link
    ) {
      setSnackbarSeverity("error");
      setSnackbarMessage("All fields are required");
      setOpenSnackbar(true);
      return;
    }

    const data = {
      studentName: studentName,
      placedPosition: placedPosition,
      placedCompany: placedCompany,
      courseUnderTaken: courseUnderTaken,
      linkedin_Link: linkedin_Link,
    };

    try {
      const response = await AddNewRecentPlacements(data);

      if (response?.status === true) {
        setStudentName("");
        setPlacedPosition("");
        setPlacedCompany("");
        setCourseUnderTaken("");
        setlinkedin_Link("");
        setSnackbarSeverity("success");
        setSnackbarMessage("Recent placement  added successfully");

        // You may redirect the user or perform other actions here
      } else {
        setSnackbarSeverity("error");
        setSnackbarMessage("Failed to add record");
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage("An error occurred");
    }

    setOpenSnackbar(true);
  };

  return (
    <div style={{ width: "100%" }} className="AddNewTeacher-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={"/recentplacements"} className="link">
            All teachers
          </Link>
          <h4>Add New Teacher</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmitHandler}>
            <div className="form-element">
              <TextField
                label="Enter student Name"
                variant="outlined"
                fullWidth
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter placed Position"
                variant="outlined"
                fullWidth
                type="text"
                value={placedPosition}
                onChange={(e) => setPlacedPosition(e.target.value)}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter placed Company"
                variant="outlined"
                fullWidth
                type="text"
                value={placedCompany}
                onChange={(e) => setPlacedCompany(e.target.value)}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter course UnderTaken"
                variant="outlined"
                fullWidth
                type="text"
                value={courseUnderTaken}
                onChange={(e) => setCourseUnderTaken(e.target.value)}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter linked in link"
                variant="outlined"
                fullWidth
                type="text"
                value={linkedin_Link}
                onChange={(e) => setlinkedin_Link(e.target.value)}
              />
            </div>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Add
              </Button>
            </Grid>
          </form>
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AddRecentPlacements;
