import React, { useEffect } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link, NavLink, useParams } from "react-router-dom";
import {
  deleteRecodedStudentPlacements,
  getRecodedStudentPlacements,
  updateRecodedStudentPlacements,
} from "../../../../Redux/ActionCompo/TeacherActions";
import { useDispatch, useSelector } from "react-redux";
import { addteacher_recodedStudent_placements } from "../../../../Redux/Reduuces/TeacherReducers";
import "../../../../Genaral.css";
const RecordedStudentPlacements = () => {
  const teacher_recodedStudent_placements = useSelector(
    (state) => state.teacher.teacher_recodedStudent_placements
  );
  const dispatch = useDispatch();

  const { studentId } = useParams();
  useEffect(() => {
    const getData = async () => {
      const data = await getRecodedStudentPlacements(studentId);
      dispatch(addteacher_recodedStudent_placements(data));
    };
    getData();
  }, [dispatch, studentId]);

  const deletehandler = async (placement_Id) => {
    const responce = await deleteRecodedStudentPlacements(placement_Id);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const placementStatusHandler = async (placement_Id) => {
    const newStatus = {
      active_status: "change",
    };
    const responce = await updateRecodedStudentPlacements(
      placement_Id,
      newStatus
    );
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="Placements-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All placements</h4>
          <Link to={"addPlacements"} className="link">
            Add New placement
          </Link>
        </div>
      </div>

      <TableContainer component={Paper} className="Placements-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>PLACEMENT ID</TableCell>
              <TableCell>COMPANY NAME</TableCell>
              <TableCell>COMPONY INTRUDUCTION</TableCell>
              <TableCell>ACTIVE</TableCell>
              <TableCell>APPLY LINK</TableCell>
              <TableCell>IMAGES</TableCell>
              <TableCell>DELETE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teacher_recodedStudent_placements &&
              teacher_recodedStudent_placements?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.placement_Id}</TableCell>
                  <TableCell>{val.placement_CompanyName}</TableCell>
                  <TableCell>{val.placement_ConmpanyIntruduction}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        placementStatusHandler(val.placement_Id);
                      }}
                      variant="contained"
                      size="small"
                      className={`${
                        val.active_status === "A"
                          ? "Active_status"
                          : "Deactive_satus"
                      }`}
                    >
                      {val.active_status === "A" ? "ACTIVE" : "DEACTIVE"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        window.open(val.apply_Link, "_blank");
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      link
                    </Button>
                  </TableCell>
                  <TableCell>
                    <NavLink to={`${val.placement_Id}/images`}>
                      <Button className="link">view</Button>
                    </NavLink>
                  </TableCell>
                  <TableCell>
                    <span
                      onClick={() => {
                        deletehandler(val.placement_Id);
                      }}
                      className="delete_button"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Link to={`/teachers/recodedStudent`} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>
    </div>
  );
};

export default RecordedStudentPlacements;
