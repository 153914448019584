import React from "react";
import "./StaffMainRoot.scss";
import { Outlet } from "react-router-dom";
import StaffHeader from "./StaffHeaderComponent/StaffHeader";
const StaffMainRoot = () => {
  return (
    <div className="StaffMainRoot-main-container">
      <StaffHeader />
      <Outlet />
    </div>
  );
};

export default StaffMainRoot;
