import React from "react";
import Header from "./HeaderComponent/Header";
import { Outlet } from "react-router-dom";
import "./RootLayout.scss";
const RootLayout = () => {
  return (
    <div className="RootLayout-main-container">
      <Header className="header" />
      <Outlet className="Outlet" />
    </div>
  );
};

export default RootLayout;
