import { configureStore } from "@reduxjs/toolkit";

import coursesReducer from "./Reduuces/CourceReducer";
import BatchReducer from "./Reduuces/BatchReducer";
import EnrollRequestReducer from "./Reduuces/EnrollRequestReducer";
import StudentTeacherAdmineReducer from "./Reduuces/StudentTeacherAdmineReducer";
import AuthReducer from "./Reduuces/AuthReducer";
import GenaralReducer from "./Reduuces/GenaralReducer";

import TeacherReducers from "./Reduuces/TeacherReducers";
import WorkShopReducer from "./Reduuces/WorkShopReducer";
import WorkShopBatchReducer from "./Reduuces/WorkShopBatchReducer";
import RecodedStudentReducer from "./Reduuces/RecodedStudentReducer";
import TeamMemberReducer from "./Reduuces/TeamMemberReducer";
import BlogReducer from "./Reduuces/BlogReducer";
import RecentPlacementsReducers from "./Reduuces/RecentPlacementsReducers";
import LandingPageReducer from "./Reduuces/LandingPageReducer";
import StudentDetailsReducer from "./Reduuces/StudentDetailsReducer";
import AwsVideoReducer from "./Reduuces/AwsVideoReducer";
import EventReducer from "./Reduuces/EventReducer";
import JuzzItJobReducer from "./Reduuces/JuzzItJobReducer";
// import FreeCourseReducer from "./Reduuces/FreeCourseReducer";

const store = configureStore({
  reducer: {
    courseData: coursesReducer,
    batchData: BatchReducer,
    requests: EnrollRequestReducer,
    genaral: GenaralReducer,
    studentTeacherData: StudentTeacherAdmineReducer,
    teacher: TeacherReducers,
    AuthData: AuthReducer,
    workShopData: WorkShopReducer,
    workShopBatchData: WorkShopBatchReducer,
    recodedStudentData: RecodedStudentReducer,
    teamMemberData: TeamMemberReducer,
    blogData: BlogReducer,
    recentPlacementData: RecentPlacementsReducers,
    landingPageData: LandingPageReducer,
    studentDetailsData: StudentDetailsReducer,
    AwsVideoData: AwsVideoReducer,
    EventData: EventReducer,
    jobData: JuzzItJobReducer,
    // freeCourseData:FreeCourseReducer
  },
});

export default store;
