import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";
////  teacher

export const addNewTeacher = async (payload) => {
  try {
    const responce = await myaxios.post("/tch/signUp", payload, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllTeachers = async () => {
  try {
    const responce = await myaxios.get("/tch/teachers", {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteTeacher = async (teachetId) => {
  try {
    const responce = await myaxios.delete(`/tch/teacher/${teachetId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

//// students

export const addNewStudent = async (payload) => {
  try {
    const responce = await myaxios.post("/std/signUp", payload, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllStudents = async () => {
  try {
    const responce = await myaxios.get("/std/students", {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
