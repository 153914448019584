import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Button, Input, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SingleModuleImage from "./SingleModuleImage";
import { uploadImage } from "../../../../../../../Redux/ActionCompo/CloudenarowImageUplode";
import {
  addModuleImges,
  getAllModuleImages,
} from "../../../../../../../Redux/ActionCompo/CourseAction";
import { addcourseModuleImages } from "../../../../../../../Redux/Reduuces/CourceReducer";

const ModuleImage = () => {
  const [file, setFile] = useState(null);
  const courseModuleImages = useSelector(
    (state) => state.courseData.courseModuleImages
  );

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // const CorrentWorkShopMainImages = [];
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getAllModuleImages(moduleId);
      dispatch(addcourseModuleImages(data));
    };
    getData();
  }, [moduleId, dispatch]);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid JPEG or PNG image.");
      }
    }
  };

  const handleAddImage = async () => {
    const data = await uploadImage(file);
    if (data) {
      const newImageData = {
        image_URL: data?.secure_url,
      };
      console.log(newImageData);
      const responce = await addModuleImges(moduleId, newImageData);
      if (responce?.status === true) {
        setSuccessMessage("image added successfully");
        setOpenSnackbar(true);
        const data = await getAllModuleImages(moduleId);
        dispatch(addcourseModuleImages(data));
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="CourseImage-main-container">
      <div>
        <NavLink to={`/courses`}>back</NavLink>
        <div className="IMAGE-FORM-MAIN-CONTAINER">
          <div className="IMAGE-FORM-CONTAINER">
            <form>
              <Input
                type="file"
                accept="image/jpeg, image/png"
                onChange={handleImageChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddImage}
              >
                Add Image
              </Button>
            </form>
          </div>
        </div>

        <div className="ALL-IMAGE-MAIN-CONTAINER">
          <div className="ALL-IMAGE-CONTAINER-heading">
            <h1 variant="h4">All Images</h1>
          </div>
          <div className="ALL-IMAGE-CONTAINER">
            {courseModuleImages &&
              courseModuleImages.map((val, key) => (
                <SingleModuleImage imageData={val} key={key} />
              ))}
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default ModuleImage;
