import React, { useState } from "react";
import "./AddCourseCategory.scss";
import { Link } from "react-router-dom";
import { addNewCourseCategory } from "../../Redux/ActionCompo/AdminGenaralActions";
import { Snackbar, TextField, Button } from "@mui/material";

const AddCourseCategory = () => {
  const [category, setCategory] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();
    const newCategory = {
      category_Name: category,
    };
    const response = await addNewCourseCategory(newCategory);
    if (response?.status === true) {
      setCategory("");
      setSuccessMessage("Category added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddCourseCategory-main-container">
      <div className="AddCourseCategory-container-links">
        <div className="link-contant">
          <Link to={"/categorys"} className="link">
            All categories
          </Link>
          <h4>Add New categories</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <label>Enter Category</label>
              <TextField
                type="text"
                placeholder="Enter Category"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                variant="outlined"
                fullWidth
              />
            </div>
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddCourseCategory;
