import React, { useEffect, useState } from "react";
import { addCourseModelsToStore } from "../../../../../../Redux/Reduuces/CourceReducer";
import { Link, NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteModule,
  getAllModule,
  setActive_module_Status,
} from "../../../../../../Redux/ActionCompo/CourseAction";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import "../../../../../../Genaral.css";
const Models = () => {
  const { branchId, courseId, courseTypeId } = useParams();
  const models = useSelector((state) => state.courseData.courseModels);
  const dispatch = useDispatch();

  const [order, setOrder] = useState(0);

  useEffect(() => {
    const getdata = async () => {
      const data = await getAllModule(courseTypeId);
      dispatch(addCourseModelsToStore(data));
    };
    getdata();
  }, [courseTypeId, dispatch]);

  const activeStatusHandler = async (module_Id) => {
    const newData = {
      active_module: "change",
    };

    const responce = await setActive_module_Status(module_Id, newData);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const deletehandler = async (model_Id) => {
    const responce = await deleteModule(model_Id);
    if (responce.status === true) {
      window.location.reload();
    }
  };

  const updateModuleOrder = async (module_Id) => {
    const newData = {
      module_Order: Number(order),
    };
    const responce = await setActive_module_Status(module_Id, newData);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="Models-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Models</h4>
          <Link to={"addModels"} className="link">
            Add New Model
          </Link>
        </div>
      </div>

      <Link
        to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/details`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TextField
        variant="outlined"
        type="number"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <TableContainer component={Paper} className="model-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>Model TITLE</TableCell>
              <TableCell>ACTIVE</TableCell>
              <TableCell>IMAGES</TableCell>
              <TableCell>ORDER</TableCell>
              <TableCell>EDITE</TableCell>

              <TableCell>delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {models?.map((val, key) => {
              return (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>
                    <NavLink
                      to={`${val.module_Id}/lessons`}
                      className="nev_link"
                    >
                      {val.module_Title}
                    </NavLink>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        activeStatusHandler(val.module_Id);
                      }}
                      variant="contained"
                      size="small"
                      color={`${
                        val.active_Module === "A" ? "success" : "error"
                      }`}
                    >
                      {val.active_Module === "A" ? "Active" : "Deactive"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <NavLink className={"link"} to={`${val.module_Id}/images`}>
                      view
                    </NavLink>
                  </TableCell>
                  <TableCell>
                    <p>{val.module_order}</p>
                  </TableCell>

                  <TableCell>
                    <Button
                      onClick={() => {
                        updateModuleOrder(val.module_Id);
                      }}
                    >
                      update
                    </Button>
                  </TableCell>
                  <TableCell>
                    <span
                      className="delete_button"
                      onClick={() => {
                        deletehandler(val.module_Id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <br />
    </div>
  );
};

export default Models;
