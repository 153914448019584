import React from "react";
import "./RootBatch.scss";
import { Outlet } from "react-router-dom";
const RootBatch = () => {
  return (
    <div className="RootBatch-main-container">
      <div className="RootBatch-content">Batchs</div>
      <Outlet />
    </div>
  );
};

export default RootBatch;
