import React, { useEffect } from "react";
import "./BatchDetails.scss";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addteacher_batch_Details } from "../../../../../Redux/Reduuces/TeacherReducers";
import { getAllBatchDetail } from "../../../../../Redux/ActionCompo/TeacherActions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const BatchDetails = () => {
  const { batchCourseId } = useParams();

  const teacher_batch_Details = useSelector(
    (state) => state.teacher.teacher_batch_Details
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllBatchDetail(batchCourseId);
      dispatch(addteacher_batch_Details(data));
    };
    getData();
  }, [batchCourseId, dispatch]);

  let sotedModuleData = [];

  if (
    teacher_batch_Details &&
    teacher_batch_Details.length > 0 &&
    teacher_batch_Details[0]?.CourseTypeData &&
    teacher_batch_Details[0]?.CourseTypeData.length > 0
  ) {
    sotedModuleData = [...teacher_batch_Details[0]?.CourseTypeData].sort(
      (a, b) => a.order - b.order
    );
  }

  return (
    <div className="BatchDetails-main-container">
      <Link to={"/teachers/batchs"} className="back">
        <i class="fa-solid fa-backward"></i>
      </Link>
      <br />
      <div className="CourseStructure-main-container">
        <div className="CourseStructure-container">
          <h4 data-aos="fade-up-right" data-aos-duration="1000">
            Course Structure
          </h4>

          <div className="CourseStructure-content">
            {sotedModuleData &&
              sotedModuleData?.map((val, key) => {
                const val1 = [...val?.lessons].sort(
                  (a, b) => a.order - b.order
                );
                return (
                  <Accordion key={key} className="Accordion">
                    <AccordionSummary
                      id={`panel${key}-header`}
                      aria-controls={`panel${key}-content`}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant="h6" className="Typography-head">
                        {key + 1}
                        {".   "}
                        {val.module_Title}
                      </Typography>
                    </AccordionSummary>

                    {val1 &&
                      val1?.map((val1, key1) => {
                        return (
                          <AccordionDetails key={key1}>
                            <Typography
                              variant="h6"
                              className="Typography-desc"
                            >
                              {"    "}
                              --{"  "}
                              {val1.lesson_Tites}
                            </Typography>
                          </AccordionDetails>
                        );
                      })}
                  </Accordion>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchDetails;
