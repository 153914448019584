import React, { useEffect, useState } from "react";
import "./RecentPlacementProjects.scss";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Snackbar,
} from "@mui/material";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteRecentPlacementProjects,
  getRecentPlacementProjects,
  updateRecentPlacementProjects,
} from "../../../../../Redux/ActionCompo/RecentPlacementsAction";
import { useDispatch, useSelector } from "react-redux";
import { addRecentPlacementsProjects } from "../../../../../Redux/Reduuces/RecentPlacementsReducers";

const RecentPlacementProjects = () => {
  const [order, setOrder] = useState(0);
  const { recentplacementId } = useParams();

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const RecentPlacementsProjects = useSelector(
    (state) => state.recentPlacementData.RecentPlacementsProjects
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const data = await getRecentPlacementProjects(recentplacementId);
      dispatch(addRecentPlacementsProjects(data));
    };
    getData();
  }, [dispatch, recentplacementId]);

  const handleUpdateRequest = async (id, type, status) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the blog detaile?"
      );
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to update the order of blog details ?"
      );
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteRecentPlacementProjects(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("blog details delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ORDER") {
      if (order && order > 0) {
        const data = {
          order: Number(order),
        };
        const responce = await updateRecentPlacementProjects(UpdateId, data);
        if (responce?.status === true) {
          setSuccessMessage("blog detail order  updated successfully");
          setOpenSnackbar(true);
        }
      }
    }
    const data = await getRecentPlacementProjects(recentplacementId);
    dispatch(addRecentPlacementsProjects(data));
  };

  return (
    <div style={{ width: "100vw" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>projects</h4>
          <Link to={"add"} className="link">
            add projects
          </Link>
        </div>
      </div>
      <Link
        to={`/recentplacements/${recentplacementId}/details`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TextField
        type="text"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="course-id"> SI/NO</TableCell>
              <TableCell className="course-name">name</TableCell>
              <TableCell className="future-course">desc 1</TableCell>
              <TableCell className="active-status">link</TableCell>
              <TableCell className="course-Image">order</TableCell>
              <TableCell className="course-Image">update</TableCell>
              <TableCell className="course-Image">images</TableCell>
              <TableCell className="delete-button">delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {RecentPlacementsProjects &&
              RecentPlacementsProjects?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.project_Name}</TableCell>
                  <TableCell>{val.project_desc1}</TableCell>
                  <TableCell>{val.project_desc2}</TableCell>
                  <TableCell>{val.order}</TableCell>
                  <TableCell
                    onClick={() => handleUpdateRequest(val.project_id, "ORDER")}
                  >
                    <Button>update</Button>
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      navigate(
                        `/recentplacements/${recentplacementId}/projects/${val.project_id}/images`
                      )
                    }
                  >
                    <Button>view</Button>
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleUpdateRequest(val.project_id, "DELETE")
                    }
                  >
                    <i class="fa-solid fa-trash-can"></i>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default RecentPlacementProjects;
