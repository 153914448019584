import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { addNewBlog } from "../../Redux/ActionCompo/BlogActions";
import "../../Genaral.css";
import { Snackbar } from "@mui/material";
const AddBlog = () => {
  const [blogheading, setblogheading] = useState("");
  const [blog_intruduction, setblog_intruduction] = useState("");
  const [blog_Desc, setblog_Desc] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    const newBlog = {
      blog_Heading: blogheading,
      blog_Intruduction: blog_intruduction,
      blog_Desc: blog_Desc,
    };

    const responce = await addNewBlog(newBlog);

    if (responce?.status === true) {
      setblog_Desc("");
      setblog_intruduction("");
      setblogheading("");
      setSuccessMessage("blog added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ width: "100%" }} className="AddObjectievs-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={`/blogs`} className="link">
            All blog
          </Link>
          <h4>Add New blog</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                fullWidth
                multiline
                rows={5}
                label="Enter blog   heading"
                variant="outlined"
                value={blogheading}
                onChange={(e) => setblogheading(e.target.value)}
              />
            </div>
            <div className="form-element">
              <TextField
                fullWidth
                multiline
                rows={5}
                label="Enter blog_intruduction"
                variant="outlined"
                value={blog_intruduction}
                onChange={(e) => setblog_intruduction(e.target.value)}
              />
            </div>
            <div className="form-element">
              <TextField
                fullWidth
                multiline
                rows={5}
                label="Enter blog_Desc"
                variant="outlined"
                value={blog_Desc}
                onChange={(e) => setblog_Desc(e.target.value)}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddBlog;
