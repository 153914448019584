import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  deleteRecordedStudent,
  deleteRecordedStudentMenter,
  getAllRecodedStudentWithMenter,
  updateRecodedStudentData,
} from "../../Redux/ActionCompo/RecodedStudentActions";
import { addRecodedStudentsWithMenter } from "../../Redux/Reduuces/RecodedStudentReducer";
import { useDispatch, useSelector } from "react-redux";
const AllRecordedStudentWithMenter = () => {
  // const courseStudents = [];
  // const admissionStatusHandler = [];

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllRecodedStudentWithMenter();
      dispatch(addRecodedStudentsWithMenter(data));
    };
    getData();
  }, [dispatch]);

  const recodedStudentsWithMenter = useSelector(
    (state) => state.recodedStudentData.recodedStudentsWithMenter
  );

  const handleStudentPermitionStatus = async (studentId) => {
    const data = {
      student_Permition_Status: "change",
    };

    const responce = await updateRecodedStudentData(studentId, data);
    if (responce?.status === true) {
      window.location.reload();
    }
    console.log(responce);
  };
  const handleMenterPermitionStatus = async (studentId) => {
    const data = {
      menter_Permition_Status: "change",
    };

    const responce = await updateRecodedStudentData(studentId, data);
    if (responce?.status === true) {
      window.location.reload();
    }
    console.log(responce);
  };

  const handleStudentPLACEMENTStatus = async (studentId) => {
    const data = {
      student_Placement_Status: "change",
    };

    const responce = await updateRecodedStudentData(studentId, data);
    if (responce?.status === true) {
      window.location.reload();
    }
    console.log(responce);
  };
  const handlebATCH_COMPLETION_Status = async (studentId) => {
    const data = {
      batch_CONPLETION_Status: "change",
    };

    const responce = await updateRecodedStudentData(studentId, data);
    if (responce?.status === true) {
      window.location.reload();
    }
    console.log(responce);
  };

  const deleteStudentHandler = async (studentId) => {
    const responce = await deleteRecordedStudent(studentId);
    if (responce?.status) {
      window.location.reload();
    }
  };
  const deleteStudentMenterHandler = async (studentId) => {
    const responce = await deleteRecordedStudentMenter(studentId);
    if (responce?.status) {
      window.location.reload();
    }
  };

  return (
    <div className="AllRecordedStudentWithOutMenter-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={"/recodedStudents"} className="link">
            Students withOut Menter
          </Link>
          <h4> Students with Menter </h4>
        </div>
      </div>
      <Link to={``} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>
      <div className="CourseStudent-table">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>STUDENT ID</TableCell>
                <TableCell>STUDENT NAME</TableCell>
                <TableCell>COURSE NAME</TableCell>
                <TableCell>COURSE TYPE</TableCell>
                <TableCell>ENROLL TYPE</TableCell>
                <TableCell>STUDENT PERMITION STATUS</TableCell>
                <TableCell>student details</TableCell>
                <TableCell>STUDENT PLACEMNENT STATUS</TableCell>
                <TableCell>MENTER</TableCell>
                <TableCell>MENTER PLACEMNENT STATUS</TableCell>
                <TableCell>BATCH COMPLETION STATUS</TableCell>
                <TableCell>DELETE Menter</TableCell>
                <TableCell>DELETE Student</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recodedStudentsWithMenter &&
                recodedStudentsWithMenter?.map((val, key) => (
                  <TableRow key={key}>
                    <TableCell>{val.RECORDED_STUDENT_ID}</TableCell>
                    <TableCell>{val.STUDENT_NAME}</TableCell>
                    <TableCell>{val.COURSE_NAME}</TableCell>
                    <TableCell>{val.COURSE_TYPE}</TableCell>
                    <TableCell>{val.ENROLL_TYPE}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          handleStudentPermitionStatus(val.RECORDED_STUDENT_ID);
                        }}
                        variant="contained"
                        size="small"
                        color={`${
                          val.STUDENT_PERMITION_STATUS === "A"
                            ? "success"
                            : "error"
                        }`}
                      >
                        {val.STUDENT_PERMITION_STATUS === "A" ? "ON" : "OFF"}
                      </Button>
                    </TableCell>

                    <TableCell>
                      <Button>view</Button>
                    </TableCell>

                    <TableCell>
                      <Button
                        onClick={() => {
                          handleStudentPLACEMENTStatus(val.RECORDED_STUDENT_ID);
                        }}
                        variant="contained"
                        size="small"
                        color={`${
                          val.STUDENT_PLACEMNENT_STATUS === "A"
                            ? "success"
                            : "error"
                        }`}
                      >
                        {val.STUDENT_PLACEMNENT_STATUS === "A" ? "ON" : "OFF"}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          navigate(
                            `/recodedStudents/${val.RECORDED_STUDENT_ID}/menter/details`
                          );
                        }}
                      >
                        view
                      </Button>
                    </TableCell>

                    <TableCell>
                      <Button
                        onClick={() => {
                          handleMenterPermitionStatus(val.RECORDED_STUDENT_ID);
                        }}
                        variant="contained"
                        size="small"
                        // className={`${
                        //   val.MENTER_PERMITION_STATUS === "A"
                        //     ? "Active_status"
                        //     : "Deactive_satus"
                        // }`}
                        color={`${
                          val.MENTER_PERMITION_STATUS === "A"
                            ? "success"
                            : "error"
                        }`}
                      >
                        {val.MENTER_PERMITION_STATUS === "A" ? "ON" : "OFF"}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          handlebATCH_COMPLETION_Status(
                            val.RECORDED_STUDENT_ID
                          );
                        }}
                        variant="contained"
                        size="small"
                        color={`${
                          val.BATCH_COMPLETION_STATUS === "A"
                            ? "success"
                            : "error"
                        }`}
                      >
                        {val.BATCH_COMPLETION_STATUS === "A" ? "ON" : "OFF"}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <span
                        className="delete_button"
                        onClick={() => {
                          deleteStudentMenterHandler(val.RECORDED_STUDENT_ID);
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        className="delete_button"
                        onClick={() => {
                          deleteStudentHandler(val.RECORDED_STUDENT_ID);
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AllRecordedStudentWithMenter;
