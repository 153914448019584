import React, { useEffect, useState } from "react";
import { uploadImage } from "../../../../Redux/ActionCompo/CloudenarowImageUplode";
import SingleLandingPageBanner from "./SingleLandingPageBanner";
import {
  addLandingPageBanner,
  getLandingPageBanner,
} from "../../../../Redux/ActionCompo/LandingPageActions";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { Button, Input, Snackbar } from "@mui/material";
import { addlandingPageBannerToStore } from "../../../../Redux/Reduuces/LandingPageReducer";

const LandingPageBanner = () => {
  const [file, setFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const landingPageBanner = useSelector(
    (state) => state.landingPageData.landingPageBanner
  );

  const { pageId, pageName } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getLandingPageBanner(pageId);
      dispatch(addlandingPageBannerToStore(data));
    };

    getData();
  }, [pageId, dispatch]);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid JPEG or PNG image.");
      }
    }
  };

  const handleAddImage = async () => {
    const data = await uploadImage(file);
    if (data) {
      const newImageData = {
        image_URL: data?.secure_url,
      };
      const responce = await addLandingPageBanner(pageId, newImageData);
      if (responce?.status === true) {
        setFile(null);
        setSuccessMessage("branch added successfully");
        setOpenSnackbar(true);
        const data = await getLandingPageBanner(pageId);
        dispatch(addlandingPageBannerToStore(data));
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="CourseImage-main-container" style={{ width: "100%" }}>
      <NavLink to={`/landingPages/${pageId}/${pageName}/details`}>back</NavLink>
      <div className="">
        <div className="IMAGE-FORM-MAIN-CONTAINER">
          <div className="IMAGE-FORM-CONTAINER">
            <form>
              <Input
                type="file"
                accept="image/jpeg, image/png"
                onChange={handleImageChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddImage}
              >
                Add Image
              </Button>
            </form>
          </div>
        </div>

        <div className="ALL-IMAGE-MAIN-CONTAINER">
          <div className="ALL-IMAGE-CONTAINER-heading">
            <h1>All Images</h1>
          </div>
          <div className="ALL-IMAGE-CONTAINER">
            {landingPageBanner &&
              landingPageBanner.map((val, key) => (
                <SingleLandingPageBanner courseTypeImage={val} key={key} />
              ))}
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default LandingPageBanner;
