import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { addNewEvent } from "../../Redux/ActionCompo/EventActions";
import { Link } from "react-router-dom";

const AddEvents = () => {
  const [eventName, setEventName] = useState("");

  const [evantNameError, setEvantNameError] = useState("");

  const handleAddEvent = async (event) => {
    event.preventDefault();
    if (eventName === "") {
      setEvantNameError("event name is required");
    }
    const data = {
      event_name: eventName,
    };

    const response = addNewEvent(data);

    if (response.status === true) {
      setEventName("");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={`/Events`} className="link">
            All event
          </Link>
          <h4>Add New event</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={handleAddEvent}>
            <TextField
              variant="outlined"
              type="text"
              name="eventName"
              label="Enter Evant name"
              value={eventName}
              onChange={(e) => {
                setEventName(e.target.value);
              }}
              error={evantNameError}
              helperText={evantNameError}
            />

            <Button type="submit">add</Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEvents;
