import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { addNewKeyHighLight } from "../../../../../../Redux/ActionCompo/CourseAction";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import "../../../../../../Genaral.css";
import { Snackbar } from "@mui/material";
const AddHighlights = () => {
  const [highlight, setHighlight] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { branchId, courseId, courseTypeId } = useParams();

  const validateForm = () => {
    if (highlight.trim() === "") {
      setError("Highlight cannot be empty.");
      return false;
    }
    return true;
  };

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return; // Do not submit if validation fails
    }

    const newHilight = {
      highlight: highlight,
    };

    const responce = await addNewKeyHighLight(courseTypeId, newHilight);

    if (responce?.status === true) {
      setHighlight("");
      setSuccessMessage("key highlight added successfully");
      setOpenSnackbar(true);
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddHighlights-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/highlights`}
            className="link"
          >
            All Highlights
          </Link>
          <h4>Add New Highlight</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <label>Enter Highlight</label>
              <TextField
                multiline
                rows={10}
                cols={40}
                fullWidth
                placeholder="Enter Course Highlight"
                variant="outlined"
                value={highlight}
                onChange={(e) => setHighlight(e.target.value)}
                error={!!error}
                helperText={error}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddHighlights;
