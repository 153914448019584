import React, { useState } from "react";
import "./SignUp.scss";
import {
  adminExistById,
  cretateAdminAcount,
  serntSignUpOtpRequest,
} from "../Redux/ActionCompo/AuthActions";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { settrainerOtpRequestData } from "../Redux/Reduuces/AuthReducer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../LoadingComponent/Loading";
const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    id: "qwqwdsqw",
    otp: "",
  });
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [signUpNotification, setsignUpNotification] = useState(false);
  const [otpVarification, setotpVarification] = useState(false);
  const [isLoading, setIsLoadding] = useState(false);
  const [otpError, setOtpError] = useState("");
  const trainerOtpRequestData = useSelector(
    (state) => state.AuthData.trainerOtpRequestData
  );

  // const isEmailValid = (email) => {
  //   const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //   return emailPattern.test(email);
  // };

  // const isMobileNumberValid = (mobileNumber) => {
  //   const mobileNumberPattern = /^[0-9]{10}$/;
  //   return mobileNumberPattern.test(mobileNumber);
  // };

  // const isPasswordValid = (password) => {
  //   const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  //   return passwordPattern.test(password);
  // };

  // const [emailAuth, setemailAuth] = useState(false);

  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const responce1 = await adminExistById(formData.email);
    if (responce1?.status === false) {
      setsignUpNotification(true);
    }

    // if (responce1?.status === false) {
    //
    // }
  };

  const HandleSendOTPrEQUEST = async () => {
    setIsLoadding(true);
    setsignUpNotification(false);
    setOtpError("");
    const requestData = {
      EMAIL: formData.email,
    };

    const responce = await serntSignUpOtpRequest(requestData);

    if (responce?.status === true) {
      setIsLoadding(false);
      dispatch(settrainerOtpRequestData({}));
      dispatch(settrainerOtpRequestData(responce));
      setotpVarification(true);
    } else {
      setIsLoadding(false);
    }
  };

  const handlleOTPvarification = async () => {
    setIsLoadding(true);
    setOtpError("");
    if (!otp) {
      setOtpError("OTP is required for verification.");
      return;
    }

    const responce = await cretateAdminAcount(
      trainerOtpRequestData?.requestId,
      { ...formData, otp: otp }
    );

    if (responce?.successStatus === true) {
      localStorage.setItem("userData", JSON.stringify(responce));
      navigate("/");
      window.location.reload();
    } else {
      setIsLoadding(false);
      setOtpError("Enter correct OTP");
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="signup-main-container">
      <div
        className={`SignUp-conformation-pop-up-container  ${
          signUpNotification ? "showNotification" : ""
        }`}
      >
        <div className="SignUp-conformation-pop-up">
          <h1>
            For the varification we send the mail to email{" "}
            <span>{formData.email}</span>
          </h1>
          <div className="SignUp-conformation-pop-up-button">
            <Button
              variant="outlined"
              onClick={() => setsignUpNotification(false)}
            >
              cancel
            </Button>
            <Button variant="outlined" onClick={HandleSendOTPrEQUEST}>
              send
            </Button>
          </div>
        </div>
      </div>

      <div
        className={`signUp-otp-main-container ${
          otpVarification ? "showOtpForm" : ""
        }`}
      >
        <div className="signUp-otp-container">
          <h1>Enter OTP</h1>

          {otpError && (
            <Typography className="error-message" color={"red"}>
              {otpError}
            </Typography>
          )}
          <input
            type="number"
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
          ></input>
          <Button onClick={handlleOTPvarification} variant="outlined">
            varify
          </Button>
          <Button onClick={HandleSendOTPrEQUEST} variant="outlined">
            Resend OTP
          </Button>
        </div>
      </div>

      <form className="signup-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          required
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          required
        />
        <button type="submit">Sign Up</button>
        <NavLink to={"/"} className="login-link">
          login
        </NavLink>
      </form>
    </div>
  );
};

export default SignUp;
