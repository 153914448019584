import { myaxios } from "../ApiBase";
import authHeader from "./auth-header";

export const addLandingPage = async (landingPageData) => {
  try {
    const response = await myaxios.post(`/page/landingPages`, landingPageData, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const deleteLandingPage = async (landingpageId) => {
  try {
    const response = await myaxios.delete(
      `/page/landingPages/${landingpageId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateLandingPage = async (landingpageId, landingPageData) => {
  try {
    const response = await myaxios.put(
      `/page/landingPages/${landingpageId}`,
      landingPageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getLandingPage = async () => {
  try {
    const response = await myaxios.get(`/page/landingPages`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

/// application

export const addLandingPageApplications = async (
  landingpageId,
  landingPageData
) => {
  try {
    const response = await myaxios.post(
      `/page/landingPages/${landingpageId}/applications`,
      landingPageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteLandingPageApplications = async (applicationId) => {
  try {
    const response = await myaxios.delete(
      `/page/landingPage/applications/${applicationId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateLandingPageApplications = async (
  applicationId,
  landingPageData
) => {
  try {
    const response = await myaxios.put(
      `/page/landingPage/applications/${applicationId}"`,
      landingPageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getLandingPageApplications = async (landingpageId) => {
  try {
    const response = await myaxios.get(
      `/page/landingPages/${landingpageId}/applications`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

////

export const addLandingPageKeyHighLites = async (
  landingpageId,
  landingPageKeyHighLiteData
) => {
  try {
    const response = await myaxios.post(
      `/page/landingPages/${landingpageId}/keyHighLites`,
      landingPageKeyHighLiteData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteLandingPageKeyHighLites = async (keyHighLiteId) => {
  try {
    const response = await myaxios.delete(
      `/page/landingPages/keyHighLites/${keyHighLiteId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateLandingPageKeyHighLites = async (
  keyHighLiteId,
  landingPageKeyHighLiteData
) => {
  try {
    const response = await myaxios.put(
      `/page/landingPages/keyHighLites/${keyHighLiteId}`,
      landingPageKeyHighLiteData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getLandingPageKeyHighLites = async (landingpageId) => {
  try {
    const response = await myaxios.get(
      `/page/landingPages/${landingpageId}/keyHighLites`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

///objectives

export const addLandingPageObjective = async (
  landingpageId,
  landingPageObjectiveData
) => {
  try {
    const response = await myaxios.post(
      `/page/landingPages/${landingpageId}/objective`,
      landingPageObjectiveData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteLandingPageObjective = async (objectiveId) => {
  try {
    const response = await myaxios.delete(
      `/page/landingPages/objective/${objectiveId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateLandingPageObjective = async (
  objectiveId,
  landingPageObjectiveData
) => {
  try {
    const response = await myaxios.put(
      `/page/landingPages/objective/${objectiveId}`,
      landingPageObjectiveData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getLandingPageObjective = async (landingpageId) => {
  try {
    const response = await myaxios.get(
      `/page/landingPages/${landingpageId}/objective`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// banner

export const addLandingPageBanner = async (
  landingpageId,
  landingPageBannerData
) => {
  try {
    const response = await myaxios.post(
      `/page/landingPages/${landingpageId}/banners`,
      landingPageBannerData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteLandingPageBanner = async (bannerId) => {
  try {
    const response = await myaxios.delete(
      `/page/landingPages/banners/${bannerId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateLandingPageBanner = async (
  bannerId,
  landingPageBannerData
) => {
  try {
    const response = await myaxios.put(
      `/page/landingPages/banners/${bannerId}`,
      landingPageBannerData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getLandingPageBanner = async (landingpageId) => {
  try {
    const response = await myaxios.get(
      `/page/landingPages/${landingpageId}/banners`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};
