import { Button } from "@mui/material";
import React from "react";
import {
  deleteCourseTypeCereerServiceDetailsImages,
  updateCourseTypeCereerServiceDetailsImages,
} from "../../../../../../../Redux/ActionCompo/CourseAction";

const SingleCereerServiceDetailsImage = (props) => {
  const imageData = props.courseTypeImage;

  const activeStatusHandler = async (imageId) => {
    const data = {
      active_status: "change",
    };

    const responce = await updateCourseTypeCereerServiceDetailsImages(
      imageId,
      data
    );

    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const deleteHandler = async (imageId) => {
    const responce = await deleteCourseTypeCereerServiceDetailsImages(imageId);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData?.image_URL} alt="sx"></img>
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => activeStatusHandler(imageData.image_id)}
        >
          {imageData?.active_status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteHandler(imageData.image_id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default SingleCereerServiceDetailsImage;
