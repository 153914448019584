import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workShopsBatch: [],
  workShopEnrollRequests: [],
  WorkShopEnrollRequestDetails: [],
  workShopsToAddToBatch: [],
  workShopBatchStudents: [],
  workShopBatchSubjects: [],
  workShopBatchPlacements: [],
  workShopPlacementImages: [],
};

const WorkShopBatch = createSlice({
  name: "workShopBatch",
  initialState,
  reducers: {
    addworkShopsBatch: (state, action) => {
      state.workShopsBatch = action.payload;
    },
    addworkShopEnrollRequests: (state, action) => {
      state.workShopEnrollRequests = action.payload;
    },
    addWorkShopEnrollRequestDetails: (state, action) => {
      state.WorkShopEnrollRequestDetails = action.payload;
    },
    addworkShopsToAddToBatch: (state, action) => {
      state.workShopsToAddToBatch = action.payload;
    },
    addworkShopBatchStudents: (state, action) => {
      state.workShopBatchStudents = action.payload;
    },
    addworkShopBatchSubjects: (state, action) => {
      state.workShopBatchSubjects = action.payload;
    },
    addworkShopBatchPlacements: (state, action) => {
      state.workShopBatchPlacements = action.payload;
    },
    addworkShopPlacementImages: (state, action) => {
      state.workShopPlacementImages = action.payload;
    },
  },
});

export const {
  addworkShopsBatch,
  addworkShopEnrollRequests,
  addWorkShopEnrollRequestDetails,
  addworkShopsToAddToBatch,
  addworkShopBatchStudents,
  addworkShopBatchSubjects,
  addworkShopBatchPlacements,
  addworkShopPlacementImages,
} = WorkShopBatch.actions;

export default WorkShopBatch.reducer;
