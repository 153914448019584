import React,{useState} from 'react'
import './CreateStudentCount.scss'
import { addNewStudent } from '../../Redux/ActionCompo/StudentTeachetAdminActions';
const CreateStudentCount = () => {

 const [student_Name,setStudent_Name]=useState("");
const [student_Email,setStudent_Email]=useState("");
const [mobile_Number,setMobile_Number]=useState("");
const [password,setPassword]=useState("");
const [conformPassword,setConformPassword]=useState("");
const [address,setAddress]=useState("");

const onsubmiteHandler=async(event)=>{
    event.preventDefault();
    const newStudent={
        student_Name:student_Name,
        student_Email:student_Email,
        student_MobileNum:Number(mobile_Number),
        student_Password:password,
        student_Address:address
    }

   const responce= await addNewStudent(newStudent);
   if (responce?.status === true) {
    window.location.reload();
  }

}

  return (
    <div className='CreateStudentCount-mainContainer'>
        
        <div className='CreateStudentCount-form-container'>
        <div className='form-contant'>
          <form onSubmit={onsubmiteHandler}>
          <div className='form-element'>
                      <label>Enter Student Name</label>
                     <input type='text' placeholder='enter name' value={student_Name} onChange={e=>setStudent_Name(e.target.value)}/>
                      <span>error message</span>
                    </div>
                    <div className='form-element'>
                      <label>Enter Student Email</label>
                     <input type='email' placeholder='enter name' value={student_Email} onChange={e=>setStudent_Email(e.target.value)}/>
                      <span>error message</span>
                    </div>
                    <div className='form-element'>
                      <label>Enter Student Mobile Number</label>
                     <input type='number' placeholder='enter name' value={mobile_Number} onChange={e=>setMobile_Number(e.target.value)}/>
                      <span>error message</span>
                    </div>
                    <div className='form-element'>
                      <label>Enter password</label>
                     <input type='password' placeholder='enter name' value={password} onChange={e=>setPassword(e.target.value)}/>
                      <span>error message</span>
                    </div>
                    <div className='form-element'>
                      <label>Conform PassWord</label>
                     <input type='password' placeholder='enter name' value={conformPassword} onChange={e=>setConformPassword(e.target.value)}/>
                      <span>error message</span>
                    </div>

                    <div className='form-element'>
                      <label>Enter  Address</label>
                     <textarea rows={10} cols={30} placeholdesr='enter Address'  value={address} onChange={e=>setAddress(e.target.value)}/>
                      <span>error message</span>
                    </div>
          
                <button type='submit'>Add</button>
          </form>


        </div>
        </div> 


    </div>
  )
}

export default CreateStudentCount