import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./JobDetails.scss";
const JobDetails = () => {
  const navigate = useNavigate();
  const { jobDomainId, jobId } = useParams();

  return (
    <div className="JobDetails-main-container">
      <Link to={`/JobDomain/${jobDomainId}/jobs`} className="back">
        <i class="fa-solid fa-backward"></i>
      </Link>
      <ul>
        <li
          onClick={() => {
            navigate(`/JobDomain/${jobDomainId}/jobs/${jobId}/JobApplicants`);
          }}
        >
          Job Applicants
        </li>
        <li
          onClick={() => {
            navigate(
              `/JobDomain/${jobDomainId}/jobs/${jobId}/JobSkillRequireds`
            );
          }}
        >
          Job Skill Requireds
        </li>
        <li
          onClick={() => {
            navigate(
              `/JobDomain/${jobDomainId}/jobs/${jobId}/JobResponcibilitys`
            );
          }}
        >
          Job Responcibilitys
        </li>
        <li
          onClick={() => {
            navigate(`/JobDomain/${jobDomainId}/jobs/${jobId}/JobRequirements`);
          }}
        >
          Job Requirements
        </li>
        <li
          onClick={() => {
            navigate(
              `/JobDomain/${jobDomainId}/jobs/${jobId}/JobRequiredQualification`
            );
          }}
        >
          Add Job Required Qualification
        </li>
        <li
          onClick={() => {
            navigate(`/JobDomain/${jobDomainId}/jobs/${jobId}/JobLocations`);
          }}
        >
          Job Locations
        </li>
        <li
          onClick={() => {
            navigate(`/JobDomain/${jobDomainId}/jobs/${jobId}/jobImages`);
          }}
        >
          job Images
        </li>
      </ul>
    </div>
  );
};

export default JobDetails;
