import React, { useState } from "react";
import "./AddNewSalesExecutive.scss";
import { Link } from "react-router-dom";
import { TextField, TextareaAutosize, Button, Grid } from "@mui/material";

const AddNewSalesExecutive = () => {
  const [salesExecutiveName, setSalesExecutiveName] = useState("");
  const [salesExecutiveEmail, setSalesExecutiveEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [salesExecutiveAddress, setSalesExecutiveAddress] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    salesExecutiveName: "",
    salesExecutiveEmail: "",
    mobileNumber: "",
    password: "",
    conformPassword: "",
    salesExecutiveAddress: "",
  });

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

    const errors = {
      salesExecutiveName: salesExecutiveName === "",
      salesExecutiveEmail: !emailRegex.test(salesExecutiveEmail),
      mobileNumber: !/^\d{10}$/.test(mobileNumber),
      password: !passwordRegex.test(password),
      conformPassword: conformPassword !== password,
      salesExecutiveAddress: salesExecutiveAddress === "",
    };

    setErrorMessages(errors);

    return !Object.values(errors).some((v) => v);
  };

  const onsubmitHandler = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Add your logic here to submit the data
    }
  };

  return (
    <div className="AddNewSalesExecutive-main-container">
      <div className="AddNewSalesExecutive-container-links">
        <div className="link-contant">
          <Link to={"/SalesExecutives"} className="link">
            All Sales Executives
          </Link>
          <h4>Add New Sales Executive</h4>
        </div>
      </div>

      <div className="AddNewSalesExecutive-form-contant">
        <div className="form-contant">
          <form onSubmit={onsubmitHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Enter Sales Executive Name"
                  variant="outlined"
                  fullWidth
                  value={salesExecutiveName}
                  onChange={(e) => setSalesExecutiveName(e.target.value)}
                  error={errorMessages.salesExecutiveName}
                  helperText={
                    errorMessages.salesExecutiveName ? "Name is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Enter Sales Executive Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={salesExecutiveEmail}
                  onChange={(e) => setSalesExecutiveEmail(e.target.value)}
                  error={errorMessages.salesExecutiveEmail}
                  helperText={
                    errorMessages.salesExecutiveEmail
                      ? "Enter a valid email"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Enter Sales Executive Mobile Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  error={errorMessages.mobileNumber}
                  helperText={
                    errorMessages.mobileNumber
                      ? "Enter a valid 10-digit mobile number"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Enter Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={errorMessages.password}
                  helperText={
                    errorMessages.password
                      ? "Password must contain at least 8 characters with at least one number, one lowercase, and one uppercase letter."
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  value={conformPassword}
                  onChange={(e) => setConformPassword(e.target.value)}
                  error={errorMessages.conformPassword}
                  helperText={
                    errorMessages.conformPassword
                      ? "Passwords do not match"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  rowsMin={5}
                  aria-label="Enter Address"
                  placeholder="Enter Address"
                  fullWidth
                  value={salesExecutiveAddress}
                  onChange={(e) => setSalesExecutiveAddress(e.target.value)}
                  error={errorMessages.salesExecutiveAddress}
                />
                {errorMessages.salesExecutiveAddress && (
                  <div className="error-message">Address is required</div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewSalesExecutive;
