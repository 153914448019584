import React from "react";
import "./TeacherDetailsInAdmin.scss";
import { Link } from "react-router-dom";
const TeacherDetailsInAdmin = () => {
  const teacherDetailsInAdmin = {
    teacherId: "dhasi",
    teacherName: "dcnos",
    teacherEmail: "ewfduyewd",
    teacherAddress: "duiqwdkjwqiudqwhdouqoidq",
  };

  return (
    <div className="TeacherDetailsInAdmin-main-container">
      <div className="TeacherDetailsInAdmin-heading">
        <h1>Teacher Details </h1>
      </div>

      <Link to={"/teachers"}>back</Link>
      <div className="teacher-Container">
        <div className="teacher-Content">
          <h3>TEACHER ID</h3>
          <p>
            <i class="fa-solid fa-circle-dot"></i>
            <span>{teacherDetailsInAdmin.teacherId}</span>
          </p>
        </div>
        <div className="teacher-Content">
          <h3>TEACHER NAME</h3>
          <p>
            <i class="fa-solid fa-circle-dot"></i>
            <span>{teacherDetailsInAdmin.teacherName}</span>
          </p>
        </div>

        <div className="teacher-Content">
          <h3>TEACHER EMAIL</h3>
          <p>
            <i class="fa-solid fa-circle-dot"></i>{" "}
            <span>{teacherDetailsInAdmin.teacherEmail}</span>
          </p>
        </div>

        <div className="teacher-Content">
          <h3>TEACHER ADDRESS</h3>
          <p>
            <i class="fa-solid fa-circle-dot"></i>{" "}
            <span>{teacherDetailsInAdmin.teacherAddress}</span>
          </p>
        </div>
      </div>

      <div>
        <h1>All Batchs</h1>
      </div>
    </div>
  );
};

export default TeacherDetailsInAdmin;
