import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courses: [],
  branchs: [],
  branchTypes: [],
  branchCategorys: [],
  courseTypes: [],
  courseCategorys: [],
  courseTypesDetails: [],
  courseTypeDetailsCondition: [],
  courseEssentials: [],
  CourseHeyHighlights: [],
  courseModels: [],
  courseModuleImages: [],
  courseLessons: [],
  courseTopics: [],
  courseObjectives: [],
  courseProjects: [],
  courseTools: [],
  courseCourseType: [],
  courseImages: [],
  courseTypeImage: [],
  courseTypeBagroundImages: [],
  courseTypeVideo: [],
  toolImages: [],
  TopicVideos: [],
  topicAssignment: [],
  placementImages: [],
  allFAQs: [],
  allCereerServices: [],
  allCereerServiceDetails: [],
  allCereerServiceimages: [],
  allProgramHighlight: [],
  programStructure1: [],
  programStructure2: [],
  programStructure2Lesson: [],
  toolsToMaster: [],
  courseEmployeeRole: [],
  certificateData: [],
  aluminiWorksAtImage: [],
};

const coursesReducer = createSlice({
  name: "course",
  initialState,
  reducers: {
    setaluminiWorksAtImage(state, action) {
      state.aluminiWorksAtImage = action.payload;
    },
    setcertificateData(state, action) {
      state.certificateData = action.payload;
    },
    setcourseTypeDetailsCondition(state, action) {
      state.courseTypeDetailsCondition = action.payload;
    },
    setbranchCategorys(state, action) {
      state.branchCategorys = action.payload;
    },
    setbranchTypes(state, action) {
      state.branchTypes = action.payload;
    },
    setprogramStructure2Data(state, action) {
      state.programStructure2 = action.payload;
    },
    setprogramStructure2LessonData(state, action) {
      state.programStructure2Lesson = action.payload;
    },
    settoolsToMasterData(state, action) {
      state.toolsToMaster = action.payload;
    },
    setcourseEmployeeRole(state, action) {
      state.courseEmployeeRole = action.payload;
    },

    addprogramStructure1(state, action) {
      state.programStructure1 = action.payload;
    },
    addallFAQs(state, action) {
      state.allFAQs = action.payload;
    },

    addAllCereerServices(state, action) {
      state.allCereerServices = action.payload;
    },

    addallCereerServiceimages(state, action) {
      state.allCereerServiceimages = action.payload;
    },
    addAllProgramHighlight(state, action) {
      state.allProgramHighlight = action.payload;
    },
    addAllCereerServiceDetails(state, action) {
      state.allCereerServiceDetails = action.payload;
    },
    addbranchs: (state, action) => {
      state.branchs = action.payload;
    },
    addCoursesToStore: (state, action) => {
      state.courses = action.payload;
    },
    addCourseCourseTypes: (state, action) => {
      state.courseCourseType = action.payload;
    },
    addCourseCategoryToStore: (state, action) => {
      state.courseCategorys = action.payload;
    },

    addCourseTypesToStore: (state, action) => {
      state.courseTypes = action.payload;
    },
    addcourseTypesDetailsToStore: (state, action) => {
      state.courseTypesDetails = action.payload;
    },
    addcourseEssentialsToStore: (state, action) => {
      state.courseEssentials = action.payload;
    },
    addCourseHeyHighlightsToStore: (state, action) => {
      state.CourseHeyHighlights = action.payload;
    },
    addCourseModelsToStore: (state, action) => {
      state.courseModels = action.payload;
    },
    addCourseLessonsToStore: (state, action) => {
      state.courseLessons = action.payload;
    },
    addCourseTopicsToStore: (state, action) => {
      state.courseTopics = action.payload;
    },
    addCourseObjectivesToStore: (state, action) => {
      state.courseObjectives = action.payload;
    },
    addCourseProjectsToStore: (state, action) => {
      state.courseProjects = action.payload;
    },
    addCourseToolsToStore: (state, action) => {
      state.courseTools = action.payload;
    },
    addcourseImages: (state, action) => {
      state.courseImages = action.payload;
    },

    addcourseTypeImage: (state, action) => {
      state.courseTypeImage = action.payload;
    },
    addcourseTypeBagroundImages: (state, action) => {
      state.courseTypeBagroundImages = action.payload;
    },
    addcourseTypeVideo: (state, action) => {
      state.courseTypeVideo = action.payload;
    },
    addtoolImages: (state, action) => {
      state.toolImages = action.payload;
    },
    addTopicVideos: (state, action) => {
      state.TopicVideos = action.payload;
    },
    addplacementImages: (state, action) => {
      state.placementImages = action.payload;
    },
    addcourseModuleImages: (state, action) => {
      state.courseModuleImages = action.payload;
    },

    addTopicAssignment: (state, action) => {
      state.topicAssignment = action.payload;
    },
  },
});

export const {
  setcertificateData,
  setcourseTypeDetailsCondition,
  setaluminiWorksAtImage,
  setbranchCategorys,
  setbranchTypes,
  setprogramStructure2Data,
  setprogramStructure2LessonData,
  settoolsToMasterData,
  setcourseEmployeeRole,
  addprogramStructure1,
  addbranchs,
  addcourseImages,
  addtoolImages,
  addTopicVideos,
  addcourseTypeBagroundImages,
  addplacementImages,
  addcourseTypeVideo,
  addcourseTypeImage,
  addCourseToolsToStore,
  addCourseCourseTypes,
  addCourseProjectsToStore,
  addCoursesToStore,
  addCourseObjectivesToStore,
  addCourseTypesToStore,
  addcourseTypesDetailsToStore,
  addcourseEssentialsToStore,
  addCourseHeyHighlightsToStore,
  addCourseModelsToStore,
  addCourseLessonsToStore,
  addCourseTopicsToStore,
  addcourseModuleImages,
  addTopicAssignment,
  addallFAQs,
  addAllCereerServices,
  addallCereerServiceimages,
  addAllProgramHighlight,
  addAllCereerServiceDetails,
} = coursesReducer.actions;

export default coursesReducer.reducer;
