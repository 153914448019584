import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addCourseObjectivesToStore } from "../../../../../../Redux/Reduuces/CourceReducer";
import { Link, useParams } from "react-router-dom";
import {
  deleteObjective,
  getAllObjective,
  updateObjective,
} from "../../../../../../Redux/ActionCompo/CourseAction";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import "../../../../../../Genaral.css";
const Objectievs = () => {
  const { branchId, courseId, courseTypeId } = useParams();

  const [order, setOrder] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const objectives = useSelector((state) => state.courseData.courseObjectives);
  const dispatch = useDispatch();


  useEffect(() => {
    const getData = async () => {
      const data = await getAllObjective(courseTypeId);
      dispatch(addCourseObjectivesToStore(data));
    };
    getData();
  }, [courseTypeId, dispatch]);



  const handleUpdateRequest = async (id, type) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to delete the objective ?");
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to update order  of objective?"
      );
    }
    setUpdateId(id);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteObjective(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("objective delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ORDER") {
      const payload = {
        order: Number(order),
      };

      const responce = await updateObjective(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("objective order update successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getAllObjective(courseTypeId);
    dispatch(addCourseObjectivesToStore(data));
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="Objectievs-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Objectievs</h4>
          <Link to={"addObjectievs"} className="link">
            Add New Objectievs
          </Link>
        </div>
      </div>

      <Link
        to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/details`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TextField
        variant="outlined"
        type="number"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <TableContainer component={Paper} className="course-Type-details-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>Objective</TableCell>
              <TableCell>order</TableCell>
              <TableCell>update</TableCell>
              <TableCell>delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {objectives?.map((val, key) => {
              return (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.objective}</TableCell>
                  <TableCell>{val.ORDER}</TableCell>

                  <TableCell>
                    <Button
                      onClick={() => {
                        handleUpdateRequest(val.objective_id, "ORDER");
                      }}
                    >
                      update
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        handleUpdateRequest(val.objective_id, "DELETE");
                      }}
                      variant="contained"
                      size="small"
                      className="delete_button"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default Objectievs;
