import React, { useEffect, useState } from "react";
import { uploadImage } from "../../../../../Redux/ActionCompo/CloudenarowImageUplode";
import SingleRecentPlacementProfileImage from "./SingleRecentPlacementProfileImage";
import {
  addRecentPlacementProfileImage,
  getRecentPlacementProfileImage,
} from "../../../../../Redux/ActionCompo/RecentPlacementsAction";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addRecentPlacementProfileImageToStore } from "../../../../../Redux/Reduuces/RecentPlacementsReducers";
import { Snackbar } from "@mui/material";

const RecentPlacementProfileImage = () => {
  const [file, setFile] = useState(null);
  const { recentplacementId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const RecentPlacementProfileImage = useSelector(
    (state) => state.recentPlacementData.RecentPlacementProfileImage
  );

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("useEffect is running");
    const getData = async () => {
      const data = await getRecentPlacementProfileImage(recentplacementId);
      dispatch(addRecentPlacementProfileImageToStore(data));
    };

    getData();
  }, [dispatch, recentplacementId]);

  const handleAddImage = async (event) => {
    event.preventDefault();
    const data = await uploadImage(file);
    console.log(data?.secure_url);

    if (data) {
      const newImageData = {
        image_URL: data?.secure_url,
      };
      const response = await addRecentPlacementProfileImage(
        recentplacementId,
        newImageData
      );
      if (response?.status === true) {
        setFile(null);
        setSuccessMessage("image added successfully");
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="IMAGE-FORM-MAIN-CONTAINER">
        <div className="IMAGE-FORM-CONTAINER">
          <form className="form" onSubmit={handleAddImage}>
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            ></input>
            <button type="submit">addfile</button>
          </form>
        </div>
      </div>
      <div className="ALL-IMAGE-MAIN-CONTAINER">
        <div className="ALL-IMAGE-CONTAINER-heading">
          <h1>All Images</h1>
        </div>
        <div className="ALL-IMAGE-CONTAINER">
          {RecentPlacementProfileImage &&
            RecentPlacementProfileImage.map((val, key) => (
              <SingleRecentPlacementProfileImage ToolImage={val} key={key} />
            ))}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default RecentPlacementProfileImage;
