import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teacher_profileData: {},
  teacher_Batchs: [],
  teacher_batch_Students: [],
  teacher_batch_ClassLinks: [],
  teacher_batch_Details: [],
  teacher_WorkShop_Batchs: [],
  teacher_WorkShop_Students: [],
  teacher_WorkShop_ClassLinks: [],
  teacher_WorkShop_Details: [],
  teacher_RecodedStudents: [],
  teacher_recodedStudent_placements: [],
  teacher_recodedStudent_placement_images: [],
  teacher_recodedStudent_Sessions: [],
  
};

const TeacherReducer = createSlice({
  name: "teacher",
  initialState,
  reducers: {
    addteacher_WorkShop_Details(state, action) {
      state.teacher_WorkShop_Details = action.payload;
    },

    addteacher_batch_Details(state, action) {
      state.teacher_batch_Details = action.payload;
    },

    addTeacherProfileData: (state, action) => {
      state.teacher_profileData = action.payload;
    },
    addTeacherBatchs: (state, action) => {
      state.teacher_Batchs = action.payload;
    },
    addTeacherBatchStudents: (state, action) => {
      state.teacher_batch_Students = action.payload;
    },
    addteacherBatchClassLink: (state, action) => {
      state.teacher_batch_ClassLinks = action.payload;
    },
    addteacherWorkShopBatchs: (state, action) => {
      state.teacher_WorkShop_Batchs = action.payload;
    },
    addteacherWorkShopStudents: (state, action) => {
      state.teacher_WorkShop_Students = action.payload;
    },
    addteacherWorkShopClassLinks: (state, action) => {
      state.teacher_WorkShop_ClassLinks = action.payload;
    },
    addteacher_RecodedStudents: (state, action) => {
      state.teacher_RecodedStudents = action.payload;
    },
    addteacher_recodedStudent_placements: (state, action) => {
      state.teacher_recodedStudent_placements = action.payload;
    },
    addteacher_recodedStudent_Sessions: (state, action) => {
      state.teacher_recodedStudent_Sessions = action.payload;
    },
    addteacher_recodedStudent_placement_images: (state, action) => {
      state.teacher_recodedStudent_placement_images = action.payload;
    },
  },
});

export const {
  addteacher_WorkShop_Details,
  addTeacherProfileData,
  addteacher_batch_Details,
  addTeacherBatchs,
  addTeacherBatchStudents,
  addteacherBatchClassLink,
  addteacherWorkShopBatchs,
  addteacherWorkShopStudents,
  addteacherWorkShopClassLinks,
  addteacher_RecodedStudents,
  addteacher_recodedStudent_placements,
  addteacher_recodedStudent_Sessions,
  addteacher_recodedStudent_placement_images,
} = TeacherReducer.actions;

export default TeacherReducer.reducer;
