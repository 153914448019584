import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  landingPages: [],
  landingPageObjective: [],
  laningPageKeyHighlite: [],
  landingPageApplicants: [],
  landingPageBanner: [],
};

const LandingPageReducer = createSlice({
  name: "genaral",
  initialState,
  reducers: {
    addlandingPagesToStore(state, action) {
      state.landingPages = action.payload;
    },
    addlandingPageObjectiveToStore(state, action) {
      state.landingPageObjective = action.payload;
    },
    addlaningPageKeyHighliteToStore(state, action) {
      state.laningPageKeyHighlite = action.payload;
    },
    addlandingPageApplicantsToStore(state, action) {
      state.landingPageApplicants = action.payload;
    },
    addlandingPageBannerToStore(state, action) {
      state.landingPageBanner = action.payload;
    },
  },
});

export const {
  addlandingPagesToStore,
  addlandingPageObjectiveToStore,
  addlaningPageKeyHighliteToStore,
  addlandingPageApplicantsToStore,
  addlandingPageBannerToStore,
} = LandingPageReducer.actions;

export default LandingPageReducer.reducer;
