import React, { useState } from "react";
import { Link } from "react-router-dom";
import { addNewTeacher } from "../../Redux/ActionCompo/StudentTeachetAdminActions";
import { TextField, TextareaAutosize, Button, Grid } from "@mui/material";
import "../../Genaral.css";
import { adminExistById } from "../../Redux/ActionCompo/AuthActions";
const AddNewTeacher = () => {
  const [teacherName, setTeacherName] = useState("");
  const [teacherEmail, setTeacherEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [teacherAddress, setTeacherAddress] = useState("");
  const [error, setError] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    teacherName: "",
    teacherEmail: "",
    mobileNumber: "",
    password: "",
    conformPassword: "",
    teacherAddress: "",
  });

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    const errors = {
      teacherName: teacherName === "",
      teacherEmail: !emailRegex.test(teacherEmail),
      mobileNumber: !/^\d{10}$/.test(mobileNumber),
      password: !passwordRegex.test(password),
      conformPassword: conformPassword !== password,
      teacherAddress: teacherAddress === "",
    };

    setErrorMessages(errors);

    return !Object.values(errors).some((v) => v);
  };

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    const responce1 = await adminExistById(teacherEmail);

    if (responce1?.status === true) {
      setError("account  with the email is alrady exist ");
      return;
    }
    if (validateForm()) {
      const newTeacher = {
        teacher_Name: teacherName,
        teacher_Email: teacherEmail,
        teacher_MobileNum: Number(mobileNumber),
        teacher_Password: password,
        teacher_Address: teacherAddress,
      };
      const responce = await addNewTeacher(newTeacher);

      if (responce?.status === true) {
        // Reset form fields
        setTeacherName("");
        setTeacherEmail("");
        setMobileNumber("");
        setConformPassword("");
        setPassword("");
        setTeacherAddress("");
      }
    }
  };

  return (
    <div style={{ width: "100%" }} className="AddNewTeacher-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={"/teachers"} className="link">
            All teachers
          </Link>
          <h4>Add New Teacher</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Enter Teacher Name"
                  variant="outlined"
                  fullWidth
                  value={teacherName}
                  onChange={(e) => setTeacherName(e.target.value)}
                  error={errorMessages.teacherName}
                  helperText={
                    errorMessages.teacherName ? "Name is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Enter Teacher Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={teacherEmail}
                  onChange={(e) => setTeacherEmail(e.target.value)}
                  error={errorMessages.teacherEmail}
                  helperText={
                    errorMessages.teacherEmail ? "Enter a valid email" : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Enter Teacher Mobile Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  error={errorMessages.mobileNumber}
                  helperText={
                    errorMessages.mobileNumber
                      ? "Enter a valid 10-digit mobile number"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Enter Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={errorMessages.password}
                  helperText={
                    errorMessages.password
                      ? "Password must contain at least 8 characters with at least one number, one lowercase, and one uppercase letter."
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  value={conformPassword}
                  onChange={(e) => setConformPassword(e.target.value)}
                  error={errorMessages.conformPassword}
                  helperText={
                    errorMessages.conformPassword
                      ? "Passwords do not match"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  rowsMin={8}
                  aria-label="Enter Address"
                  placeholder="Enter Address"
                  fullWidth
                  value={teacherAddress}
                  onChange={(e) => setTeacherAddress(e.target.value)}
                  error={errorMessages.teacherAddress}
                />
                {errorMessages.teacherAddress && (
                  <div className="error-message">Address is required</div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
          <span>{error}</span>  
        </div>
      </div>
    </div>
  );
};

export default AddNewTeacher;
