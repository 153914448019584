import React, { useEffect, useState } from "react";
import "./CourseCategory.scss";
import { useSelector, useDispatch } from "react-redux";
import { addallCategorysTostore } from "../../Redux/Reduuces/GenaralReducer";
import { Link } from "react-router-dom";
import {
  deleteCourseCtegory,
  getAllCourseCategory,
} from "../../Redux/ActionCompo/AdminGenaralActions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Button,
} from "@mui/material";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const CourseCategory = () => {
  const courseCategorys = useSelector((state) => state.genaral.allCategorys);
  const dispatch = useDispatch();

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  useEffect(() => {
    const getData = async () => {
      const data = await getAllCourseCategory();
      dispatch(addallCategorysTostore(data));
    };
    getData();
  }, [dispatch]);

  const handleUpdateRequest = async (id, type) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to delete the essential ?");
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteCourseCtegory(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("essential delete successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getAllCourseCategory();
    dispatch(addallCategorysTostore(data));
  };

  return (
    <div className="CourseCategory-main-container">
      <div className="CourseCategory-container-links">
        <div className="link-contant">
          <h4>All Categories</h4>
          <Link to={"addcategory"} className="link">
            Add New Category
          </Link>
        </div>
      </div>

      <div className="CourseCategory-table">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CATEGORY ID</TableCell>
                <TableCell>CATEGORY NAME</TableCell>
                <TableCell>DELETE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courseCategorys?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.category_Id}</TableCell>
                  <TableCell>{val.category_Name}</TableCell>
                  <TableCell>
                    <span
                      onClick={() => {
                        handleUpdateRequest(val.category_Id, "DELETE");
                      }}
                      className="delete_button"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle className="dialog-title">
            Confirm Status Update
          </DialogTitle>
          <DialogContent className="dialog-content">
            {updateAlertMessage}
          </DialogContent>
          <DialogActions className="dialog-actions">
            <Button
              variant="contained"
              onClick={handleDialogClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateStatus}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={5000} // Auto-hide after 5 seconds
          onClose={handleCloseSnackbar}
          message={successMessage}
        />
      </div>
    </div>
  );
};

export default CourseCategory;
