import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./MyBatchs.scss";
import { addTeacherBatchs } from "../../../Redux/Reduuces/TeacherReducers";
import { useSelector, useDispatch } from "react-redux";
import { getAllBatchCourseOfTeacher } from "../../../Redux/ActionCompo/TeacherActions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import "../../../";

const MyBatchs = () => {
  const batchDetails = useSelector((state) => state.teacher.teacher_Batchs);

  const dispatch = useDispatch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));

    const getData = async () => {
      const teacherId = user.userId;
      const data = await getAllBatchCourseOfTeacher(teacherId);
      dispatch(addTeacherBatchs(data));
    };

    getData();
  }, [dispatch]);

  return (
    <div className="MyBatchs-main-container">
      <div className="MyBatchs-nav-container">
        <ul>
          <li>
            <Link to={"/"} className="link">
              Profile
            </Link>
          </li>
          <li className="link">
            <h3>Batchs</h3>
          </li>
          <li>
            <Link to={"/teachers/WorkShopBatchs"} className="link">
              WorkShop Batchs
            </Link>
          </li>
          <li>
            <Link to={"/teachers/recodedStudent"} className="link">
              Recoded Students
            </Link>
          </li>
        </ul>

        <div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>BATCH ID</TableCell>
                  <TableCell>BATCH NAME</TableCell>
                  <TableCell>COURSE NAME</TableCell>
                  <TableCell>COURSE TYPE NAME</TableCell>
                  <TableCell>SUBJECT NAME</TableCell>
                  <TableCell>STUDENTS</TableCell>
                  <TableCell>BATCH DETAILS</TableCell>
                  <TableCell>CLASS LINK</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {batchDetails &&
                  batchDetails?.map((val, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>{val.batch_course_id}</TableCell>
                        <TableCell>{val.batch_cource_name}</TableCell>
                        <TableCell>{val.course_name}</TableCell>
                        <TableCell>{val.course_type_name}</TableCell>
                        <TableCell>{val.batch_course_subject_name}</TableCell>
                        <TableCell>
                          <Link
                            className="link"
                            to={`/teachers/batch/${val.batch_course_id}/Students`}
                          >
                            {" "}
                            students
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            className="link"
                            to={`/teacher/batch/${val.batch_course_id}/details`}
                          >
                            details
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            className="link"
                            to={`/teacher/batch/${val.batch_course_id}/classLink/${val.batch_course_subject_id}`}
                          >
                            class link
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default MyBatchs;
