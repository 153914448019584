import React, { useEffect, useState } from "react";
import { addBatchCourseStudebntsToStore } from "../../../../../../Redux/Reduuces/BatchReducer";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteBatchCourseStudent,
  getBatchCourseStudents,
  setStudent_PermitionStatus,
} from "../../../../../../Redux/ActionCompo/BatchActions";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
} from "@mui/material";
import "../../../../../../Genaral.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const CourseStudent = () => {
  const { batchId, subBatchId, batchCourseId } = useParams();

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const courseStudents = useSelector(
    (state) => state.batchData.batchCourseStudebnts
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getBatchCourseStudents(batchCourseId);
      dispatch(addBatchCourseStudebntsToStore(data));
    };
    getData();
  }, [batchCourseId, dispatch]);

  const handleUpdateRequest = async (id, type, status) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the recent placement?"
      );
    } else if (type === "STUDENT_PERMITION") {
      setUpdateType("STUDENT_PERMITION");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `Are you sure you want to   ${
          status ? "deactive" : "active"
        } recent place ment?`
      );
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteBatchCourseStudent(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("recent placement delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "STUDENT_PERMITION") {
      const payload = {
        student_PermitionStatus: "change",
      };
      const responce = await setStudent_PermitionStatus(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("details updated successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getBatchCourseStudents(batchCourseId);
    dispatch(addBatchCourseStudebntsToStore(data));
  };

  return (
    <div className="CourseStudent-main-container">
      <Link
        to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/details`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>
      <div className="CourseStudent-table">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>STUDENT ID</TableCell>
                <TableCell>STUDENT NAME</TableCell>
                <TableCell>ENROLL TYPE</TableCell>
                <TableCell>ACTIVE</TableCell>
                <TableCell>DELETE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courseStudents?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.student_batchId}</TableCell>
                  <TableCell>{val.student_name}</TableCell>
                  <TableCell>{val.enroll_Type}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        handleUpdateRequest(
                          val.student_batchId,
                          "STUDENT_PERMITION",
                          val.student_PermitionStatus === "A"
                        );
                      }}
                      variant="contained"
                      size="small"
                      color={`${
                        val.student_PermitionStatus === "A"
                          ? "success"
                          : "error"
                      }`}
                    >
                      {val.student_PermitionStatus === "A" ? "ON" : "OFF"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <span
                      className="delete_button"
                      onClick={() => {
                        handleUpdateRequest(val.student_batchId, "DELETE");
                      }}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default CourseStudent;
