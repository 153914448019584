import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Link, useParams } from "react-router-dom";
import { addRecentPlacementAcadamicProjects } from "../../../../../Redux/ActionCompo/RecentPlacementsAction";
import { Button, Snackbar } from "@mui/material";

const AddRecentPlacementProjects = () => {
  const { recentplacementId } = useParams();

  // Step 1: Add state variables
  const [projectName, setProjectName] = useState("");
  const [projectDesc1, setProjectDesc1] = useState("");
  const [projectDesc2, setProjectDesc2] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Step 2: Validation checks for empty fields
  const validateForm = () => {
    const errors = {};

    if (!projectName.trim()) {
      errors.projectName = "Project Name is required";
    }

    if (!projectDesc1.trim()) {
      errors.projectDesc1 = "Project Description 1 is required";
    }
    if (!projectDesc2.trim()) {
      errors.projectDesc2 = "Project Description 2 is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Step 3: Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      Project_Name: projectName,
      Project_Desc1: projectDesc1,
      Project_Desc2: projectDesc2,
    };

    if (validateForm()) {
      const response = await addRecentPlacementAcadamicProjects(
        recentplacementId,
        data
      );

      if (response?.status === true) {
        setProjectName("");
        setProjectDesc1("");
        setProjectDesc2("");
        setSuccessMessage("branch added successfully");
        setOpenSnackbar(true);
      }
    } else {
      console.log("Form has errors. Please fix them.");
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ width: "100vw" }} className="AddClassLinks-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/recentplacements/${recentplacementId}/projects`}
            className="link"
          >
            All projects
          </Link>
          <h4>Add projects</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={handleSubmit}>
            <TextField
              label="Enter Project_Name"
              type="text"
              fullWidth
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              error={!!formErrors.projectName}
              helperText={formErrors.projectName}
            />
            <TextField
              label="Enter Project_Desc1"
              type="text"
              fullWidth
              value={projectDesc1}
              onChange={(e) => setProjectDesc1(e.target.value)}
              error={!!formErrors.projectDesc1}
              helperText={formErrors.projectDesc1}
            />
            <TextField
              label="Enter Project link"
              type="text"
              fullWidth
              value={projectDesc2}
              onChange={(e) => setProjectDesc2(e.target.value)}
              error={!!formErrors.projectDesc2}
              helperText={formErrors.projectDesc2}
            />
            <Button type="submit">Add</Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddRecentPlacementProjects;
