import React, { useEffect, useState } from "react";
import { uploadImage } from "../../../Redux/ActionCompo/CloudenarowImageUplode";
import { Button, Input } from "@mui/material";
import SingleBlogImage from "./SingleBlogImage";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setblogImages } from "../../../Redux/Reduuces/BlogReducer";
import {
  addBlogImage,
  getBlogImages,
} from "../../../Redux/ActionCompo/BlogActions";
const BlogImage = () => {
  const [file, setFile] = useState(null);
  const blogImages = useSelector((state) => state.blogData.blogImages);

  const { blogId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getBlogImages(blogId);
      console.log(data);
      dispatch(setblogImages(data));
    };

    getData();
  }, [blogId, dispatch]);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid JPEG or PNG image.");
      }
    }
  };

  const handleAddImage = async () => {
    const data = await uploadImage(file);
    console.log(data?.secure_url);
    if (data) {
      const newImageData = {
        image_URL: data?.secure_url,
      };
      console.log(newImageData);
      const responce = await addBlogImage(blogId, newImageData);
      console.log(responce);
    }
  };

  return (
    <div style={{ width: "100%" }} className="CourseImage-main-container">
      <div>
        <NavLink to={`/courses`}>back</NavLink>
        <div className="IMAGE-FORM-MAIN-CONTAINER">
          <div className="IMAGE-FORM-CONTAINER">
            <form>
              <Input
                type="file"
                accept="image/jpeg, image/png"
                onChange={handleImageChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddImage}
              >
                Add Image
              </Button>
            </form>
          </div>
        </div>
        <div className="ALL-IMAGE-MAIN-CONTAINER">
          <div className="ALL-IMAGE-CONTAINER-heading">
            <h1>All Images</h1>
          </div>
          <div className="ALL-IMAGE-CONTAINER">
            {blogImages &&
              blogImages.map((val, key) => (
                <SingleBlogImage courseTypeImage={val} key={key} />
              ))}
            ,
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogImage;
