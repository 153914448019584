import React, { useEffect, useState } from "react";
import { addCourseTopicsToStore } from "../../../../../../../../Redux/Reduuces/CourceReducer";
import { Link, NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteTopic,
  getAllTopics,
  setActive_Topic_status,
} from "../../../../../../../../Redux/ActionCompo/CourseAction";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";

import "../../../../../../../../Genaral.css";
const Topics = () => {
  const topics = useSelector((state) => state.courseData.courseTopics);
  const dispatch = useDispatch();

  // const navigate = useNavigate();
  const [order, setOrder] = useState(0);
  const { branchId, courseId, courseTypeId, moduleId, lessonId } = useParams();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllTopics(lessonId);
      if (data == null) {
        dispatch(addCourseTopicsToStore([]));
      }

      dispatch(addCourseTopicsToStore(data));
    };
    getData();
  }, [lessonId, dispatch]);

  const activeStatusHandler = async (topicId) => {
    const newStatus = {
      active_title: "change",
    };

    const responce = await setActive_Topic_status(topicId, newStatus);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const deleteHandeler = async (topicId) => {
    const responce = await deleteTopic(topicId);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const updateModuleOrder = async (lessonId) => {
    const newStatue = {
      topic_Order: Number(order),
    };
    const responce = await setActive_Topic_status(lessonId, newStatue);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="Topics-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Topics</h4>
          <Link to={"addTopics"} className="link">
            Add New Topic
          </Link>
        </div>
      </div>

      <Link
        to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/models/${moduleId}/lessons`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TextField
        variant="outlined"
        type="number"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <TableContainer component={Paper} className="topic-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>TOPIC_TITLE</TableCell>
              <TableCell>ACTIVE</TableCell>
              <TableCell>videos</TableCell>
              <TableCell>Assignments</TableCell>
              <TableCell>ORDER</TableCell>
              <TableCell>UPDATE</TableCell>
              <TableCell>delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topics &&
              topics?.map((val, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell>{key + 1}</TableCell>
                    <TableCell>{val.topic_title}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          activeStatusHandler(val.topic_id);
                        }}
                        variant="contained"
                        size="small"
                        color={`${
                          val.active_Topic === "A" ? "success" : "error"
                        }`}
                      >
                        {val.active_Topic === "A" ? "Active" : "Deactive"}
                      </Button>
                    </TableCell>

                    <TableCell>
                      <NavLink to={`${val.topic_id}/videos`}>
                        <Button size="small">view</Button>
                      </NavLink>
                    </TableCell>
                    <TableCell>
                      <NavLink to={`${val.topic_id}/assignment`}>
                        <Button size="small">view</Button>
                      </NavLink>
                    </TableCell>
                    <TableCell>{val.topic_Order}</TableCell>
                    <TableCell>
                      <span
                        className="delete_button"
                        onClick={() => {
                          deleteHandeler(val.topic_id);
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          updateModuleOrder(val.topic_id);
                        }}
                      >
                        update
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Topics;
