import React, { useEffect, useState } from "react";
import "./EventType.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEventType,
  getEventType,
  updateEventTypeVideo,
} from "../../../Redux/ActionCompo/EventActions";
import { setEventTypes } from "../../../Redux/Reduuces/EventReducer";
const EventType = () => {
  const dispatch = useDispatch();

  const { eventId } = useParams();
  const eventTypes = useSelector((state) => state.EventData.eventTypes);
  const [order, setOrder] = useState(0);
  console.log(eventTypes);
  useEffect(() => {
    const getData = async () => {
      const data = await getEventType(eventId);
      dispatch(setEventTypes(data));
    };
    getData();
  }, [dispatch, eventId]);

  const updateorderData = async (EVENT_ID) => {
    const data = {
      order: Number(order),
    };
    updateEventTypeVideo(EVENT_ID, data);
  };

  const updateActiveStatusData = async (EVENT_ID) => {
    const data = {
      ACTIVE_STATUS: "change",
    };
    updateEventTypeVideo(EVENT_ID, data);
  };

  const navigate = useNavigate();

  const handleDeleteEventType = async (id) => {
    deleteEventType(id);
  };

  return (
    <div className="Events-main-container" style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4> All event type</h4>
          <Link to={`add`} className="link">
            Add New event type
          </Link>
        </div>
      </div>

      <TextField
        variant="outlined"
        value={order}
        onChange={(e) => {
          setOrder(e.target.value);
        }}
      />

      <TableContainer style={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>SI/NO</TableCell>
            <TableCell>Event Name</TableCell>
            <TableCell>Event order</TableCell>
            <TableCell>update</TableCell>
            <TableCell>images</TableCell>
            <TableCell>videos</TableCell>
            <TableCell>Active Status</TableCell>
            <TableCell>delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventTypes &&
            eventTypes.length > 0 &&
            eventTypes?.map((val, key) => (
              <TableRow key={key}>
                <TableCell>{key + 1}</TableCell>
                <TableCell
                  onClick={() => {
                    navigate(`/Events/${val.EVENT_TYPE_ID}/EventType`);
                  }}
                >
                  {val.EVENT_TYPE_NAME}
                </TableCell>
                <TableCell>{val.ORDER}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => {
                      updateorderData(val.EVENT_TYPE_ID);
                    }}
                  >
                    update
                  </Button>
                </TableCell>
                <TableCell
                  onClick={() => {
                    navigate(`${val.EVENT_TYPE_ID}/images`);
                  }}
                >
                  <Button>images</Button>
                </TableCell>
                <TableCell
                  onClick={() => {
                    navigate(`${val.EVENT_TYPE_ID}/videos`);
                  }}
                >
                  <Button>videos</Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color={`${val.ACTIVE_STATUS === "A" ? "success" : "error"}`}
                    onClick={() => {
                      updateActiveStatusData(val.EVENT_TYPE_ID);
                    }}
                  >
                    {val.ACTIVE_STATUS === "A" ? "active" : "DeActive"}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleDeleteEventType(val.EVENT_TYPE_ID);
                    }}
                  >
                    delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TableContainer>
    </div>
  );
};

export default EventType;
