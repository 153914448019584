import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  deleteTopicAssignment,
  getTopicAssignment,
  updateTopicAssignment,
} from "../../../../../../../../../Redux/ActionCompo/CourseAction";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addTopicAssignment } from "../../../../../../../../../Redux/Reduuces/CourceReducer";
import axios from "axios";
const TopicAssignment = () => {
  const { topicId } = useParams();

  const [order, setOrder] = useState(0);

  const dispatch = useDispatch();

  const topicAssignment = useSelector(
    (state) => state.courseData.topicAssignment
  );

  useEffect(() => {
    const getData = async () => {
      const data = await getTopicAssignment(topicId);
      dispatch(addTopicAssignment(data));
    };
    getData();
  }, [dispatch, topicId]);

  const orderHandler = async (assignment_id) => {
    const data = {
      order: Number(order),
    };
    const response = await updateTopicAssignment(assignment_id, data);
    console.log(response);
  };

  const activeStatusHandler = async (assignment_id) => {
    const data = {
      active_status: "change",
    };
    const response = await updateTopicAssignment(assignment_id, data);
    console.log(response);
  };
  const deleteHandeler = async (assignment_id) => {
    const response = await deleteTopicAssignment(assignment_id);
    console.log(response);
  };

  const downloadForm = async (filename) => {
    const data = {
      path: filename,
    };

    try {
      const res = await axios.post(
        "http://localhost:8082/api/data/download",
        data,
        {
          responseType: "arraybuffer", // Specify the response type as arraybuffer
        }
      );

      // Handle the response, for example, by triggering a download
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename; // You might want to adjust the filename
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All video </h4>
          <NavLink to={"add"} className="link">
            Add New video
          </NavLink>
        </div>
      </div>
      <TextField
        variant="outlined"
        type="number"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <TableContainer component={Paper} className="topic-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>assignment topic</TableCell>
              <TableCell>assignment desc</TableCell>
              <TableCell>file location</TableCell>
              <TableCell>order</TableCell>
              <TableCell>active</TableCell>
              <TableCell>update</TableCell>
              <TableCell>download</TableCell>
              <TableCell>delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topicAssignment &&
              topicAssignment?.map((val, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell>{key + 1}</TableCell>
                    <TableCell>{val.assignment_topic}</TableCell>
                    <TableCell>{val.assignmant_desc}</TableCell>
                    <TableCell>{val.file_location}</TableCell>
                    <TableCell>{val.order}</TableCell>

                    <TableCell>
                      <Button
                        onClick={() => {
                          activeStatusHandler(val.assignment_id);
                        }}
                        variant="contained"
                        size="small"
                        color={`${
                          val.active_Status === "A" ? "success" : "error"
                        }`}
                      >
                        {val.active_Status === "A" ? "Active" : "Deactive"}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => orderHandler(val.assignment_id)}>
                        update
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => downloadForm(val.file_location)}>
                        download
                      </Button>
                    </TableCell>
                    <TableCell>
                      <span
                        className="delete_button"
                        onClick={() => {
                          deleteHandeler(val.assignment_id);
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TopicAssignment;
