import React from "react";
import {
  deleteRecodedStudentPlacementsImages,
  updateRecoredStudentPlacementsImageData,
} from "../../../../../Redux/ActionCompo/TeacherActions";
import { Button } from "@mui/material";
const RecodedStudentSinglePlacementImage = (props) => {
  const imageData = props.PlacmentImage;
  console.log(imageData);
  const deletePlacementImagedata = async (imageId) => {
    const responce = await deleteRecodedStudentPlacementsImages(imageId);
    console.log(responce);
  };

  const updatePlacementImageData = async (imageId) => {
    const data = {
      actuve_status: "change",
    };

    const responce = await updateRecoredStudentPlacementsImageData(
      imageId,
      data
    );
    console.log(responce);
  };
  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData.Image_URL} alt="cjn"></img>
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.image_active_Staute === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => {
            updatePlacementImageData(imageData.image_id);
          }}
        >
          {imageData.image_active_Staute === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deletePlacementImagedata(imageData.image_id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default RecodedStudentSinglePlacementImage;
