import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Link, useParams } from "react-router-dom";
import { addRecentPlacementAcadamicQualification } from "../../../../../Redux/ActionCompo/RecentPlacementsAction";
import { Snackbar } from "@mui/material";

const AddPlacemetAcadamicQualification = () => {
  const { recentplacementId } = useParams();

  // Step 1: Add state variables
  const [qualification, setQualification] = useState("");
  const [qualificationFrom, setQualificationFrom] = useState("");
  const [date, setDate] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Step 2: Validation checks for empty fields
  const validateForm = () => {
    const errors = {};
    if (!qualification.trim()) {
      errors.qualification = "Qualification is required";
    }
    if (!qualificationFrom.trim()) {
      errors.qualificationFrom = "Qualification From is required";
    }
    if (!date) {
      errors.date = "Date is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      qualification: qualification,
      qualificatin_from: qualificationFrom,
      date: new Date(date),
    };

    if (validateForm()) {
      const responce = await addRecentPlacementAcadamicQualification(
        recentplacementId,
        data
      );
      if (responce?.status === true) {
        setQualification("");
        setQualificationFrom("");
        setDate("");
        setSuccessMessage("Qualification added successfully");
        setOpenSnackbar(true);
      }
    } else {
      console.log("Form has errors. Please fix them.");
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div style={{ width: "100vw" }} className="AddClassLinks-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/recentplacements/${recentplacementId}/acadamicQualification`}
            className="link"
          >
            All Recent Placement
          </Link>
          <h4>Add Recent Placement</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={handleSubmit}>
            {/* Step 2: Display validation errors */}
            <TextField
              label="Enter qualification"
              type="text"
              fullWidth
              value={qualification}
              onChange={(e) => setQualification(e.target.value)}
              error={!!formErrors.qualification}
              helperText={formErrors.qualification}
            />

            <TextField
              label="Enter qualificationFrom"
              type="text"
              fullWidth
              value={qualificationFrom}
              onChange={(e) => setQualificationFrom(e.target.value)}
              error={!!formErrors.qualificationFrom}
              helperText={formErrors.qualificationFrom}
            />

            <TextField
              type="date"
              fullWidth
              value={date}
              onChange={(e) => setDate(e.target.value)}
              error={!!formErrors.date}
              helperText={formErrors.date}
            />

            <button type="submit">Add</button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddPlacemetAcadamicQualification;
