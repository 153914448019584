import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";

export const getAllRecodedStudentWithMenter = async () => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/recordedStudents/withMenter`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllRecodedStudentWithOutMenter = async () => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/recordedStudents/noMenter`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const updateRecodedStudentData = async (
  recordedStudentId,
  studentData
) => {
  try {
    const responce = await myaxios.put(
      `/recoded/bch/recordedStudents/${recordedStudentId}`,
      studentData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const assignMenterToStudent = async (recordedStudentId, teacherId) => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/recordedStudents/${recordedStudentId}/teacher/${teacherId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteRecordedStudent = async (recordedStudentId) => {
  try {
    const responce = await myaxios.delete(
      `/recoded/bch/recordedStudents/${recordedStudentId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteRecordedStudentMenter = async (recordedStudentId) => {
  try {
    const responce = await myaxios.delete(
      `/recoded/bch/recordedStudents/${recordedStudentId}/menter`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};
