import React from "react";
import {
  deletePlacementImage,
  updatPlacemetImage,
} from "../../../../../../../Redux/ActionCompo/BatchActions";

import "../../../../../../../Genaral.css";
import { Button } from "@mui/material";
const PlacementSingleImage = (props) => {
  const imageData = props.PlacmentImage;
  console.log(imageData);
  const deletePlacementImagedata = async (imageId) => {
    const responce = await deletePlacementImage(imageId);
    console.log(responce);
  };

  const updatePlacementImageData = async (imageId) => {
    const data = {
      active_Status: "change",
    };

    const responce = await updatPlacemetImage(imageId, data);
    console.log(responce);
  };

  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData.Image_URL} alt="cjn"></img>
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_Status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => {
            updatePlacementImageData(imageData.image_id);
          }}
        >
          {imageData.active_Status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deletePlacementImagedata(imageData.image_id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default PlacementSingleImage;
