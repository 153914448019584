import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBlogs: [],
  blogImages: [],
  BlogDetails: [],
  BlogComments: [],
};

const BlogReducer = createSlice({
  name: "blogData",
  initialState: initialState,
  reducers: {
    setallBlogs: (state, action) => {
      state.allBlogs = action.payload;
    },

    setblogImages: (state, action) => {
      state.blogImages = action.payload;
    },

    setBlogDetails: (state, action) => {
      state.BlogDetails = action.payload;
    },
    setBlogComments: (state, action) => {
      state.BlogComments = action.payload;
    },
  },
});

export const { setallBlogs, setblogImages, setBlogDetails, setBlogComments } =
  BlogReducer.actions;

export default BlogReducer.reducer;
