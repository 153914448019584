import React from "react";
import "./SingleModuleImage.scss";
import { Button } from "@mui/material";
import {
  deleteModuleImage,
  updateModuleImages,
} from "../../../../../../../Redux/ActionCompo/CourseAction";
const SingleModuleImage = (props) => {
  const imageData = props.imageData;
  console.log(imageData);

  const activeStatusHandler = async (imageId) => {
    const data = {
      active_Status: "change",
    };

    const responce = await updateModuleImages(imageId, data);

    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const deleteHandler = async (imageId) => {
    const responce = await deleteModuleImage(imageId);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData?.IMAGE_URL} alt="sx"></img>
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => activeStatusHandler(imageData.IMAGE_ID)}
        >
          {imageData?.active_status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteHandler(imageData.IMAGE_ID);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
export default SingleModuleImage;
