import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";
export const sandEnrollRequest = async (studentId, courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/enroll/request/${studentId}/courses/${courseTypeId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllEnrollRequest = async () => {
  try {
    const responce = await myaxios.get(`/enroll/requests`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const addStudentToBatch = async (
  batchCourseId,
  studentId,
  requestId,
  payload
) => {
  try {
    const responce = await myaxios.post(
      `/bch/batchCourses/${batchCourseId}/student/${studentId}/${requestId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const addStudentToRecodedBatch = async (
  courseTypeId,
  studentId,
  requestId,
  studentData
) => {
  try {
    const responce = await myaxios.post(
      `/recoded/bch/courseTypes/${courseTypeId}/recordedStudents/${studentId}/${requestId}`,
      studentData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteRquests = async (requestId) => {
  try {
    const responce = await myaxios.delete(`/enroll/requests/${requestId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};
