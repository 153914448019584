import React, { useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import {
  deleteLandingPageObjective,
  getLandingPageObjective,
} from "../../../../Redux/ActionCompo/LandingPageActions";
import { addlandingPageObjectiveToStore } from "../../../../Redux/Reduuces/LandingPageReducer";
import { useDispatch, useSelector } from "react-redux";
const LandingPageObjectives = () => {
  const landingPageObjective = useSelector(
    (state) => state.landingPageData.landingPageObjective
  );

  const { pageId, pageName } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getadata = async () => {
      const data = await getLandingPageObjective(pageId);
      dispatch(addlandingPageObjectiveToStore(data));
    };
    getadata();
  }, [dispatch, pageId]);

  const deleteObjective = async (id) => {
    const responce = await deleteLandingPageObjective(id);
    console.log(responce);
  };
  return (
    <div style={{ width: "100vw" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Objective</h4>
          <Link to={"add"} className="link">
            Add New Objective
          </Link>
        </div>
      </div>
      <Link to={`/landingPages/${pageId}/${pageName}/details`} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="course-id"> SI/NO</TableCell>
              <TableCell className="course-name">objective heading</TableCell>
              <TableCell className="future-course">objective desc</TableCell>
              <TableCell className="delete-button">delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {landingPageObjective &&
              landingPageObjective?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.objective_heading}</TableCell>
                  <TableCell>{val.objective_desc}</TableCell>

                  <TableCell>
                    <i
                      onClick={() => {
                        deleteObjective(val.objective_id);
                      }}
                      class="fa-solid fa-trash-can"
                    ></i>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LandingPageObjectives;
