import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { uploadImage } from "../../../../../../../Redux/ActionCompo/CloudenarowImageUplode";
import {
  addCourseTypeToolImage,
  getCourseTypeTools,
} from "../../../../../../../Redux/ActionCompo/CourseAction";
import { useDispatch, useSelector } from "react-redux";
import { addtoolImages } from "../../../../../../../Redux/Reduuces/CourceReducer";
import SingleToolImage from "./SingleToolImage";
import "../../../../../../../Genaral.css";
import { Snackbar } from "@mui/material";
const ToolImage = () => {
  const { courseId, courseTypeId, toolId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [file, setFile] = useState(null);

  const courseTypeVideoData = useSelector(
    (state) => state.courseData.toolImages
  );

  const dispatch = useDispatch();

  const handleAddImage = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const data = await uploadImage(file);
    console.log(data?.secure_url);

    if (data) {
      const newImageData = {
        image_URL: data?.secure_url,
      };
      console.log(newImageData);
      const response = await addCourseTypeToolImage(toolId, newImageData);

      if (response?.status === true) {
        setSuccessMessage("branch added successfully");
        setOpenSnackbar(true);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      const responce = await getCourseTypeTools(toolId);
      dispatch(addtoolImages(responce));
    };

    getData();
  }, [toolId, dispatch]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <div className="IMAGE-FORM-MAIN-CONTAINER">
        <NavLink to={`/courses/${courseId}/courseTypes/${courseTypeId}/tools`}>
          back
        </NavLink>
        <div className="IMAGE-FORM-CONTAINER">
          <form className="form" onSubmit={handleAddImage}>
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            ></input>
            <button type="submit">addfile</button>
          </form>
        </div>
      </div>
      <div className="ALL-IMAGE-MAIN-CONTAINER">
        <div className="ALL-IMAGE-CONTAINER-heading">
          <h1>All Images</h1>
        </div>
        <div className="ALL-IMAGE-CONTAINER">
          {courseTypeVideoData &&
            courseTypeVideoData?.map((val, key) => {
              return <SingleToolImage ToolImage={val} key={key} />;
            })}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default ToolImage;
