import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { addNewModule } from "../../../../../../Redux/ActionCompo/CourseAction";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../../../../../../Genaral.css";
import { Snackbar } from "@mui/material";
const AddModels = () => {
  const [model_Title, setModel_Title] = useState("");
  const [error, setError] = useState(""); // State to hold validation error message
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { branchId, courseId, courseTypeId } = useParams();

  const validateForm = () => {
    if (model_Title.trim() === "") {
      setError("Model Title cannot be empty.");
      return false;
    }
    return true;
  };

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return; // Do not submit if validation fails
    }

    const newModule = {
      module_Title: model_Title,
    };
    const responce = await addNewModule(courseTypeId, newModule);
    if (responce?.status === true) {
      setModel_Title("");
      setSuccessMessage("module  added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddModels-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/models`}
            className="link"
          >
            All Models
          </Link>
          <h4>Add New Models</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <label>Enter Model Title</label>
              <TextField
                fullWidth
                placeholder="Enter Model Title"
                variant="outlined"
                value={model_Title}
                onChange={(e) => setModel_Title(e.target.value)}
                error={!!error}
                helperText={error}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddModels;
