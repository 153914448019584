import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  studentData: {},
  enrolledCourses: [],
  enrolledRecodedCourseModules: [],
  enrolledModouleLessons: [],
};

const StudentDetailsReducer = createSlice({
  name: "student details",
  initialState,
  reducers: {
    setStudentData(state, action) {
      state.studentData = action.payload;
    },
    setEnrolledCourses(state, action) {
      state.enrolledCourses = action.payload;
    },
    setEnrolledRecodedCourseModules(state, action) {
      state.enrolledRecodedCourseModules = action.payload;
    },
    setEnrolledModouleLessons(state, action) {
      state.enrolledModouleLessons = action.payload;
    },
  },
});

export const {
  setStudentData,
  setEnrolledCourses,
  setEnrolledRecodedCourseModules,
  setEnrolledModouleLessons,
} = StudentDetailsReducer.actions;
export default StudentDetailsReducer.reducer;
