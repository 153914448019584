import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
  import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBlogDetails,
  getAllBlogDetails,
  updateBlogDetails,
} from "../../../Redux/ActionCompo/BlogActions";
import "../../../Genaral.css";
import { setBlogDetails } from "../../../Redux/Reduuces/BlogReducer";
const BlogDetails = () => {
  const { courseId, courseTypeId, blogId } = useParams();

  const [order, setOrder] = useState(0);

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const BlogDetails = useSelector((state) => state.blogData.BlogDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllBlogDetails(blogId);
      dispatch(setBlogDetails(data));
    };
    getData();
  }, [blogId, dispatch]);

  const handleUpdateRequest = async (id, type, status) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the blog detaile?"
      );
    } else if (type === "ACTIVE") {
      setUpdateType("ACTIVE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `Are you sure you want to   ${
          status ? "deactive" : "active"
        } blog  detaile?`
      );
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to update the order of blog details ?"
      );
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteBlogDetails(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("blog details delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ACTIVE") {
      const payload = {
        active_Status: "change",
      };

      const responce = await updateBlogDetails(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("details updated successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ORDER") {
      if (order && order > 0) {
        const data = {
          order: Number(order),
        };
        const responce = await updateBlogDetails(UpdateId, data);
        if (responce?.status === true) {
          setSuccessMessage("blog detail order  updated successfully");
          setOpenSnackbar(true);
        }
      }
    }
    const data = await getAllBlogDetails(blogId);
    dispatch(setBlogDetails(data));
  };

  return (
    <div className="Objectievs-main-container" style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All blog details</h4>
          <Link to={"addDetails"} className="link">
            Add blog details
          </Link>
        </div>
      </div>

      <Link
        to={`/courses/${courseId}/courseTypes/${courseTypeId}/details`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>

      <div className="order-Input">
        <TextField
          type="number"
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          variant="outlined"
        ></TextField>
      </div>

      <TableContainer component={Paper} className="course-Type-details-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>blog heading</TableCell>
              <TableCell>blog desc</TableCell>
              <TableCell>blog order</TableCell>
              <TableCell>order update</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {BlogDetails?.map((val, key) => {
              return (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.BLOG_DETAILS_HEADING}</TableCell>
                  <TableCell>{val.BLOG_DETAILS_DESC}</TableCell>
                  <TableCell>{val.BLOG_DETAILS_ORDER}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(u) =>
                        handleUpdateRequest(val.BLOG_DETAILS_ID, "ORDER")
                      }
                    >
                      update
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        handleUpdateRequest(
                          val.BLOG_DETAILS_ID,
                          "ACTIVE",
                          val.ACTIVE_STATUS === "A"
                        );
                      }}
                      variant="contained"
                      size="small"
                      color={`${
                        val.ACTIVE_STATUS === "A" ? "success" : "error"
                      }`}
                    >
                      {val.ACTIVE_STATUS === "A" ? "active" : "deactive"}
                    </Button>
                  </TableCell>

                  <TableCell>
                    <span
                      onClick={() => {
                        handleUpdateRequest(val.BLOG_DETAILS_ID, "DELETE");
                      }}
                      variant="contained"
                      size="small"
                      className="delete_button"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default BlogDetails;
