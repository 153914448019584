import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  awsVideoBranch: [],
  awsVideoBranchCourse: [],
  awsVideoBranchCourseType: [],
  awsVideoModule: [],
  awsVideoLesson: [],
  awsVideoTopicVideos: [],
};

const AwsVideoReducer = createSlice({
  name: "awsvideo",
  initialState,
  reducers: {
    setAwsVideoBranch(state, action) {
      state.awsVideoBranch = action.payload;
    },
    setAwsVideoBranchCourse(state, action) {
      state.awsVideoBranchCourse = action.payload;
    },
    setAwsVideoBranchCourseType(state, action) {
      state.awsVideoBranchCourseType = action.payload;
    },
    setAwsVideoModule(state, action) {
      state.awsVideoModule = action.payload;
    },
    setAwsVideoLesson(state, action) {
      state.awsVideoLesson = action.payload;
    },
    setAwsVideoTopicVideos(state, action) {
      state.awsVideoTopicVideos = action.payload;
    },
  },
});

export const {
  setAwsVideoBranch,
  setAwsVideoBranchCourse,
  setAwsVideoBranchCourseType,
  setAwsVideoModule,
  setAwsVideoLesson,
  setAwsVideoTopicVideos,
} = AwsVideoReducer.actions;

export default AwsVideoReducer.reducer;
