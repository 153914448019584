import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { addteacher_recodedStudent_Sessions } from "../../../../Redux/Reduuces/TeacherReducers";
import {
  deleteRecodedStudentCLassLinks,
  getRecodedStudentCLassLinks,
} from "../../../../Redux/ActionCompo/TeacherActions";
import "../../../../Genaral.css";
const RecodedStudentSessions = () => {
  const teacher_recodedStudent_Sessions = useSelector(
    (state) => state.teacher.teacher_recodedStudent_Sessions
  );

  const { studentId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getRecodedStudentCLassLinks(studentId);
      dispatch(addteacher_recodedStudent_Sessions(data));
    };
    getData();
  }, [dispatch, studentId]);

  const deletehandler = async (classId) => {
    const responce = await deleteRecodedStudentCLassLinks(classId);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="BatchClassLink-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All session</h4>
          <Link to={"addSessions"} className="link">
            Add session
          </Link>
        </div>
      </div>
      <Link to={"/teachers/recodedStudent"} className="back">
        <i class="fa-solid fa-backward"></i>
      </Link>

      <TableContainer
        component={Paper}
        className="BatchClassLink-table-container"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI NO</TableCell>
              <TableCell>CLASS TITLE</TableCell>
              <TableCell>SUBJECT NAME</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>CLASS TIME</TableCell>
              <TableCell>CLASS LINK</TableCell>
              <TableCell>DELETE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teacher_recodedStudent_Sessions &&
              teacher_recodedStudent_Sessions?.map((val, key) => {
                const date = new Date(val.class_Date);
                const options = {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                };
                const formattedDate = date.toLocaleDateString("en-US", options);
                return (
                  <TableRow key={key}>
                    <TableCell>{key + 1}</TableCell>
                    <TableCell>{val.class_Title}</TableCell>
                    <TableCell>{val.class_subjectName}</TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell>{val.class_Time}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          window.open(val.class_Link, "_blank");
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        link
                      </Button>
                    </TableCell>
                    <TableCell>
                      <span
                        onClick={() => {
                          deletehandler(val.class_Id);
                        }}
                        className="delete_button"
                      >
                        <i class="fa-solid fa-trash-can"></i>
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RecodedStudentSessions;
