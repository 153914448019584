import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { addCourseTypesToStore } from "../../../Redux/Reduuces/CourceReducer";
import {
  active_courseTypeHandler,
  delete_courseType,
  getAllCourseType,
} from "../../../Redux/ActionCompo/CourseAction";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  TextField,
} from "@mui/material";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import "../../../Genaral.css";

const CourseTypes = () => {
  const [order, setOrder] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [courseTypeIdToUpdate, setCourseTypeIdToUpdate] = useState(null);
  const [updateType, setUpdateType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { courseId, branchId } = useParams();
  const dispatch = useDispatch();
  const courseTypes = useSelector((state) => state.courseData.courseTypes);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllCourseType(courseId);
      dispatch(addCourseTypesToStore(data[0]?.Course_type));
    };
    getData();
  }, [courseId, dispatch]);



  const handleUpdateRequest = async (id, type, STATUS) => {
    if (type === "ACTIVE_STATUS") {
      setUpdateType("ACTIVE_STATUS");
      setOpenDialog(true);
      if (STATUS) {
        setUpdateAlertMessage(
          "Are you sure you want to deactive the course type?"
        );
      } else {
        setUpdateAlertMessage(
          "Are you sure you want to active the course type?"
        );
      }
    } else if (type === "RECENT_PLACEMENT") {
      setUpdateType("RECENT_PLACEMENT");
      setOpenDialog(true);
      if (STATUS) {
        setUpdateAlertMessage(
          "Are you sure you want to remove   course type to the recent placement ?"
        );
      } else {
        setUpdateAlertMessage(
          "Are you sure you want to add course type to the recent placement ?"
        );
      }
    } else if (type === "FUTURED_COURSES") {
      setUpdateType("FUTURED_COURSES");
      setOpenDialog(true);
      if (STATUS) {
        setUpdateAlertMessage(
          "Are you sure you want to remove   course type to the  futured course ?"
        );
      } else {
        setUpdateAlertMessage(
          "Are you sure you want to add course type to the futured course  ?"
        );
      }
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to update order course type?"
      );
    } else if (type ===   "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to  delete course type ?");
    }
    setCourseTypeIdToUpdate(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    let payload = {};
    if (updateType === "ACTIVE_STATUS") {
      const payload = {
        active_courseType: "change",
      };
      const responce = await active_courseTypeHandler(
        courseTypeIdToUpdate,
        payload
      );
      if (responce?.status === true) {
        setSuccessMessage("course active  ststus updated successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "RECENT_PLACEMENT") {
      const payload = {
        Recomended_Status: "change",
      };
      const responce = await active_courseTypeHandler(
        courseTypeIdToUpdate,
        payload
      );
      if (responce?.status === true) {
        setSuccessMessage(
          "course recent placement status updated successfully"
        );
        setOpenSnackbar(true);
      }
    } else if (updateType === "FUTURED_COURSES") {
      const payload = {
        futuredCourse_Status: "change",
      };
      const responce = await active_courseTypeHandler(
        courseTypeIdToUpdate,
        payload
      );
      if (responce?.status === true) {
        setSuccessMessage(
          "course  futured courses status updated successfully"
        );
        setOpenSnackbar(true);
      }
    } else if (updateType === "ORDER") {
      payload = {
        order: Number(order),
      };

      const responce = await active_courseTypeHandler(
        courseTypeIdToUpdate,
        payload
      );
      if (responce?.status === true) {
        setSuccessMessage("course order update successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "DELETE") {
      const responce = await delete_courseType(courseTypeIdToUpdate);
      if (responce?.status === true) {
        setSuccessMessage("course delete successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getAllCourseType(courseId);
    dispatch(addCourseTypesToStore(data[0]?.Course_type));
  };

  return (
    <div className="CourseTypes-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Course Types</h4>
          <Link
            to={`/branch/${branchId}/courses/${courseId}/addCourseTypes`}
            className="link"
          >
            Add New Course Type
          </Link>
        </div>
      </div>
      <Link to={`/branch/${branchId}/courses`} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TextField
        variant="outlined"
        label="Enter Order"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
        type="number"
      ></TextField>

      <div className="courseType-table">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>COURSE TYPE ID</TableCell>
                <TableCell>COURSE TYPE</TableCell>
                <TableCell>ORDER</TableCell>
                <TableCell>ORDER UPDATE</TableCell>
                {/* <TableCell>COURSE LEVEL</TableCell> */}
                <TableCell>ACTIVE</TableCell>
                <TableCell>RECOMENDED STATUS</TableCell>
                <TableCell>FUTUDED STATUS</TableCell>
                <TableCell>Images</TableCell>
                <TableCell>videos</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {courseTypes &&
                courseTypes.map((val, key) => (
                  <TableRow key={key}>
                    <TableCell>{val.course_typeId}</TableCell>
                    <TableCell>
                      <NavLink
                        to={`${val.course_typeId}/details`}
                        className="nev_link"
                      >
                        {val.course_typeName}
                      </NavLink>
                    </TableCell>
                    <TableCell>{val.order}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          handleUpdateRequest(val.course_typeId, "ORDER")
                        }
                      >
                        update
                      </Button>
                    </TableCell>
                    {/* <TableCell>{val.course_level}</TableCell> */}
                    <TableCell>
                      <Button
                        onClick={() => {
                          handleUpdateRequest(
                            val.course_typeId,
                            "ACTIVE_STATUS",
                            val.active_courseType === "A"
                          );
                        }}
                        variant="contained"
                        size="small"
                        color={`${
                          val.active_courseType === "A" ? "success" : "error"
                        }`}
                      >
                        {val.active_courseType === "A" ? "Active" : "Deactive"}
                      </Button>
                    </TableCell>

                    <TableCell>
                      <Button
                        onClick={() => {
                          handleUpdateRequest(
                            val.course_typeId,
                            "RECENT_PLACEMENT",
                            val.Recomende_Status === "A"
                          );
                        }}
                        variant="contained"
                        size="small"
                        color={`${
                          val.Recomende_Status === "A" ? "success" : "error"
                        }`}
                      >
                        {val.Recomende_Status === "A" ? "Active" : "Deactive"}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          handleUpdateRequest(
                            val.course_typeId,
                            "FUTURED_COURSES",
                            val.Futured_status === "A"
                          );
                        }}
                        variant="contained"
                        size="small"
                        color={`${
                          val.Futured_status === "A" ? "success" : "error"
                        }`}
                      >
                        {val.Futured_status === "A" ? "Active" : "Deactive"}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <NavLink to={`${val.course_typeId}/images`}>
                        <Button size="small">view</Button>
                      </NavLink>
                    </TableCell>
                    <TableCell>
                      <NavLink to={`${val.course_typeId}/videos`}>
                        <Button size="small">view</Button>
                      </NavLink>
                    </TableCell>

                    <TableCell>
                      <span
                        className="delete_button"
                        onClick={() => {
                          handleUpdateRequest(val.course_typeId, "DELETE");
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle className="dialog-title">
            Confirm Status Update
          </DialogTitle>
          <DialogContent className="dialog-content">
            {updateAlertMessage}
          </DialogContent>
          <DialogActions className="dialog-actions">
            <Button
              variant="contained"
              onClick={handleDialogClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateStatus}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={5000} // Auto-hide after 5 seconds
          onClose={handleCloseSnackbar}
          message={successMessage}
        />
      </div>
    </div>
  );
};

export default CourseTypes;
