import React, { useEffect, useState } from "react";
import "./CourseTypeDetails.scss";
import { Link, useParams } from "react-router-dom";
import {
  addcourseTypesDetailsToStore,
  setcourseTypeDetailsCondition,
} from "../../../../Redux/Reduuces/CourceReducer";
import { useSelector, useDispatch } from "react-redux";
import {
  addCourseTypeDetailsConditions,
  deleteCourseTypeConditionHeading,
  getCourseTypeConditions,
  getCourseTypeDetails,
  updateCourseTypeDetailsConditions,
} from "../../../../Redux/ActionCompo/CourseAction";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";

import "../../../../Genaral.css";
import { Button, TextField } from "@mui/material";
const CourseTypeDetails = () => {
  const courseTypeDetailsCondition = useSelector(
    (state) => state.courseData.courseTypeDetailsCondition
  );
  const { branchId, courseId, courseTypeId } = useParams();

  const [entity, setEntity] = useState("");
  const [entityData, setEntityData] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const [objectiveHeading, setObjectiveHeading] = useState("");
  const [project_Heading, setproject_Heading] = useState("");
  const [toolsHeading, settoolsHeading] = useState("");
  const [faq_Heading, setfaq_Heading] = useState("");
  const [programHighlightsHeading, setprogramHighlightsHeading] = useState("");
  const [CereerServiceHeading, setCereerServiceHeading] = useState("");
  const [EmployeeRole, setEmployeeRole] = useState("");
  const [ProgramStructure1, setProgramStructure1] = useState("");
  const [ProgramStructure2, setProgramStructure2] = useState("");
  const [ToolsToMaster, setToolsToMaster] = useState("");
  const [certificate, setcertificate] = useState("");
  const [aluminiWorksAtImage, setaluminiWorksAtImage] = useState("");

  useEffect(() => {
    if (courseTypeDetailsCondition && courseTypeDetailsCondition.length > 0) {
      setObjectiveHeading(courseTypeDetailsCondition[0]?.objective_heading);
      setproject_Heading(courseTypeDetailsCondition[0]?.project_heading);
      settoolsHeading(courseTypeDetailsCondition[0]?.skills_To_Master_Heading);
      setfaq_Heading(courseTypeDetailsCondition[0]?.faq_heading);
      setprogramHighlightsHeading(
        courseTypeDetailsCondition[0]?.program_highlight_heading
      );
      setCereerServiceHeading(
        courseTypeDetailsCondition[0]?.career_service_heading
      );
      setEmployeeRole(courseTypeDetailsCondition[0]?.Emploee_heading);
      setProgramStructure1(
        courseTypeDetailsCondition[0]?.program_structure_1_heading
      );
      setProgramStructure2(
        courseTypeDetailsCondition[0]?.program_structure_2_heading
      );
      setToolsToMaster(courseTypeDetailsCondition[0]?.tools_to_master_heading);
      setcertificate(courseTypeDetailsCondition[0]?.certificate_heading);
      setaluminiWorksAtImage(
        courseTypeDetailsCondition[0]?.alumini_works_at_heading
      );
    }
  }, [courseTypeDetailsCondition]);

  const curenDetaile = useSelector(
    (state) => state.courseData.courseTypesDetails
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCourseTypeDetails(courseTypeId);
      dispatch(addcourseTypesDetailsToStore(data));

      const data1 = await getCourseTypeConditions(courseTypeId);
      console.log(data1);
      dispatch(setcourseTypeDetailsCondition(data1));
    };
    fetchData();
  }, [courseTypeId, dispatch]);

  const handleUpdateRequest = async (
    id,
    type,
    activeData,
    entityName,
    entityType,
    data
  ) => {
    if (type === "UPDATE_HEADING") {
      setUpdateType("UPDATE_HEADING");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `Are you sure you want to update  the ${entityName} to ${data} ?`
      );
      setEntity(entityType);
      setEntityData(data);
    } else if (type === "ACTIVE") {
      setUpdateType("ACTIVE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `Are you sure you want to ${
          activeData ? "deactive" : "active"
        } ${entityName} ?`
      );
      setEntity(entityType);
      setEntityData(data);
    } else if (type === "DELETE_HEADING") {
      setUpdateType("DELETE_HEADING");
      setOpenDialog(true);
      setUpdateAlertMessage(
        `Are you sure you want to update  the ${entityName} to NO?`
      );
      setEntity(entityType);
      setEntityData(data);
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);

    if (courseTypeDetailsCondition && courseTypeDetailsCondition.length > 0) {
      if (updateType === "ACTIVE") {
        const activeData = {
          [entity]: "change",
        };

        const responce = await updateCourseTypeDetailsConditions(
          UpdateId,
          activeData
        );
        if (responce?.status === true) {
          setSuccessMessage("status update  successfully");
          setOpenSnackbar(true);
        }
      } else if (updateType === "UPDATE_HEADING") {
        const updateData = {
          [entity]: entityData,
        };

        const responce = await updateCourseTypeDetailsConditions(
          UpdateId,
          updateData
        );
        if (responce?.status === true) {
          setSuccessMessage("details updaed successfully");
          setOpenSnackbar(true);
        }
      } else if (updateType === "DELETE_HEADING") {
        const addNew = {
          [entity]: "delete",
        };

        const responce = await deleteCourseTypeConditionHeading(
          UpdateId,
          addNew
        );
        if (responce?.status === true) {
          setSuccessMessage("details updaed successfully");
          setOpenSnackbar(true);
        }
      }
    } else {
      if (updateType === "ACTIVE") {
        const addNew = {
          [entity]: "A",
        };

        const responce = await addCourseTypeDetailsConditions(
          courseTypeId,
          addNew
        );
        if (responce?.status === true) {
          setSuccessMessage("status updaed successfully");
          setOpenSnackbar(true);
        } else if (updateType === "UPDATE_HEADING") {
          const addNew = {
            [entity]: entityData,
          };

          const responce = await addCourseTypeDetailsConditions(
            courseTypeId,
            addNew
          );
          if (responce?.status === true) {
            setSuccessMessage("details updaed successfully");
            setOpenSnackbar(true);
          }
        }
      }
    }

    const data1 = await getCourseTypeConditions(courseTypeId);
    dispatch(setcourseTypeDetailsCondition(data1));
  };

  return (
    <div className="CourseTypeDetails-main-container">
      <div className="pageLinkHeader-main ">
        <div className="pageLinkHeader-main-content">
          <h4>Course Details</h4>
          <Link to={"addDetails"} className="link">
            Add Details
          </Link>
        </div>
      </div>

      <Link
        to={`/branch/${branchId}/courses/${courseId}/courseTypes`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>

      <div className="details-container">
        <div className="course_Type_Details">
          <div className="course_TYPE-content">
            <h5>Actual Price</h5>
            <p>
              <i className="fa-solid fa-circle-dot"></i>
              {curenDetaile && curenDetaile[0]?.actual_Price}
            </p>
          </div>

          <div className="course_TYPE-content">
            <h5>Discount Price</h5>
            <p>
              <i className="fa-solid fa-circle-dot"></i>
              {curenDetaile && curenDetaile[0]?.discount_Price}
            </p>
          </div>
          <div className="course_TYPE-content">
            <h5>CourseType Heading</h5>
            <p>
              <i className="fa-solid fa-circle-dot"></i>
              {curenDetaile && curenDetaile[0]?.CourseType_Heading}
            </p>
          </div>
          <div className="course_TYPE-content">
            <h5>course Duriation</h5>
            <p>
              <i className="fa-solid fa-circle-dot"></i>
              {curenDetaile && curenDetaile[0]?.course_Duriation}
            </p>
          </div>
          <div className="course_TYPE-content">
            <h5>course learning formate</h5>
            <p>
              <i className="fa-solid fa-circle-dot"></i>
              {curenDetaile && curenDetaile[0]?.course_learning_formate}
            </p>
          </div>

          <div className="course_TYPE-content">
            <h5>Course Description</h5>
            <p>
              <i className="fa-solid fa-circle-dot"></i>
              {curenDetaile && curenDetaile[0]?.course_Desc}
            </p>
          </div>

          <div className="course_TYPE-content">
            <h5>Course Overview</h5>
            <p>
              <i className="fa-solid fa-circle-dot"></i>
              {curenDetaile && curenDetaile[0]?.course_Overview}
            </p>
          </div>

          <div className="course_TYPE-content">
            <h5>Course Duration Description</h5>
            <p>
              <i className="fa-solid fa-circle-dot"></i>
              {curenDetaile && curenDetaile[0]?.course_DuriationDesc}
            </p>
          </div>

          <ul>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/essentials`}
                className="link"
              >
                Essentials
              </Link>
            </li>

            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/models`}
                className="link"
              >
                Modules
              </Link>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/highlights`}
                className="link"
              >
                KeyHighlight
              </Link>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/objectievs`}
                className="link"
              >
                Objectives
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={objectiveHeading}
                    onChange={(e) => setObjectiveHeading(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "obective",
                        "objective_Heading",
                        objectiveHeading
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "obective",
                        "objective_Heading",
                        objectiveHeading
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_objective === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "obective",
                      "show_Objective",
                      objectiveHeading
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_objective === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/projects`}
                className="link"
              >
                Projects
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={project_Heading}
                    onChange={(e) => setproject_Heading(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "Projects",
                        "project_Heading",
                        project_Heading
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "Projects",
                        "project_Heading",
                        project_Heading
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_project === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "Projects",
                      "show_Project",
                      project_Heading
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_project === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/tools`}
                className="link"
              >
                Tools
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={toolsHeading}
                    onChange={(e) => settoolsHeading(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "Tools",
                        "skill_To_MasterHeading",
                        toolsHeading
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "Tools",
                        "skill_To_MasterHeading",
                        toolsHeading
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_skills_To_Master === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "obective",
                      "show_Skill_To_Master",
                      toolsHeading
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_skills_To_Master === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/faqs`}
                className="link"
              >
                FAQs
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={faq_Heading}
                    onChange={(e) => setfaq_Heading(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "FAQs",
                        "faq_Heading",
                        faq_Heading
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "FAQs",
                        "faq_Heading",
                        faq_Heading
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_faq === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "FAQs",
                      "show_Faq",
                      faq_Heading
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_faq === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/programHighlight`}
                className="link"
              >
                program highlights
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={programHighlightsHeading}
                    onChange={(e) =>
                      setprogramHighlightsHeading(e.target.value)
                    }
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "program highlights",
                        "program_Highlight_Heading",
                        programHighlightsHeading
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "program highlights",
                        "program_Highlight_Heading",
                        programHighlightsHeading
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_program_highlight ===
                      "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "program highlights",
                      "show_Program_Highlight",
                      programHighlightsHeading
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_program_highlight ===
                      "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/CereerService`}
                className="link"
              >
                Cereer Service
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={CereerServiceHeading}
                    onChange={(e) => setCereerServiceHeading(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "Cereer Service",
                        "career_Service_Heading",
                        CereerServiceHeading
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "Cereer Service",
                        "career_Service_Heading",
                        CereerServiceHeading
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_career_service === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "Cereer Service",
                      "show_Career_Service",
                      CereerServiceHeading
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_career_service === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/EmployeeRole`}
                className="link"
              >
                EmployeeRole
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={EmployeeRole}
                    onChange={(e) => setEmployeeRole(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "Employee Role",
                        "employee_RoleHeading",
                        EmployeeRole
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "Employee Role",
                        "employee_RoleHeading",
                        EmployeeRole
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_Employee_Role === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "Employee Role",
                      "show_EmployeeRole",
                      EmployeeRole
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_Employee_Role === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/ProgramStructure1`}
                className="link"
              >
                Program Structure 1
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={ProgramStructure1}
                    onChange={(e) => setProgramStructure1(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "Program Structure 1",
                        "program_Structure1_Heading",
                        ProgramStructure1
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "Program Structure 1",
                        "program_Structure1_Heading",
                        ProgramStructure1
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_program_structure1 ===
                      "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "Program Structure 1",
                      "show_Program_Structure1",
                      ProgramStructure1
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_program_structure1 ===
                      "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/ProgramStructure2`}
                className="link"
              >
                Program Structure 2
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={ProgramStructure2}
                    onChange={(e) => setProgramStructure2(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "Program Structure 2",
                        "Program_Structure2_Heading",
                        ProgramStructure2
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "Program Structure 2",
                        "Program_Structure2_Heading",
                        ProgramStructure2
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_program_structure_2 ===
                      "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "Program Structure 2",
                      "show_program_Structure2",
                      ProgramStructure2
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_program_structure_2 ===
                      "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/ToolsToMaster`}
                className="link"
              >
                Tools To Master
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={ToolsToMaster}
                    onChange={(e) => setToolsToMaster(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "Tools To Master",
                        "tools_ToMaster_Heading",
                        ToolsToMaster
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "Tools To Master",
                        "tools_ToMaster_Heading",
                        ToolsToMaster
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_tools_to_master === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "Tools To Master",
                      "showTools_To_Master",
                      ToolsToMaster
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_tools_to_master === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/certificate`}
                className="link"
              >
                certificate
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={certificate}
                    onChange={(e) => setcertificate(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "certificate",
                        "certificate_Heading",
                        certificate
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "certificate",
                        "certificate_Heading",
                        certificate
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_certificate === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "certificate",
                      "show_Certificate",
                      certificate
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_certificate === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/AluminiWorksAtImage`}
                className="link"
              >
                alumini works at image
              </Link>
              <div className="heading-condition-main-status">
                <div className="heading-condition-status">
                  <TextField
                    type="text"
                    variant="standard"
                    value={aluminiWorksAtImage}
                    onChange={(e) => setaluminiWorksAtImage(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "UPDATE_HEADING",
                        "A",
                        "alumini image",
                        "alimini_WorksAt_Heading",
                        aluminiWorksAtImage
                      )
                    }
                    variant="contained"
                  >
                    update
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleUpdateRequest(
                        courseTypeDetailsCondition &&
                          courseTypeDetailsCondition[0]?.condition_Id,
                        "DELETE_HEADING",
                        "A",
                        "alumini image",
                        "alimini_WorksAt_Heading",
                        aluminiWorksAtImage
                      )
                    }
                  >
                    delete
                  </Button>
                </div>
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_alumini_works_at === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "alumini image",
                      "show_Alumini_WorksAt",
                      aluminiWorksAtImage
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_alumini_works_at === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
            <li>
              <Link
                to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/AluminiWorksAtImage`}
                className="link"
              >
                NO COST EMI
              </Link>
              <div className="heading-condition-main-status">
                <Button
                  color={`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_no_cost_emi === "A"
                      ? "success"
                      : "error"
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleUpdateRequest(
                      courseTypeDetailsCondition &&
                        courseTypeDetailsCondition[0]?.condition_Id,
                      "ACTIVE",
                      "A",
                      "show_no_cost_emi",
                      "show_NoCost_Emi",
                      objectiveHeading
                    )
                  }
                >
                  {`${
                    courseTypeDetailsCondition &&
                    courseTypeDetailsCondition[0]?.show_no_cost_emi === "A"
                      ? "ACTIVE"
                      : "DEACTIVE"
                  }`}
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default CourseTypeDetails;
