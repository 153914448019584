import React from "react";
import "./Home.scss";
import VideoUploadComponent from "./FileUploadComponent";

// const S3_BUCKET = "juzzitcloudfrantvideo";
// const REGION = "ap-southeast-2"; // e.g., 'us-east-1'
// const ACCESS_KEY = "AKIATLNZ64XGHATX7QEA";
// const SECRET_ACCESS_KEY = "2xTHxkYj29dzSIPfzbJQCS/Q+3aR16HYcRZygksE";

// import FileUploadForm from "./FileUploadForm";
const Home = () => {
  return (
    <div className="home-main-container">
      {/* Well Come To My Admin */}
      <VideoUploadComponent />
      {/* <FileUploadForm /> */}
    </div>
  );
};

export default Home;
