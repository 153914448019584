import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import "./ModulesLesson.scss";
import { setEnrolledModouleLessons } from "../../../../../Redux/Reduuces/StudentDetailsReducer";
import ListOfAllClassVideos from "./components/ListOfAllClassVideos";
import { getLessonTopicVides } from "../../../../../Redux/ActionCompo/StudentDetailActions";
import Loading from "../../../../../LoadingComponent/Loading";
const ModulesLesson = () => {
  const { studentBatchId, moduleId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const enrolledModouleLessons = useSelector(
    (state) => state.studentDetailsData.enrolledModouleLessons
  );
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getLessonTopicVides(studentBatchId, moduleId);
      dispatch(setEnrolledModouleLessons(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, studentBatchId, moduleId]);

  if (isLoading) {
    <Loading />;
  }
  return (
    <div className="ModulesLesson-main-container">
      <div className="AllClassVideos-container">
        <ListOfAllClassVideos videoData={enrolledModouleLessons} />
      </div>
    </div>
  );
};

export default ModulesLesson;
