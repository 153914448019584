import React, { useEffect, useState } from "react";
import { Snackbar, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addRecentPlacementCertificateToStore } from "../../../../../Redux/Reduuces/RecentPlacementsReducers";
import {
  addRecentPlacementCertificate,
  getRecentPlacementCertificate,
} from "../../../../../Redux/ActionCompo/RecentPlacementsAction";
import SingleRecentPlacementCertificate from "./SingleRecentPlacementCertificate";
import { uploadImage } from "../../../../../Redux/ActionCompo/CloudenarowImageUplode";

const RecentPlacementCertificate = () => {
  const [file, setFile] = useState(null);
  const { recentplacementId } = useParams();
  const [certificateName, setCertificateName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const RecentPlacementCertificate = useSelector(
    (state) => state.recentPlacementData.RecentPlacementCertificate
  );

  const dispatch = useDispatch();

  const handleAddImage = async (event) => {
    event.preventDefault();
    const data = await uploadImage(file);

    if (data) {
      const newImageData = {
        certificate_URL: data?.secure_url,
        certificate_Name: certificateName,
      };
      const response = await addRecentPlacementCertificate(
        recentplacementId,
        newImageData
      );
      if (response?.status === true) {
        setFile(null);
        setSuccessMessage("certificate added successfully");
        setOpenSnackbar(true);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getRecentPlacementCertificate(recentplacementId);
      dispatch(addRecentPlacementCertificateToStore(data));
    };
    getData();
  }, [dispatch, recentplacementId]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="IMAGE-FORM-MAIN-CONTAINER">
        <div className="IMAGE-FORM-CONTAINER">
          <form className="form" onSubmit={handleAddImage}>
            <TextField
              type="text"
              value={certificateName}
              onChange={(e) => setCertificateName(e.target.value)}
            ></TextField>
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            ></input>
            <button type="submit">addfile</button>
          </form>
        </div>
      </div>
      <div className="ALL-IMAGE-MAIN-CONTAINER">
        <div className="ALL-IMAGE-CONTAINER-heading">
          <h1>All Images</h1>
        </div>
        <div className="ALL-IMAGE-CONTAINER">
          {RecentPlacementCertificate &&
            RecentPlacementCertificate?.map((val, key) => (
              <SingleRecentPlacementCertificate ToolImage={val} key={key} />
            ))}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default RecentPlacementCertificate;
