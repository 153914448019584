import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { Button, Input, Snackbar } from "@mui/material";
import {
  addCourseTypeAluminiWorksAtImage,
  getCoursePositionTypeAluminiWorksAtImage,
} from "../../../../../../Redux/ActionCompo/CourseAction";
import { setaluminiWorksAtImage } from "../../../../../../Redux/Reduuces/CourceReducer";
import SingleAluminiWorksAtImage from "./SingleAluminiWorksAtImage";
import { uploadImage } from "../../../../../../Redux/ActionCompo/CloudenarowImageUplode";
const AluminiWorksAtImage = () => {
  const [file, setFile] = useState(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const aluminiWorksAtImage = useSelector(
    (state) => state.courseData.aluminiWorksAtImage
  );
  const { courseTypeId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getCoursePositionTypeAluminiWorksAtImage(courseTypeId);
      dispatch(setaluminiWorksAtImage(data));
    };
    getData();
  }, [courseTypeId, dispatch]);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid JPEG or PNG image.");
      }
    }
  };
  const handleAddImage = async () => {
    const data = await uploadImage(file);

    if (data) {
      const newImageData = {
        image_URL: data?.secure_url,
      };
      const responce = await addCourseTypeAluminiWorksAtImage(
        courseTypeId,
        newImageData
      );
      if (responce?.status === true) {
        setSuccessMessage("images updated successfully");
        setOpenSnackbar(true);
        const data = await getCoursePositionTypeAluminiWorksAtImage(
          courseTypeId
        );
        dispatch(setaluminiWorksAtImage(data));
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div className="CourseImage-main-container">
      <NavLink to={`/courses`}>back</NavLink>
      <div className="">
        <div className="IMAGE-FORM-MAIN-CONTAINER">
          <div className="IMAGE-FORM-CONTAINER">
            <form>
              <Input
                type="file"
                accept="image/jpeg, image/png"
                onChange={handleImageChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddImage}
              >
                Add Image
              </Button>
            </form>
          </div>
        </div>
        <div className="ALL-IMAGE-MAIN-CONTAINER">
          <div className="ALL-IMAGE-CONTAINER-heading">
            <h1>All Images</h1>
          </div>
          <div className="ALL-IMAGE-CONTAINER">
            {aluminiWorksAtImage &&
              aluminiWorksAtImage.map((val, key) => (
                <SingleAluminiWorksAtImage courseTypeImage={val} key={key} />
              ))}
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openSnackbar}
            autoHideDuration={5000} // Auto-hide after 5 seconds
            onClose={handleCloseSnackbar}
            message={successMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default AluminiWorksAtImage;
