import React, { useState } from "react";
import Loading from "../../LoadingComponent/Loading";
import "./TrainerLogInWithOtp.scss";
import { Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { trainerExistById } from "../../Redux/ActionCompo/TeacherActions";
import {
  serntSignUpOtpRequest,
  validateAdminDataWithOtp,
} from "../../Redux/ActionCompo/AuthActions";
import { settrainerOtpRequestData } from "../../Redux/Reduuces/AuthReducer";
import { useNavigate } from "react-router-dom";
const TrainerLogInWithOtp = () => {
  const [email, setEmail] = useState("");
  const [signUpNotification, setsignUpNotification] = useState(false);
  const [error, setError] = useState(null);
  const [OTPerror, setOtpError] = useState(null);
  const [isLoading, setIsLoadding] = useState(false);
  const [otpVarification, setotpVarification] = useState(false);
  const [otp, setOtp] = useState("");

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const dispatch = useDispatch();

  const trainerOtpRequestData = useSelector(
    (state) => state.AuthData.trainerOtpRequestData
  );

  const onSubmitHandler = async () => {
    if (!isEmailValid(email)) {
      setError("Invalid email address.");
      return;
    }
    const responce1 = await trainerExistById(email);
    if (responce1?.status === true) {
      setIsLoadding(false);
      setsignUpNotification(true);
    }
    setIsLoadding(false);
    setError("Account with the email not found");
  };

  const HandleSendOTPrEQUEST = async () => {
    setIsLoadding(true);
    setsignUpNotification(false);

    const requestData = {
      EMAIL: email,
    };

    const responce = await serntSignUpOtpRequest(requestData);

    if (responce?.status === true) {
      setIsLoadding(false);
      dispatch(settrainerOtpRequestData({}));
      dispatch(settrainerOtpRequestData(responce));
      setotpVarification(true);
    } else {
      setIsLoadding(false);
    }
  };

  const navigate = useNavigate();
  const handlleOTPvarification = async () => {
    setIsLoadding(true);

    const logInData = {
      email: trainerOtpRequestData?.requestEmail,
      otp: otp,
    };

    const response = await validateAdminDataWithOtp(
      trainerOtpRequestData?.requestId,
      logInData
    );
    console.log(response);

    if (response?.successStatus === true) {
      localStorage.setItem("userData", JSON.stringify(response));
      setIsLoadding(false);
      navigate("/");
      window.location.reload();
    } else {
      setOtpError("Enter correct OTP");
    }
    setIsLoadding(false);
    setEmail("");
    setOtp("");
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="TrainerLogInWithOtp-main-container">
      <div
        className={`LoginWithOTp-otp-main-container ${
          otpVarification ? "showOtpForm" : ""
        }`}
      >
        <div className="LoginWithOTp-otp-container">
          <span>{OTPerror}</span>
          <h1>Enter OTP</h1>
          <input
            type="number"
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
          ></input>
          <Button onClick={handlleOTPvarification} variant="outlined">
            varify
          </Button>
          <Button onClick={HandleSendOTPrEQUEST} variant="outlined">
            Resend OTP
          </Button>
          <Button onClick={() => setotpVarification(false)} variant="outlined">
            cancel
          </Button>
        </div>
      </div>

      <div
        className={`LoginWithOTp-conformation-pop-up-container  ${
          signUpNotification ? "showNotification" : ""
        }`}
      >
        <div className="LoginWithOTp-conformation-pop-up">
          <h1>
            For the varification we send the mail to email <span>{email}</span>{" "}
          </h1>
          <div className="LoginWithOTp-conformation-pop-up-button">
            <Button
              variant="outlined"
              onClick={() => setsignUpNotification(false)}
            >
              cancel
            </Button>
            <Button variant="outlined" onClick={HandleSendOTPrEQUEST}>
              send
            </Button>
          </div>
        </div>
      </div>

      <div className="LoginWithOTp-container">
        <span>{error}</span>
        <h1>Enter Email Address </h1>
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input"
        />
        <Button variant="contained" onClick={onSubmitHandler}>
          next
        </Button>
      </div>
    </div>
  );
};

export default TrainerLogInWithOtp;
