import React, { useState, useEffect } from "react";
import { addCourseCourseTypes } from "../../../../Redux/Reduuces/CourceReducer";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAllCourseCourseType } from "../../../../Redux/ActionCompo/CourseAction";
import { addNewBatchCourse } from "../../../../Redux/ActionCompo/BatchActions";
import {
  TextField,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
} from "@mui/material";
import "../../../../Genaral.css";
const AddBatchCourse = () => {
  const { batchId, subBatchId } = useParams();

  const [batchCourseName, setBatchCourseName] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseTypeId, setCourseTypeId] = useState("");
  const [courseType, setCourseType] = useState([]);
  const [error, setError] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const courseCourseTypes = useSelector(
    (state) => state.courseData.courseCourseType
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const filtedCourse = courseCourseTypes?.filter((course) => {
      return course.course_name === courseName;
    });

    setCourseType(filtedCourse[0]?.courseType);
  }, [courseName, courseCourseTypes]);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllCourseCourseType();
      dispatch(addCourseCourseTypes(data));
    };
    getData();
  }, [dispatch]);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    // Check for empty inputs
    if (!batchCourseName.trim() || !courseName || !courseTypeId) {
      setError("All fields are required");
      setIsEmpty(true);
      return;
    }

    const newBatchCourse = {
      courseName: courseName,
      batch_CourseName: batchCourseName.trim(),
    };

    const responce = await addNewBatchCourse(
      subBatchId,
      courseTypeId,
      newBatchCourse
    );
    if (responce?.status === true) {
      setBatchCourseName("");
      setCourseType("");
      setCourseTypeId("");
      setCourseName("");
      setSuccessMessage("course to the batch added successfully");
      setOpenSnackbar(true);
      setError("");
      setIsEmpty(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container className="AddBatchCourse-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses`}
            className="link"
          >
            All Batch Courses
          </Link>
          <h4 variant="h4">Add New Batch Course</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                type="text"
                label="Enter Batch Course Name"
                variant="outlined"
                fullWidth
                value={batchCourseName}
                onChange={(e) => setBatchCourseName(e.target.value)}
                error={isEmpty}
                helperText={error}
              />
            </div>

            <div className="form-element">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Select Course Name</InputLabel>
                <Select
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                  label="Select Course Name"
                  error={isEmpty}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {courseCourseTypes &&
                    courseCourseTypes?.map((course) => (
                      <MenuItem
                        value={course.course_name}
                        key={course.courseId}
                      >
                        {course.course_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div className="form-element">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Select Course Type</InputLabel>
                <Select
                  value={courseTypeId}
                  onChange={(e) => setCourseTypeId(e.target.value)}
                  label="Select Course Type"
                  error={isEmpty}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {courseType &&
                    courseType?.map((courseType) => (
                      <MenuItem
                        value={courseType.course_typeId}
                        key={courseType.course_typeId}
                      >
                        {courseType.course_typeName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </Container>
  );
};

export default AddBatchCourse;
