import React from "react";
import "./singleRecodedVideo.scss";
import { Button } from "@mui/material";
import {
  deleteRecodedSubjectVideo,
  updatedRecodedSubjectVideo,
} from "../../../../../../../../Redux/ActionCompo/BatchActions";
import ReactPlayer from "react-player";

import "../../../../../../../../Genaral.css";
const SingleRecodedVideo = (props) => {
  const videoData = props.RecodedvideoData;
  const updateRecordedVideoData = async (videoId) => {
    const data = {
      active_Status: "change",
    };
    const responce = await updatedRecodedSubjectVideo(videoId, data);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const deleteRecodedVideoDatadata = async (videoId) => {
    const responce = await deleteRecodedSubjectVideo(videoId);

    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="video-main-components">
      <div className="video-main-components-image-content">
        <ReactPlayer
          url={videoData?.videoURL}
          width={"100%"}
          height={"100%"}
          controls={false}
        ></ReactPlayer>
      </div>

      <div className="video-main-components-content">
        <h3>{videoData?.vedioTopic}</h3>
        <p>{videoData?.videoDesc}</p>
        <p>{videoData?.createdDate}</p>
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={videoData?.activeStatus === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => {
            updateRecordedVideoData(videoData?.recordedVideoId);
          }}
        >
          {videoData?.activeStatus === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteRecodedVideoDatadata(videoData?.recordedVideoId);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default SingleRecodedVideo;
