import React from "react";
import "./TeacherHome.scss";
import { Link } from "react-router-dom";
import TeacherProfile from "./Components/TeacherProfileComponent/TeacherProfile";
const TeacherHome = () => {
  return (
    <div className="TeacherHome-main-container">
      <div className="Teacher-nav-container">
        <ul>
          <li className="link">
            <h3>Profile</h3>
          </li>
          <li>
            <Link to={"/teachers/batchs"} className="link">
              Batchs
            </Link>
          </li>
          <li>
            <Link to={"/teachers/WorkShopBatchs"} className="link">
              WorkShop Batchs
            </Link>
          </li>

          <li>
            <Link to={"/teachers/recodedStudent"} className="link">
              Recoded Students
            </Link>
          </li>
        </ul>
      </div>

      <TeacherProfile />
    </div>
  );
};

export default TeacherHome;
