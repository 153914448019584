import React, { useEffect } from "react";
import "./EnrollRequests.scss";
import { useSelector, useDispatch } from "react-redux";
import { addEnrollRequests } from "../../Redux/Reduuces/EnrollRequestReducer";
import { Link, NavLink } from "react-router-dom";
import {
  deleteRquests,
  getAllEnrollRequest,
} from "../../Redux/ActionCompo/EnrollRequestActions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const EnrollRequests = () => {
  const enrollRequests = useSelector((state) => state.requests.enrollRequests);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllEnrollRequest();
      console.log(data);
      dispatch(addEnrollRequests(data));
    };
    getData();
  }, [dispatch]);

  const deleteHandler = async (requestId) => {
    const response = await deleteRquests(requestId);
    if (response?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="EnrollRequests-main-container">
      <div className="EnrollRequests-table">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>REQUEST ID</TableCell>
                <TableCell>STUDENT NAME</TableCell>
                <TableCell>COURSE NAME</TableCell>
                <TableCell>COURSE TYPE NAME</TableCell>
                <TableCell>ENROLL TYPE</TableCell>
                <TableCell>DETAILS</TableCell>
                <TableCell>DELETE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enrollRequests?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.request_Id}</TableCell>
                  <TableCell>{val.student_Name}</TableCell>
                  <TableCell>{val.course_Name}</TableCell>
                  <TableCell>{val.course_TypeName}</TableCell>
                  <TableCell>{val.enroll_Type}</TableCell>
                  <TableCell>
                    <NavLink
                      to={`${val.request_Id}/details`}
                      className="nev_link"
                    >
                      Details
                    </NavLink>
                  </TableCell>
                  <TableCell>
                    <span
                      onClick={() => {
                        deleteHandler(val.request_Id);
                      }}
                      className="delete_button"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Link to={"1234/details"}>details</Link>
    </div>
  );
};

export default EnrollRequests;
