import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Snackbar, TextField } from "@mui/material";
import { addCourseTypeCereerServiceDetails } from "../../../../../../../Redux/ActionCompo/CourseAction";

const AddCereerServiceDetails = () => {
  const { branchId, courseId, courseTypeId, CereerServiceId } = useParams();

  const [essentials_Type, setEssential_Type] = useState("");
  const [essentials_Desc, setAssential_Desc] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorDesc, setErrorDesc] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    // Empty input validation
    if (essentials_Type.trim() === "") {
      setErrorType("Essential Type is required");
      return;
    }

    if (essentials_Desc.trim() === "") {
      setErrorDesc("Essential Description is required");
      return;
    }

    const newEssential = {
      details_heading: essentials_Type,
      details_desc: essentials_Desc,
    };
    const response = await addCourseTypeCereerServiceDetails(
      CereerServiceId,
      newEssential
    );
    if (response?.status === true) {
      setAssential_Desc("");
      setEssential_Type("");
      setSuccessMessage("career details added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddEssentials-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/CereerService/${CereerServiceId}/details`}
            className="link"
          >
            All Essentials
          </Link>
          <h4>Add New Essential </h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                label="Enter Essential Type"
                value={essentials_Type}
                onChange={(e) => setEssential_Type(e.target.value)}
                error={!!errorType}
                helperText={errorType}
                fullWidth
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter Essential Desc"
                value={essentials_Desc}
                onChange={(e) => setAssential_Desc(e.target.value)}
                error={!!errorDesc}
                helperText={errorDesc}
                fullWidth
                multiline
                rows={4}
              />
            </div>

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openSnackbar}
              autoHideDuration={5000} // Auto-hide after 5 seconds
              onClose={handleCloseSnackbar}
              message={successMessage}
            />

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCereerServiceDetails;
