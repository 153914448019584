import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enrollRequests: [],
  activeBatchCourses: [],
  requestDetails: [],
  batchDetails: [],
};

const EnrollRequestReducer = createSlice({
  name: "enrollRequest",
  initialState,
  reducers: {
    addEnrollRequests: (state, action) => {
      state.enrollRequests = action.payload;
      console.log(state.enrollRequests);
    },

    addActiveBatchCourses: (state, action) => {
      state.activeBatchCourses = action.payload;
    },

    addrequestDetails: (state, action) => {
      state.requestDetails = action.payload;
    },
    addbatchDetails: (state, action) => {
      state.batchDetails = action.payload;
    },
  },
});

export const {
  addEnrollRequests,
  addActiveBatchCourses,
  addrequestDetails,
  addbatchDetails,
} = EnrollRequestReducer.actions;

export default EnrollRequestReducer.reducer;
