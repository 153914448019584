import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { addNewPlacement } from "../../../../../../Redux/ActionCompo/BatchActions";
import { Button, Container, Snackbar, TextField } from "@mui/material";
import "../../../../../../Genaral.css";
const AddPlacements = () => {
  const [companyName, setCompanyName] = useState("");
  const [companyIntroduction, setCompanyIntroduction] = useState("");
  const [applyLink, setApplyLink] = useState("");
  const { batchId, subBatchId, batchCourseId } = useParams();
  const [companyNameError, setCompanyNameError] = useState("");
  const [companyIntroductionError, setCompanyIntroductionError] = useState("");
  const [applyLinkError, setApplyLinkError] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onsubmitHandler = async (event) => {
    event.preventDefault();

    // Check for empty inputs
    if (!companyName) {
      setCompanyNameError("Company name is required");
    } else {
      setCompanyNameError("");
    }

    if (!companyIntroduction) {
      setCompanyIntroductionError("Company introduction is required");
    } else {
      setCompanyIntroductionError("");
    }

    if (!applyLink) {
      setApplyLinkError("Apply link is required");
    } else {
      setApplyLinkError("");
    }

    if (companyName && companyIntroduction && applyLink) {
      const newPlacement = {
        company_name: companyName,
        company_Intruduction: companyIntroduction,
        apply_link: applyLink,
      };

      const response = await addNewPlacement(batchCourseId, newPlacement);
      if (response?.status === true) {
        setCompanyName("");
        setApplyLink("");
        setCompanyIntroduction("");
        setSuccessMessage("batch for this month created successfully");
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container className="AddPlacements-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/placements`}
            className="link"
          >
            All placements
          </Link>
          <h4 variant="h4">Add New Placement</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmitHandler}>
            <div className="form-element">
              <TextField
                fullWidth
                label="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                error={!!companyNameError}
                helperText={companyNameError}
              />
            </div>
            <div className="form-element">
              <TextField
                fullWidth
                label="Company Introduction"
                multiline
                minRows={4}
                value={companyIntroduction}
                onChange={(e) => setCompanyIntroduction(e.target.value)}
                error={!!companyIntroductionError}
                helperText={companyIntroductionError}
              />
            </div>
            <div className="form-element">
              <TextField
                fullWidth
                label="Apply Link"
                value={applyLink}
                onChange={(e) => setApplyLink(e.target.value)}
                error={!!applyLinkError}
                helperText={applyLinkError}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </Container>
  );
};

export default AddPlacements;
