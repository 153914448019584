import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./AddCourse.scss";
import {
  addNewCourse,
  getNewBranchCategory,
} from "../../Redux/ActionCompo/CourseAction";
import { Button, Snackbar } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import "../../Genaral.css";
import { setbranchCategorys } from "../../Redux/Reduuces/CourceReducer";
import { useDispatch, useSelector } from "react-redux";
const AddCourse = () => {
  const [courseName, setCourseName] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { branchId } = useParams();

  const branchCategorys = useSelector(
    (state) => state.courseData.branchCategorys
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getNewBranchCategory();
      dispatch(setbranchCategorys(data));
    };
    getData();
  }, [dispatch]);

  const onSubmiteHandler = async (event) => {
    event.preventDefault();
    if (!courseName.trim()) {
      setError("Course name is required");
    } else {
      setError("");
      const newCourse = {
        course_name: courseName.trim(),
      };
      const response = await addNewCourse(branchId, newCourse);
      if (response?.status === true) {
        setCourseName("");
        setSuccessMessage("Course added successfully");
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddCourse-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={`/branch/${branchId}/courses`} className="link">
            All courses
          </Link>
          <h4>Add New Course</h4>
        </div>
      </div>
      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onSubmiteHandler}>
            <span>{error}</span>
            <div className="form-element">
              <FormControl fullWidth variant="outlined">
                <InputLabel>select course</InputLabel>
                <Select
                  label="Select Course Type"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {branchCategorys &&
                    branchCategorys?.map((category) => (
                      <MenuItem
                        key={category.branch_category_Id}
                        value={category.branch_category}
                      >
                        {category.branch_category}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* 

                <TextField
                  fullWidth
                  variant="outlined"
                  label="Course Name"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                  error={!!error}
                  helperText={error}
                /> */}
            </div>
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
          {/* Display the success message in a Snackbar */}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openSnackbar}
            autoHideDuration={5000} // Auto-hide after 5 seconds
            onClose={handleCloseSnackbar}
            message={successMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCourse;
