import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";

export const trainerExistById = async (email) => {
  try {
    const responce = await myaxios.get(`/api/trainer/${email}/exists`);
    return responce.data;
  } catch (error) {}
};

export const getAllBatchCourseOfTeacher = async (teacherId) => {
  try {
    const responce = await myaxios.get(
      `/tch/teachers/${teacherId}/BatchDetails`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getTeachetData = async (teacherId) => {
  try {
    const responce = await myaxios.get(`/tch/teachers/${teacherId}/details`, {
      headers: authHeader(),
    });

    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const addClassLinks = async (
  batchCourseId,
  batchCourseSubjectId,
  payload
) => {
  try {
    const responce = await myaxios.post(
      `/bch/batchCourses/${batchCourseId}/classLinks/${batchCourseSubjectId}`,
      payload,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllClassLinks = async (batchCourseId, batchCourseSubjectId) => {
  try {
    const responce = await myaxios.get(
      `/bch/batchCourses/${batchCourseId}/classLinks/${batchCourseSubjectId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getAllBatchDetail = async (batchCourseId) => {
  try {
    const responce = await myaxios.get(
      `/bch/trainer/batchCourses/${batchCourseId}/batchDetail`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteClassLink = async (classId) => {
  try {
    const responce = await myaxios.delete(`/bch/classLinks/${classId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

////  workShop Batch

export const getAllWorkShopBatches = async (teacherId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/teacher/${teacherId}/workShopBatchs`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getWorkShopWeekStructueDetails = async (batchId) => {
  try {
    const responce = await myaxios.get(
      `/wrks/workShops/workshopBatch/${batchId}/weekStructuresDetails`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

//// workShop Batch classLinks

export const addWorkShopBatchClassLink = async (
  batchId,
  batchSubjectId,
  classLinkData
) => {
  try {
    const responce = await myaxios.post(
      `/wsbch/workShopBatchs/${batchId}/subjects/${batchSubjectId}/classLinks`,
      classLinkData,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteWorkShopBatchClasslinks = async (classlinkId) => {
  try {
    const responce = await myaxios.delete(
      `/wsbch/workshopClassLinks/${classlinkId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllWorkShopBatchClassLinks = async (batchSubjectId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/workShopBatchSubjects/${batchSubjectId}/classLinks`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

///// rcoded student batchs

export const getAllRecodedStudentBatchs = async (trainerId) => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/trainer/${trainerId}/students`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//// recoded Student placements

export const addRecodedStudentPlacements = async (
  recodedStudentBatchId,
  placementData
) => {
  try {
    const responce = await myaxios.post(
      `/recoded/bch/recodedStudent/${recodedStudentBatchId}/placements`,
      placementData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteRecodedStudentPlacements = async (placementId) => {
  try {
    const responce = await myaxios.delete(
      `/recoded/bch/recodedStudent/placements/${placementId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateRecodedStudentPlacements = async (
  placementId,
  placementData
) => {
  try {
    const responce = await myaxios.put(
      `/recoded/bch/recodedStudent/placements/${placementId}`,
      placementData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getRecodedStudentPlacements = async (recodedStudentBatchId) => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/recodedStudent/${recodedStudentBatchId}/placements`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

///// recodedStudentSessions

export const addRecodedStudentCLassLinks = async (
  recodedStudentBatchId,
  classLinkData
) => {
  try {
    const responce = await myaxios.post(
      `/recoded/bch/recodedStudent/${recodedStudentBatchId}/classLinks`,
      classLinkData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteRecodedStudentCLassLinks = async (classLinkId) => {
  try {
    const responce = await myaxios.delete(
      `/recoded/bch/recodedStudent/classLinks/${classLinkId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getRecodedStudentCLassLinks = async (recodedStudentBatchId) => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/recodedStudent/${recodedStudentBatchId}/classLinks`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/// redcoded student placement Images

export const addRecodedStudentPlacementImage = async (
  placementId,
  imageData
) => {
  try {
    const responce = await myaxios.post(
      `/recoded/bch/recodedStudent/placements/${placementId}/images`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteRecodedStudentPlacementsImages = async (imageId) => {
  try {
    const responce = await myaxios.delete(
      `/recoded/bch/recodedStudent/placements/images/${imageId}`,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateRecoredStudentPlacementsImageData = async (
  imageId,
  imageData
) => {
  try {
    const responce = await myaxios.put(
      `/recoded/bch/recodedStudent/placements/images/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllRecodedStudentPlacementImages = async (placementId) => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/recodedStudent/placements/${placementId}/images`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
