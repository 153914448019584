import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";

import {
  deleteCourseTypeEmployeeRole,
  getCourseTypeEmployeeRole,
  updateCourseTypeEmployeeRole,
} from "../../../../../../Redux/ActionCompo/CourseAction";
import { setcourseEmployeeRole } from "../../../../../../Redux/Reduuces/CourceReducer";
const EmployeeRole = () => {
  const { branchId, courseId, courseTypeId } = useParams();

  const [order, setOrder] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const dispatch = useDispatch();
  const courseEmployeeRole = useSelector(
    (state) => state.courseData.courseEmployeeRole
  );
  useEffect(() => {
    const getadata = async () => {
      const data = await getCourseTypeEmployeeRole(courseTypeId);
      dispatch(setcourseEmployeeRole(data));
    };
    getadata();
  }, [courseTypeId, dispatch]);



  const handleUpdateRequest = async (id, type) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the employee role  ?"
      );
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to update order  of employee role  ?"
      );
    }
    setUpdateId(id);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteCourseTypeEmployeeRole(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage("employee role  delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ORDER") {
      const payload = {
        order: Number(order),
      };

      const responce = await updateCourseTypeEmployeeRole(UpdateId, payload);
      if (responce?.status === true) {
        setSuccessMessage("employee role order update successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getCourseTypeEmployeeRole(courseTypeId);
    dispatch(setcourseEmployeeRole(data));
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="FAQ-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Employee Role</h4>
          <Link to={"add"} className="link">
            Add New Employee Role
          </Link>
        </div>
      </div>

      <Link
        to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/details`}
        className="back"
      >
        <i class="fa-solid fa-backward"></i>
      </Link>

      <TextField
        variant="outlined"
        type="number"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>HEADING</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell>ORDER</TableCell>
              <TableCell>ORDER UPDATE</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courseEmployeeRole &&
              courseEmployeeRole?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.ROLE_HEADING}</TableCell>
                  <TableCell>{val.ROLE_DESC}</TableCell>
                  <TableCell>{val.ROLE_ORDER}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleUpdateRequest(val.ROLE_ID, "ORDER")}
                    >
                      update
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        handleUpdateRequest(val.ROLE_ID, "DELETE");
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default EmployeeRole;
