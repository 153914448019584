import React, { useEffect, useState } from "react";
import "./Courses.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  activeStatusChange,
  deleteCourse,
  getAllCoursesByBranch,
} from "../../Redux/ActionCompo/CourseAction";
import { Link, NavLink, useParams } from "react-router-dom";
import { addCoursesToStore } from "../../Redux/Reduuces/CourceReducer";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Snackbar,
} from "@mui/material";

import "../../Genaral.css";

const Courses = () => {
  const [order, setOrder] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [courseIdToUpdate, setCourseIdToUpdate] = useState(null);
  const [updateType, setUpdateType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const courses = useSelector((state) => state.courseData.courses);
  const dispatch = useDispatch();

  const { branchId } = useParams();

  useEffect(() => {
    const updateData = async () => {
      const data = await getAllCoursesByBranch(branchId);
      dispatch(addCoursesToStore(data));
    };
    updateData();
  }, [dispatch, branchId]);

  const handleUpdateRequest = async (id, type, STATUS) => {
    if (type === "ACTIVE_STATUS") {
      setUpdateType("ACTIVE_STATUS");
      setOpenDialog(true);
      if (STATUS) {
        setUpdateAlertMessage("Are you sure you want to deactive the course ?");
      } else {
        setUpdateAlertMessage("Are you sure you want to active the course ?");
      }
    } else if (type === "RECENT_PLACEMENT") {
      setUpdateType("RECENT_PLACEMENT");
      setOpenDialog(true);
      if (STATUS) {
        setUpdateAlertMessage(
          "Are you sure you want to remove   course to the recent placement ?"
        );
      } else {
        setUpdateAlertMessage(
          "Are you sure you want to add course to the recent placement ?"
        );
      }
    } else if (type === "FUTURED_COURSES") {
      setUpdateType("FUTURED_COURSES");
      setOpenDialog(true);
      if (STATUS) {
        setUpdateAlertMessage(
          "Are you sure you want to remove   course to the  futured course ?"
        );
      } else {
        setUpdateAlertMessage(
          "Are you sure you want to add branch to the futured course  ?"
        );
      }
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to update order course ?");
    } else if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to  delete course ?");
    }
    setCourseIdToUpdate(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    let payload = {};
    if (updateType === "ACTIVE_STATUS") {
      const payload = {
        course_active: "change",
      };
      const responce = await activeStatusChange(courseIdToUpdate, payload);
      if (responce?.status === true) {
        setSuccessMessage("course active  ststus updated successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "RECENT_PLACEMENT") {
      const payload = {
        Recomended_Status: "change",
      };
      const responce = await activeStatusChange(courseIdToUpdate, payload);
      if (responce?.status === true) {
        setSuccessMessage(
          "course recent placement status updated successfully"
        );
        setOpenSnackbar(true);
      }
    } else if (updateType === "FUTURED_COURSES") {
      const payload = {
        future_course: "change",
      };
      const responce = await activeStatusChange(courseIdToUpdate, payload);
      if (responce?.status === true) {
        setSuccessMessage(
          "course  futured courses status updated successfully"
        );
        setOpenSnackbar(true);
      }
    } else if (updateType === "ORDER") {
      payload = {
        order: Number(order),
      };
      const responce = await activeStatusChange(courseIdToUpdate, payload);
      if (responce?.status === true) {
        setSuccessMessage("course order update successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "DELETE") {
      const responce = await deleteCourse(courseIdToUpdate);
      if (responce?.status === true) {
        setSuccessMessage("course delete successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getAllCoursesByBranch(branchId);
    dispatch(addCoursesToStore(data));
  };

  return (
    <div className="Courses-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Courses</h4>
          <Link to={"addcourse"} className="link">
            Add Courses
          </Link>
        </div>
      </div>
      <Link to={"/branch"} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>
      <TextField
        variant="outlined"
        label="Enter order"
        type="number"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="course-id">COURSE ID</TableCell>
              <TableCell className="course-name">COURSE</TableCell>
              <TableCell className="course-name">ORDER</TableCell>
              <TableCell className="course-name">UPDATE</TableCell>
              <TableCell className="active-status">ACTIVE</TableCell>
              <TableCell className="future-course">FUTURED COURSE</TableCell>
              <TableCell className="future-course">RECOMENDED COURSE</TableCell>
              {/* <TableCell className="course-Image">Images</TableCell> */}
              <TableCell className="delete-button">DELETE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses &&
              courses?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.course_Id}</TableCell>
                  <TableCell>
                    <NavLink
                      to={`${val.course_Id}/courseTypes`}
                      className="nev_link"
                    >
                      {val.course_name}
                    </NavLink>
                  </TableCell>
                  <TableCell>{val.order}</TableCell>

                  <TableCell>
                    <Button
                      onClick={() =>
                        handleUpdateRequest(val.course_Id, "ORDER")
                      }
                    >
                      update
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        handleUpdateRequest(
                          val.course_Id,
                          "ACTIVE_STATUS",
                          val.course_active === "A"
                        )
                      }
                      variant="contained"
                      size="small"
                      color={`${
                        val.course_active === "A" ? "success" : "error"
                      }`}
                    >
                      {val.course_active === "A" ? "Active" : "Deactive"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        handleUpdateRequest(
                          val.course_Id,
                          "FUTURED_COURSES",
                          val.future_course === "A"
                        )
                      }
                      variant="contained"
                      size="small"
                      color={`${
                        val.future_course === "A" ? "success" : "error"
                      }`}
                    >
                      {val.future_course === "A" ? "YES" : "NO"}
                    </Button>
                  </TableCell>

                  <TableCell>
                    <Button
                      onClick={() =>
                        handleUpdateRequest(
                          val.course_Id,
                          "RECENT_PLACEMENT",
                          val.recoemnded_Status === "A"
                        )
                      }
                      variant="contained"
                      size="small"
                      color={`${
                        val.recoemnded_Status === "A" ? "success" : "error"
                      }`}
                    >
                      {val.recoemnded_Status === "A" ? "Active" : "Deactive"}
                    </Button>
                  </TableCell>
                  {/* <TableCell>
                    <NavLink to={`${val.course_Id}/images`}>
                      <Button size="small">view</Button>
                    </NavLink>
                  </TableCell> */}
                  <TableCell>
                    <span
                      onClick={() =>
                        handleUpdateRequest(val.course_Id, "DELETE")
                      }
                      className="delete_button"
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default Courses;
