import {createSlice} from '@reduxjs/toolkit';


const initialState={
    allTeachers:[],
    allStudents:[]
}
const StudentTeacherAdmineReducer=createSlice({
    name:'student_teacher',
    initialState,
    reducers:{
        addallTeachersTostore:(state,action)=>{
            state.allTeachers=action.payload;
             }
,
             addAllStudentToStore:(state,action)=>{
                state.allStudents=action.payload
             }
              
    }

})


export const {addallTeachersTostore,addAllStudentToStore}=StudentTeacherAdmineReducer.actions

export default StudentTeacherAdmineReducer.reducer