import React, { useState, useEffect } from "react";

import {
  addbatchDetails,
  addrequestDetails,
} from "../../../Redux/Reduuces/EnrollRequestReducer";
import "./EnrollRequestsDetails.scss";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Select, MenuItem } from "@mui/material";
import {
  addStudentToBatch,
  addStudentToRecodedBatch,
  getAllEnrollRequest,
} from "../../../Redux/ActionCompo/EnrollRequestActions";
import { getBatchDetails } from "../../../Redux/ActionCompo/BatchActions";
const EnrollRequestsDetails = () => {
  const { requestId } = useParams();

  const [batchId, setBatchId] = useState("");
  const [subBatchId, setSubBatchId] = useState("");
  const [batchCourseId, setBatchCourseId] = useState("");

  const requestDetails = useSelector((state) => state.requests.requestDetails);
  const batchDetails = useSelector((state) => state.requests.batchDetails);

  let selectedSubbatch = [];
  if (batchDetails && batchDetails.length > 0) {
    const selectedSubbatch1 = batchDetails.filter((val) => {
      return val.batch_Id === batchId;
    });

    if (selectedSubbatch1 && selectedSubbatch1.length > 0) {
      selectedSubbatch = selectedSubbatch1[0]?.subBatch;
    }
  }

  let selectedBatchCourse = [];

  if (selectedSubbatch && selectedSubbatch.length > 0) {
    const selectedBatchCourse1 = selectedSubbatch.filter((val) => {
      return val.subBatch_Id === subBatchId;
    });

    if (selectedBatchCourse1 && selectedBatchCourse1.length > 0) {
      selectedBatchCourse = selectedBatchCourse1[0]?.batchCourses;
    }
  }

  const handleBathcSelection = (val) => {
    setSubBatchId("");
    setBatchCourseId("");
    setBatchId(val);
  };

  const handleSubBatchSelectioon = (val) => {
    setBatchCourseId("");
    setSubBatchId(val);
  };

  // const admitionActiveBatchCourses = useSelector(
  //   (state) => state.requests.activeBatchCourses
  // );
  const dispatch = useDispatch();

  const StudentData = requestDetails?.filter((request) => {
    return request.request_Id === requestId;
  })[0];

  const details = StudentData?.student_Detail;

  // useEffect(()=>{
  //   dispatch(addActiveBatchCourses(admitionActiveBatchCoursesData));
  //   // dispatch(addrequestDetails(requestDetailsData))
  // },[dispatch])
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllEnrollRequest();
      dispatch(addrequestDetails(data));
    };
    getData();
  }, [dispatch]);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    if (batchCourseId) {
      const studentId = StudentData?.student_Id;

      const newBtchCourseStudent = {
        student_name: StudentData?.student_Name,
        enroll_type: StudentData?.enroll_Type,
      };

      const response = await addStudentToBatch(
        batchCourseId,
        studentId,
        requestId,
        newBtchCourseStudent
      );

      if (response?.status === true) {
        navigate("/enrollRequests");
      }
    }
  };

  const RecodedStudentSubmiteHandler = async (event) => {
    event.preventDefault();

    // const batchCourseId = batchId;
    const studentId = StudentData?.student_Id;

    const newBtchCourseStudent = {
      student_name: StudentData?.student_Name,
      enroll_type: StudentData?.enroll_Type,
    };

    const response = await addStudentToRecodedBatch(
      StudentData?.courseTypeData.COURSE_TYPE_ID,
      studentId,
      requestId,
      newBtchCourseStudent
    );

    if (response?.status === true) {
      window.location.reload();
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getBatchDetails();
      dispatch(addbatchDetails(data));
    };
    getData();
  }, [dispatch]);

  return (
    <div className="EnrollRequestsDetails-main-container">
      <Link to={"/enrollRequests"}>back</Link>
      <h1>REQUEST DETAILS</h1>

      <div className="details-container">
        <div className="left-content">
          <div className="detail-content">
            <h5>Student Name</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {StudentData?.student_Name}
            </p>
          </div>

          <div className="detail-content">
            <h5>Student Email</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {details?.studentEmail}
            </p>
          </div>

          <div className="detail-content">
            <h5>Student mobile Number</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {details?.mobileNubber}
            </p>
          </div>

          <div className="detail-content">
            <h5>country</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {details?.country}
            </p>
          </div>

          <div className="detail-content">
            <h5>state</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {details?.state}
            </p>
          </div>

          <div className="detail-content">
            <h5>district</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {details?.district}
            </p>
          </div>

          <div className="detail-content">
            <h5>pinCode</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {details?.pinCode}
            </p>
          </div>
        </div>

        <div className="rigth-content">
          <div className="detail-content">
            <h5>couese Name</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {StudentData?.course_Name}
            </p>
          </div>

          <div className="detail-content">
            <h5>course Type Name</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {StudentData?.course_TypeName}
            </p>
          </div>

          <div className="detail-content">
            <h5>enroll_Type</h5>
            <p>
              <i class="fa-solid fa-circle-dot"></i>
              {StudentData?.enroll_Type}
            </p>
          </div>
        </div>
      </div>

      <div className="EnrollRequestsDetails-form-content">
        <div className="form-contant">
          {StudentData?.enroll_Type === "RECODED" ? (
            <form onSubmit={RecodedStudentSubmiteHandler}>
              <button type="submit">add</button>
            </form>
          ) : (
            <form onSubmit={onsubmiteHandler}>
              <div className="form-element">
                <label>Select Batch Id</label>

                <Select
                  label="Select batch Type"
                  value={batchId}
                  onChange={(e) => handleBathcSelection(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {batchDetails &&
                    batchDetails?.map((val) => (
                      <MenuItem key={val.batch_Id} value={val.batch_Id}>
                        {val.batch_Name}
                      </MenuItem>
                    ))}
                </Select>

                <Select
                  label="Select batch Type"
                  value={subBatchId}
                  onChange={(e) => handleSubBatchSelectioon(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {selectedSubbatch &&
                    selectedSubbatch?.map((val) => (
                      <MenuItem key={val.subBatch_Id} value={val.subBatch_Id}>
                        {val.subBatch_Name}
                      </MenuItem>
                    ))}
                </Select>
                <Select
                  label="Select batch Type"
                  value={batchCourseId}
                  onChange={(e) => setBatchCourseId(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {selectedBatchCourse &&
                    selectedBatchCourse
                      .filter((val) => val.admition_status === "A")
                      .map((val) => (
                        <MenuItem
                          key={val.batch_courseId}
                          value={val.batch_courseId}
                        >
                          {val.batch_courseName}
                        </MenuItem>
                      ))}
                </Select>

                <span>error message</span>
              </div>
              <button type="submit">Add</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
export default EnrollRequestsDetails;
