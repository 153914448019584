import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  coreTeamMembers: [],
  coreTeamMemberImages: [],
  mainTeamMembers: [],
  mainTeamMemberImages: [],
};

const TeamMemberReducer = createSlice({
  name: "teamMember",
  initialState: initialState,
  reducers: {
    addCoreTeamMembers: (state, action) => {
      state.coreTeamMembers = action.payload;
    },
    addMainTeamMembers: (state, action) => {
      state.mainTeamMembers = action.payload;
    },
    addcoreTeamMemberImages: (state, action) => {
      state.coreTeamMemberImages = action.payload;
    },

    addMainTeamMemberImages: (state, action) => {
      state.mainTeamMemberImages = action.payload;
    },
  },
});

export const {
  addCoreTeamMembers,
  addMainTeamMembers,
  addcoreTeamMemberImages,
  addMainTeamMemberImages,
} = TeamMemberReducer.actions;

export default TeamMemberReducer.reducer;
