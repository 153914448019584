import { Button } from "@mui/material";
import React from "react";
import {
  deleteCourseTypeCertificate,
  updateCourseTypeCertificate,
} from "../../../../../../Redux/ActionCompo/CourseAction";
const SingleCourseTypeCertificate = (props) => {
  const imageData = props.courseTypeImage;

  const activeStatusHandler = async (imageId) => {
    const data = {
      active_Status: "change",
    };

    const responce = await updateCourseTypeCertificate(imageId, data);

    if (responce?.status === true) {
    }
  };

  const deleteHandler = async (imageId) => {
    const responce = await deleteCourseTypeCertificate(imageId);
    if (responce?.status === true) {
    }
  };
  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData?.certificate_URL} alt="sx"></img>
      </div>

      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_Status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => activeStatusHandler(imageData.certificate_id)}
        >
          {imageData?.active_Status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteHandler(imageData.certificate_id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default SingleCourseTypeCertificate;
