import React, { useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addbatchRecordedSubjects } from "../../../../../../Redux/Reduuces/BatchReducer";
import {
  deleteRecordedVideoSubject,
  getAllRecordedSubjectVideo,
  updateRecordedVideoSubject,
} from "../../../../../../Redux/ActionCompo/BatchActions";
import "../../../../../../Genaral.css";
const BatchRecodedVideoSubject = () => {
  const batchRecordedSubjects = useSelector(
    (state) => state.batchData.batchRecordedSubjects
  );

  const dispatch = useDispatch();

  const ActiveStatusHandler = async (subjectId) => {
    const data = {
      active_Status: "change",
    };

    const responce = await updateRecordedVideoSubject(subjectId, data);

    if (responce.status === true) {
      window.location.reload();
    }
  };
  const { batchId, subBatchId, batchCourseId } = useParams();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllRecordedSubjectVideo(batchCourseId);
      dispatch(addbatchRecordedSubjects(data));
    };
    getData();
  }, [batchCourseId, dispatch]);

  const deletehandler = async (sibjectId) => {
    const responce = await deleteRecordedVideoSubject(sibjectId);
    if (responce.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="BatchRecodedVideoSubject-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All Recorded subjects</h4>
          <Link to={"addSubject"} className="link">
            Add New Subjects
          </Link>
        </div>
      </div>

      <Link
        to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/details`}
        className="back"
      >
        <i className="fa-solid fa-backward"></i>
      </Link>
      <TableContainer component={Paper} className="Placements-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>subject ID</TableCell>
              <TableCell>subject NAME</TableCell>
              <TableCell>ACTIVE</TableCell>
              <TableCell>Videos</TableCell>
              <TableCell>Images</TableCell>
              <TableCell>DELETE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {batchRecordedSubjects &&
              batchRecordedSubjects?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.video_Subject_Id}</TableCell>
                  <TableCell>{val.video_Subject_Name}</TableCell>

                  <TableCell>
                    <Button
                      onClick={() => {
                        ActiveStatusHandler(val.video_Subject_Id);
                      }}
                      variant="contained"
                      size="small"
                      color={`${
                        val.active_Status === "A" ? "success" : "error"
                      }`}
                    >
                      {val.active_Status === "A" ? "ACTIVE" : "DEACTIVE"}
                    </Button>
                  </TableCell>

                  <TableCell>
                    <NavLink to={`${val.video_Subject_Id}/videos`}>
                      <Button>view</Button>
                    </NavLink>
                  </TableCell>
                  <TableCell>
                    <NavLink to={`${val.video_Subject_Id}/images`}>
                      <Button>view</Button>
                    </NavLink>
                  </TableCell>
                  <TableCell>
                    <span
                      onClick={() => {
                        deletehandler(val.video_Subject_Id);
                      }}
                      className="delete_button"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BatchRecodedVideoSubject;
