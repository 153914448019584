import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  addNewCourseType,
  // getCoursePosition,
} from "../../../Redux/ActionCompo/CourseAction";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
} from "@mui/material";

import "../../../Genaral.css";
import {
  addallCategorysTostore,
  // addcoursePositionToStore,
} from "../../../Redux/Reduuces/GenaralReducer";
import { getAllCourseCategory } from "../../../Redux/ActionCompo/AdminGenaralActions";
import { useDispatch, useSelector } from "react-redux";
const AddCourseType = () => {
  const { courseId, branchId } = useParams();
  // const [coursePosition, setCoursePosition] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const courseCategorys = useSelector((state) => state.genaral.allCategorys);

  // const coursePositionData = useSelector(
  //   (state) => state.genaral.coursePosition
  // );
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getAllCourseCategory();
      dispatch(addallCategorysTostore(data));
    };
    getData();
  }, [dispatch]);

  // useEffect(() => {
  //   const getData = async () => {
  //     const data = await getCoursePosition();
  //     dispatch(addcoursePositionToStore(data));
  //   };
  //   getData();
  // }, [dispatch]);
  const [courseType, setCourseType] = useState("");
  // const [courseLevel, setCourseLevel] = useState("");

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    if (courseType === "") {
      // Handle validation/error here
    } else {
      const newCourseType = {
        course_TypeName: courseType,
        course_Level: "No",
      };
      const responce = await addNewCourseType(courseId, newCourseType);
      if (responce?.status === true) {
        // setCourseLevel("");
        setCourseType("");
        setSuccessMessage("course type added successfully");
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddCourseType-main-container">
      <div className="pageLinkHeader-main ">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/branch/${branchId}/courses/${courseId}/courseTypes`}
            className="link"
          >
            All course Type
          </Link>
          <h4>Add New Course Type</h4>
        </div>
      </div>
      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Course Type</InputLabel>
              <Select
                label="Select Course Type"
                value={courseType}
                onChange={(e) => setCourseType(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {courseCategorys &&
                  courseCategorys?.map((category) => (
                    <MenuItem
                      key={category.category_Id}
                      value={category.category_Name}
                    >
                      {category.category_Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* <InputLabel>Enter Course Level</InputLabel> */}
            {/* <FormControl fullWidth variant="outlined">
              <TextField
                type="text"
                value={courseLevel}
                onChange={(e) => setCourseLevel(e.target.value)}
              />
            </FormControl> */}

            {/* <FormControl fullWidth variant="outlined">
              <InputLabel>Select Course Type</InputLabel>
              <Select
                label="Select Course Type"
                value={coursePosition}
                onChange={(e) => setCoursePosition(e.target.value)}
              >

                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {coursePositionData &&
                  coursePositionData?.map((position, key) => (
                    <MenuItem key={key} value={position.position}>
                      {position.position}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              style={{ marginTop: "40px" }}
            >
              Add
            </Button>
          </form>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openSnackbar}
            autoHideDuration={5000} // Auto-hide after 5 seconds
            onClose={handleCloseSnackbar}
            message={successMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCourseType;
