import React from "react";
import { Button } from "@mui/material";
import {
  deleteCourseImage,
  updateCourseImages,
} from "../../../../Redux/ActionCompo/CourseAction";
import "../../../../Genaral.css";
const CourseSingleImage = (props) => {
  const imageData = props.courseTypeImage;
  const activeStatusHandler = async (imageId) => {
    const data = {
      active_Status: "change",
    };

    const responce = await updateCourseImages(imageId, data);

    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const deleteHandler = async (imageId) => {
    const responce = await deleteCourseImage(imageId);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData?.image_URL} alt="sx"></img>
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_Status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => activeStatusHandler(imageData.image_Id)}
        >
          {imageData?.active_Status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteHandler(imageData.image_Id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default CourseSingleImage;
