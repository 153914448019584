import { myaxios } from "../ApiBase";
import authHeader from "./auth-header";

export const addNewAwsVideoBranch = async (branchData) => {
  try {
    const response = await myaxios.post(`/aws/videos/branchs`, branchData, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const deleteAwsVideoBranch = async (branchId) => {
  try {
    const response = await myaxios.delete(`/aws/videos/branchs/${branchId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateAwsVideoBranch = async (branchId, branchData) => {
  try {
    const response = await myaxios.put(
      `/aws/videos/branchs/${branchId}`,
      branchData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getAwsVideoBranchs = async () => {
  try {
    const response = await myaxios.get(`/aws/videos/branchs`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

/// branch course

export const addNewAwsVideoBranchCourse = async (
  branchId,
  branchCourseData
) => {
  try {
    const response = await myaxios.post(
      `/aws/videos/branchs/${branchId}/courses`,
      branchCourseData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteAwsVideoBranchCourse = async (courseId) => {
  try {
    const response = await myaxios.delete(`/aws/videos/courses/${courseId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateAwsVideoBranchCourse = async (
  courseId,
  branchCourseData
) => {
  try {
    const response = await myaxios.put(
      `/aws/videos/courses/${courseId}`,
      branchCourseData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getAwsVideoBranchsCourse = async (branchId) => {
  try {
    const response = await myaxios.get(
      `/aws/videos/branchs/${branchId}/courses`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// branch course type
export const addNewAwsVideoBranchCourseType = async (
  courseId,
  branchCourseTypeData
) => {
  try {
    const response = await myaxios.post(
      `/aws/videos/courses/${courseId}/courseTypes`,
      branchCourseTypeData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteAwsVideoBranchCourseType = async (courseTypeId) => {
  try {
    const response = await myaxios.delete(
      `/aws/videos/courseTypes/${courseTypeId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateAwsVideoBranchCourseType = async (
  courseTypeId,
  branchCourseTypeData
) => {
  try {
    const response = await myaxios.put(
      `/aws/videos/courseType/${courseTypeId}`,
      branchCourseTypeData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getAwsVideoBranchsCourseType = async (courseId) => {
  try {
    const response = await myaxios.get(
      `/aws/videos/courses/${courseId}/courseTypes`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

// aws video module

export const addNewAwsVideoModule = async (courseTypeId, moduleData) => {
  try {
    const response = await myaxios.post(
      `/aws/videos/courseType/${courseTypeId}/modules`,
      moduleData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteAwsVideoModule = async (moduleId) => {
  try {
    const response = await myaxios.delete(`/aws/videos/module/${moduleId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateAwsVideoModule = async (moduleId, moduleData) => {
  try {
    const response = await myaxios.put(
      `/aws/videos/modules/${moduleId}`,
      moduleData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getAwsVideoModule = async (courseTypeId) => {
  try {
    const response = await myaxios.get(
      `/aws/videos/courseType/${courseTypeId}/modules`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// aws video lesson

export const addNewAwsVideoModuleLesson = async (moduleId, lessonData) => {
  try {
    const response = await myaxios.post(
      `/aws/videos/modules/${moduleId}/lessons`,
      lessonData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteAwsVideoModuleLesson = async (lessonId) => {
  try {
    const response = await myaxios.delete(`/aws/videos/lesson/${lessonId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateAwsVideoModuleLesson = async (lessonId, lessonData) => {
  try {
    const response = await myaxios.put(
      `/aws/videos/lesson/${lessonId}`,
      lessonData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getAwsVideoModuleLesson = async (moduleId) => {
  try {
    const response = await myaxios.get(
      `/aws/videos/modules/${moduleId}/lessons`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// aws video topic video

export const addNewAwsVideoTopic = async (lessonId, TopicVideoDataData) => {
  try {
    const response = await myaxios.post(
      `/aws/videos/lessons/${lessonId}/topicVideos`,
      TopicVideoDataData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteAwsVideoTopic = async (topicVideoId) => {
  try {
    const response = await myaxios.delete(
      `/aws/videos/topicVideos/${topicVideoId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateAwsVideoTopic = async (topicVideoId, TopicVideoDataData) => {
  try {
    const response = await myaxios.put(
      `/aws/videos/topicVideos/${topicVideoId}`,
      TopicVideoDataData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getAwsVideoTopic = async (lessonId) => {
  try {
    const response = await myaxios.get(
      `/aws/videos/lessons/${lessonId}/topicVideos`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};
