import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Snackbar, TextField } from "@mui/material";
import { addNewAwsVideoModuleLesson } from "../../../../../Redux/ActionCompo/AwsVideoActions";
const AddAwsVideoLesson = () => {
  const {
    awsVideoBranchId,
    AwsVideoBranchCourseId,
    awsVideoCourseTypeId,
    awsVideoModuleId,
  } = useParams();

  const [essentials_Type, setEssential_Type] = useState("");
  const [errorType, setErrorType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const onsubmiteHandler = async (event) => {
    event.preventDefault();
    if (essentials_Type.trim() === "") {
      setErrorType("Essential Type is required");
      return;
    }
    const newEssential = {
      lesson_name: essentials_Type,
    };
    const response = await addNewAwsVideoModuleLesson(
      awsVideoModuleId,
      newEssential
    );
    if (response?.status === true) {
      setEssential_Type("");
      setSuccessMessage("program hight added successfully");
      setOpenSnackbar(true);
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div className="AddEssentials-main-container" style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/AwsVideoBranch/${awsVideoBranchId}/courses/${AwsVideoBranchCourseId}/courseTypes/${awsVideoCourseTypeId}/modules/${awsVideoModuleId}/lessons`}
            className="link"
          >
            All program Highlight
          </Link>
          <h4>Add New program Highlite</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                label="Enter Essential Type"
                value={essentials_Type}
                onChange={(e) => setEssential_Type(e.target.value)}
                error={!!errorType}
                helperText={errorType}
                fullWidth
              />
            </div>
            {/* <div className="form-element">
      <TextField
        label="Enter Essential Desc"
        value={essentials_Desc}
        onChange={(e) => setAssential_Desc(e.target.value)}
        error={!!errorDesc}
        helperText={errorDesc}
        fullWidth
        multiline
        rows={4}
      />
    </div> */}

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddAwsVideoLesson;
