import React, { useState } from "react";
import { Link } from "react-router-dom";
import { addCoursePosition } from "../../Redux/ActionCompo/CourseAction";
import { Snackbar, TextField, Button } from "@mui/material";

const AddCoursePosition = () => {
  const [coursePosition, setCoursePosition] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();
    const newPosition = {
      position: coursePosition,
    };
    const response = await addCoursePosition(newPosition);
    if (response?.status === true) {
      setCoursePosition("");
      setSuccessMessage("Course position added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ width: "100%" }} className="AddNewSubject-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={"/coursePosition"} className="link">
            All course positions
          </Link>
          <h4>Add Course Position </h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <label>Enter Course Position</label>
              <TextField
                type="text"
                placeholder="Course Position"
                value={coursePosition}
                onChange={(e) => {
                  setCoursePosition(e.target.value);
                }}
                variant="outlined"
                fullWidth
                required
              />
            </div>
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds  
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddCoursePosition;
