import React from "react";
import "./RecentPlacementDetails.scss";
import { useNavigate, useParams } from "react-router-dom";
const RecentPlacementDetails = () => {
  const navigate = useNavigate();

  const { recentplacementId } = useParams();

  return (
    <div className="RecentPlacementDetails-main-container">
      <div className="RecentPlacementDetails-container">
        <ul>
          <li
            onClick={() => {
              navigate(
                `/recentplacements/${recentplacementId}/acadamicQualification`
              );
            }}
          >
            Acadamic Qualification
          </li>
          <li
            onClick={() => {
              navigate(`/recentplacements/${recentplacementId}/projects`);
            }}
          >
            Projects
          </li>
          <li
            onClick={() => {
              navigate(`/recentplacements/${recentplacementId}/skillsLearned`);
            }}
          >
            Skills Learned
          </li>
          <li
            onClick={() => {
              navigate(`/recentplacements/${recentplacementId}/ProfileImage`);
            }}
          >
            profile image
          </li>
          <li
            onClick={() => {
              navigate(`/recentplacements/${recentplacementId}/certificate`);
            }}
          >
            certificate
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RecentPlacementDetails;
