import { myaxios } from "../ApiBase";
import authHeader from "./auth-header";

export const getStudentDetailsByEmail = async (email) => {
  try {
    const response = await myaxios.get(`/std/student/${email}/studentDetails`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const getAllEnrolledCourseData = async (studentId) => {
  try {
    const responce = await myaxios.get(
      `/bch/student/${studentId}/batchCourse/Details`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getRecodedStudentClassModules = async (
  studentBatchId,
  courseTypeId
) => {
  try {
    const responce = await myaxios.get(
      `/cur/student/${studentBatchId}/courseTypes/${courseTypeId}/models`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getLessonTopicVides = async (studentBatchId, moduleId) => {
  try {
    const responce = await myaxios.get(
      `/cur/student/${studentBatchId}/module/${moduleId}/lessons/topics`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};
