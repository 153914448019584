import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";

const S3_BUCKET = "juzzitcloudfrantvideo";
const REGION = "ap-southeast-2"; // e.g., 'us-east-1'
const ACCESS_KEY = "AKIATLNZ64XGHATX7QEA";
const SECRET_ACCESS_KEY = "2xTHxkYj29dzSIPfzbJQCS/Q+3aR16HYcRZygksE";

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION,
});

const s3 = new AWS.S3();

const VideoUploadComponent = () => {
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleVideoChange = (event) => {
    setVideo(event.target.files[0]);
  };

  const getPresignedUrl = async (isMultipart, partNumber) => {
    // Call your backend API to get the pre-signed URL
    const response = await fetch(
      `/api/getPresignedUrl?isMultipart=${isMultipart}&partNumber=${partNumber}`
    );
    const data = await response.json();
    return data.url;
  };

  const initiateMultipartUpload = async () => {
    const key = `videos/${uuidv4()}-${video.name}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      ContentType: video.type,
    };

    return s3.createMultipartUpload(params).promise();
  };

  const uploadPart = async (uploadUrl, partNumber, body) => {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(uploadUrl, {
        method: "PUT",
        body: body,
        onprogress: (event) => {
          const percentage = Math.round((event.loaded / event.total) * 100);
          setProgress(percentage);
        },
      });

      if (response.ok) {
        resolve();
      } else {
        reject("Error uploading part");
      }
    });
  };

  const completeMultipartUpload = async (uploadUrl, parts) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: uploadUrl.Key,
      UploadId: uploadUrl.UploadId,
      MultipartUpload: { Parts: parts },
    };

    return s3.completeMultipartUpload(params).promise();
  };

  const uploadVideoToS3 = async () => {
    try {
      if (!video) {
        console.error("No video selected.");
        return;
      }

      setLoading(true);
      setProgress(0);

      const initiateResponse = await initiateMultipartUpload();

      const partSize = 5 * 1024 * 1024; // 5MB part size
      const chunkSize = Math.ceil(video.size / partSize);
      const parts = [];

      for (let i = 0; i < chunkSize; i++) {
        const start = i * partSize;
        const end = Math.min(start + partSize, video.size);
        const chunk = video.slice(start, end);
        const partNumber = i + 1;

        const uploadUrl = await getPresignedUrl(true, partNumber);

        // Upload individual part
        await uploadPart(uploadUrl, partNumber, chunk);

        parts.push({ PartNumber: partNumber, ETag: "" });
      }

      await completeMultipartUpload(initiateResponse, parts);

      console.log("Video uploaded successfully!");
    } catch (error) {
      console.error("Error uploading video:", error);
    } finally {
      setLoading(false);
      setProgress(100); // Set progress to 100% after completion
    }
  };

  const handleSubmit = async () => {
    await uploadVideoToS3();
  };

  return (
    <div>
      <input type="file" accept="video/*" onChange={handleVideoChange} />
      {video && <p>Selected Video: {video.name}</p>}
      <button onClick={handleSubmit} disabled={loading}>
        {loading ? "Uploading..." : "Upload Video"}
      </button>

      {loading && (
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
    </div>
  );
};

export default VideoUploadComponent;
