import React from "react";

import TextField from "@mui/material/TextField";
import { Link, useParams } from "react-router-dom";
const AddRecentPlacementCertificate = () => {
  const { recentplacementId } = useParams();
  return (
    <div style={{ width: "100vw" }} className="AddClassLinks-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/recentplacements/${recentplacementId}/acadamicQualification`}
            className="link"
          >
            All certificate
          </Link>
          <h4>Add certificate</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form>
            <TextField
              label="Enter Class Title"
              type="text"
              //   value={classTitle}
              //   onChange={(e) => setClassTitle(e.target.value)}
              //   error={!!classTitleError}
              //   helperText={classTitleError}
              fullWidth
            />

            <TextField
              label="Enter Subject Name"
              type="text"
              //   value={subjectName}
              //   onChange={(e) => setSubjectName(e.target.value)}
              //   error={!!subjectNameError}
              //   helperText={subjectNameError}
              fullWidth
            />

            <TextField
              type="date"
              //   value={classDate}
              //   onChange={(e) => setClassDate(e.target.value)}
              //   error={!!classDateError}
              //   helperText={classDateError}
              fullWidth
            />

            <button type="submit">Add</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddRecentPlacementCertificate;
