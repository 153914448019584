import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Snackbar, TextField } from "@mui/material";
import { addNewbranchType } from "../../Redux/ActionCompo/CourseAction";
const AddBranchTypes = () => {
  const [coursePosition, setCoursePosition] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const onsubmiteHandler = async (event) => {
    event.preventDefault();
    const newposition = {
      branch_name: coursePosition,
    };
    const responce = await addNewbranchType(newposition);
    if (responce?.status === true) {
      setCoursePosition("");
      setSuccessMessage("branch type added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ width: "100%" }} className="AddNewSubject-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={"/BranchTypes"} className="link">
            All branch types
          </Link>
          <h4>Add branch types </h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <label>Enter Enroll Type</label>
              <TextField
                variant="outlined"
                type="text"
                label="Branch Type"
                value={coursePosition}
                onChange={(e) => {
                  setCoursePosition(e.target.value);
                }}
              ></TextField>
            </div>
            <Button type="submit">Add</Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddBranchTypes;
