import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addBatchAllSubjectsToStore,
  addBatchAllTeachersToStore,
} from "../../../../../../Redux/Reduuces/BatchReducer";
import { Link, useParams } from "react-router-dom";
import { getAllTeachers } from "../../../../../../Redux/ActionCompo/StudentTeachetAdminActions";
import { getAllSubjects } from "../../../../../../Redux/ActionCompo/AdminGenaralActions";
import { addNewSubjectToBatchCourse } from "../../../../../../Redux/ActionCompo/BatchActions";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Container,
  Snackbar,
} from "@mui/material";

import "../../../../../../Genaral.css";

const AddCourseSubject = () => {
  const [subjectName, setSubjectName] = useState("");
  const [teacherId, setTeacherId] = useState("");

  const batchAllSubjects = useSelector(
    (state) => state.batchData.batchAllSubjects
  );
  const batchAllTeachers = useSelector(
    (state) => state.batchData.batchAllTeachers
  );

  const dispatch = useDispatch();
  const { batchId, subBatchId, batchCourseId } = useParams();

  const [subjectNameError, setSubjectNameError] = useState("");
  const [teacherIdError, setTeacherIdError] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllSubjects();
      dispatch(addBatchAllSubjectsToStore(data));
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllTeachers();
      dispatch(addBatchAllTeachersToStore(data));
    };
    getData();
  }, [dispatch]);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    // Check for empty inputs
    if (!subjectName) {
      setSubjectNameError("Subject is required");
    } else {
      setSubjectNameError("");
    }

    if (!teacherId) {
      setTeacherIdError("Teacher is required");
    } else {
      setTeacherIdError("");
    }

    if (subjectName && teacherId) {
      const newBatchCourseSubject = {
        subject_name: subjectName,
      };
      const responce = await addNewSubjectToBatchCourse(
        batchCourseId,
        teacherId,
        newBatchCourseSubject
      );
      if (responce?.status === true) {
        setSubjectName("");
        setTeacherId("");
        setSuccessMessage("batch for this month created successfully");
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container className="AddCourseSubject-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/batch/${batchId}/subBatch/${subBatchId}/courses/${batchCourseId}/subjects`}
            className="link"
          >
            All subjects
          </Link>
          <Typography variant="h4">Add New Subject</Typography>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <FormControl fullWidth>
                <InputLabel>Select Subject</InputLabel>
                <Select
                  value={subjectName}
                  onChange={(e) => setSubjectName(e.target.value)}
                  error={!!subjectNameError}
                >
                  <MenuItem value={""}>Select</MenuItem>
                  {batchAllSubjects?.map((subject) => (
                    <MenuItem
                      value={subject.subjectName}
                      key={subject.subjectId}
                    >
                      {subject.subjectName}
                    </MenuItem>
                  ))}
                </Select>
                <span>{subjectNameError}</span>
              </FormControl>
            </div>
            <div className="form-element">
              <FormControl fullWidth>
                <InputLabel>Select Teacher</InputLabel>
                <Select
                  value={teacherId}
                  onChange={(e) => setTeacherId(e.target.value)}
                  error={!!teacherIdError}
                >
                  <MenuItem value={""}>Select</MenuItem>
                  {batchAllTeachers?.map((teacher) => (
                    <MenuItem value={teacher.teacherId} key={teacher.teacherId}>
                      {teacher.teacherName}
                    </MenuItem>
                  ))}
                </Select>
                <span>{teacherIdError}</span>
              </FormControl>
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </Container>
  );
};

export default AddCourseSubject;
