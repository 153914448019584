import React from "react";
import "./TeacherMailRoot.scss";
import { Outlet } from "react-router-dom";
import TeacherHeader from "./Components/TeacherHeader";
const TeacherMailRoot = () => {
  return (
    <div>
      <TeacherHeader />

      <Outlet />
    </div>
  );
};

export default TeacherMailRoot;
