import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Snackbar, TextField } from "@mui/material";
import { addBlogDetails } from "../../../Redux/ActionCompo/BlogActions";
import "../../../Genaral.css";

const AddBlogDetails = () => {
  const { blogId } = useParams();
  const [blogDetaild_Desc, setBlogDetaild_Desc] = useState("");
  const [blogDetails_Heading, setBlogDetails_Heading] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errors, setErrors] = useState({
    blogDetails_Heading: false,
    blogDetaild_Desc: false,
  });

  const onsubmitHandler = async (event) => {
    event.preventDefault();

    // Validation
    const newErrors = {
      blogDetails_Heading: !blogDetails_Heading.trim(),
      blogDetaild_Desc: !blogDetaild_Desc.trim(),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    const newObjective = {
      blogDetaild_Desc: blogDetaild_Desc,
      blogDetails_Heading: blogDetails_Heading,
    };

    const response = await addBlogDetails(blogId, newObjective);

    if (response?.status === true) {
      setBlogDetaild_Desc("");
      setBlogDetails_Heading("");
      setSuccessMessage("blog added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div style={{ width: "100%" }} className="AddObjectievs-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={`/blogs/${blogId}/details`} className="link">
            All blog details
          </Link>
          <h4>Add blog details</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmitHandler}>
            <div className="form-element">
              <TextField
                fullWidth
                variant="outlined"
                label="blog detail heading"
                value={blogDetails_Heading}
                onChange={(e) => setBlogDetails_Heading(e.target.value)}
                error={errors.blogDetails_Heading}
                helperText={errors.blogDetails_Heading ? "Required field" : ""}
              />
            </div>
            <div className="form-element">
              <TextField
                fullWidth
                multiline
                rows={5}
                label="blod details desc"
                variant="outlined"
                value={blogDetaild_Desc}
                onChange={(e) => setBlogDetaild_Desc(e.target.value)}
                error={errors.blogDetaild_Desc}
                helperText={errors.blogDetaild_Desc ? "Required field" : ""}
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddBlogDetails;
