import React from "react";
import "./AllSalesExecutives.scss";

import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, NavLink } from "react-router-dom";
const AllSalesExecutives = () => {
  const selesExecutiove = [
    {
      salesExecutive_Id: "dsdweqd",
      salesExecutive_name: "dsdweqd",
      salesExecutive_email: "dsdweqd",
    },
    {
      salesExecutive_Id: "dsdweqd",
      salesExecutive_name: "dsdweqd",
      salesExecutive_email: "dsdweqd",
    },
    {
      salesExecutive_Id: "dsdweqd",
      salesExecutive_name: "dsdweqd",
      salesExecutive_email: "dsdweqd",
    },
    {
      salesExecutive_Id: "dsdweqd",
      salesExecutive_name: "dsdweqd",
      salesExecutive_email: "dsdweqd",
    },
    {
      salesExecutive_Id: "dsdweqd",
      salesExecutive_name: "dsdweqd",
      salesExecutive_email: "dsdweqd",
    },
    {
      salesExecutive_Id: "dsdweqd",
      salesExecutive_name: "dsdweqd",
      salesExecutive_email: "dsdweqd",
    },
  ];

  const deleteHandler = (id) => {};

  return (
    <div className="AllSalesExecutives-main-container">
      <div className="AllSalesExecutives-container-links">
        <div className="link-contant">
          <h4>All sales Executive</h4>
          <Link to={"AddSalesExecutive"} className="link">
            Add New Sales Executive
          </Link>
        </div>
      </div>

      <div className="AllSalesExecutives-tables">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sales Executive ID</TableCell>
                <TableCell>Sales Executive NAME</TableCell>
                <TableCell>Sales Executive EMAIL</TableCell>
                <TableCell>delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selesExecutiove?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.salesExecutive_Id}</TableCell>
                  <TableCell>
                    <NavLink
                      to={`${val.salesExecutive_Id}/details`}
                      className="nev_link"
                    >
                      {val.salesExecutive_name}
                    </NavLink>
                  </TableCell>
                  <TableCell>{val.salesExecutive_email}</TableCell>
                  <TableCell>
                    <IconButton
                      className="delete_button"
                      onClick={() => deleteHandler(val.salesExecutive_Id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AllSalesExecutives;
