import React, { useEffect, useState } from "react";
import "./AddBranch.scss";
import { Link } from "react-router-dom";
import {
  addNewBranch,
  getBranchTypeData,
} from "../../Redux/ActionCompo/CourseAction";
import { Button, Container, Snackbar } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import "../../Genaral.css";
import { useDispatch, useSelector } from "react-redux";
import { setbranchTypes } from "../../Redux/Reduuces/CourceReducer";
const AddBranch = () => {
  const [branchName, setbranchName] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const branchTypes = useSelector((state) => state.courseData.branchTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getBranchTypeData();
      dispatch(setbranchTypes(data));
    };
    getData();
  }, [dispatch]);

  const onSubmiteHandler = async (event) => {
    event.preventDefault();
    if (!branchName.trim()) {
      setError("Course name is required");
    } else {
      setError("");
      const newCourse = {
        branch_name: branchName.trim(),
      };

      const response = await addNewBranch(newCourse);
      if (response?.status === true) {
        setbranchName("");
        setSuccessMessage("branch added successfully");
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div className="AddCourse-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to="/branch" className="link">
            All branchs
          </Link>
          <h4>Add branchs</h4>
        </div>
      </div>
      <div className="form-container">
        <Container maxWidth="sm">
          <div className="form-contant">
            <span>{error}</span>
            <form onSubmit={onSubmiteHandler}>
              <div className="form-element">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>select branch</InputLabel>
                  <Select
                    label="Select Course Type"
                    value={branchName}
                    onChange={(e) => setbranchName(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {branchTypes &&
                      branchTypes?.map((category) => (
                        <MenuItem
                          key={category.branch_type_id}
                          value={category.branch_type_name}
                        >
                          {category.branch_type_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>
            </form>
            {/* Display the success message in a Snackbar */}
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openSnackbar}
              autoHideDuration={5000} // Auto-hide after 5 seconds
              onClose={handleCloseSnackbar}
              message={successMessage}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AddBranch;
