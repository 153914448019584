import React, { useState } from "react";
import { Button, Snackbar } from "@mui/material";
import {
  deleteCourseTypeBagroundImages,
  updateCourseTypeBagroundImage,
} from "../../../../../Redux/ActionCompo/CourseAction";
import "../../../../../Genaral.css";
const CourseTypeSingleBagroundImage = (props) => {
  const imageData = props.courseTypeImage;
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const deleteCourseTypeImage = async (imageId) => {
    const responce = await deleteCourseTypeBagroundImages(imageId);
    console.log(responce);
  };

  const updateCourseTypeImageData = async (imageId) => {
    const data = {
      active_status: "change",
    };

    const responce = await updateCourseTypeBagroundImage(imageId, data);
    if (responce?.status === true) {
      setSuccessMessage("branch added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData.image_URL} alt="'ascx"></img>
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_Status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => {
            updateCourseTypeImageData(imageData.courseType_image_Id);
          }}
        >
          {imageData.active_Status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteCourseTypeImage(imageData.courseType_image_Id);
          }}
        >
          Delete
        </Button>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default CourseTypeSingleBagroundImage;
