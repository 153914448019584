import React, { useEffect, useState } from "react";
import "./AddMenterToTheRecodedStudent.scss";
import { getAllTeachers } from "../../../Redux/ActionCompo/StudentTeachetAdminActions";
import { useDispatch, useSelector } from "react-redux";
import { addallMenter } from "../../../Redux/Reduuces/RecodedStudentReducer";
import { useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { assignMenterToStudent } from "../../../Redux/ActionCompo/RecodedStudentActions";
const AddMenterToTheRecodedStudent = () => {
  const [teacherId, setTeacherId] = useState("");
  const [teacherIdError, setTeacherIdError] = useState("");
  const dispatch = useDispatch();
  const { recodedStudentId } = useParams();

  const allMenter = useSelector((state) => state.recodedStudentData.allMenter);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllTeachers();
      dispatch(addallMenter(data));
    };
    getData();
  }, [dispatch]);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    // Check for empty inputs

    if (!teacherId) {
      setTeacherIdError("Teacher is required");
    } else {
      setTeacherIdError("");
    }

    if (teacherId) {
      const responce = await assignMenterToStudent(recodedStudentId, teacherId);
      if (responce?.status === true) {
        setTeacherId("");
      }
    }
  };

  return (
    <div className="AddMenterToTheRecodedStudent-form-container">
      <div className="form-contant">
        <form onSubmit={onsubmiteHandler}>
          <div className="form-element">
            <FormControl fullWidth>
              <InputLabel>Select Menter</InputLabel>
              <Select
                value={teacherId}
                onChange={(e) => setTeacherId(e.target.value)}
                error={!!teacherIdError}
              >
                <MenuItem value={""}>Select</MenuItem>
                {allMenter &&
                  allMenter?.map((teacher) => (
                    <MenuItem value={teacher.teacherId} key={teacher.teacherId}>
                      {teacher.teacherName}
                    </MenuItem>
                  ))}
              </Select>
              <span>{teacherIdError}</span>
            </FormControl>
          </div>

          <Button type="submit" variant="contained" color="primary">
            Add
          </Button>
        </form>
      </div>
    </div>
  );
};
export default AddMenterToTheRecodedStudent;
