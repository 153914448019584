import React from "react";
import "./TeacherHeader.scss";
import {  NavLink, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
const TeacherHeader = () => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem("userData");
    navigate("/");
    window.location.reload();
  };
  return (
    <div className="TeacherHeader-main-container">
      <div className="TeacherHeader-container">
        <div className="teacher-header-logo">
          <NavLink to={"/"}>
            <img
              src="http://juzzit.in/wp-content/uploads/2023/10/DOC-20230905-WA0010.-1.pdf-2.png"
              alt="logo"
            ></img>
          </NavLink>
          <span>Juzz IT</span>
        </div>

        <ul>
          <Button variant="contained" onClick={logoutHandler} size="small">
            Log Out
          </Button>
          <div className="token-content">
            {/* <Link to={"/teachers/recodedStudent/active/tokens"}>
              <span>2</span>
              <i class="fa-regular fa-comment"></i>
            </Link> */}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default TeacherHeader;
