import React, { useEffect } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { getLandingPageApplications } from "../../../../Redux/ActionCompo/LandingPageActions";
import { useDispatch, useSelector } from "react-redux";
import { addlandingPageApplicantsToStore } from "../../../../Redux/Reduuces/LandingPageReducer";

const LandingPageApplicants = () => {
  const { pageId, pageName } = useParams();

  const dispatch = useDispatch();

  const landingPageApplicants = useSelector(
    (state) => state.landingPageData.landingPageApplicants
  );

  useEffect(() => {
    const getData = async () => {
      const data = await getLandingPageApplications(pageId);
      dispatch(addlandingPageApplicantsToStore(data));
    };
    getData();
  }, [dispatch, pageId]);

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `${pageName}.xlsx`);
  };

  let sortedData = [];

  if (landingPageApplicants && landingPageApplicants.length > 0) {
    sortedData = [...landingPageApplicants].sort((a, b) => {
      return new Date(b.application_date) - new Date(a.application_date);
    });
  }

  return (
    <div style={{ width: "100vw" }}>
      {/* <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>qualification</h4>
          <Link to={"add"} className="link">
            qualification
          </Link>
        </div>
      </div> */}

      <Link to={`/landingPages/${pageId}/${pageName}/details`} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="course-id">
                {" "}
                <Button onClick={() => downloadExcel(sortedData)}>
                  {" "}
                  Download As Excel
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell className="course-id"> SI/NO</TableCell>
              <TableCell className="course-name">name</TableCell>
              <TableCell className="future-course">email</TableCell>
              <TableCell className="active-status">mobile number</TableCell>
              <TableCell className="course-Image">appled DATE</TableCell>
              <TableCell className="delete-button">delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {landingPageApplicants &&
              landingPageApplicants?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.name}</TableCell>
                  <TableCell>{val.email}</TableCell>
                  <TableCell>{val.mobile_number}</TableCell>
                  <TableCell>{val.application_date}</TableCell>

                  <TableCell>
                    <i class="fa-solid fa-trash-can"></i>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LandingPageApplicants;
