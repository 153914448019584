import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Snackbar, TextField } from "@mui/material";
import { addJuzzItJobDomain } from "../../Redux/ActionCompo/JuzzItJobActions";

const AddJobDomain = () => {
  const [essentials_Type, setEssential_Type] = useState("");
  const [errorType, setErrorType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();
    if (essentials_Type.trim() === "") {
      setErrorType("Essential Type is required");
      return;
    }

    const newEssential = {
      job_domain_name: essentials_Type,
    };
    const response = await addJuzzItJobDomain(newEssential);
    if (response?.status === true) {
      setEssential_Type("");
      setSuccessMessage("essential  added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddEssentials-main-container" style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={`/JobDomain`} className="link">
            All Job Domain
          </Link>
          <h4>Add New Job Domain </h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                label="Enter Essential Type"
                value={essentials_Type}
                onChange={(e) => setEssential_Type(e.target.value)}
                error={!!errorType}
                helperText={errorType}
                fullWidth
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddJobDomain;
