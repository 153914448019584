import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allSubjects: [],
  allCategorys: [],
  teacherDetailInAdmin: {},
  teacherAllBatchsInAdmin: [],
  allEnrollTypes: [],
  coursePosition: [],
};

const GenaralReducer = createSlice({
  name: "genaral",
  initialState,
  reducers: {
    addallSubjectTostore: (state, action) => {
      state.allSubjects = action.payload;
    },
    addallCategorysTostore: (state, action) => {
      state.allCategorys = action.payload;
    },
    addteacherDetailInAdminTostore: (state, action) => {
      state.teacherDetailInAdmin = action.payload;
    },
    addteacherAllBatchsInAdminTostore: (state, action) => {
      state.teacherAllBatchsInAdmin = action.payload;
    },
    addallEnrollTypesTostore: (state, action) => {
      state.allEnrollTypes = action.payload;
    },

    addcoursePositionToStore: (state, action) => {
      state.coursePosition = action.payload;
    },
  },
});

export const {
  addallCategorysTostore,
  addallEnrollTypesTostore,
  addallSubjectTostore,
  addteacherAllBatchsInAdminTostore,
  addteacherDetailInAdminTostore,
  addcoursePositionToStore,
} = GenaralReducer.actions;

export default GenaralReducer.reducer;
