import React, { useEffect } from "react";
import "./WorkShopStudentDetails.scss";
import { addteacherWorkShopStudents } from "../../../../../Redux/Reduuces/TeacherReducers";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getAllWorkShopBatchStudent } from "../../../../../Redux/ActionCompo/WorkShopBatchActions";

const WorkShopStudentDetails = () => {
  const teacher_WorkShop_Students = useSelector(
    (state) => state.teacher.teacher_WorkShop_Students
  );

  const dispatch = useDispatch();

  const { WorkShopbatchId } = useParams();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllWorkShopBatchStudent(WorkShopbatchId);
      dispatch(addteacherWorkShopStudents(data));
    };
    getData();
  }, [WorkShopbatchId, dispatch]);

  return (
    <div className="StudentDetails-main-container">
      <Link to={"/teachers/WorkShopBatchs"} className="back">
        <i className="fa-solid fa-backward"></i>
      </Link>
      <br />

      <TableContainer
        component={Paper}
        className="StudentDetails-table-container"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI NO</TableCell>
              <TableCell>STUDENT ID</TableCell>
              <TableCell>STUDENT NAME</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teacher_WorkShop_Students &&
              teacher_WorkShop_Students?.map((val, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell>{key + 1}</TableCell>
                    <TableCell>{val.student_BatchId}</TableCell>
                    <TableCell>{val.student_Name}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WorkShopStudentDetails;
