export default function authHeader() {
  let headerContent = "";
  const user = localStorage.getItem("userData");
  if (user) {
    headerContent = JSON.parse(user).jwtTokan;
  }

  if (user) {
    // return { Authorization: 'Bearer ' + user.accessToken };
    return { Authorization: "Bearer " + headerContent };
  } else {
    return {};
  }
}
