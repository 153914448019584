import React, { useEffect } from "react";
import "./MyWorkShopBatch.scss";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addteacherWorkShopBatchs } from "../../../Redux/Reduuces/TeacherReducers";
import { getAllWorkShopBatches } from "../../../Redux/ActionCompo/TeacherActions";
const MyWorkShopBatch = () => {
  const teacher_WorkShop_Batchs = useSelector(
    (state) => state.teacher.teacher_WorkShop_Batchs
  );

  const userData = localStorage.getItem("userData");
  let user = {};
  if (userData) {
    user = JSON.parse(userData);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getAllWorkShopBatches(user?.userId);
      dispatch(addteacherWorkShopBatchs(data));
    };
    getData();
  }, [dispatch, user?.userId]);

  return (
    <div className="MyWorkShopBatch-main-container">
      <div className="MyBatchs-nav-container">
        <ul>
          <li>
            <Link to={"/"} className="link">
              Profile
            </Link>
          </li>
          <li>
            <Link to={"/teachers/batchs"} className="link">
              Batchs
            </Link>
          </li>
          <li className="link">
            <h3>Work Shop Batchs</h3>
          </li>
          <li>
            <Link to={"/teachers/recodedStudent"} className="link">
              Recoded Students
            </Link>
          </li>
        </ul>
      </div>

      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>BATCH ID</TableCell>
                <TableCell>BATCH NAME</TableCell>
                <TableCell>COURSE NAME</TableCell>

                <TableCell>SUBJECT NAME</TableCell>
                <TableCell>STUDENTS</TableCell>
                <TableCell>BATCH DETAILS</TableCell>
                <TableCell>CLASS LINK</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teacher_WorkShop_Batchs &&
                teacher_WorkShop_Batchs?.map((val, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{val.workshop_batch_id}</TableCell>
                      <TableCell>{val.workshop_batch_name}</TableCell>
                      <TableCell>{val.workshop_name}</TableCell>
                      <TableCell>{val.subject_name}</TableCell>
                      <TableCell>
                        <Link
                          className="link"
                          to={`/teachers/WorkShopBatchs/${val.workshop_batch_id}/Students`}
                        >
                          {" "}
                          students
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          className="link"
                          to={`/teacher/WorkShopBatchs/${val.workshop_batch_id}/details`}
                        >
                          details
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          className="link"
                          to={`/teacher/WorkShopBatchs/${val.workshop_batch_id}/classLink/${val.subject_batch_id}`}
                        >
                          class link
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default MyWorkShopBatch;
