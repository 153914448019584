import React, { useState } from "react";
import "./LogIn.scss";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
// import SignUp from "./SignUp";
import { useNavigate } from "react-router-dom";
import { adminLogIn, trainerLogin } from "../Redux/ActionCompo/AuthActions";
import Loading from "../LoadingComponent/Loading";

const LogIn = () => {
  const [userType, setUserType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userTypeError, setUserTypeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginstatus, setloginstatus] = useState(false);
  // const [loginstatusMassege, setloginstatusMassege] = useState(false);

  const userTypes = [
    {
      userType_Id: "131",
      userType_Name: "TRAINER",
    },
    {
      userType_Id: "133",
      userType_Name: "ADMIN",
    },
  ];
  const navigate = useNavigate();

  //   const handleLogin = (user) => {
  //     // Simulate login logic here. Replace this with your actual login process.
  //     if (user.email === "demo@example.com" && user.password === "demo123") {
  //       // Store user data in session storage
  //       sessionStorage.setItem("userType", user.userType);
  //       sessionStorage.setItem("email", user.email);

  //       // Redirect or perform any other action on successful login
  //       window.location.href = "/dashboard"; // Redirect to the dashboard or any other page.
  //     } else {
  //       setLoginError("Invalid email or password.");
  //     }
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset any previous error messages
    setUserTypeError("");
    setEmailError("");
    setPasswordError("");
    setLoginError("");

    // Basic form validation
    if (userType === "") {
      setUserTypeError("Please select a user type");
      return;
    }

    // if (!email.match(/^\S+@\S+\.\S+$/)) {
    //   setEmailError("Invalid email format");
    //   return;
    // }

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      return;
    }
    const liginData = {
      email: email,
      password: password,
    };

    // // If validation passes, call the login function
    // const responce = await handleLogin();

    if (userType === "ADMIN") {
      setIsLoading(true);
      const responce = await adminLogIn(liginData);

      console.log(responce);

      if (responce?.successStatus === true) {
        setIsLoading(false);
        localStorage.setItem("userData", JSON.stringify(responce));
        navigate("/");
        window.location.reload();
      } else {
        setIsLoading(false);
        setLoginError(responce?.massage);
      }
    }
    if (userType === "TRAINER") {
      setIsLoading(true);
      const responce = await trainerLogin(liginData);

      console.log(responce);

      if (responce?.successStatus === true) {
        setIsLoading(false);
        localStorage.setItem("userData", JSON.stringify(responce));
        setloginstatus(true);
      } else {
        setIsLoading(false);
        setLoginError(responce?.massage);
      }
    }

    // if (responce.status === true) {
    //   localStorage.setItem("juzzIt_userData", JSON.stringify(responce.user));
    //   navigate("/");
    //   window.location.reload();
    // }
  };

  const handleLogInWithOtp = () => {
    if (userType === "") {
      setUserTypeError("Please select a user type");
      return;
    }

    if (userType === "ADMIN") {
      navigate("/AdminLogINWithOTP");
    }
    if (userType === "TRAINER") {
      navigate("/TrainerLogInWithOtp");
    }
  };

  return (
    <div className="LogIn-main-container">
      {isLoading && <Loading />}
      <div
        className={`successfully-main-request-Submited ${
          loginstatus ? "successfullySubmited" : ""
        }   `}
      >
        <div className="successfully-request-Submited" data-aos="zoom-in">
          <h1>login Successfully</h1>
          <Button
            variant="contained"
            onClick={() => {
              setloginstatus(false);
              window.location.reload();
            }}
          >
            continue
          </Button>
        </div>
      </div>

      <div className="LogIn-container">
        <h2>Login</h2>

        <form onSubmit={handleSubmit}>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel id="user-type-label">Login Type</InputLabel>
            <Select
              labelId="user-type-label"
              id="user-type"
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              label="Login Type"
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {userTypes.map((val, key) => (
                <MenuItem value={val.userType_Name} key={key}>
                  {val.userType_Name}
                </MenuItem>
              ))}
            </Select>
            <div className="error-message">{userTypeError}</div>
          </FormControl>

          <TextField
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="error-message">{emailError}</div>

          <TextField
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="error-message">{passwordError}</div>

          <Button type="submit" variant="contained" color="primary">
            Login
          </Button>
          <Button variant="outlined" onClick={handleLogInWithOtp}>
            login with otp
          </Button>
          <div className="error-message">{loginError}</div>
          {/* <NavLink to={"/signUp"} className="signup-link">
            Create admin account
          </NavLink> */}
        </form>
      </div>
    </div>
  );
};

export default LogIn;
