import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Snackbar, TextField } from "@mui/material";
import "../../../../../../Genaral.css";
import { addCourseTypeCereerService } from "../../../../../../Redux/ActionCompo/CourseAction";
const AddCereerService = () => {
  const { branchId, courseId, courseTypeId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [essentials_Type, setEssential_Type] = useState("");
  //   const [essentials_Desc, setAssential_Desc] = useState("");
  const [errorType, setErrorType] = useState("");
  //   const [errorDesc, setErrorDesc] = useState("");

  const onsubmiteHandler = async (event) => {
    event.preventDefault();
    if (essentials_Type.trim() === "") {
      setErrorType("Essential Type is required");
      return;
    }

    // if (essentials_Desc.trim() === "") {
    //   setErrorDesc("Essential Description is required");
    //   return;
    // }

    const newEssential = {
      careerService_Heading: essentials_Type,
    };
    const response = await addCourseTypeCereerService(
      courseTypeId,
      newEssential
    );
    if (response?.status === true) {
      setEssential_Type("");
      setSuccessMessage("career Service successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="AddEssentials-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/CereerService`}
            className="link"
          >
            All careere service
          </Link>
          <h4>Add New career service </h4>
        </div>
      </div>
      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                label="Enter Essential Type"
                value={essentials_Type}
                onChange={(e) => setEssential_Type(e.target.value)}
                error={!!errorType}
                helperText={errorType}
                fullWidth
              />
            </div>

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openSnackbar}
              autoHideDuration={5000}
              onClose={handleCloseSnackbar}
              message={successMessage}
            />
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCereerService;
