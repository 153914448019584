import { myaxios } from "../ApiBase";
import authHeader from "./auth-header";

export const addRecentPlacementAcadamicQualification = async (
  recentPlacementId,
  qualificationData
) => {
  try {
    const response = await myaxios.post(
      `/placement/recentPlacement/${recentPlacementId}/qualification`,
      qualificationData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteRecentPlacementQualification = async (qualificationId) => {
  try {
    const response = await myaxios.delete(
      `/placement/recentPlacement/qualification/${qualificationId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateRecentPlacementQualification = async (
  qualificationId,
  qualificationData
) => {
  try {
    const response = await myaxios.put(
      `/placement/recentPlacement/qualification/${qualificationId}`,
      qualificationData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getRecentPlacementQualification = async (recentPlacementId) => {
  try {
    const response = await myaxios.get(
      `/placement/recentPlacement/${recentPlacementId}/qualification`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

///prjects

export const addRecentPlacementAcadamicProjects = async (
  recentPlacementId,
  projectData
) => {
  try {
    const response = await myaxios.post(
      `/placement/recentPlacement/${recentPlacementId}/projects`,
      projectData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteRecentPlacementProjects = async (projectId) => {
  try {
    const response = await myaxios.delete(
      `/placement/recentPlacement/projects/${projectId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateRecentPlacementProjects = async (projectId, projectData) => {
  try {
    const response = await myaxios.put(
      `/placement/recentPlacement/projects/${projectId}`,
      projectData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getRecentPlacementProjects = async (recentPlacementId) => {
  try {
    const response = await myaxios.get(
      `/placement/recentPlacement/${recentPlacementId}/projects`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

//// projects image

export const addRecentPlacementAcadamicProjectsImage = async (
  projectId,
  projectImageData
) => {
  try {
    const response = await myaxios.post(
      `/placement/recentPlacement/projects/${projectId}/images`,
      projectImageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteRecentPlacementProjectsImage = async (imageId) => {
  try {
    const response = await myaxios.delete(
      `/placement/recentPlacement/projects/images/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateRecentPlacementProjectsImage = async (
  imageId,
  projectImageData
) => {
  try {
    const response = await myaxios.put(
      `/placement/recentPlacement/projects/images/${imageId}`,
      projectImageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getRecentPlacementProjectsImage = async (projectId) => {
  try {
    const response = await myaxios.get(
      `/placement/recentPlacement/projects/${projectId}/images`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// skills learned

export const addRecentPlacementAcadamicSkillsLearned = async (
  recentPlacementId,
  skillsLearnedData
) => {
  try {
    const response = await myaxios.post(
      `/placement/recentPlacement/${recentPlacementId}/skillsAquired`,
      skillsLearnedData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteRecentPlacementSkillsLearned = async (skillId) => {
  try {
    const response = await myaxios.delete(
      `/placement/recentPlacement/skillsAquired/${skillId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateRecentPlacementSkillsLearned = async (
  skillId,
  skillsLearnedData
) => {
  try {
    const response = await myaxios.put(
      `/placement/recentPlacement/skillsAquired/${skillId}`,
      skillsLearnedData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getRecentPlacementSkillsLearned = async (recentPlacementId) => {
  try {
    const response = await myaxios.get(
      `/placement/recentPlacement/${recentPlacementId}/skillsAquired`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

/// ProfileImage

export const addRecentPlacementProfileImage = async (
  recentPlacementId,
  ProfileImageData
) => {
  try {
    const response = await myaxios.post(
      `/placement/recentPlacement/${recentPlacementId}/profilImages`,
      ProfileImageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteRecentPlacementProfileImage = async (imageId) => {
  try {
    const response = await myaxios.delete(
      `/placement/recentPlacement/profilImages/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateRecentPlacementProfileImage = async (
  imageId,
  ProfileImageData
) => {
  try {
    const response = await myaxios.put(
      `/placement/recentPlacement/profilImages/${imageId}`,
      ProfileImageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getRecentPlacementProfileImage = async (recentplacementId) => {
  try {
    const response = await myaxios.get(
      `/placement/recentPlacement/${recentplacementId}/profilImages`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/// certificate

export const addRecentPlacementCertificate = async (
  recentPlacementId,
  certificateData
) => {
  try {
    const response = await myaxios.post(
      `/placement/recentPlacement/${recentPlacementId}/certifications`,
      certificateData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteRecentPlacementCertificate = async (certificatId) => {
  try {
    const response = await myaxios.delete(
      `/placement/recentPlacements/certifications/${certificatId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updateRecentPlacementCertificate = async (
  certificatId,
  certificateData
) => {
  try {
    const response = await myaxios.put(
      `/placement/recentPlacements/certifications/${certificatId}`,
      certificateData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getRecentPlacementCertificate = async (recentPlacementId) => {
  try {
    const response = await myaxios.get(
      `/placement/recentPlacement/${recentPlacementId}/certifications`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};
