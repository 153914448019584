import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import {
  deleteAwsVideoTopic,
  getAwsVideoTopic,
} from "../../../../../Redux/ActionCompo/AwsVideoActions";
import { setAwsVideoTopicVideos } from "../../../../../Redux/Reduuces/AwsVideoReducer";
const AwsTopicVideo = () => {
  const {
    awsVideoBranchId,
    AwsVideoBranchCourseId,
    awsVideoCourseTypeId,
    awsVideoModuleId,
  } = useParams();

  const dispatch = useDispatch();
  const awsVideoBranchCourseType = useSelector(
    (state) => state.AwsVideoData.awsVideoBranchCourseType
  );

  useEffect(() => {
    const getadata = async () => {
      const data = await getAwsVideoTopic(AwsVideoBranchCourseId);
      dispatch(setAwsVideoTopicVideos(data));
    };
    getadata();
  }, [AwsVideoBranchCourseId, dispatch]);

  const deleteHandler = async (essential_Id) => {
    const responce = await deleteAwsVideoTopic(essential_Id);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div className="FAQ-main-container" style={{ width: "100%" }}>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All program highlight</h4>
          <Link to={"add"} className="link">
            Add New program highlight
          </Link>
        </div>
      </div>

      <Link
        to={`/AwsVideoBranch/${awsVideoBranchId}/courses/${AwsVideoBranchCourseId}/courseTypes/${awsVideoCourseTypeId}/modules/${awsVideoModuleId}/lessons`}
        className="back"
      >
        <i class="fa-solid fa-backward"></i>
      </Link>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>topic</TableCell>
              <TableCell>original</TableCell>
              <TableCell>360q</TableCell>
              <TableCell>540q</TableCell>
              <TableCell>720q</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {awsVideoBranchCourseType &&
              awsVideoBranchCourseType?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.COURSE_TYPE_NAME}</TableCell>
                  <TableCell>original</TableCell>
                  <TableCell>360q</TableCell>
                  <TableCell>540q</TableCell>
                  <TableCell>720q</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        deleteHandler(val.COURSE_TYPE_ID);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AwsTopicVideo;
