import React, { useState } from "react";
import "./StaffHeader.scss";
import { FaTh, FaBars, FaRegChartBar } from "react-icons/fa";
import { BiCoinStack } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const StaffHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem("juzzIt_userData");
    navigate("/");
    window.location.reload();
  };
  const menuItem = [
    {
      path: "/",
      name: "HOME",
      icon: <FaTh />,
    },
    {
      path: "/requests",
      name: "Requests",
      icon: <BiCoinStack />,
    },
    {
      path: "/createStudentAcount",
      name: "Create Student Acount",
      icon: <FaRegChartBar />,
    },
    {
      path: "/SandStudentRequest",
      name: "Sand Student Request",
      icon: <FaRegChartBar />,
    },
  ];

  return (
    <div className="Header-main-container">
      <div className="Header-container">
        <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
          <div className="top_section">
            <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
              <img
                src="http://juzzit.in/wp-content/uploads/2023/07/juzz-IT-LOGO-1.png"
                alt="logo"
              />
            </h1>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeclassName="active"
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}

          {isOpen && (
            <Button size="small" onClick={logoutHandler}>
              logOut
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffHeader;
