import React, { useEffect, useState } from "react";
import "./Branch.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteBranch,
  getAllBranch,
  updateBranch,
} from "../../Redux/ActionCompo/CourseAction";
import { Link, NavLink } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
} from "@mui/material";

import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import "../../Genaral.css";
import { addbranchs } from "../../Redux/Reduuces/CourceReducer";
const Branch = () => {
  const [order, setOrder] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [branchIdToUpdate, setBranchIdToUpdate] = useState(null);
  const [updateType, setUpdateType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const branchs = useSelector((state) => state.courseData.branchs);
  const dispatch = useDispatch();
  useEffect(() => {
    const updateData = async () => {
      const data = await getAllBranch();
      dispatch(addbranchs(data));
    };
    updateData();
  }, [dispatch]);

  const handleUpdateRequest = async (id, type, STATUS) => {
    if (type === "ACTIVE_STATUS") {
      setUpdateType("ACTIVE_STATUS");
      setOpenDialog(true);
      if (STATUS) {
        setUpdateAlertMessage("Are you sure you want to deactive the branch ?");
      } else {
        setUpdateAlertMessage("Are you sure you want to active the branch ?");
      }
    } else if (type === "RECENT_PLACEMENT") {
      setUpdateType("RECENT_PLACEMENT");
      setOpenDialog(true);

      if (STATUS) {
        setUpdateAlertMessage(
          "Are you sure you want to remove   branch to the recent placement ?"
        );
      } else {
        setUpdateAlertMessage(
          "Are you sure you want to add branch to the recent placement ?"
        );
      }
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to update order branch ?");
    } else if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to  DELETE branch ?");
    }
    setBranchIdToUpdate(id);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    let payload = {};
    if (updateType === "ACTIVE_STATUS") {
      payload = {
        active_Status: "change",
      };
      const responce = await updateBranch(branchIdToUpdate, payload);
      if (responce?.status === true) {
        setSuccessMessage("branch ACTIVE STATUS STATUS UPDATED successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "RECENT_PLACEMENT") {
      payload = {
        Recomended_Status: "change",
      };
      const responce = await updateBranch(branchIdToUpdate, payload);
      if (responce?.status === true) {
        setSuccessMessage("branch RECNT PLACEMENT STATUS UPDATED successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ORDER") {
      payload = {
        order: Number(order),
      };
      const responce = await updateBranch(branchIdToUpdate, payload);
      if (responce?.status === true) {
        setSuccessMessage("branch ORDER UPDATED successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "DELETE") {
      const responce = await deleteBranch(branchIdToUpdate);
      if (responce?.status === true) {
        setSuccessMessage("branch DELETED successfully");
        setOpenSnackbar(true);
      }
    }

    const data = await getAllBranch();
    dispatch(addbranchs(data));
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div className="Courses-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All branch</h4>
          <Link to={"addbranch"} className="link">
            Add branch
          </Link>
        </div>
      </div>

      <TextField
        label="Enter Order"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
        variant="outlined"
        type="number"
      ></TextField>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="course-id">BRANCH ID</TableCell>
              <TableCell className="course-name">BRANCH</TableCell>
              <TableCell className="course-name">ORDER</TableCell>
              <TableCell className="course-name">UPDATE</TableCell>
              <TableCell className="active-status">ACTIVE</TableCell>
              <TableCell className="active-status">Recomended Status</TableCell>
              <TableCell className="delete-button">delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {branchs?.map((val, key) => (
              <TableRow key={key}>
                <TableCell>{val.branch_id}</TableCell>
                <TableCell>
                  <NavLink to={`${val.branch_id}/courses`} className="nev_link">
                    {val.branch_name}
                  </NavLink>
                </TableCell>
                <TableCell>{val.order}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleUpdateRequest(val.branch_id, "ORDER")}
                  >
                    update
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleUpdateRequest(
                        val.branch_id,
                        "ACTIVE_STATUS",
                        val.active_status === "A"
                      );
                    }}
                    variant="contained"
                    size="small"
                    color={`${val.active_status === "A" ? "success" : "error"}`}
                  >
                    {val.active_status === "A" ? "Active" : "Deactive"}
                  </Button>
                </TableCell>

                <TableCell>
                  <Button
                    onClick={() => {
                      handleUpdateRequest(
                        val.branch_id,
                        "RECENT_PLACEMENT",
                        val.recomended_status === "A"
                      );
                    }}
                    variant="contained"
                    size="small"
                    color={`${
                      val.recomended_status === "A" ? "success" : "error"
                    }`}
                  >
                    {val.recomended_status === "A" ? "Active" : "Deactive"}
                  </Button>
                </TableCell>

                <TableCell>
                  <span
                    onClick={() => {
                      handleUpdateRequest(val.branch_id, "DELETE");
                    }}
                    className="delete_button"
                  >
                    <i class="fa-solid fa-trash-can"></i>
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};
export default Branch;
