import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { AddTopicAssignmentTostore } from "../../../../../../../../../Redux/ActionCompo/CourseAction";
import { Snackbar } from "@mui/material";

const AddTopicAssignment = () => {
  const [file, setFile] = useState(null);

  const [assignmentTitle, setAssignmentTitle] = useState("");
  const [assignmentDesc, setAssignmentDesc] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { branchId, courseId, courseTypeId, moduleId, lessonId, topicId } =
    useParams();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assignment_Topic", assignmentTitle);
    formData.append("assignment_Desc", assignmentDesc);
    const response = await AddTopicAssignmentTostore(topicId, formData);
    if (response?.status === true) {
      setAssignmentTitle("");
      setAssignmentDesc("");
      setSuccessMessage("assignment added successfully");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All video </h4>
          <NavLink
            to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/models/${moduleId}/lessons/${lessonId}/topics/${topicId}/assignment`}
            className="link"
          >
            Add New video
          </NavLink>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="file">Select File:</label>
          <input type="file" id="file" onChange={handleFileChange} />
        </div>
        <div>
          <label htmlFor="topicId">Assignment Title :</label>
          <input
            type="text"
            id="topicId"
            value={assignmentTitle}
            onChange={(e) => setAssignmentTitle(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="key1">Key 1:</label>
          <input
            type="text"
            id="key1"
            value={assignmentDesc}
            onChange={(e) => setAssignmentDesc(e.target.value)}
          />
        </div>

        <div>
          <button type="submit">Submit</button>
        </div>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default AddTopicAssignment;
