import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  addNewCourseTypeDetails,
  getCourseTypeDetails,
  upDateCourseTypeDetails,
} from "../../../../../Redux/ActionCompo/CourseAction";
import { addcourseTypesDetailsToStore } from "../../../../../Redux/Reduuces/CourceReducer";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../Genaral.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Snackbar } from "@mui/material";
const AddCourseTypeDetails = () => {
  const { branchId, courseId, courseTypeId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const curseDetaile = useSelector(
    (state) => state.courseData.courseTypesDetails
  );
  const [currentDetailsStatus, setcurrentDetailsStatus] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getCourseTypeDetails(courseTypeId);
      dispatch(addcourseTypesDetailsToStore(data));
      if (data.length === 0) {
        const initialState = [
          {
            actual_Price: 0,
            discount_Price: 0,
            course_Desc: "",
            course_Duriation_Desc: "",
            // course_Overview: "",
            // course_Duriation: "",
            // course_learning_formate: "",
            CourseType_Heading: "",
            BOTTOM_HEADING: "",
            SUB_HEADING: "",
          },
        ];
        dispatch(addcourseTypesDetailsToStore(initialState));
      } else {
        setcurrentDetailsStatus(true);
      }
    };

    getData();
  }, [courseTypeId, dispatch]);

  const [actual_Price, setActual_Price] = useState(
    curseDetaile[0]?.actual_Price
  );
  const [disCount_Price, setDecount_Price] = useState(
    curseDetaile[0]?.discount_Price
  );
  const [course_Desc, setCourse_Desc] = useState(curseDetaile[0]?.course_Desc);
  // const [course_Overview, setCourse_Overview] = useState(
  //   curseDetaile[0]?.course_Overview
  // );
  const [course_Duriation_Desc, setCourse_Duriation_Desc] = useState(
    curseDetaile[0]?.course_DuriationDesc
  );
  // const [course_Duriation, setcourse_Duriation] = useState(
  //   curseDetaile[0]?.course_Duriation
  // );
  // const [course_learning_formate, setcourse_learning_formate] = useState(
  //   curseDetaile[0]?.course_learning_formate
  // );
  const [CourseType_Heading, setCourseType_Heading] = useState(
    curseDetaile[0]?.CourseType_Heading
  );
  const [BOTTOM_HEADING, setBOTTOM_HEADING] = useState(
    curseDetaile[0]?.BOTTOM_HEADING
  );
  const [SUB_HEADING, setSUB_HEADING] = useState(curseDetaile[0]?.SUB_HEADING);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    if (currentDetailsStatus === false) {
      const newDetails = {
        details_id: "wdkjlqiudqw",
        actual_Price: Number(actual_Price),
        discount_Price: Number(disCount_Price),
        course_Desc: course_Desc,
        // course_Overview: course_Overview,
        course_Duriation_Desc: course_Duriation_Desc,
        // course_Duriation: course_Duriation,
        // course_learning_formate: course_learning_formate,
        CourseType_Heading: CourseType_Heading,
        BOTTOM_HEADING: BOTTOM_HEADING,
        SUB_HEADING: SUB_HEADING,
      };

      const responce = await addNewCourseTypeDetails(courseTypeId, newDetails);
      if (responce?.status === true) {
        setSuccessMessage("course type details added updated successfully");
        setOpenSnackbar(true);
        const data = await getCourseTypeDetails(courseTypeId);
        dispatch(addcourseTypesDetailsToStore(data));
      }
    } else {
      const updatedDetails = {
        detail_Id: curseDetaile[0]?.detail_Id,
        actual_Price: Number(actual_Price),
        discount_Price: Number(disCount_Price),
        course_Desc: course_Desc,
        // course_Overview: course_Overview,
        course_Duriation_Desc: course_Duriation_Desc,
        // course_Duriation: course_Duriation,
        // course_learning_formate: course_learning_formate,
        CourseType_Heading: CourseType_Heading,
        BOTTOM_HEADING: BOTTOM_HEADING,
        SUB_HEADING: SUB_HEADING,
      };

      const responce = await upDateCourseTypeDetails(
        curseDetaile[0]?.detail_Id,
        updatedDetails
      );

      if (responce?.status === true) {
        setSuccessMessage("details updated successfully");
        setOpenSnackbar(true);
        const data = await getCourseTypeDetails(courseTypeId);
        dispatch(addcourseTypesDetailsToStore(data));
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false); // Hide the Snackbar when closed
  };

  return (
    <div className="AddCourseTypeDetails-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link
            to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/details`}
            className="link"
          >
            All course Type
          </Link>
          <h4>Add course Details </h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <label>Enter Course Amount</label>
              <TextField
                type="number"
                placeholder="Enter  Amount"
                value={disCount_Price}
                onChange={(e) => setDecount_Price(e.target.value)}
                variant="outlined"
              />
            </div>

            <div className="form-element">
              <label>Enter Course EMI Amount</label>
              <TextField
                type="number"
                placeholder="Enter EMI Amount"
                value={actual_Price}
                onChange={(e) => setActual_Price(e.target.value)}
                variant="outlined"
              />
            </div>

            <div className="form-element">
              <label>Enter CourseType Heading</label>
              <TextField
                type="text"
                placeholder="Enter Discount Amount"
                value={CourseType_Heading}
                onChange={(e) => setCourseType_Heading(e.target.value)}
                variant="outlined"
              />
            </div>
            <div className="form-element">
              <label>Enter SUB HEADING </label>
              <TextareaAutosize
                rowsMin={5}
                placeholder="Course Duration Desc"
                value={SUB_HEADING}
                onChange={(e) => setSUB_HEADING(e.target.value)}
                className="TextareaAutosize"
              />
            </div>

            <div className="form-element">
              <label>Enter BOTTOM reanked HEADING</label>
              <TextareaAutosize
                rowsMin={5}
                placeholder="Course Duration Desc"
                value={BOTTOM_HEADING}
                onChange={(e) => setBOTTOM_HEADING(e.target.value)}
                className="TextareaAutosize"
              />
            </div>

            <div className="form-element">
              <label>Enter Course Desc</label>
              <TextareaAutosize
                rowsMin={5}
                placeholder="Course Desc"
                value={course_Desc}
                onChange={(e) => setCourse_Desc(e.target.value)}
                className="TextareaAutosize"
              />
            </div>

            {/* <div className="form-element">
              <label>Enter Course Structure duriation</label>
              <TextField
                type="text"
                placeholder="Enter Discount Amount"
                value={course_Duriation}
                onChange={(e) => setcourse_Duriation(e.target.value)}
                variant="outlined"
              />
            </div> */}
            {/* 
            <div className="form-element">
              <label>Enter Course learning formante</label>
              <TextField
                type="text"
                placeholder="Enter Discount Amount"
                value={course_learning_formate}
                onChange={(e) => setcourse_learning_formate(e.target.value)}
                variant="outlined"
              />
            </div> */}

            {/* <div className="form-element">
              <label>Enter Course Overview</label>
              <TextareaAutosize
                rowsMin={5}
                placeholder="Course Overview"
                value={course_Overview}
                onChange={(e) => setCourse_Overview(e.target.value)}
                className="TextareaAutosize"
              />
            </div> */}
            <div className="form-element">
              <label>Enter Course Structure Duration Description</label>
              <TextareaAutosize
                rowsMin={5}
                placeholder="Course Duration Desc"
                value={course_Duriation_Desc}
                onChange={(e) => setCourse_Duriation_Desc(e.target.value)}
                className="TextareaAutosize"
              />
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openSnackbar}
            autoHideDuration={5000} // Auto-hide after 5 seconds
            onClose={handleCloseSnackbar}
            message={successMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCourseTypeDetails;
