import React from "react";
import { Button } from "@mui/material";

import {
  deleteRecentPlacementProfileImage,
  updateRecentPlacementProfileImage,
} from "../../../../../Redux/ActionCompo/RecentPlacementsAction";
const SingleRecentPlacementProfileImage = (props) => {
  const imageData = props.ToolImage;
  const deleteToolImage = async (imageId) => {
    const responce = await deleteRecentPlacementProfileImage(imageId);
    console.log(responce);
  };

  const updateToolImageData = async (imageId) => {
    const data = {
      active_status: "change",
    };

    const responce = await updateRecentPlacementProfileImage(imageId, data);
    console.log(responce);
  };
  return (
    <div className="image-main-components">
      <div className="image-main-components-image-content">
        <img src={imageData.image_URL} alt="dsad"></img>
      </div>
      <div className="image-main-components-button-content">
        <Button
          color={imageData?.active_status === "A" ? "success" : "error"}
          variant="contained"
          onClick={() => {
            updateToolImageData(imageData.image_id);
          }}
        >
          {imageData.active_status === "A" ? "active" : "deactive"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteToolImage(imageData.image_id);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default SingleRecentPlacementProfileImage;
