import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addallSubjectTostore } from "../../Redux/Reduuces/GenaralReducer";
import { Link } from "react-router-dom";
import {
  deleteSubject,
  getAllSubjects,
} from "../../Redux/ActionCompo/AdminGenaralActions";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const AllSubject = () => {
  const subjects = useSelector((state) => state.genaral.allSubjects);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllSubjects();
      dispatch(addallSubjectTostore(data));
    };
    getData();
  }, [dispatch]);

  const deleteHandler = async (subjectId) => {
    const response = await deleteSubject(subjectId);

    if (response?.status === true) {
      window.location.reload();
    }
  };

  return (
    <div style={{ width: "100%" }} className="AllSubject-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All subjects</h4>
          <Link to={"addSubject"} className="link">
            Add New subject
          </Link>
        </div>
      </div>

      <TableContainer component={Paper} className="AllSubject-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SUBJECT ID</TableCell>
              <TableCell>SUBJECT NAME</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subjects?.map((val, key) => (
              <TableRow key={key}>
                <TableCell>{val.subjectId}</TableCell>
                <TableCell>{val.subjectName}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      deleteHandler(val.subjectId);
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AllSubject;
