import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { Button, Input, Snackbar, TextField } from "@mui/material";
import {
  addCourseTypeToolsToMaster,
  getCourseTypeToolsToMaster,
} from "../../../../../../Redux/ActionCompo/CourseAction";
import { settoolsToMasterData } from "../../../../../../Redux/Reduuces/CourceReducer";
import ToolsToMasterSingleImage from "./ToolsToMasterSingleImage";
import { uploadImage } from "../../../../../../Redux/ActionCompo/CloudenarowImageUplode";
const ToolsToMaster = () => {
  const [file, setFile] = useState(null);
  const [toolName, settoolName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorDesc, setErrorDesc] = useState("");

  const [url, setUrl] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const toolsToMaster = useSelector((state) => state.courseData.toolsToMaster);
  const { courseTypeId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getCourseTypeToolsToMaster(courseTypeId);
      dispatch(settoolsToMasterData(data));
    };
    getData();
  }, [courseTypeId, dispatch]);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid JPEG or PNG image.");
      }
    }
  };
  const handleAddImage = async () => {
    if (toolName.trim() === "") {
      setErrorDesc(" Description is required");
      return;
    }

    const data = await uploadImage(file);

    if (data) {
      const newImageData = {
        IMAGE_URL: data?.secure_url,
        TOOL_NAME: toolName,
      };
      const responce = await addCourseTypeToolsToMaster(
        courseTypeId,
        newImageData
      );
      if (responce?.status === true) {
        setSuccessMessage("images updated successfully");
        setOpenSnackbar(true);
        const data = await getCourseTypeToolsToMaster(courseTypeId);
        dispatch(settoolsToMasterData(data));
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const addtoolImages = async (event) => {
    event.preventDefault();
    if (toolName.trim() === "") {
      setErrorDesc(" Description is required");
      return;
    }
    if (url.trim() === "") {
      setErrorDesc(" Description is required");
      return;
    }

    const newImageData = {
      IMAGE_URL: url,
      TOOL_NAME: toolName,
    };
    const responce = await addCourseTypeToolsToMaster(
      courseTypeId,
      newImageData
    );
    if (responce?.status === true) {
      setSuccessMessage("images updated successfully");
      setOpenSnackbar(true);
      const data = await getCourseTypeToolsToMaster(courseTypeId);
      dispatch(settoolsToMasterData(data));
    }
  };
  return (
    <div className="CourseImage-main-container">
      <NavLink to={`/courses`}>back</NavLink>
      <div className="">
        <div className="IMAGE-FORM-MAIN-CONTAINER">
          <div className="IMAGE-FORM-CONTAINER">
            <form>
              <Input
                type="text"
                value={toolName}
                onChange={(e) => {
                  settoolName(e.target.value);
                }}
                variant="outlined"
                lebal="tool name"
                error={!!errorDesc}
                helperText={errorDesc}
                fullWidth
              ></Input>
              <Input
                type="file"
                accept="image/jpeg, image/png"
                onChange={handleImageChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddImage}
              >
                Add Image
              </Button>
            </form>

            <form onSubmit={addtoolImages}>
              <TextField
                type="text"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              ></TextField>
              <Button type="submit">add</Button>
            </form>
          </div>
        </div>
        <div className="ALL-IMAGE-MAIN-CONTAINER">
          <div className="ALL-IMAGE-CONTAINER-heading">
            <h1>All Images</h1>
          </div>
          <div className="ALL-IMAGE-CONTAINER">
            {toolsToMaster &&
              toolsToMaster.map((val, key) => (
                <ToolsToMasterSingleImage courseTypeImage={val} key={key} />
              ))}
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openSnackbar}
            autoHideDuration={5000} // Auto-hide after 5 seconds
            onClose={handleCloseSnackbar}
            message={successMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default ToolsToMaster;
