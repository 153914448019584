import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Button, Input } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "../../../../../../../Redux/ActionCompo/CloudenarowImageUplode";
import SingleRecodedSubjectImage from "./components/SingleRecodedSubjectImage";
import {
  addRecordedSubjectImages,
  getAllRecodedSubjectImages,
} from "../../../../../../../Redux/ActionCompo/BatchActions";
import { addbatchRecordedSubjectsImages } from "../../../../../../../Redux/Reduuces/BatchReducer";
import "../../../../../../../Genaral.css";
const RecodedSubjectImage = () => {
  const [file, setFile] = useState(null);
  const batchRecordedSubjectsImages = useSelector(
    (state) => state.batchData.batchRecordedSubjectsImages
  );
  const { subjectId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getAllRecodedSubjectImages(subjectId);
      console.log(data);
      dispatch(addbatchRecordedSubjectsImages(data));
    };

    getData();
  }, [subjectId, dispatch]);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid JPEG or PNG image.");
      }
    }
  };

  const handleAddImage = async () => {
    const data = await uploadImage(file);
    console.log(data?.secure_url);
    if (data) {
      const newImageData = {
        image_URL: data?.secure_url,
      };
      console.log(newImageData);
      const responce = await addRecordedSubjectImages(subjectId, newImageData);
      console.log(responce);
    }
  };

  return (
    <div className="CourseImage-main-container">
      <div className="IMAGE-FORM-MAIN-CONTAINER">
        <NavLink to={`/courses`}>back</NavLink>
        <div className="IMAGE-FORM-CONTAINER">
          <form className="form">
            <Input
              type="file"
              accept="image/jpeg, image/png"
              onChange={handleImageChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddImage}
            >
              Add Image
            </Button>
          </form>
        </div>
      </div>

      <div className="ALL-IMAGE-MAIN-CONTAINER">
        <div className="ALL-IMAGE-CONTAINER-heading">
          <h1>All Images</h1>
        </div>
        <div className="ALL-IMAGE-CONTAINER">
          {batchRecordedSubjectsImages &&
            batchRecordedSubjectsImages.map((val, key) => (
              <SingleRecodedSubjectImage courseTypeImage={val} key={key} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default RecodedSubjectImage;
