import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";

export const addNewSubject = async (payload) => {
  try {
    const responce = await myaxios.post("/sub/subjects", payload, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllSubjects = async () => {
  try {
    const responce = await myaxios.get("/sub/subjects", {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return [];
  }
};

export const deleteSubject = async (subjectId) => {
  try {
    const responce = await myaxios.delete(`/sub/subjects/${subjectId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

////for enroll Type Conponents

export const addNewEnrollType = async (payload) => {
  try {
    const responce = await myaxios.post("/sub/enrollTypes", payload, {
      headers: authHeader(),
    });
    return await responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllEnrollTypes = async () => {
  try {
    const responce = await myaxios.get("/sub/enrollTypes", {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return [];
  }
};

export const deleteEnrollTypes = async (enrollTypeId) => {
  try {
    const responce = await myaxios.delete(`/sub/enrollTypes/${enrollTypeId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

/// course Category compo

export const addNewCourseCategory = async (payload) => {
  try {
    const responce = await myaxios.post("/cur/categorys", payload, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllCourseCategory = async () => {
  try {
    const responce = await myaxios.get("/cur/categorys", {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return [];
  }
};
export const deleteCourseCtegory = async (categoryId) => {
  try {
    const responce = await myaxios.delete(`/cur/categorys/${categoryId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

/////  teacher
