import React, { useEffect } from "react";
import "./WorkShopBatchDetailsInTeacher.scss";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWorkShopWeekStructueDetails } from "../../../../../Redux/ActionCompo/TeacherActions";
import { addteacher_WorkShop_Details } from "../../../../../Redux/Reduuces/TeacherReducers";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const WorkShopBatchDetailsInTeacher = () => {
  const { WorkShopbatchId } = useParams();

  const teacher_WorkShop_Details = useSelector(
    (state) => state.teacher.teacher_WorkShop_Details
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const data = await getWorkShopWeekStructueDetails(WorkShopbatchId);
      dispatch(addteacher_WorkShop_Details(data));
    };
    getData();
  }, [dispatch, WorkShopbatchId]);

  let workShop_Strusture = [];

  if (teacher_WorkShop_Details) {
    workShop_Strusture = teacher_WorkShop_Details[0]?.workShop_Strusture;
  }
  return (
    <div className="BatchDetails-main-container">
      <Link to={"/teachers/WorkShopBatchs"} className="back">
        <i class="fa-solid fa-backward"></i>
      </Link>
      <br />

      <div className="WorkShpoStructure-main-container">
        <h4>WorkShop CourseStructure</h4>
        <div className="WorkShpoStructure-container">
          {workShop_Strusture &&
            workShop_Strusture?.map((val, key) => {
              if (val.active_Status === "A") {
                return (
                  <Accordion key={key} className="Accordion">
                    <AccordionSummary
                      id={`panel${key}-header`}
                      aria-controls={`panel${key}-content`}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant="h6" className="Typography-head">
                        {key + 1}
                        {". Week "}
                        {val.week_Start}- {val.week_end}
                        {"   :  "}
                        {val.week_heading}
                      </Typography>
                    </AccordionSummary>

                    {val.day_Structure &&
                      val.day_Structure?.map((val1, key1) => {
                        if (val1.active_Status === "A") {
                          return (
                            <AccordionDetails key={key1}>
                              <Typography
                                variant="h6"
                                className="Typography-desc"
                              >
                                {"    "}
                                --{" Day "}
                                {val1.day_start}-{val1.day_end}
                                {" :   "}
                                {val1.day_heading}
                              </Typography>
                            </AccordionDetails>
                          );
                        }
                        return null;
                      })}
                  </Accordion>
                );
              }

              return null;
            })}
        </div>
      </div>
    </div>
  );
};

export default WorkShopBatchDetailsInTeacher;
