import React, { useEffect, useState } from "react";
import "./EventTypeImage.scss";
import SingleEventTypeImage from "./SingleEventTypeImage";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import {
  addEventTypeImage,
  getEventTypeImage,
} from "../../../../../Redux/ActionCompo/EventActions";
import { setEventTypeImage } from "../../../../../Redux/Reduuces/EventReducer";

import { Button, Snackbar, Input } from "@mui/material";
import { uploadImage } from "../../../../../Redux/ActionCompo/CloudenarowImageUplode";
const EventTypeImage = () => {
  const [file, setFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const CorrentWorkShopMainImages = useSelector(
    (state) => state.EventData.eventTypeImage
  );

  const { eventTypeId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getEventTypeImage(eventTypeId);

      dispatch(setEventTypeImage(data));
    };
    getData();
  }, [eventTypeId, dispatch]);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid JPEG or PNG image.");
      }
    }
  };

  const handleAddImage = async () => {
    const data = await uploadImage(file);
    if (data) {
      const newImageData = {
        image_URL: data?.secure_url,
      };
      const responce = await addEventTypeImage(eventTypeId, newImageData);
      if (responce?.status === true) {
        setFile(null);
        setSuccessMessage("branch added successfully");
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div className="CourseImage-main-container" style={{ width: "100%" }}>
      <NavLink to={`/courses`}>back</NavLink>
      <div className="">
        <div className="IMAGE-FORM-MAIN-CONTAINER">
          <div className="IMAGE-FORM-CONTAINER">
            <form>
              <Input
                type="file"
                accept="image/jpeg, image/png"
                onChange={handleImageChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddImage}
              >
                Add Image
              </Button>
            </form>
          </div>
        </div>

        <div className="ALL-IMAGE-MAIN-CONTAINER">
          <div className="ALL-IMAGE-CONTAINER-heading">
            <h1>All Images</h1>
          </div>
          <div className="ALL-IMAGE-CONTAINER">
            {CorrentWorkShopMainImages &&
              CorrentWorkShopMainImages.map((val, key) => (
                <SingleEventTypeImage courseTypeImage={val} key={key} />
              ))}
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default EventTypeImage;
