import React from "react";
import "./ListOfAllClassVideos.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { useNavigate, useParams } from "react-router-dom";
const ListOfAllClassVideos = ({ videoData }) => {
  // const { courseId, batchId, studentBatchId, moduleId } = useParams();
  // const navigate = useNavigate();

  return (
    <div className="ListOfAllClassVideos-main-container">
      {videoData &&
        videoData?.map((val, key) => {
          return (
            <Accordion key={key} className="Accordion">
              <AccordionSummary
                id={`panel${key}-header`}
                aria-controls={`panel${key}-content`}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h6" className="Typography-head">
                  {val.lesson_Completion_percentage?.completed_percentage}%{" "}
                  {val.lesson_Completion_percentage?.completed_percentage >
                  90 ? (
                    <i class="fas fa-check-square"></i>
                  ) : (
                    <i class="fa-regular fa-square"></i>
                  )}
                  {"   "} {key + 1}
                  {".   "}
                  {/* <i class="fas fa-check-square"></i>  */}
                  {val.lesson_Tites}
                </Typography>
              </AccordionSummary>

              {val.lesson_topic &&
                val.lesson_topic?.map((val1, key1) => {
                  return (
                    <AccordionDetails
                      key={key1}
                      //   onClick={() => setSingleVideoData(val1)}
                    >
                      <span>
                        <Typography variant="h6" className="Typography-desc1">
                          {
                            val1.topic_completion_percentage
                              ?.Topic_Completion_Percentage
                          }
                          %{" "}
                          {val1.topic_completion_percentage
                            ?.Topic_Completion_Percentage > 90 ? (
                            <i class="fas fa-check-square"></i>
                          ) : (
                            <i class="fa-regular fa-square"></i>
                          )}
                          {key1 + 1}
                          {". "}
                          {val1.topic_title}
                        </Typography>
                      </span>
                    </AccordionDetails>
                  );
                })}
            </Accordion>
          );
        })}
    </div>
  );
};

export default ListOfAllClassVideos;
