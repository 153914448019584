import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import {
  deleteCourseTypeProgramStructure2,
  getCourseTypeProgramStructure2,
  updateCourseTypeProgramStructure2,
} from "../../../../../../Redux/ActionCompo/CourseAction";
import { setprogramStructure2Data } from "../../../../../../Redux/Reduuces/CourceReducer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
const ProgramStructure2 = () => {
  const [order, setOrder] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");
  const { branchId, courseId, courseTypeId } = useParams();

  const dispatch = useDispatch();
  const programStructure2 = useSelector(
    (state) => state.courseData.programStructure2
  );

  useEffect(() => {
    const getadata = async () => {
      const data = await getCourseTypeProgramStructure2(courseTypeId);
      dispatch(setprogramStructure2Data(data));
    };
    getadata();
  }, [courseTypeId, dispatch]);

  const navigate = useNavigate();

  const handleUpdateRequest = async (id, type) => {
    if (type === "DELETE") {
      setUpdateType("DELETE");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to delete the program structure 2  ?"
      );
    } else if (type === "ORDER") {
      setUpdateType("ORDER");
      setOpenDialog(true);
      setUpdateAlertMessage(
        "Are you sure you want to update order  of program structure 2  ?"
      );
    }
    setUpdateId(id);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);
    if (updateType === "DELETE") {
      const responce = await deleteCourseTypeProgramStructure2(UpdateId);
      if (responce?.status === true) {
        setSuccessMessage(" program structure 2  delete successfully");
        setOpenSnackbar(true);
      }
    } else if (updateType === "ORDER") {
      const payload = {
        order: Number(order),
      };

      const responce = await updateCourseTypeProgramStructure2(
        UpdateId,
        payload
      );
      if (responce?.status === true) {
        setSuccessMessage("program structure 2 order update successfully");
        setOpenSnackbar(true);
      }
    }
    const data = await getCourseTypeProgramStructure2(courseTypeId);
    dispatch(setprogramStructure2Data(data));
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="FAQ-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All program Struture 2</h4>
          <Link to={"add"} className="link">
            Add New program Struture 2
          </Link>
        </div>
      </div>

      <Link
        to={`/branch/${branchId}/courses/${courseId}/courseTypes/${courseTypeId}/details`}
        className="back"
      >
        <i class="fa-solid fa-backward"></i>
      </Link>

      <TextField
        variant="outlined"
        type="number"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>MODULE</TableCell>
              <TableCell>ORDER</TableCell>
              <TableCell>ORDER UPDATE</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programStructure2 &&
              programStructure2?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell
                    onClick={() => {
                      navigate(`${val.structure_id}/lessons`);
                    }}
                  >
                    {val.module_Title}
                  </TableCell>
                  <TableCell>{val.order}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        handleUpdateRequest(val.structure_id, "ORDER")
                      }
                    >
                      update
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        handleUpdateRequest(val.structure_id, "DELETE");
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle className="dialog-title">
          Confirm Status Update
        </DialogTitle>
        <DialogContent className="dialog-content">
          {updateAlertMessage}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateStatus}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default ProgramStructure2;
