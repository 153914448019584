import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  addCourseTypeVideos,
  getAllImageVideos,
} from "../../../Redux/ActionCompo/CourseAction";
import { useDispatch, useSelector } from "react-redux";
import { addcourseTypeVideo } from "../../../Redux/Reduuces/CourceReducer";
import CourseTypeSingleVideo from "./CourseTypeSingleVideo";
import { Button, Snackbar, TextField } from "@mui/material";

const CourseTypeVideo = () => {
  const { courseId, courseTypeId } = useParams();
  const [videoURL, setvideoURL] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const courseTypeVideoData = useSelector(
    (state) => state.courseData.courseTypeVideo
  );

  const dispatch = useDispatch();

  const onsubmiteHandler = async (event) => {
    event.preventDefault();
    const videoData = {
      video_URL: videoURL,
    };
    const responce = await addCourseTypeVideos(courseTypeId, videoData);
   
    if (responce?.status === true) {
      setvideoURL("");
      setSuccessMessage("video added successfully");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getAllImageVideos(courseTypeId);
      dispatch(addcourseTypeVideo(data));
    };
    getData();
  }, [courseTypeId, dispatch]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <NavLink to={`/courses/${courseId}/courseTypes`}>back</NavLink>
      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <TextField
              variant="outlined"
              value={videoURL}
              onChange={(e) => setvideoURL(e.target.value)}
            ></TextField>
            <Button type="submit">add</Button>
          </form>
        </div>
      </div>
      <div>
        {courseTypeVideoData &&
          courseTypeVideoData.map((val, key) => {
            return <CourseTypeSingleVideo videoData={val} key={key} />;
          })}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </div>
  );
};

export default CourseTypeVideo;
