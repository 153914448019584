import React, { useState } from "react";
import { addNewTopicVideos } from "../../../../../../../../../Redux/ActionCompo/CourseAction";
import { NavLink, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import "../../../../../../../../../Genaral.css";
const AddNewTopicVideo = () => {
  const [videoDesc, setVideoDesc] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [validationError, setValidationError] = useState({
    videoDesc: "",
    videoURL: "",
  });

  const { courseId, courseTypeId, moduleId, lessonId, topicId } = useParams();

  const validateInputs = () => {
    let isValid = true;
    const errors = {
      videoDesc: "",
      videoURL: "",
    };

    if (!videoDesc.trim()) {
      errors.videoDesc = "Video description is required";
      isValid = false;
    }

    if (!videoURL.trim()) {
      errors.videoURL = "Video URL is required";
      isValid = false;
    }

    setValidationError(errors);
    return isValid;
  };

  const onSubmiteHandler = async (event) => {
    event.preventDefault();

    if (validateInputs()) {
      const newTopicData = {
        vedio_Heading: videoDesc,
        video_URL: videoURL,
      };
      addNewTopicVideos(topicId, newTopicData);
    }
  };

  return (
    <div>
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <NavLink
            to={`/courses/${courseId}/courseTypes/${courseTypeId}/models/${moduleId}/lessons/${lessonId}/topics/${topicId}/videos`}
            className="link"
          >
            All video
          </NavLink>
          <h4> Add New video</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onSubmiteHandler}>
            <TextField
              label="Video Description"
              variant="outlined"
              fullWidth
              margin="normal"
              value={videoDesc}
              onChange={(e) => setVideoDesc(e.target.value)}
              error={Boolean(validationError.videoDesc)}
              helperText={validationError.videoDesc}
            />

            <TextField
              label="Video URL"
              variant="outlined"
              fullWidth
              margin="normal"
              value={videoURL}
              onChange={(e) => setVideoURL(e.target.value)}
              error={Boolean(validationError.videoURL)}
              helperText={validationError.videoURL}
            />

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewTopicVideo;
