import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { addNewSubBatch } from "../../../Redux/ActionCompo/BatchActions";
import { InputLabel, Button, Container, Snackbar } from "@mui/material";
import "../../../Genaral.css";

import { Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourseCategory } from "../../../Redux/ActionCompo/AdminGenaralActions";
import { addallCategorysTostore } from "../../../Redux/Reduuces/GenaralReducer";

const AddSubBatch = () => {
  const { batchId } = useParams();
  const [subBatch_Name, setSubBatch_Name] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const courseCategorys = useSelector((state) => state.genaral.allCategorys);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllCourseCategory();
      dispatch(addallCategorysTostore(data));
    };
    getData();
  }, [dispatch]);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();

    const newSubBatch = {
      subBatch_Name: subBatch_Name.trim(),
    };
    const responce = await addNewSubBatch(batchId, newSubBatch);
    if (responce?.status === true) {
      setSubBatch_Name("");
      setSuccessMessage("subbatch successfully");
      setOpenSnackbar(true);
    } else {
      setSuccessMessage(responce.massege);
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container className="AddSubBatch-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <Link to={`/batch/${batchId}/subBatch`} className="link">
            All SubBatches
          </Link>
          <h4 variant="h4">Add New SubBatch</h4>
        </div>
      </div>

      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <InputLabel>Select Batch Type</InputLabel>
              <Select
                label="Select batch Type"
                value={subBatch_Name}
                onChange={(e) => setSubBatch_Name(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {courseCategorys &&
                  courseCategorys?.map((category) => (
                    <MenuItem
                      key={category.category_Id}
                      value={category.category_Name}
                    >
                      {category.category_Name}
                    </MenuItem>
                  ))}
              </Select>
            </div>

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </Container>
  );
};

export default AddSubBatch;
