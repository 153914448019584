import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getRecodedSubjectVideos } from "../../../../../../../Redux/ActionCompo/BatchActions";
import { useDispatch, useSelector } from "react-redux";
import { addrecodedSubjectVideo } from "../../../../../../../Redux/Reduuces/BatchReducer";
import SingleRecodedVideo from "./singleRecodedVideoComponent/SingleRecodedVideo";
import "../../../../../../../Genaral.css";
const AllSubjectRecodedVideoes = () => {
  const recodedSubjectVideo = useSelector(
    (state) => state.batchData.recodedSubjectVideo
  );
  const { subjectId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getRecodedSubjectVideos(subjectId);
      dispatch(addrecodedSubjectVideo(data));
    };
    getData();
  }, [subjectId, dispatch]);

  return (
    <div className="AllSubjectRecodedVideoes-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All videos </h4>
          <Link to={"addVideo"} className="link">
            Add New videos
          </Link>
        </div>
      </div>

      <div className="ALL-IMAGE-MAIN-CONTAINER">
        <div className="ALL-IMAGE-CONTAINER-heading">
          <h1>All videos</h1>
        </div>
        <div className="ALL-IMAGE-CONTAINER">
          {recodedSubjectVideo &&
            recodedSubjectVideo?.map((val, key) => {
              return <SingleRecodedVideo RecodedvideoData={val} key={key} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default AllSubjectRecodedVideoes;
