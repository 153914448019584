import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allWorkShops: [],
  workShopWeekStructures: [],
  workShopDayStructures: [],
  workShopProjects: [],
  workShopTools: [],
  workShopObjectives: [],
  workShopVideos: [],
  workShopImages: [],
  workShopBagroundImages: [],
  workShopToolImages: [],
  workShopDetails: [],
  addNewWorkShopDetails: [],
};

const WorkShopReducer = createSlice({
  name: "workShop",
  initialState: initialState,
  reducers: {
    AddallWorkShops: (state, action) => {
      state.allWorkShops = action.payload;
    },

    addworkShopWeekStructures: (state, action) => {
      state.workShopWeekStructures = action.payload;
    },
    addworkShopDayStructures: (state, action) => {
      state.workShopDayStructures = action.payload;
    },
    addworkShopProjects: (state, action) => {
      state.workShopProjects = action.payload;
    },
    addworkShopTools: (state, action) => {
      state.workShopTools = action.payload;
    },

    addworkShopObjectives: (state, action) => {
      state.workShopObjectives = action.payload;
    },
    addworkShopVideos: (state, action) => {
      state.workShopVideos = action.payload;
    },
    addworkShopImages: (state, action) => {
      state.workShopImages = action.payload;
    },
    addworkShopBagroundImages: (state, action) => {
      state.workShopBagroundImages = action.payload;
    },
    addworkShopToolImages: (state, action) => {
      state.workShopToolImages = action.payload;
    },
    addworkShopDetails: (state, action) => {
      state.workShopDetails = action.payload;
    },
    addaddNewWorkShopDetails: (state, action) => {
      state.addNewWorkShopDetails = action.payload;
    },
  },
});

export const {
  AddallWorkShops,
  addworkShopWeekStructures,
  addworkShopDayStructures,
  addworkShopTools,
  addworkShopProjects,
  addworkShopObjectives,
  addworkShopVideos,
  addworkShopImages,
  addworkShopBagroundImages,
  addworkShopToolImages,
  addworkShopDetails,
  addaddNewWorkShopDetails,
} = WorkShopReducer.actions;

export default WorkShopReducer.reducer;
