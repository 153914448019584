import { myaxios } from "../ApiBase";
import authHeader from "./auth-header";

export const addNewEvent = async (eventData) => {
  try {
    const response = await myaxios.post(`/events/add`, eventData, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const getEventData = async () => {
  try {
    const responce = await myaxios.get(`/events/get`, {
      headers: authHeader(),
    });

    return responce.data;
  } catch (error) {}
};

export const updateEventData = async (eventId, eventData) => {
  try {
    const responce = await myaxios.put(`/events/${eventId}`, eventData, {
      headers: authHeader(),
    });

    return responce.data;
  } catch (error) {}
};

export const deleteEventData = async (eventId) => {
  try {
    const response = await myaxios.delete(`/events/${eventId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

/// event type

export const addNewEventType = async (eventId, eventTypeData) => {
  try {
    const responce = await myaxios.post(
      `/events/${eventId}/eventTypes`,
      eventTypeData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

/// event type

export const addEventType = async (eventId, eventData) => {
  try {
    const response = await myaxios.post(
      `/events/${eventId}/eventTypes`,
      eventData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteEventType = async (eventTypeId) => {
  try {
    const response = await myaxios.delete(`events/eventTypes/${eventTypeId}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const updateEventType = async (eventTypeId, eventTypeData) => {
  try {
    const responce = await myaxios.put(
      `/events/eventTypes/${eventTypeId}`,
      eventTypeData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getEventType = async (eventId) => {
  try {
    const response = await myaxios.get(`/events/${eventId}/eventTypes`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

/// event Type image

export const addEventTypeImage = async (eventTypeId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/events/eventTypes/${eventTypeId}/images`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const deleteEventTypeImage = async (imageId) => {
  try {
    const responce = await myaxios.delete(
      `/events/eventTypes/image/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const updateEventTypeImage = async (imageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/events/eventTypes/image/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );

    return responce.data;
  } catch (error) {}
};

export const getEventTypeImage = async (eventTypeId) => {
  try {
    const responce = await myaxios.get(
      `/events/eventTypes/${eventTypeId}/images`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

// event type video

export const addEventTypeVideo = async (eventTypeId, videoData) => {
  try {
    const responce = await myaxios.post(
      `/events/eventTypes/${eventTypeId}/videos`,
      videoData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const deleteEventTypeVideo = async (videoId) => {
  try {
    const responce = await myaxios.delete(
      `/events/eventTypes/videos/${videoId}`,
      { headers: authHeader() }
    );
    return responce.data;
  } catch (error) {}
};

export const updateEventTypeVideo = async (videoId, videoData) => {
  try {
    const responce = await myaxios.put(
      `/events/eventTypes/videos/${videoId}`,
      videoData,
      { headers: authHeader() }
    );
    return responce.data;
  } catch (error) {}
};

export const getEventTypeVideo = async (eventTypeId) => {
  try {
    const responce = await myaxios.get(
      `/events/eventTypes/${eventTypeId}/videos`,
      { headers: authHeader() }
    );
    return responce.data;
  } catch (error) {}
};
