import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";

export const addNewCoreTeamMember = async (memberData) => {
  try {
    const responce = await myaxios.post(`/tmr/teamMembers`, memberData, {
      headers: authHeader(),
    });

    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteCoreTeamMember = async (memberId) => {
  try {
    const responce = await myaxios.delete(`/tmr/teamMembers/${memberId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllCoreTeamMember = async () => {
  try {
    const responce = await myaxios.get(`/tmr/teamMembers`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

///core team Member image

export const addNewCoreMemberImage = async (teamMemberId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/tmr/teamMembers/${teamMemberId}/images`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteCoreMemberImage = async (imageId) => {
  try {
    const responce = await myaxios.delete(
      `/tmr/teamMembers/images/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateCoreMemberImage = async (imageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/tmr/teamMembers/images/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllCoreMemberImage = async (teamMemberId) => {
  try {
    const responce = await myaxios.get(
      `/tmr/teamMembers/${teamMemberId}/images`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

///  main team member

export const addMainTeamMember = async (memberData) => {
  try {
    const responce = await myaxios.post(`/tmr/mainTeamMembers`, memberData, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteTMainTeamMember = async (memberId) => {
  try {
    const responce = await myaxios.delete(`/tmr/mainTeamMember/${memberId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateMainTeamMember = async (memberData) => {
  try {
    const responce = await myaxios.put(``, memberData, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllMainTeamMember = async () => {
  try {
    const responce = await myaxios.get(`/tmr/mainTeamMembers`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//// main team member Image

export const addMainTeamMemberImage = async (mainTeamMemberId, imageData) => {
  try {
    const responce = await myaxios.post(
      `/tmr/mainTeamMembers/${mainTeamMemberId}/images`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const deleteMainTeamMemberImage = async (imageId) => {
  try {
    const responce = await myaxios.delete(
      `/tmr/mainTeamMembers/images/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const updateMainTeamMemberImage = async (imageId, imageData) => {
  try {
    const responce = await myaxios.put(
      `/tmr/mainTeamMembers/images/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    return {
      status: false,
      massege: error.response.data,
    };
  }
};

export const getAllMainTeamMemberImage = async (mainTeamMemberId) => {
  try {
    const responce = await myaxios.get(
      `/tmr/mainTeamMembers/${mainTeamMemberId}/images`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
