import React, { useEffect } from "react";
import "./AllStudents.scss";
import { useSelector, useDispatch } from "react-redux";
import { addAllStudentToStore } from "../../Redux/Reduuces/StudentTeacherAdmineReducer";
import { getAllStudents } from "../../Redux/ActionCompo/StudentTeachetAdminActions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const AllStudents = () => {
  const students = useSelector((state) => state.studentTeacherData.allStudents);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllStudents();
      dispatch(addAllStudentToStore(data));
    };
    getData();
  }, [dispatch]);

  return (
    <div className="AllStudents-main-Container">
      <div className="AllStudents-table">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>STUDENT ID</TableCell>
                <TableCell>STUDENT NAME</TableCell>
                <TableCell>STUDENT EMAIL</TableCell>
                <TableCell>MOBILE</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Enroll Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students &&
                students?.map((val, key) => (
                  <TableRow key={key}>
                    <TableCell>{val.student_Id}</TableCell>
                    <TableCell>{val.student_Name}</TableCell>
                    <TableCell>{val.student_Email}</TableCell>
                    <TableCell>{val.student_MobileNum}</TableCell>
                    <TableCell>{val.student_Address}</TableCell>
                    <TableCell>{val.student_EnrolledStatus}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AllStudents;
