import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteLandingPage,
  getLandingPage,
} from "../../Redux/ActionCompo/LandingPageActions";
import { addlandingPagesToStore } from "../../Redux/Reduuces/LandingPageReducer";
const LandingPage = () => {
  const [order, setOrder] = useState(0);

  const dispatch = useDispatch();
  const landingPages = useSelector(
    (state) => state.landingPageData.landingPages
  );

  useEffect(() => {
    const getadata = async () => {
      const data = await getLandingPage();
      dispatch(addlandingPagesToStore(data));
    };
    getadata();
  }, [dispatch]);

  const deleteHandler = async (essential_Id) => {
    const responce = await deleteLandingPage(essential_Id);
    if (responce?.status === true) {
      window.location.reload();
    }
  };

  const navigate = useNavigate();

  return (
    <div style={{ width: "100%" }} className="FAQ-main-container">
      <div className="pageLinkHeader-main">
        <div className="pageLinkHeader-main-content">
          <h4>All landing page </h4>
          <Link to={"add"} className="link">
            Add New landing page 
          </Link>
        </div>
      </div>

      <TextField
        variant="outlined"
        type="number"
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      ></TextField>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI/NO</TableCell>
              <TableCell>LANDING PAGE</TableCell>
              <TableCell>LANDING PAGE DESC</TableCell>
              <TableCell>Details</TableCell>

              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {landingPages &&
              landingPages?.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{key + 1}</TableCell>
                  <TableCell>{val.TOPIC}</TableCell>
                  <TableCell>{val.TOPIC_DESC}</TableCell>
                  <TableCell
                    onClick={() => {
                      navigate(`${val.LANDING_PAGE_ID}/${val.TOPIC}/details`);
                    }}
                  >
                    Details
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        deleteHandler(val.LANDING_PAGE_ID);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LandingPage;
