import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  AuthStatus: false,

  trainerOtpRequestData: {},
};

const AuthReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthStatus(state, action) {
      state.AuthStatus = action.payload;
    },
    settrainerOtpRequestData(state, action) {
      state.trainerOtpRequestData = action.payload;
    },
  },
});
export const { setAuthStatus, settrainerOtpRequestData } = AuthReducer.actions;

export default AuthReducer.reducer;
